import React from 'react';

const TimeSwitch = ({ activeTime = 'all', onTimeChange }) => {
  const switchStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '300px',
    borderRadius: '20px',
    fontFamily: 'Arial, sans-serif',
    margin: '0 auto 10px',
  };

  const buttonStyle = (isActive) => ({
    padding: '8px 16px',
    border: 'none',
    color: isActive ? 'black' : '#747474',
    fontWeight: isActive ? 'bold' : 'normal',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '12px',
    background: 'none',
    textDecoration: isActive ? 'underline' : 'none',
  });

  return (
    <div style={switchStyle}>
      {[
        { label: 'ALL TIME', value: 'all' },
        { label: 'WEEKLY', value: 'weekly' },
        { label: 'DAILY', value: 'daily' },
      ].map(({ label, value }) => (
        <button
          key={value}
          style={buttonStyle(activeTime === value)}  // Set active style based on activeTime prop
          onClick={() => onTimeChange(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default TimeSwitch;
