import React, { useState } from 'react';
import { Tabs, rem, useMantineTheme } from '@mantine/core';
import LatestNews from '../../components/News/latestNews';
import TodaysQuestions from '../../components/News/todaysQuestions';
import SavedTab from '../../components/News/saved';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

export default function Breadcrumbs({ setActiveTab }) {
  const theme = useMantineTheme();
  const [activeTab, setActiveTabState] = useState('latest');
  const { colors } = useTheme();

  const tabLabels = {
    latest: 'Latest News',
    'todays-questions': "Today's Questions",
    saved: 'Saved',
  };

  const handleTabChange = (value) => {
    setActiveTabState(value);
    setActiveTab(value);
    const tabLabel = tabLabels[value];
    MixpanelEvent('news_tab_changed', { tab_name: tabLabel });
    sendPostHogEvent('news_tab_changed', { tab_name: tabLabel });
  };

  return (
    <>
      <style>
        {`
        .m_539e827b:where([data-active]) {
          border-color: #6374FA !important;
        }
      `}
      </style>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#fff',
          color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[9],
          position: 'absolute',
          top: 60,
          zIndex: 999,
          overflow: 'hidden',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          style={{
            width: '100%',
            backgroundColor: colors.cardBackground,
          }}
        >
          <Tabs.List
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-evenly',
            }}
          >
            <Tabs.Tab
              value="latest"
              style={({ active }) => ({
                fontSize: '15px',
                fontWeight: active ? 'bold' : 'normal',
                color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[9], // Conditional tab color
                backgroundColor: 'transparent',
              })}
            >
              Latest News
            </Tabs.Tab>
            <Tabs.Tab
              value="todays-questions"
              style={({ active }) => ({
                fontSize: '15px',
                fontWeight: active ? 'bold' : 'normal',
                color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[9], // Conditional tab color
                backgroundColor: 'transparent',
              })}
            >
              Daily news MCQs
            </Tabs.Tab>
            <Tabs.Tab
              value="saved"
              style={({ active }) => ({
                fontSize: '15px',
                fontWeight: active ? 'bold' : 'normal',
                color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[9], // Conditional tab color
                backgroundColor: 'transparent',
              })}
            >
              Saved
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="latest">
            <LatestNews />
          </Tabs.Panel>
          <Tabs.Panel value="saved">
            <SavedTab />
          </Tabs.Panel>
          <Tabs.Panel value="todays-questions">
            <TodaysQuestions />
          </Tabs.Panel>
        </Tabs>
      </div>
    </>
  );
}
