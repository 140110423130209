import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Box, Button, Text, Image } from '@mantine/core';
import { welcomeSliderStore } from '../../stores/welcomeSliderStore';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '../../assets/images';
import { slides } from '../../constants/slides';

const OnboardingPage = () => {
  const currentSlide = welcomeSliderStore((state) => state.currentSlide);
  const setCurrentSlide = welcomeSliderStore((state) => state.setCurrentSlide);
  const resetSliderState = welcomeSliderStore((state) => state.resetSliderState);

  const navigate = useNavigate();

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const getStarted = () => {
    navigate('/login');
    resetSliderState();
  };

  return (
    <Box style={{ height: '100vh', width: '100%' }}>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        selectedItem={currentSlide}
        onChange={(index) => setCurrentSlide(index)}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={false}
        autoPlay={false}
        dynamicHeight={false}
        renderIndicator={(clickHandler, isSelected, index, label) => {
          return (
            <li
              style={{
                background: isSelected ? '#070519' : '#D1D1D1',
                width: 34,
                height: 4,
                borderRadius: '50%',
                display: 'inline-block',
                margin: '0 6px',
                position: 'relative',
                top: '20px',
              }}
              onClick={clickHandler}
              onKeyDown={clickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {slides.map((slide, index) => (
          <Box
            key={index}
            style={{
              height: '100vh',
              backgroundColor: slide.svgBgColor[1],
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image w={'5em'} src={slide.icon || ''} alt="icon" />
            <Text align="center" weight={500} fz={'1.5em'} style={{ marginTop: '1em' }}>
              {slide.title}
            </Text>
            <Text align="center" weight={400} fz={'1em'} style={{ marginTop: '0.5em' }}>
              {slide.description}
            </Text>
            <Box mt={20}>
              <Image width="auto" src={slide.component} alt={`Slide ${index + 1}`} />
            </Box>
          </Box>
        ))}
      </Carousel>

      {/* Buttons */}
      <Box
        style={{
          position: 'absolute',
          bottom: 24,
          left: 16,
          right: 16,
          gap: '20px',
          display: 'flex',
          justifyContent: currentSlide === slides.length - 1 ? 'center' : 'space-between',
        }}
      >
        {currentSlide === slides.length - 1 ? (
          <Button
            style={{
              width: '75%',
              height: '3em',
              backgroundColor: '#070519',
              borderRadius: '10em',
              color: '#FFFFFF',
              fontWeight: 500,
              zIndex: 100,
            }}
            onClick={getStarted}
          >
            Get Started <Image src={ArrowRight} />
          </Button>
        ) : (
          <>
            <Button
              style={{
                fontWeight: 500,
                zIndex: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30%',
                height: '3em',
                backgroundColor: '#070519',
                borderRadius: '10em',
                color: '#FFFFFF',
              }}
              onClick={getStarted}
            >
              Skip
            </Button>
            <Button
              style={{
                width: '70%',
                height: '3em',
                backgroundColor: '#070519',
                borderRadius: '10em',
                color: '#FFFFFF',
                fontWeight: 500,
                zIndex: 100,
              }}
              onClick={handleNextSlide}
            >
              Next <Image src={ArrowRight} />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingPage;
