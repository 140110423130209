import React from 'react';
import { useTheme } from '../../context/themeProvider';
import { FireSteak, FireBackIcon, FireZero } from '../../assets/images';

const StreakProgress = ({ streak, setModalVisible }) => {
  const maxStreak = 30;
  const progress = (streak / maxStreak) * 100;
  const { colors } = useTheme();
  const thresholds = [10, 20, 30];

  const isStreakZero = streak === 0;

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.cardBackground,
      color: colors.cardText,
      borderRadius: '12px',
      padding: '0',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
      border: `1px solid ${colors.borderColor}`,
      width: '100%',
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    iconWrapper: {
      position: 'relative',
      width: '70px',
      height: '80px',
      marginRight: '15px',
    },
    fireBackIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    fireSteakIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '70%',
    },
    fireZeroIcon: {
      width: '70px',
      height: '80px',
      padding: '10px',
      marginRight: '15px',
    },
    count: {
      position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '16px',
    },
    info: {
      flex: 1,
      padding: '10px 10px 10px 0',
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },
    label: {
      margin: 0,
      fontSize: '16px',
      color: colors.text,
      lineHeight: '22.4px',
    },
    progressBarWrapper: {
      position: 'relative',
      height: '12px',
      backgroundColor: isStreakZero ? '#E5C29F' : '#FFF2DC', // Brownish background when disabled
      borderRadius: '50px',
      width: '95%',
      overflow: 'visible',
    },
    progressBar: {
      height: '100%',
      backgroundColor: isStreakZero ? '#946521' : '#FC9502', // Brownish color when disabled
      borderRadius: '50px',
      transition: 'width 0.3s ease-in-out',
      width: `${progress}%`,
      opacity: isStreakZero ? 0.7 : 1, // Slightly faded when disabled
    },
    dotsWrapper: {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: '100%',
      transform: 'translateY(-50%)',
    },
    dot: (threshold) => ({
      position: 'absolute',
      left: `${(threshold / maxStreak) * 100}%`,
      width: '20px',
      height: '20px',
      opacity: isStreakZero ? 0.4 : progress >= (threshold / maxStreak) * 100 ? 1 : 0.4,
      borderRadius: '50%',
      border: `2px solid ${
        isStreakZero
          ? '#946521' // Brownish border when disabled
          : progress >= (threshold / maxStreak) * 100
          ? '#946521'
          : '#FF7F50'
      }`,
      backgroundColor: isStreakZero ? '#E5C29F' : '#FFF2DC', // Brownish background when disabled
      transition: 'background-color 0.3s ease, border-color 0.3s ease',
      transform: 'translate(-50%, -50%)',
      top: '50%',
    }),
    arrowWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: isStreakZero ? 'not-allowed' : 'pointer',
      opacity: isStreakZero ? 0.5 : 1,
    },
    arrowIcon: {
      fontSize: '12px',
      color: '#3B82F6',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.iconWrapper}>
          {isStreakZero ? (
            <img src={FireZero} alt='' style={styles.fireZeroIcon} />
          ) : (
            <>
              <img src={FireBackIcon} alt='' style={styles.fireBackIcon} />
              <img src={FireSteak} alt='' style={styles.fireSteakIcon} />
              <span style={styles.count}>{streak}</span>
            </>
          )}
        </div>
        <div style={styles.info}>
          <div style={styles.topRow}>
            <p style={styles.label}>{isStreakZero ? 'No streak' : `${streak} day reading streak`}</p>
            <div
              style={styles.arrowWrapper}
              onClick={() => !isStreakZero && setModalVisible(true)}
            >
              <span style={styles.arrowIcon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.18005 6.08V12.31V17.92C8.18005 18.88 9.34005 19.36 10.0201 18.68L15.2001 13.5C16.0301 12.67 16.0301 11.32 15.2001 10.49L13.2301 8.52L10.0201 5.31C9.34005 4.64 8.18005 5.12 8.18005 6.08Z" fill="#6374FA"/>
                </svg>
              </span>
            </div>
          </div>
          <div style={styles.progressBarWrapper}>
            <div style={styles.progressBar}></div>
            <div style={styles.dotsWrapper}>
              {thresholds.map((threshold) => (
                <span key={threshold} style={styles.dot(threshold)}></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreakProgress;
