import React, { useState, useEffect } from 'react';
import { InfoHighlightImage, InfoHighlightImageDark } from '../../assets/images'; // Ensure these images are available
import { useTheme } from '../../context/themeProvider';

const InfoHighlight = ({ onGoBack }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = window.innerWidth <= 2500;

  // Use theme context
  const { colors, colorScheme } = useTheme(); // colorScheme determines if it's 'light' or 'dark'

  const closeModal = () => {
    setIsOpen(false);
    onGoBack(); 
  };

  const handleClickOutside = (event) => {
    if (event.target.className === 'modal-overlay') {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Select the appropriate image based on the theme
  const selectedImage = colorScheme === 'dark' ? InfoHighlightImageDark : InfoHighlightImage;

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" style={{ ...styles.modalOverlay, backgroundColor: colors.overlay }} onClick={handleClickOutside}>
          <div style={{ 
            ...styles.modalContainer, 
            maxWidth: isMobile ? '28rem' : '22vw', 
            backgroundColor: colorScheme === 'dark' ? '#303034' : 'white' // Set modal background color
          }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <span style={{ ...styles.topLine, background: colorScheme === 'dark' ? '#5D5D5D' : '#E8E8E8' }} onClick={closeModal}></span> {/* Close modal on click */}
            </div>
            <h2 style={{ ...styles.heading, color: colors.text }}>Create Notes</h2>
            <p style={{ ...styles.subText, color: colors.subText, maxWidth: '100%', marginLeft: '30px', marginRight: '30px' }}>
              Long hold on the summary points to save them in the ‘My Notes’ for future reference.
            </p>
            <img src={selectedImage} alt="Thank You Illustration" style={styles.backgroundImage} />

            <button 
              onClick={closeModal} 
              style={{ 
                ...styles.completeButton, 
                backgroundColor: colorScheme === 'dark' ? 'white' : 'black', 
                color: colorScheme === 'dark' ? '#070519' : 'white', 
              }}
            >
              Got it
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '20px',
  },
  subText: {
    fontSize: '1rem',
    marginBottom: '20px',
  },
  backgroundImage: {
    marginBottom: '20px',
    maxWidth: '100%', // Ensure the image doesn't exceed the modal width
    height: 'auto', // Maintain aspect ratio
  },
  completeButton: {
    padding: '10px',
    borderRadius: '10px',
    border: '2px solid black',
    width: '100%',
    cursor: 'pointer',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default InfoHighlight;
