import React, { useState, useRef, useEffect } from 'react';
import { ArrowLeft, ArrowDown, HelpCircle } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../constants/iconData';
import InfoTips from './InfoTip/DuelStateInfoTip';
import LeaderBoard from './LeaderBoads';
import MyStats from './MyStats';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function DuelPage() {
  const [activeTab, setActiveTab] = useState('leaderboards'); // Set default to 'leaderboards'
  const [selectedSubject, setSelectedSubject] = useState('polity'); // Store as lowercase
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showInfoTips, setShowInfoTips] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);

  const backClick = () => {
    navigate(-1);
    MixpanelEvent('navigation_back', { source_screen: 'duel_stats' });
    sendPostHogEvent('navigation_back', { source_screen: 'duel_stats' });
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject.toLowerCase());
    setIsDropdownOpen(false);
  };

  const handleInfoTip = () => {
    MixpanelEvent('info_tip', { type: 'duel_stats', source: 'clicked' });
    sendPostHogEvent('info_tip', { type: 'duel_stats', source: 'clicked' });
    setShowInfoTips(true);
  };

  const closeModal = () => {
    MixpanelEvent('screen_closed', { source_screen: 'duel_stats', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'duel_stats', close_type: 'modal' });
    setShowInfoTips(false);
  };

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const subjects = [
    { value: 'csat', label: 'CSAT' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const displaySubject = subjects.find((subject) => subject.value === selectedSubject)?.label || 'Subject';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div style={{ maxWidth: '28em', margin: '0 auto', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '3.9em',
          padding: '10px 0',
          backgroundColor: 'white',
          color: 'black',
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '160px' }}>
          <img onClick={backClick} src={ArrowLeft} alt="Arrow Left" style={{ cursor: 'pointer', marginLeft: '1em' }} />
          <span style={{ fontSize: '16px', marginLeft: '0.5em', display: 'flex', alignItems: 'center' }}>
            Duel Stats
            <img
              src={HelpCircle}
              alt="Help icon"
              style={{ marginLeft: '8px', width: '20px', cursor: 'pointer', height: '20px' }}
              onClick={handleInfoTip}
            />
          </span>
        </div>

        {/* Dropdown */}
        <div ref={dropdownRef} style={{ position: 'relative', marginRight: '1em', width: '300px' }}>
          <div
            onClick={toggleDropdown}
            style={{
              fontSize: '1em',
              borderRadius: '4px',
              backgroundColor: 'white',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <span>{displaySubject}</span> {/* Use predefined displaySubject from array */}
            <img src={ArrowDown} style={{ marginLeft: '8px', opacity: '0.7' }} alt="arrow down" />
          </div>
          {isDropdownOpen && (
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                border: '1px solid #E0E0E0',
                borderRadius: '4px',
                zIndex: 10,
                width: '100%',
                minHeight: '360px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                top: 'calc(100% + 5px)',
                left: 0,
              }}
            >
              {Object.entries(icons)
                .filter(([key]) => key !== 'all_subjects')
                .map(([key, icon]) => (
                  <div
                    key={key}
                    onClick={() => handleSubjectChange(key)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '12px',
                      backgroundColor: selectedSubject === key ? 'white' : 'transparent',
                    }}
                  >
                    <div style={{ marginRight: '12px', width: '24px', height: '24px' }}>{icon}</div>
                    <span style={{ flex: 1, marginLeft: '3px', marginTop: '10px' }}>
                      {subjects.find((subject) => subject.value === key)?.label || key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                    </span>
                    {selectedSubject === key && (
                      <div
                        style={{
                          marginLeft: '12px',
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: '#6374FA',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            position: 'absolute',
                          }}
                        />
                        <div
                          style={{
                            color: 'white',
                            position: 'relative',
                            zIndex: 1,
                          }}
                        >
                          ✓
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {/* Tab Navigation */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#fff',
          color: '#000',
          position: 'absolute',
          top: 60,
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        {/* Tabs */}
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          <div
            onClick={() => setActiveTab('leaderboards')}
            style={{
              fontSize: '12px',
              color: activeTab === 'leaderboards' ? '#000' : '#747474',
              cursor: 'pointer',
              padding: '10px 20px',
              position: 'relative',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Leaderboards</span>
            {activeTab === 'leaderboards' && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#6374FA',
                }}
              />
            )}
          </div>
          <div
            onClick={() => setActiveTab('mystats')}
            style={{
              fontSize: '12px',
              color: activeTab === 'mystats' ? '#000' : '#747474',
              cursor: 'pointer',
              padding: '10px 20px',
              position: 'relative',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>My Stats</span>
            {activeTab === 'mystats' && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#6374FA',
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ paddingTop: '120px' }}>
        {activeTab === 'leaderboards' && <LeaderBoard selectedSubject={selectedSubject} userId={userData.user} />}
        {activeTab === 'mystats' && <MyStats selectedSubject={selectedSubject} userId={userData.user} />}
      </div>
      <InfoTips opened={showInfoTips} onClose={closeModal} />
    </div>
  );
}
