import React, { useState, useEffect, useRef } from 'react';
import { CrownGeneral, VirsusBlue } from '../../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const Matching = () => {
  const location = useLocation();
  const topic = location.state?.topic;
  const userData = userStore((state) => state.userData);
  const userId = userData.user;

  const [matchStatus, setMatchStatus] = useState('searching');
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const socketRef = useRef(null);
  const [opponent, setOpponent] = useState({});
  const [bot, setBot] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [startTime, setStartTime] = useState(null);
  const endTime = Date.now();
  let loadingTime = Math.round((endTime - startTime) / 1000); // Calculate loading time in seconds
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

  useEffect(() => {
    const matchingURL = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/matchmaking/${userId}/?category=${topic}`;
    console.log(matchingURL, 'Connecting to WebSocket...');

    socketRef.current = new WebSocket(matchingURL);
    let findMatchAttempts = 0;
    let findMatchInterval;

    const sendFindMatchRequest = () => {
      if (Object.keys(bot).length > 0 || Object.keys(opponent).length > 0) {
        clearInterval(findMatchInterval);
      } else {
        if (findMatchAttempts < 5) {
          if (socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ find_match: true }));
            findMatchAttempts++;
            setProgress((prevProgress) => Math.min(prevProgress + 20, 100));
          }
        } else {
          clearInterval(findMatchInterval);
          if (socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ fetch_bot: true }));
          }
        }
      }
    };

    socketRef.current.onopen = () => {
      console.log('WebSocket connection established');
      setStartTime(Date.now());
      findMatchInterval = setInterval(sendFindMatchRequest, 1000);
    };

    socketRef.current.onmessage = (e) => {
      if (e && e.data) {
        let parsedJson = JSON.parse(e.data);
        console.log('matching onmessage parsedJson', parsedJson);
        if (Object.keys(bot).length > 0 || Object.keys(opponent).length > 0) {
          console.log('already matched');
        } else {
          if (parsedJson.status === true) {
            setMatchStatus('found');
            if (parsedJson.bot === true) {
              setBot({ ...parsedJson.data, room_id: parsedJson.room_id });
            } else {
              setOpponent(parsedJson.data);
            }
            if (parsedJson.current_user) {
              setCurrentUser(parsedJson.current_user);
            }
            socketRef.current.close();
          }
        }
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      clearInterval(findMatchInterval);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [userId, topic]);

  useEffect(() => {
    if (matchStatus === 'found') {
      const competitorType = Object.keys(bot).length > 0 ? 'bot' : 'real';

      MixpanelEvent('duel_start', {
        category: topic,
        competitor_type: competitorType,
        mode: 'online',
        loading_time: loadingTime,
      });
      sendPostHogEvent('duel_start', {
        category: topic,
        competitor_type: competitorType,
        mode: 'online',
        loading_time: loadingTime,
      });
      const timer = setTimeout(() => {
        if (socketRef.current) {
          socketRef.current.close();
        }
        navigate('/duel-start', {
          state: {
            bot: bot,
            opponent: opponent,
            currentUser: currentUser,
            topic: topic,
            room_id: bot.room_id || opponent.room_id,
          },
        });
      }, 3000); // Wait for 3 seconds before navigating

      return () => clearTimeout(timer);
    }
  }, [matchStatus, bot, opponent, startTime, topic, navigate, currentUser]);

  const handleCancel = () => {
    MixpanelEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'pre_initiate',
    });
    sendPostHogEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'pre_initiate',
    });
    if (socketRef.current) {
      socketRef.current.close();
    }
    navigate('/duel');
  };

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.subtitle, marginTop: isResponsiveMobiles ? '1em' : '2.5em' }}>ONLINE DUEL</div>
      <div style={styles.title}>{matchStatus === 'searching' ? 'Searching for opponent' : 'Match Found'}</div>

      <div style={styles.matchBox}>
        <div style={styles.userBox}>
          <div style={styles.avatar}>
            {currentUser.photo ? (
              <img src={currentUser.photo} alt="User" style={styles.avatarImg} />
            ) : (
              <div style={{ ...styles.defaultImage, backgroundColor: '#E1EBFF', color: '#555' }}>{getInitial(currentUser.name)}</div>
            )}
            <span style={styles.meBadge}>Me</span>
          </div>
          <p style={styles.userName}>{currentUser.name || userData.name}</p>
          {currentUser.elo ? (
            <div style={styles.eloContainer}>
              <img src={CrownGeneral} alt="Crown" style={styles.crownImg} />
              <p style={styles.userElo}>{currentUser.elo || 'N/A'}</p>
            </div>
          ) : null}
        </div>

        <div style={styles.vsIconContainer}>
          <img src={VirsusBlue} alt="VS" style={{ color: 'blue' }} />
        </div>

        <div style={styles.userBox}>
          {matchStatus === 'searching' ? (
            <>
              <div style={styles.avatar}>
                <div style={{ ...styles.defaultImage, backgroundColor: '#E1EBFF', color: '#555' }}>-</div>
              </div>
              <p style={styles.searchingText}>Searching for Opponent...</p>
              <div style={styles.progressBarContainer}>
                <div style={{ ...styles.progressBar, width: `${progress}%` }} />
              </div>
            </>
          ) : (
            <>
              <div style={styles.avatar}>
                {opponent.photo ? (
                  <img src={opponent.photo} alt="User" style={styles.avatarImg} />
                ) : (
                  <div
                    style={{
                      ...styles.defaultImage,
                      backgroundColor: '#E1EBFF',
                      color: '#555',
                    }}
                  >
                    {getInitial(opponent.name || bot.name)}
                  </div>
                )}
              </div>
              <p style={styles.userName}>{opponent.name || bot.name || 'Opponent'}</p>
              <div style={styles.eloContainer}>
                <img src={CrownGeneral} alt="Crown" style={styles.crownImg} />
                <p style={styles.userElo}>{opponent.elo || bot.elo || 'N/A'}</p>
              </div>
            </>
          )}
        </div>
      </div>

      <div style={{ ...styles.duelTips, marginBottom: isResponsiveMobiles ? '3em' : '0em', paddingTop: isResponsiveMobiles ? '1em' : '3em' }}>
        <h3 style={styles.tipsTitle}>Duel Tips</h3>
        <p style={styles.tipsText}>Read the questions carefully: Take your time to thoroughly read each question before selecting an answer.</p>
      </div>

      <button style={matchStatus === 'found' ? styles.loadingButton : styles.cancelButton} onClick={handleCancel} disabled={matchStatus === 'found'}>
        {matchStatus === 'found' ? (
          <>
            <span className="spinner" style={styles.spinner}></span>
            Loading Duel...
          </>
        ) : (
          'Cancel'
        )}
      </button>
    </div>
  );
};

// Styles for the Matching component
const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100dvh',
    backgroundColor: '#fff',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  containerScrollHide: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  subtitle: {
    color: '#747474',
    fontWeight: '500',
    fontSize: '14px',
    marginBottom: '0rem',
  },
  title: {
    margin: '5px 0',
    fontWeight: 'bold',
    fontSize: '22px',
    color: '#000',
  },
  matchBox: {
    display: 'flex',
    flexDirection: 'column', // Changed to column for vertical alignment
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '16px',
    backgroundColor: '#EBF3FF',
    margin: '25px 0',
    width: '90%',
  },
  userBox: {
    textAlign: 'center',
    margin: '10px 0', // Added margin for vertical spacing
    position: 'relative',
    width: '100%',
  },
  avatar: {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    margin: '0 auto',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '25%',
  },
  meBadge: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    backgroundColor: '#6374FA',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '16px',
    fontSize: '12px',
  },
  userName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
    margin: '0.6em 0em',
  },
  userElo: {
    fontSize: '1.3em',
    color: '#070519',
    margin: '0px',
  },
  vsIconContainer: {
    margin: '20px 0', // Space between users and VS
  },
  vsText: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#6200ea',
    padding: '10px 20px',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  searchingText: {
    fontSize: '14px',
    color: 'black',
  },
  duelTips: {
    width: '90%',
    maxWidth: '300px',
  },
  tipsTitle: {
    margin: '0px',
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '1em',
  },
  tipsText: {
    color: '#747474',
    fontSize: '0.8',
    textAlign: 'center',
    letterSpacing: '0.1em',
    lineHeight: '1.4em',
  },
  cancelButton: {
    padding: '12px 24px',
    borderRadius: '24px',
    backgroundColor: 'white',
    color: 'black',
    border: '2px solid black',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px',
    position: 'absolute',
    bottom: '1rem',
    width: '90%',
  },
  loadingButton: {
    padding: '24px',
    backgroundColor: '#6374FA',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  spinner: {
    border: '4px solid #ffffff',
    borderTop: '4px solid #6374FA',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    animation: 'spin 1s linear infinite',
  },
  progressBarContainer: {
    width: '100%',
    height: '6px',
    backgroundColor: '#E0E0E0',
    borderRadius: '3px',
    overflow: 'hidden',
    marginTop: '8px',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#6200ea',
    transition: 'width 0.3s linear',
  },
  crownImg: {
    width: '32px',
  },
  eloContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.4em',
  },
  defaultImage: {
    fontSize: '26px',
    fontWeight: 'bold',
    width: '100%',
    height: '100%',
    borderRadius: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Matching;
