export const CustomDivider = ({ style }) => (
  <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0', width: '100%', ...style }}>
    {/* Left Dot */}
    <div
      style={{
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#DCDCDC',
      }}
    />
    {/* Left Divider Line */}
    <div style={{ flex: 1, borderBottom: '1px solid #DCDCDC', marginRight: '8px' }} />
    {/* Bold OR */}
    <span style={{ fontWeight: 'bold', color: '#747474' }}>OR</span>
    {/* Right Divider Line */}
    <div style={{ flex: 1, borderBottom: '1px solid #DCDCDC', marginLeft: '8px' }} />
    {/* Right Dot */}
    <div
      style={{
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#DCDCDC',
      }}
    />
  </div>
);
