import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, CrownGeneral, DuelShare, Share, DuelOnlineResultVs } from '../../../assets/images';
import { IconCheck, IconX, IconPlus, IconMinus } from '@tabler/icons-react';
import { callPOSTAPI } from '../../../utils/NetworkUtil';
import branch from 'branch-sdk';
import { Loader } from '@mantine/core';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const DuelResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    hasExited,
    duel = {},
    botElo = {},
    userProfile = {},
    botProfile = {},
    userEloChange = 0,
    botEloChange = 0,
    userAnswerStatus = [],
    opponentAnswerStatus = [],
  } = location.state || {};

  const userCorrectAnswers = userAnswerStatus.filter((answer) => answer === true).length;
  const opponentCorrectAnswers = opponentAnswerStatus.filter((answer) => answer === true).length;

  const opponentData = {
    name: botProfile.name || duel.opponent_info?.name || '',
    elo: botProfile.elo || duel.opponent_info?.elo || 0,
    photo: botProfile.photo || null,
  };
  const isUserWinner = userCorrectAnswers > opponentCorrectAnswers;
  const isTie = userCorrectAnswers === opponentCorrectAnswers;
  const [loading, setLoading] = useState(false);
  const [showUnableRematch, setShowUnableRematch] = useState(false);
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
  const [shareImg, setShareImg] = useState('');

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  useEffect(() => {
    if (!hasExited && isUserWinner) {
      handleVictoryShare();
    }
  }, [hasExited, isUserWinner]);

  const handleVictoryShare = async () => {
    const payload = JSON.stringify({
      userName: userProfile.name,
      opponentUserName: duel.opponent_info?.name || '',
      userElo: userProfile.elo,
      opponentElo: duel.opponent_info?.elo || 0,
      category: duel.category,
    });

    try {
      await callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/create-svg-duel/`,
        payload,
        (resp) => {
          console.log(resp.data.link);
          setShareImg(resp.data.link);
        },
        (err) => {
          console.error('API error:', err);
        }
      );
    } catch (error) {
      console.error('Error posting duel data:', error);
    }
  };

  const buildDeeplink = async () => {
    MixpanelEvent('duel_share_clicked', {});
    sendPostHogEvent('duel_share_clicked', {});
    const domain = window.location.origin;
    const linkData = {
      channel: 'web',
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        $og_redirect: domain,
        $fallback_url: domain,
        $og_image_url: shareImg,
        navigate_to: 'duel/',
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
      } else {
        if (link) {
          shareImage(link);
        }
      }
    });
  };

  const shareImage = async (link) => {
    if (link && navigator.canShare) {
      try {
        await navigator.share({
          title: 'Learn as you play—Install PadhAI app.',
          text: `Feel like you're wasting too much time playing mobile games? Try the Online Duels instead where you can play against UPSC aspirants from all over India. Play, compete, and learn.`,
          url: link,
        });
        console.log('Shared successfully');
      } catch (error) {
        console.error('Sharing failed:', error);
      }
    } else {
      console.error('Sharing not supported or link is missing');
    }
  };

  const rematch = async () => {
    setLoading(true);
    setShowUnableRematch(false);
    let payload = {};

    if (botProfile.name) {
      payload = {
        current_id: userProfile.user,
        category: duel.category,
        game_with_bot: true,
        bot_data: {
          bot_data: {
            name: botProfile.name,
            elo: botElo,
            id: botProfile.id,
          },
          render_type: 'text',
        },
      };
    } else {
      payload = {
        current_id: userProfile.user,
        friend_id: duel.opponentId,
        category: duel.category,
        game_with_bot: false,
      };
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/rematch/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.start_game && data.room) {
          const navigationData = {
            bot: data.bot_data
              ? {
                  name: data.bot_data.bot_data.name,
                  elo: data.bot_data.bot_data.elo,
                  id: data.bot_data.bot_data.id,
                  room_id: data.room,
                }
              : null,
            currentUser: {
              name: data.current_user.name,
              elo: data.current_user.elo,
              photo: data.current_user.photo,
              user: data.current_user.user,
            },
            topic: duel.category,
            room_id: data.room,
            opponent: !data.bot_data
              ? {
                  name: duel.opponentName,
                  elo: duel.opponentElo,
                  id: duel.opponentId,
                  room_id: data.room,
                }
              : null,
          };
          setTimeout(() => {
            setLoading(false);
            navigate('/duel-start', { state: navigationData });
          }, 3000);
        } else {
          setLoading(false);
          setShowUnableRematch(true);
        }
      } else {
        setLoading(false);
        setShowUnableRematch(true);
        console.error('Failed to create rematch:', response.statusText);
      }
    } catch (error) {
      setLoading(false);
      setShowUnableRematch(true);
      console.error('An error occurred while trying to start the rematch:', error);
    }
  };
  const handleTabClick = () => {
    console.log(duel.category, 'duel.category');
    MixpanelEvent('duel_review_clicked', {
      category: duel.category,
      source: 'post_game',
      mode: 'online',
    });
    sendPostHogEvent('duel_review_clicked', {
      category: duel.category,
      source: 'post_game',
    });
    navigate('/duel-review', { state: { duel } });
  };

  const UserCard = ({ user, isWinner, score, answerStatus }) => {
    const bgColorHeader = hasExited ? '#F3F3F3' : isWinner ? '#61BD85' : isTie ? '#F3F3F3' : '#FFEDED';

    const textColor = hasExited ? '#747474' : isWinner ? '#fff' : isTie ? '#747474' : '#333';

    const scoreColor = hasExited ? '#000' : isWinner ? '#fff' : isTie ? '#000' : '#D95353';

    const displayScore = Math.abs(score);
    const isPositiveScore = score >= 0;
    return (
      <div style={styles.card}>
        <div style={{ ...styles.cardHeader, backgroundColor: bgColorHeader }}>
          <div style={styles.userInfo}>
            {user.photo ? (
              <img src={user.photo} alt="User" style={styles.avatar} />
            ) : (
              <div style={{ ...styles.defaultImage, backgroundColor: 'rgb(189, 203, 255)', color: '#000' }}>{getInitial(user.name)}</div>
            )}
            <span style={{ ...styles.userName, color: textColor }}>{user.name}</span>
          </div>
          <div style={styles.scoreInfo}>
            <div style={{ ...styles.scoreIcon, borderColor: isWinner ? '#61BD85' : isTie ? '#FFD700' : '#FFB6B6' }}>
              {isPositiveScore ? <IconPlus size={18} stroke={2} color={scoreColor} /> : <IconMinus size={18} stroke={2} color={scoreColor} />}
            </div>
            <img src={CrownGeneral} alt="Crown" style={styles.crownImg} />
            <span style={{ ...styles.score, color: scoreColor }}>{displayScore}</span>
          </div>
        </div>
        <div style={styles.answerGrid}>
          {answerStatus.slice(0, 5).map((status, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
              <div
                style={{
                  ...styles.answerIndicator,
                  backgroundColor: status === null ? 'trans' : status ? '#61BD85' : '#FFB6B6',
                }}
              >
                {status === null ? (
                  <span style={{ color: '#747474' }}>―</span>
                ) : status ? (
                  <IconCheck color="#fff" size={16} />
                ) : (
                  <IconX color="#fff" size={16} />
                )}
              </div>
              {index !== 4 && <div style={styles.divider} />}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Flex align="center" style={{ padding: '1.2em 0em', backgroundColor: '#fff' }}>
        <img src={ArrowLeft} alt="Arrow Left" style={{ cursor: 'pointer', marginLeft: '1em' }} onClick={() => navigate('/duel')} />
        <Text fz="xl" style={{ marginLeft: '0.5em' }}>
          Exit results
        </Text>
      </Flex>
      <div style={styles.container}>
        <div style={styles.subtitle}>ONLINE DUEL</div>
        <div style={styles.title}>{hasExited ? 'Duel Aborted!' : isUserWinner ? 'Victory!' : isTie ? "It's a Draw!" : 'Defeat!'}</div>

        <UserCard user={userProfile} isWinner={!hasExited && isUserWinner} score={userEloChange} answerStatus={userAnswerStatus} />

        <div style={styles.vsContainer}>
          <span style={styles.versusDivider}>
            <img src={DuelOnlineResultVs} alt="VS" style={styles.vsIcon} />
          </span>
        </div>

        <UserCard user={opponentData} isWinner={!hasExited && !isUserWinner && !isTie} score={botEloChange} answerStatus={opponentAnswerStatus} />

        {!hasExited ? (
          <div style={styles.buttonContainer}>
            {Object.keys(botProfile).length > 0 && (
              <button style={styles.rematchButton} onClick={rematch} disabled={loading}>
                {loading ? <Loader size="sm" /> : 'Rematch'}
              </button>
            )}
            <button style={styles.reviewButton} onClick={() => handleTabClick(navigate)}>
              Duel Review
            </button>
          </div>
        ) : (
          <div style={styles.buttonContainer}>
            <button style={styles.exitButton} onClick={() => navigate('/duel')}>
              Exit Results
            </button>
          </div>
        )}

        {showUnableRematch && <div style={styles.unablerematch}>We are unable to set up a rematch against your opponent. Please try again.</div>}

        {!hasExited && isUserWinner && (
          <div style={{ ...styles.shareContent, marginBottom: isResponsiveMobiles ? '3em' : '0em' }}>
            <div style={styles.shareHeader}>
              <div>
                <div style={styles.headerTitle}>Spread the excitement </div>
                <div style={styles.headerSubtitle}>Share the duel results with your friends! </div>
              </div>
              <div style={styles.shareButton} onClick={buildDeeplink}>
                <img src={Share} alt="ShareButton" />
              </div>
            </div>
            <div>
              <img src={shareImg} alt="Share Image" style={styles.shareImage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    height: '92vh',
  },
  subtitle: {
    fontSize: '18px',
    textAlign: 'center',
    color: '#747474',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#070519',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    overflow: 'hidden',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#D95353',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    fontSize: '18px',
  },
  defaultImage: {
    fontSize: '24px',
    fontWeight: 'bold',
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    textAlign: 'center',
    marginRight: '10px',
  },
  userName: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  scoreInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreIcon: {
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '2px',
  },
  crownImg: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  score: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  answerGrid: {
    display: 'flex',
    padding: '15px',
    backgroundColor: '#FAFDFF',
  },
  answerIndicator: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '16px',
  },
  vsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #E8E8E8',
    width: '100%',
    position: 'relative',
    margin: '2.4em 0em',
  },
  vsIcon: {
    width: 'auto',
  },
  versusDivider: {
    backgroundColor: '#fff',
    padding: '0 10px',
    position: 'absolute',
    top: '-16px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#E8E8E8',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.9em 0em',
    gap: '10px',
  },
  reviewButton: {
    padding: '1.2em',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: '#070519',
    color: '#fff',
    width: '50%',
    cursor: 'pointer',
  },
  rematchButton: {
    padding: '1.2em',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: '1px solid black',
    backgroundColor: 'white',
    color: 'black',
    width: '30%',
    cursor: 'pointer',
  },
  exitButton: {
    padding: '1.2em',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: '1px solid black',
    backgroundColor: 'transparent',
    color: 'black',
    width: '50%',
    cursor: 'pointer',
  },
  unablerematch: {
    color: 'red',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 600,
    margin: '12px',
  },
  divider: {
    width: '1px',
    height: '26px',
    backgroundColor: '#E8E8E8',
    marginLeft: '2em',
  },
  shareContent: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
  },
  headerTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#070519',
  },
  headerSubtitle: {
    fontSize: '14px',
    color: '#747474',
  },
  shareButton: {
    cursor: 'pointer',
  },
  shareImage: {
    width: '100%',
  },
};

export default DuelResults;
