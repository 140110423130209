// MatchGame.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowRight } from '@tabler/icons-react';
import { icons } from '../../../constants/iconData'; // Ensure this is the correct path to your iconData
import { ScrollArea } from '@mantine/core';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const subjects = [
  { value: 'csat', label: 'CSAT' },
  { value: 'history', label: 'History' },
  { value: 'geography', label: 'Geography' },
  { value: 'polity', label: 'Polity' },
  { value: 'current_affairs', label: 'Current Affairs' },
  { value: 'economy', label: 'Economy' },
  { value: 'science', label: 'Science' },
  { value: 'environment', label: 'Environment' },
];

const MatchGame = () => {
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState('');
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

  const handleBeginDuel = () => {
    MixpanelEvent('initiate_duel', { category: selectedTopic, mode: 'online' });
    sendPostHogEvent('initiate_duel', { category: selectedTopic, mode: 'online' });
    navigate('/matching', { state: { topic: selectedTopic } });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: isResponsiveMobiles ? '94vh' : '100vh',
        padding: isResponsiveMobiles ? '25px' : '16px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', margin: isResponsiveMobiles ? '10px 0em' : '1em 0em' }}>
        <h5 style={{ color: '#747474', margin: '0' }}>ONLINE DUEL</h5>
        <h2 style={{ fontSize: '24px', fontWeight: 'bold', margin: '0' }}>Select Topic</h2>
      </div>

      <ScrollArea style={{ flexGrow: 1, marginBottom: '16px', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {subjects.map((option) => (
            <label
              key={option.value}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px 16px',
                borderRadius: '8px',
                cursor: 'pointer',
                backgroundColor: selectedTopic === option.value ? '#EBF3FF' : 'white',
                border: selectedTopic === option.value ? 'none' : '1px solid #E8E8E8',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {icons[option.value] && <div style={{ marginRight: '8px' }}>{icons[option.value]}</div>}
                <span style={{ fontSize: '16px' }}>{option.label}</span>
              </div>
              <input
                type="radio"
                name="topic"
                value={option.value}
                checked={selectedTopic === option.value}
                onChange={() => setSelectedTopic(option.value)}
                style={{ transform: 'scale(1.5)' }}
              />
            </label>
          ))}
        </div>
      </ScrollArea>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto' }}>
        <button
          onClick={handleGoBack}
          style={{
            padding: '12px 24px',
            borderRadius: '24px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid black',
            cursor: 'pointer',
          }}
        >
          Go back
        </button>
        <button
          onClick={handleBeginDuel}
          disabled={!selectedTopic}
          style={{
            padding: '12px 24px',
            borderRadius: '24px',
            backgroundColor: 'black',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Begin Duel
          <IconArrowRight style={{ marginLeft: '8px' }} />
        </button>
      </div>
    </div>
  );
};

export default MatchGame;
