import React, { useState } from 'react';
import { Modal, Button, Group, Card, Text, Title, CloseButton } from '@mantine/core';
import { ArrowLeftFilled, ArrowRightFilled, PyqInfo, PyqInfo1, PyqInfo2, PyqInfo3, PyqInfo4, SendIcon } from '../../assets/images';

const InfoTips = ({ opened = true, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    { imageUrl: PyqInfo, step: null },
    { imageUrl: PyqInfo1, step: 'Step 1', description: 'Type your query' },
    { imageUrl: PyqInfo2, step: 'Step 2', description: 'We find PYQs for you.' },
    { imageUrl: PyqInfo3, step: 'Step 3', description: 'Get PYQs with your search query keywords' },
    { imageUrl: PyqInfo4, step: 'Step 4', description: 'Find PYQs based on the meaning of your Search query' },
  ];

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="sm"
      zIndex='9999999999'
      radius='10px'
      withCloseButton={false}
      styles={{
        header: {
          padding: '20px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        title: {
          textAlign: 'center',
        },
      }}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title order={4} style={{ marginLeft: '30px',marginRight: '20px' }}>
            Meaning-Based PYQ Search
          </Title>
          <CloseButton
            onClick={onClose}
            style={{
              marginLeft: '20px',
            }}
          />
        </div>
      }
    >
      <Card
        radius="md"
        p="lg"
        mt={slides[currentSlide].step ? 'md' : 0}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {slides[currentSlide].step && (
          <div
            style={{
              border: '1px solid var(--mantine-color-gray-3)',
              borderRadius: '8px',
              padding: '10px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Title order={4}>{slides[currentSlide].step}</Title>
            <Text size="auto">
              {slides[currentSlide].description}
            </Text>
          </div>
        )}
        <img
          src={slides[currentSlide].imageUrl}
          alt={`Slide ${currentSlide + 1}`}
          width='100%'
        />
      </Card>

      <Group position="center" mt="xl">
        <Button variant="outline" color="black" onClick={prevSlide} style={{ marginRight: 'auto' }} disabled={currentSlide === 0}>
          <img src={ArrowLeftFilled} alt="Arrow left" style={{ width: '20px' }} />
        </Button>

        <Group spacing={8}>
          {slides.map((_, index) => (
            <div
              key={index}
              style={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: index === currentSlide ? 'var(--mantine-color-blue-6)' : 'var(--mantine-color-gray-3)',
              }}
            />
          ))}
        </Group>

        {currentSlide < slides.length - 1 ? (
          <Button variant="outline" color="black" onClick={nextSlide} style={{ marginLeft: 'auto' }}>
            <img src={ArrowRightFilled} alt="Arrow right" style={{ width: '20px' }} />
          </Button>
        ) : (
          <Button variant="outline" color="white" style={{ marginLeft: 'auto', background: '#070519', borderRadius: '6px' }} onClick={onClose}>
            Check out
            <img style={{marginLeft: '4px'}} src={SendIcon} alt='send' />
          </Button>
        )}
      </Group>
    </Modal>
  );
};

export default InfoTips;