import React, { useState, useRef } from 'react';
import { Box, Flex, Button, Text, Notification } from '@mantine/core';
import { ArrowLeft, Edit2, ProfilePic } from '../../assets/images'; // Assuming these are your icons
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../stores/userStore';

const EditProfile = () => {
  const { userData, updateUserData } = userStore(); // Assuming you have an updateUserData action
  const [name, setName] = useState(userData.name || '');
  const [countryCode] = useState(userData.countryCode || '+91');
  const [phoneNumber] = useState(userData.phone || '');
  const [verbosity, setVerbosity] = useState(userData.verbosity || 'Verbose');
  const [profilePic, setProfilePic] = useState(userData.photo || ProfilePic);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const windowDimensions = {
    height: window.innerHeight,
    width: window.innerWidth
  };
  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append('user_id', userData.user || '');
    formData.append('name', name);
    formData.append('verbosity', verbosity);
  
    if (fileInputRef.current && fileInputRef.current.files[0]) {
      formData.append('photo', fileInputRef.current.files[0]);
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/edit-profile/`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
  
      if (response.ok && result && result.data) {
        setProfilePic(result.data.photo || ProfilePic);

        // Update the user data in the store (context + localStorage)
        updateUserData({
          ...userData,
          name: result.data.name,
          photo: result.data.photo,
        });

        // Show success notification
        setNotificationMessage('Profile updated successfully!');
        setIsError(false);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
      } else {
        throw new Error(result.message || 'Failed to update profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      // Show error notification
      setNotificationMessage('Error updating profile.');
      setIsError(true);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ height:windowDimensions.height > 700 && windowDimensions.width > 400 ?'100vh' : '92vh', backgroundColor: '#FFFFFF', position: 'relative', display: 'flex', flexDirection: 'column' }}>
      {showNotification && (
        <Notification
          title={isError ? "Error" : "Success"}
          color={isError ? "red" : "green"}
          style={{
            position: 'fixed',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // This centers the element
            zIndex: 100,
            width: '300px', // Adjust the width as needed
            textAlign: 'center',
          }}
        >
          {notificationMessage}
        </Notification>
      )}

      <Flex direction="column" align="center" style={{ width: '100%', padding: '20px', flex: 1, overflowY: 'auto' }}>
        {/* Header with Back Button */}
        <Flex style={{ width: '100%', alignItems: 'center', paddingBottom: '20px' }}>
          <Button
            variant="subtle"
            onClick={() => navigate(-1)}
            style={{ backgroundColor: 'transparent' }}
          >
            <img src={ArrowLeft} alt="Back" style={{ width: '24px' }} />
          </Button>
          <Text style={{ marginLeft: '16px', fontSize: '20px', fontWeight: 'bold' }}>Edit Profile</Text>
        </Flex>

        {/* Profile Picture with Edit Icon */}
        <Box style={{ position: 'relative', width: '100px', height: '100px', marginBottom: '30px' }}>
          <img
            src={profilePic}
            alt="Profile"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
          <Button
            variant="subtle"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor: '#fff',
              borderRadius: '50%',
              padding: '4px',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <img src={Edit2} alt="Edit Icon" style={{ width: '20px' }} />
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Box>

        {/* Form Inputs */}
        <Box style={{ width: '100%', maxWidth: '400px' }}>
          {/* Name Input */}
          <Text style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}>Name</Text>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: '100%',
              padding: '12px',
              marginBottom: '20px',
              border: '1px solid #ccc',
              borderRadius: '12px',
              fontSize: '16px',
            }}
          />

          {/* Phone Number Display (Read Only) */}
          <Text style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}>Phone Number</Text>
          <div
            style={{
              width: '100%',
              padding: '12px',
              marginBottom: '20px',
              border: '1px solid #ccc',
              borderRadius: '12px',
              fontSize: '16px',
            }}
          >
            <span style={{ opacity: 0.7 }}>{countryCode}</span>
            <span style={{ margin: '0 12px', fontSize: '20px', opacity: 0.7 }}>|</span> 
            <span style={{ opacity: 0.3 }}>{phoneNumber}</span>
          </div>

          {/* AI Response Verbosity Select */}
          <Text style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}>AI Response Verbosity</Text>
          <select
            value={verbosity}
            onChange={(e) => setVerbosity(e.target.value)}
            style={{
              width: '100%',
              padding: '12px',
              marginBottom: '40px',
              border: '1px solid #ccc',
              borderRadius: '12px',
              fontSize: '16px',
            }}
          >
            <option value="Verbose">Verbose</option>
            <option value="Concise">Concise</option>
          </select>
        </Box>
      </Flex>

      {/* Action Buttons - Fixed at the Bottom */}
      <Box style={{ padding: '20px', backgroundColor: '#fff' }}>
        <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button
            fullWidth
            size="lg"
            variant="outline"
            color="gray"
            onClick={() => navigate(-1)}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              marginRight: '10px',
              padding: '15px',
              borderRadius: '12px',
              border: '1px solid #ccc',
              fontSize: '16px',
            }}
          >
            Cancel
          </Button>

          <Button
            fullWidth
            size="lg"
            color="dark"
            onClick={handleSaveChanges}
            style={{
              backgroundColor: '#000',
              color: '#fff',
              fontWeight: 500,
              flexGrow: 1,
              padding: '15px',
              borderRadius: '12px',
              fontSize: '16px',
            }}
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    </div>
  );
};

export default EditProfile;
