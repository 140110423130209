import React, { useState } from 'react';
import { Modal, Button, Group, Card, Text, Title, CloseButton } from '@mantine/core';
import { DuelStateInfo1, DuelStateInfo2, DuelStateInfo3, DuelStateInfo4, ArrowLeftFilled, ArrowRightFilled, SendIcon } from '../../../assets/images';

const InfoTips = ({ opened = true, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      imageUrl: DuelStateInfo1,
      step: null,
    },
    {
      imageUrl: DuelStateInfo2,
      step: 'Step 1',
      description: 'Select a topic',
    },
    {
      imageUrl: DuelStateInfo3,
      step: 'Step 2',
      description: 'Checkout the leaderboards',
    },
    {
      imageUrl: DuelStateInfo4,
      step: 'Step 3',
      description: 'Monitor your stats',
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      radius="12px"
      size="auto"
      zIndex="9999999999"
      withCloseButton={false}
      styles={{
        header: {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        },
        title: {
          flexGrow: 1,
          textAlign: 'center',
        },
      }}
      title={
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Title order={4} style={{ flexGrow: 1 }}>
            Duel Stats
          </Title>
          <CloseButton
            onClick={onClose}
            style={{
              marginLeft: '20px',
            }}
          />
        </div>
      }
    >
      <Card
        radius="md"
        p="lg"
        mt={slides[currentSlide].step ? 'md' : 0}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Conditionally render step header based on currentSlide */}
        {slides[currentSlide].step && (
          <div
            align="center"
            spacing="xs"
            mt="md"
            style={{
              border: '1px solid var(--mantine-color-gray-3)',
              borderRadius: '8px',
              padding: '10px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Title order={4}>{slides[currentSlide].step}</Title>
            <Text size="sm" color="gray">
              {slides[currentSlide].description}
            </Text>
          </div>
        )}
        <img src={slides[currentSlide].imageUrl} alt={`Slide ${currentSlide + 1}`} width="100%" style={{ width: '18.75rem' }} />
      </Card>

      <Group position="center" mt="xl">
        <Button variant="outline" color="black" onClick={prevSlide} style={{ marginRight: 'auto' }} disabled={currentSlide === 0}>
          <img src={ArrowLeftFilled} alt="Arrow left" style={{ width: '20px' }} />
        </Button>

        <Group spacing={8}>
          {slides.map((_, index) => (
            <div
              key={index}
              style={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: index === currentSlide ? 'var(--mantine-color-blue-6)' : 'var(--mantine-color-gray-3)',
              }}
            />
          ))}
        </Group>

        {currentSlide < slides.length - 1 ? (
          <Button variant="outline" color="black" onClick={nextSlide} style={{ marginLeft: 'auto' }}>
            <img src={ArrowRightFilled} alt="Arrow right" style={{ width: '20px' }} />
          </Button>
        ) : (
          <Button variant="outline" color="white" style={{ marginLeft: 'auto', backgroundColor: '#070519', borderRadius: '6px' }} onClick={onClose}>
            Check out
            <img style={{ marginLeft: '4px' }} src={SendIcon} alt="send" />
          </Button>
        )}
      </Group>
    </Modal>
  );
};

export default InfoTips;