export const hasLaTeX = (content) => {
  const regex = /(\$[\s\S]*?\$|\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\([\s\S]*?\\\)|\\frac|\\sqrt|\\pm|\\[a-zA-Z]+)/;
  return regex.test(content);
};

export const stripHtmlTags = (content) => {
  return content.replace(/<\/?[^>]+(>|$)/g, '');
};

export const cleanMarkdownContent = (content) => {
  var clean_content = content.replaceAll('`', '');
  clean_content = clean_content?.replaceAll(/\(C\)/g, '\\(C\\)');
  clean_content = clean_content?.replaceAll(/\(c\)/g, '\\(c\\)');
  clean_content = clean_content?.replaceAll(/\(r\)/g, '\\(r\\)');
  clean_content = clean_content?.replaceAll(/\(R\)/g, '\\(R\\)');
  if (clean_content.startsWith('.')) clean_content = clean_content.substring(1);
  // clean_content = clean_content.replaceAll("-", "--");
  return content;
};

export const cleanLatexContent = (content) => {
  return content.replaceAll('\n', '');
  // return content;
};

export const ensureNewlineBeforeHash = (content) => {
  return content.replace(/([^\n])(#)/g, '$1\n$2');
};

export const removeLeadingSpacesBeforeComments = (content) => {
  return content.replace(/^\s+\/\//gm, '//');
};

export const replaceDoubleDollarsWithSingleDollars = (content) => {
  return content.replace(/\$\$/g, '$');
};

function findLatexTables(text) {
  const masterTables = [];
  const stack = [];
  let startIndex = null;

  const latexTablePattern = /\\begin{array}|\{[^{}]*\}|\\end{array}/g;
  let match;

  while ((match = latexTablePattern.exec(text)) !== null) {
    if (match[0] === '\\begin{array}') {
      if (stack.length === 0) {
        startIndex = match.index;
      }
      stack.push(match[0]);
    } else if (match[0] === '\\end{array}') {
      stack.pop();
      if (stack.length === 0 && startIndex !== null) {
        const endIndex = latexTablePattern.lastIndex;
        masterTables.push(text.substring(startIndex, endIndex));
        startIndex = null;
      }
    }
  }

  return masterTables;
}
export const latexPattern =
  /\$$[^$]*\$$|\$[^$]*\$|^\\\[.*\\\]$|\\begin{[^}]*}[^]*?\\end{[^}]*}|\\left\[([^]*?)\\right\]|\\documentclass{[^}]*}|\\usepackage{[^}]*}|\\title{[^}]*}|\\author{[^}]*}|\\date{[^}]*}|\\text{[^}]*}|\\textbf{[^}]*}|\\textit{[^}]*}|\\underline{[^}]*}|\\emph{[^}]*}|\\section{[^}]*}|\\subsection{[^}]*}|\\subsubsection{[^}]*}|\\paragraph{[^}]*}|\\subparagraph{[^}]*}|\\texttt{[^}]*}|\\$[^$]*\$|\\\[([^]*?)\\\]|\\\([^]*?\\\)|\\frac{[^}]*}{[^}]*}|\\sqrt{[^}]*}|\\label{[^}]*}|\\ref{[^}]*}|\\cite{[^}]*}|\\bibitem{[^}]*}|\\includegraphics{[^}]*}|\\tableofcontents|\\listoffigures|\\listoftables\\cdot|\\cdots|\\vdot|\\vdots|\\dot|\\dots|\\ce{[^}]*}|\\\[[^]*?\\\]/gi;

const htmlPattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/gi;

const imagePattern = /\!\[.*?\]\((.*?)\)/g;

const markdownCodePattern = /```(.*?)```/gs;

const splitMarkdownByHtml = (markdown) => {
  const parts = [];
  let match;
  let lastIndex = 0;
  let htmlBuffer = '';

  while ((match = htmlPattern.exec(markdown)) !== null) {
    if (match.index > lastIndex) {
      const markdownPart = markdown.substring(lastIndex, match.index);
      parts.push({
        type: 'markdown',
        content: cleanMarkdownContent(markdownPart),
      });
    }
    // Accumulate continuous HTML
    htmlBuffer += match[0];
    lastIndex = htmlPattern.lastIndex;

    // Check the next match without advancing the regex lastIndex
    const nextMatch = htmlPattern.exec(markdown);
    if (!nextMatch || nextMatch.index > lastIndex) {
      if (htmlBuffer) {
        parts.push({ type: 'html', content: htmlBuffer });
        htmlBuffer = '';
      }
    }

    // Reset lastIndex to continue the loop from the current position
    htmlPattern.lastIndex = lastIndex;
  }

  if (lastIndex < markdown.length) {
    const remainingMarkdown = markdown.substring(lastIndex);
    parts.push({
      type: 'markdown',
      content: cleanMarkdownContent(remainingMarkdown),
    });
  }

  return parts;
};
export const customTrim = (str) => {
  return str.replace(/^[ \t]+|[ \t]+$/g, '');
};

const isWrappedByDollar = (str) => {
  if (typeof str !== 'string') {
    console.error('Invalid input type, expected a string:', str);
    return false;
  }

  // Check if the string starts and ends with '$' and has more than just two '$' characters
  return str.length > 2 && str.startsWith('$') && str.endsWith('$');
};

export const splitTextByLatex = (text) => {
  if (typeof text !== 'string') {
    console.error('Invalid input type, expected a string:', text);
    return [];
  }

  // Extract LaTeX tables
  const tables = findLatexTables(text);
  let textWithPlaceholders = text;
  const placeholders = [];

  tables.forEach((table, index) => {
    const placeholder = `##LATEX_TABLE_${index}##`;
    textWithPlaceholders = textWithPlaceholders.replace(table, placeholder);
    placeholders.push({ placeholder, table });
  });

  var parts = [];
  let match;
  let lastIndex = 0;

  while ((match = latexPattern.exec(textWithPlaceholders)) !== null) {
    if (match.index > lastIndex) {
      const markdownPart = textWithPlaceholders.substring(lastIndex, match.index);
      parts.push(...splitMarkdownByHtml(markdownPart));
    }
    var latexContent = customTrim(match[0]);
    // console.log(latexContent, "latexContent");
    if (latexContent == '$$') {
      parts.push({ type: 'latex', content: '' });
    } else if (latexContent != '') {
      if (match[0].startsWith('\\[') && match[0].endsWith('\\]')) {
        match[0] = match[0].substring(2, match[0].length - 2);
      }
      if (!isWrappedByDollar(match[0])) {
        match[0] = ' $' + match[0] + '$ ';
      }
      if (match[0].includes('##LATEX_TABLE_')) {
        const tableIndex = parseInt(match[0].match(/##LATEX_TABLE_(\d+)##/)[1]);
        const tableContent = placeholders[tableIndex].table;
        parts.push({ type: 'latex_array', content: customTrim(tableContent) });
      } else if (match[0].includes('\\begin{array}') && match[0].includes('\\end{array}')) {
        parts.push({ type: 'latex_array', content: customTrim(match[0]) });
      } else {
        parts.push({ type: 'latex', content: customTrim(match[0]) });
      }
    }

    lastIndex = latexPattern.lastIndex;
  }

  if (lastIndex < textWithPlaceholders.length) {
    const remainingMarkdown = textWithPlaceholders.substring(lastIndex);
    parts.push(...splitMarkdownByHtml(remainingMarkdown));
  }
  parts = preprocessMarkdownPartsForImage(parts);
  // console.log(parts,"parts1")
  parts = combineMarkdownCodeBlocks(parts);
  // console.log(parts,"parts2")
  parts = handleNewLineLatex(parts);
  // console.log(parts,"parts3")
  parts = combineMarkdownLatex(parts);
  // console.log(parts,"parts4")
  parts = removeNewLineOnlyContent(parts);
  // console.log(parts,"parts5")
  parts = processMarkdownLatex(parts);
  // console.log(parts,"parts6")
  parts = mergeContinuousMarkdownLatex(parts);
  // console.log(parts,"parts7")
  if (placeholders.length > 0) parts = replacePlaceholdersWithLatexTables(parts, placeholders);

  return parts;
};

function processMarkdownLatex(inputArray) {
  let resultArray = [];

  for (let i = 0; i < inputArray.length; i++) {
    let currentObj = inputArray[i];

    if (currentObj.type === 'markdown_latex') {
      // If the current object is markdown_latex, check if we can merge with the previous one
      if (resultArray.length > 0 && resultArray[resultArray.length - 1].type === 'markdown_latex') {
        // Merge the content of currentObj with the last object in resultArray
        resultArray[resultArray.length - 1].content += currentObj.content;
      } else {
        // Otherwise, push the currentObj as is
        resultArray.push(currentObj);
      }
    } else if (currentObj.type === 'newline') {
      // Handle newline logic
      if (resultArray.length > 0 && resultArray[resultArray.length - 1].type === 'markdown_latex') {
        // Append newline to the previous markdown_latex content
        resultArray[resultArray.length - 1].content += '\n';
      } // Ignore newline if it's at the beginning or after another newline
    } else {
      // Push non-markdown_latex objects as is
      resultArray.push(currentObj);
    }
  }

  return resultArray;
}

const replacePlaceholdersWithLatexTables = (parts, placeholders) => {
  const updatedParts = [];

  parts.forEach((part) => {
    if (part.type === 'markdown' || part.type === 'html' || part.type === 'markdown_latex' || part.type === 'markdown_code') {
      // Handle content that might include placeholders
      let content = part.content;
      let currentIndex = 0;

      // Iterate over placeholders to find and replace them
      placeholders.forEach(({ placeholder, table }) => {
        let index;
        while ((index = content.indexOf(placeholder, currentIndex)) !== -1) {
          if (index > currentIndex) {
            updatedParts.push({ type: part.type, content: content.substring(currentIndex, index) });
          }
          updatedParts.push({ type: 'latex_array', content: customTrim(table) });
          currentIndex = index + placeholder.length;
        }
      });

      // Add remaining content after the last placeholder
      if (currentIndex < content.length) {
        updatedParts.push({ type: part.type, content: content.substring(currentIndex) });
      }
    } else {
      // If it's not markdown or html, just push the part as is
      updatedParts.push(part);
    }
  });

  return updatedParts;
};

function combineMarkdownLatex(contentList) {
  let combinedContent = '';
  const combinedList = [];

  contentList.forEach((item) => {
    if (item.type === 'markdown' || item.type === 'latex') {
      combinedContent += item.content;
    } else {
      if (combinedContent) {
        combinedList.push({
          type: 'markdown_latex',
          content: customTrim(combinedContent),
        });
        combinedContent = '';
      }
      combinedList.push(item);
    }
  });

  // Flush the remaining combined content
  if (combinedContent) {
    combinedList.push({
      type: 'markdown_latex',
      content: customTrim(combinedContent),
    });
  }

  return combinedList;
}

const removeNewLineOnlyContent = (array) => {
  array = array.filter((item) => customTrim(item.content) !== '\n');
  array = array.filter((item) => {
    return customTrim(item.content) !== '' || item.type == 'newline';
  });
  array = array.filter((item) => customTrim(item.content) !== '.');
  return array;
};

const handleNewLineLatex = (parts) => {
  const updatedParts = [];
  parts.forEach((part, index) => {
    if (part.type === 'latex') {
      var content = customTrim(part.content);

      if (content.startsWith('$')) content = content.substring(1);
      if (content.endsWith('$')) content = content.substring(0, content.length - 1);
      content = customTrim(content);

      const [hasLeadingNewline, hasTrailingNewline] = checkForNewLineAtLeadTrail(JSON.stringify(content));

      if (hasLeadingNewline) {
        updatedParts.push({
          type: 'newline',
          content: '',
        });
      }

      updatedParts.push(part);

      if (hasTrailingNewline) {
        updatedParts.push({
          type: 'newline',
          content: '',
        });
      }
    } else {
      updatedParts.push(part);
    }
  });
  return updatedParts;
};

const checkForNewLineAtLeadTrail = (jsonString) => {
  var hasLeadingNewline = false;
  var hasTrailingNewline = false;
  if (jsonString.length > 2) {
    hasLeadingNewline ||= jsonString.substring(1, 3) == '\\n';
    hasTrailingNewline ||= jsonString.substring(jsonString.length - 3, jsonString.length - 1) == '\\n';
  }
  return [hasLeadingNewline, hasTrailingNewline];
};

const combineMarkdownCodeBlocks = (inputArray) => {
  const result = [];
  let isInsideCodeBlock = false;
  let combinedContent = '';

  inputArray.forEach((item) => {
    let content = item.content;
    let index;

    while ((index = content.indexOf('```')) !== -1) {
      if (isInsideCodeBlock) {
        // Closing triple backticks found
        combinedContent += content.substring(0, index + 3);
        result.push({
          type: 'markdown_code',
          content: customTrim(combinedContent),
        });
        combinedContent = '';
        content = content.substring(index + 3);
        isInsideCodeBlock = false;
      } else {
        // Opening triple backticks found
        // Push the content before the opening backticks
        if (combinedContent) {
          result.push({
            type: item.type,
            content: customTrim(combinedContent),
          });
          combinedContent = '';
        }
        if (index > 0) {
          result.push({
            type: item.type,
            content: customTrim(content.substring(0, index)),
          });
        }
        combinedContent += content.substring(index, index + 3);
        content = content.substring(index + 3);
        isInsideCodeBlock = true;
      }
    }

    // Add remaining content after last ``` if any
    if (customTrim(content)) {
      if (isInsideCodeBlock) {
        combinedContent += content;
      } else {
        result.push({
          type: item.type,
          content: customTrim(content),
        });
      }
    }
  });

  // If there's any leftover content in combinedContent, push it as the current type
  if (customTrim(combinedContent)) {
    result.push({
      type: isInsideCodeBlock ? 'markdown_code' : 'markdown',
      content: isInsideCodeBlock ? customTrim(combinedContent) : cleanMarkdownContent(customTrim(combinedContent)),
    });
  }

  return result;
};

function mergeContinuousMarkdownLatex(inputArray) {
  let resultArray = [];
  let tempMarkdownContent = '';

  for (let i = 0; i < inputArray.length; i++) {
    let currentObj = inputArray[i];

    if (currentObj.type === 'markdown_latex') {
      if (tempMarkdownContent !== '') {
        tempMarkdownContent += '\n';
      }
      tempMarkdownContent += currentObj.content;
    } else {
      // If there's accumulated markdown content, push it to resultArray before adding the current non-markdown object
      if (tempMarkdownContent !== '') {
        resultArray.push({
          type: 'markdown_latex',
          content: tempMarkdownContent,
        });
        tempMarkdownContent = '';
      }
      resultArray.push(currentObj);
    }
  }

  // After the loop, check if there's remaining markdown content to add
  if (tempMarkdownContent !== '') {
    resultArray.push({ type: 'markdown_latex', content: tempMarkdownContent });
  }

  return resultArray;
}

const preprocessMarkdownPartsForImage = (parts) => {
  let processedParts = [];

  parts.forEach((part) => {
    if (part.type === 'markdown') {
      let match;
      let lastIndex = 0;
      let content = part.content;

      while ((match = imagePattern.exec(content)) !== null) {
        if (match.index > lastIndex) {
          processedParts.push({
            type: 'markdown',
            content: cleanMarkdownContent(content.substring(lastIndex, match.index)),
          });
        }
        processedParts.push({ type: 'image', content: match[1] });
        lastIndex = imagePattern.lastIndex;
      }

      if (lastIndex < content.length) {
        processedParts.push({
          type: 'markdown',
          content: cleanMarkdownContent(content.substring(lastIndex)),
        });
      }
    } else {
      processedParts.push(part);
    }
  });

  return processedParts;
};

export const extractCodeBlockTypeAndContent = (content) => {
  const codeBlockRegex = /^```(\w+)?\s*([\s\S]*?)```/;
  const match = content.match(codeBlockRegex);

  if (match) {
    return { type: match[1] || 'plaintext', remainingText: match[2] };
  }

  return { type: 'plaintext', remainingText: content };
};

export const isHTML = (str) => {
  return /<\/?[a-z][\s\S]*>/i.test(str);
};

export const convertMarkdownInHTMLSupport = (inputString) => {
  var regex = /###(.+?)(?:\n|<br>)/g;

  var matches = [];
  var match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  var editedString = inputString.replace(regex, function (match) {
    var text = match ? match.substring(3, match.length - 4) : null;
    return text ? '<h3>' + text + '</h3>' : match;
  });

  return editedString;
};
