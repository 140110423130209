import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Box, Text, Space, Button } from '@mantine/core';
import StreakProgress from './StreakProgress';
import { callGETAPI } from '../../utils/NetworkUtil';
import { ArrowRight, FireBackIcon, FireSteak, StreakShareImage, StreakShareImagePost } from '../../assets/images';
import { useNews } from '../../context/newsProvide';
import branch from 'branch-sdk';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function NewsType() {
  const [streak, setStreak] = useState(0);
  const { streakCount, setStreakCount } = useNews();
  const [modalVisible, setModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [shareImageURL, setShareImageURL] = useState('');
  const [sharePostImageURL, setSharePostImageURL] = useState('');
  const isMobile = window.innerWidth <= 2500;

  const [deeplinkUrl, setDeeplinkUrl] = useState('');
  const isShareInitialized = useRef(false);

  // Fetch streak count from the API and store it
  const fetchStreakCount = useCallback(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData && userData.user) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/streak/?user=${userData.user}`;
      callGETAPI(
        url,
        (response) => {
          const data = response.data;
          if (data && data.streak) {
            setStreak(data.streak);
            setStreakCount(data.streak);
            const previousStreak = parseInt(localStorage.getItem('previousStreak')) || 0;
            if (data.streak > previousStreak) {
              localStorage.setItem('previousStreak', data.streak);
              setModalVisible(true);
            }
          }
        },
        (error) => {
          console.error('Failed to fetch streak count:', error);
        }
      );
    }
  }, [setStreak, setStreakCount]);

  useEffect(() => {
    const previousStreak = parseInt(localStorage.getItem('previousStreak')) || 0;
    fetchStreakCount();
  }, [fetchStreakCount]);

  const createShareableImages = () => {
    const createImage = (baseImage, setImageURL) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const img = new Image();
      img.src = baseImage;
      const logoImg = new Image();
      const fireImg = new Image();
      fireImg.src = FireSteak;

      Promise.all([new Promise((resolve) => (img.onload = resolve)), new Promise((resolve) => (fireImg.onload = resolve))]).then(() => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        // Draw logo
        const logoWidth = 300;
        const logoHeight = 90;
        context.drawImage(logoImg, 50, 50, logoWidth, logoHeight);

        // Draw fire icon
        const fireWidth = 100;
        const fireHeight = 100;
        const fireX = canvas.width - fireWidth - 250;
        const fireY = canvas.height / 1.9 - fireHeight / 2;

        // Draw streak count in fire (red)
        context.font = 'bold 72px Arial';
        context.fillStyle = '#D96D0F';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(`${streak}`, fireX + fireWidth / 2, fireY + fireHeight / 2);

        // Draw text with streak count (white)
        context.font = '500 56px Arial';
        context.fillStyle = 'white';
        context.fillText(`${streak}`, 90, canvas.height / 1.81);

        const dataURL = canvas.toDataURL('image/png');
        setImageURL(dataURL);
      });
    };

    createImage(StreakShareImage, setShareImageURL);
    createImage(StreakShareImagePost, setSharePostImageURL);
  };

  const initializeShare = useCallback(() => {
    if (isShareInitialized.current) return;

    const domain = window.location.origin;
    const linkData = {
      channel: 'web',
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        '$og_redirect	': domain,
        $fallback_url: domain,
        $og_title: 'My News Reading Streak',
        $og_description: `I've maintained a ${streak}-day news reading streak!`,
        navigate_to: 'news/',
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
      } else {
        setDeeplinkUrl(link);
        isShareInitialized.current = true;
      }
    });
  }, [streak]);

  const handleShare = async () => {
    try {
      const blob = await fetch(sharePostImageURL).then((res) => res.blob());
      const file = new File([blob], 'streak.png', { type: 'image/png' });

      if (deeplinkUrl && navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: 'My News Reading Streak',
          text: `I've maintained a ${streak}-day news reading streak!`,
          files: [file],
          url: deeplinkUrl,
        });
      } else {
        console.error('Sharing not supported');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  useEffect(() => {
    if (shareModalVisible) {
      isShareInitialized.current = false;
      createShareableImages();
      initializeShare();
      MixpanelEvent('streak_popup_action', { action: 'viewed', popup_type: 'achievement' });
      sendPostHogEvent('streak_popup_action', { action: 'viewed', popup_type: 'achievement' });
    }
  }, [shareModalVisible, initializeShare]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px', gap: '10px', width: '100%' }}>
        <StreakProgress streak={streak} setModalVisible={setModalVisible} />
      </div>

      <Modal
        size="sm"
        opened={modalVisible}
        onClose={() => setModalVisible(false)}
        centered
        zIndex={9999999999999}
        styles={{ modal: { borderRadius: '20px', textAlign: 'center' } }}
        withCloseButton={false}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>Reading Streak!</Text>
          <Space h="md" />
          <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={FireBackIcon} alt="Streak Fire Background" style={{ position: 'relative', width: '150px', height: '150px', zIndex: 0 }} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
            >
              <img src={FireSteak} alt="Streak Fire" style={{ width: '80px', height: '80px', position: 'relative' }} />
              <div
                style={{
                  position: 'absolute',
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '1.4rem',
                  fontWeight: 'bold',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {streak}
              </div>
            </div>
          </Box>
          <Text style={{ fontSize: '1rem', color: '#7B7B7B', marginTop: '10px' }}>
            Congratulations! You're on a {streak}-day streak with daily news summary reading.
          </Text>
          <Space h="lg" />
          <Button
            onClick={() => {
              setModalVisible(false);
              setShareModalVisible(true);
              MixpanelEvent('streak_popup_action', { action: 'clicked', popup_type: 'achievement' });
              sendPostHogEvent('streak_popup_action', { action: 'clicked', popup_type: 'achievement' });
            }}
            style={{
              width: '296px',
              height: '52px',
              padding: '16px 24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '20px',
              border: '1px solid #070519',
              backgroundColor: '#070519',
              color: '#fff',
            }}
          >
            Share Achievement
            <img src={ArrowRight} alt="arrow" />
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              fontWeight: 'bold',
              padding: '10px 20px',
              borderRadius: '10px',
            }}
            onClick={() => {
              setModalVisible(false);
              MixpanelEvent('screen_closed', { source_screen: 'Reading Streak!', close_type: 'modal' });
              sendPostHogEvent('streak_popup_action', { action: 'dismissed', popup_type: 'achievement' });
              MixpanelEvent('streak_popup_action', { action: 'dismissed', popup_type: 'achievement' });
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      <Modal
        size={isMobile ? '28rem' : '22vw'}
        opened={shareModalVisible}
        onClose={() => setShareModalVisible(false)}
        centered
        zIndex={9999999999999}
        withCloseButton={false}
        styles={{ modal: { borderRadius: '20px' } }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Text align="center" size="lg" weight={500}>
            Sharing One Image
          </Text>
        </Box>

        <Box>{shareImageURL && <img src={shareImageURL} alt="Shareable Streak" style={{ width: '100%', height: '100%' }} />}</Box>

        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          {isShareInitialized.current ? (
            <Button
              onClick={handleShare}
              style={{
                width: 'auto',
                height: '52px',
                padding: '16px 24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '20px',
                border: '1px solid #070519',
                backgroundColor: 'transparent',
                color: 'black',
              }}
            >
              Share
            </Button>
          ) : (
            <Text>Preparing share...</Text>
          )}
        </Box>
      </Modal>
    </>
  );
}
