import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconHeart, IconMessageCircle2, IconArrowLeft, IconExclamationCircle, IconCheck, IconX } from '@tabler/icons-react';
import { Button, Flex, Text, Badge, Title, Stack, Radio, Textarea, Group } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useNews } from '../../context/newsProvide';
import { useTheme } from '../../context/themeProvider';
// import { useSpring, animated } from 'react-spring';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeftFilled, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import Lottie from 'react-lottie';

export default function NewsMcqPage() {
  const { colors } = useTheme();
  const { id } = useParams();
  const { newsDetailData } = useNews();
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const containerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const mcqQuestions = useMemo(() => newsDetailData.mcqs?.questions || [], [newsDetailData.mcqs?.questions]);

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    setQuestionStates(mcqQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [mcqQuestions]);

  const currentQuestion = mcqQuestions[currentQuestionIndex];

  const handleOptionChange = (index) => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[index];
    MixpanelEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
    sendPostHogEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
  };

  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };

  const discussWithAi = (item, checkedStat) => {
    const optionKey = ['a', 'b', 'c', 'd'];
    let tutorConnectParam = {
      assoc_news: newsDetailData.id,
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: item.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: item.options[parseInt(checkedStat)],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: [
          {
            a: item.options[0],
          },
          {
            b: item.options[1],
          },
          {
            c: item.options[2],
          },
          {
            d: item.options[3],
          },
        ],
        category: dictMyCategoryName(item.category),
        question: item.question,
        question_id: item.id,
        user_answer: item.options[checkedStat],
        user_option: optionKey[checkedStat],
        correct_answer: optionKey[item.answer],
        options: item.options,
      },
      category: dictMyCategoryName(item.category),
      preSelectAnswer: item.options[parseInt(checkedStat)],
    };
    return tutorConnectParam;
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });

    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[questionStates[currentQuestionIndex].answerIndex];
    MixpanelEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
    sendPostHogEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_mcq',
    });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < mcqQuestions.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = discussWithAi(currentQuestion, questionStates[currentQuestionIndex].answerIndex);
    navigate(`/chat/mcq/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_mcq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_mcq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  return (
    <div
      style={{
        ...styles.outerContainer,
        backgroundColor: colors.background,
        color: colors.text,
      }}
    >
      <div
        style={{
          ...styles.topContainer,
          backgroundColor: colors.cardBackground,
          boxShadow: `0px 4px 9.2px 0px ${colors.shadow}`,
        }}
      >
        <Flex align="center" style={styles.header}>
          <IconArrowLeft
            style={{ ...styles.backArrow, color: colors.text }}
            onClick={() => {
              navigate(-1);
              MixpanelEvent('navigation_back', { source_screen: 'news_mcq' });
              sendPostHogEvent('navigation_back', { source_screen: 'news_mcq' });
            }}
          />
          <Text style={{ ...styles.headerText, color: colors.text }}>Related UPSC MCQs</Text>
        </Flex>

        {mcqQuestions.length > 0 && (
          <div style={styles.questionNavigation}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={ArrowLeftFilled}
                style={{
                  ...styles.navIcon,
                  color: currentQuestionIndex === 0 ? colors.disabled : colors.arrowColor,
                  cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
                  borderColor: colors.border,
                }}
                onClick={handlePreviousQuestion}
                alt="Arrow Left"
              />
              <div style={styles.qNavigation}>
                {mcqQuestions.map((_, index) => (
                  <Text
                    key={index}
                    style={{
                      ...styles.questionNumber,
                      backgroundColor: index === currentQuestionIndex ? colors.highlight : 'transparent',
                      color: index === currentQuestionIndex ? colors.primary : colors.text,
                    }}
                    onClick={() => setCurrentQuestionIndex(index)}
                  >
                    Q{index + 1}
                  </Text>
                ))}
              </div>
            </div>
            <img
              src={ArrowLeftFilled}
              style={{
                ...styles.navIcon,
                color: currentQuestionIndex !== mcqQuestions.length - 1 ? colors.arrowColor : colors.disabled,
                cursor: currentQuestionIndex !== mcqQuestions.length - 1 ? 'pointer' : 'not-allowed',
                transform: 'rotate(180deg)',
                borderColor: colors.border,
              }}
              onClick={handleNextQuestion}
              alt="Arrow Right"
            />
          </div>
        )}
      </div>

      {mcqQuestions.length === 0 ? (
        <div style={styles.noDataContainer}>
          <img src={NoDataImage} alt="no data" style={styles.noDataImage} />
          <Text style={{ ...styles.noDataText, color: colors.text }}>No Related Questions Found</Text>
          <Text style={{ ...styles.noDataSubtext, color: colors.subtleText }}>
            We were unable to generate any MCQs on this article. Please tap on try again below.
          </Text>
        </div>
      ) : (
        <>
          <div
            style={{
              ...styles.container,
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.border}`,
              margin: '15px',
              height: 'calc(100dvh - 220px)',
              position: 'relative',
            }}
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              style={{
                ...styles.bottomContainer,
                backgroundColor: colors.cardBackground,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: 'auto',
              }}
            >
              <div style={styles.questionMeta}>
                <Badge color={colors.subtleText}>CURRENT AFFAIRS</Badge>
                <IconHeart style={{ ...styles.heartIcon, color: colors.subtleText }} />
              </div>

              <Text style={{ ...styles.questionText, color: colors.text }}>
                {currentQuestion.question.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Text>

              <div style={styles.optionsContainer}>
                {currentQuestion.options.map((option, index) => {
                  const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                  const isCorrectAnswer = index === currentQuestion.answer;
                  const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                  return (
                    <div
                      key={index}
                      onClick={() => handleOptionChange(index)}
                      style={{
                        ...styles.optionBox,
                        backgroundColor: isSubmitted
                          ? isCorrectAnswer
                            ? colors.correctAnswer
                            : isSelectedAnswer
                            ? colors.incorrectAnswer
                            : colors.cardBackground
                          : isSelectedAnswer
                          ? colors.highlight
                          : colors.cardBackground,
                        border: isSubmitted
                          ? isCorrectAnswer
                            ? `1px solid ${colors.correctAnswer}`
                            : isSelectedAnswer
                            ? `1px solid ${colors.incorrectAnswer}`
                            : `1px solid ${colors.border}`
                          : isSelectedAnswer
                          ? `1px solid ${colors.primary}`
                          : `1px solid ${colors.border}`,
                      }}
                    >
                      <div style={styles.optionContent}>
                        <div
                          style={{
                            ...styles.radioCircle,
                            backgroundColor: questionStates[currentQuestionIndex]?.isSubmitted
                              ? index === currentQuestion.answer
                                ? colors.success
                                : index === questionStates[currentQuestionIndex].answerIndex
                                ? colors.error
                                : 'transparent'
                              : 'transparent',
                            border: `2px solid ${colors.primary}`,
                          }}
                        >
                          {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                            <div style={{ ...styles.purpleDot, backgroundColor: colors.primary }}></div>
                          )}
                          {questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer ? (
                              <IconCheck style={{ ...styles.icon, color: colors.cardBackground }} />
                            ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                              <IconX style={{ ...styles.icon, color: colors.cardBackground }} />
                            ) : null)}
                        </div>
                        <Text style={{ ...styles.optionLabel, color: colors.text }}>{String.fromCharCode(65 + index)}.</Text>
                        <Text style={{ ...styles.optionText, color: colors.text }}>{option}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
              width: '100%',
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.border}`,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              boxSizing: 'border-box',
              margin: 0,
            }}
          >
            <Button variant="subtle" color="red" onClick={() => handleReport(currentQuestion.id)}>
              <IconExclamationCircle style={{ marginRight: '0.25rem' }} height={18} width={18} />
              Report
            </Button>
            {!questionStates[currentQuestionIndex]?.isSubmitted ? (
              <Button
                style={{
                  ...styles.submitButton,
                  backgroundColor: colors.text,
                  color: colors.cardBackground,
                }}
                onClick={handleSubmit}
                disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
              >
                Submit
              </Button>
            ) : (
              <Button
                style={{
                  ...styles.discussButton,
                  color: colors.text,
                  border: `1px solid ${colors.text}`,
                  backgroundColor: colors.cardBackground,
                }}
                onClick={handleDiscussWithAi}
              >
                <IconMessageCircle2 style={styles.discussIcon} />
                Discuss with AI
              </Button>
            )}
          </div>
        </>
      )}

      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        colors={colors}
      />
    </div>
  );
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
  styles,
  colors,
}) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span style={styles.topLine} onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          style={{
            position: 'relative',
            right: '5px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'rgb(0, 0, 0)',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title fz="h3" m="1em" style={{ textAlign: 'left' }}>
              Report Question
            </Title>
            <Stack spacing="lg">
              <Radio.Group value={resportData} onChange={setResportData} size="1em">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} p="md" />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text style={{ textAlign: 'left' }}>Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    style={{ marginTop: '10px', fontSize: '14px' }}
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!resportData}
                style={{
                  backgroundColor: resportData ? '#000' : '#ccc',
                  fontWeight: 500,
                }}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title fz="h3" m="1em">
              Report Submitted
            </Title>
            <Title m="1em" fz="md">
              Thank you! Your report has been submitted.
            </Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};
const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  outerContainer: {
    fontFamily: 'Poppins, Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    minHeight: '100dvh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    // paddingBottom: '80px',
  },
  container: {
    position: 'relative',
    border: '1px solid #E8E8E8',
    margin: '15px',
    height: 'calc(100dvh - 180px)',
    overflow: 'hidden',
  },
  animatedContainer: {
    width: '100%',
    willChange: 'transform',
  },
  topContainer: {
    padding: '15px',
    background: 'white',
    boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
  },
  bottomContainer: {
    margin: '0 15px',
    background: 'white',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '12px',
  },
  header: {
    marginBottom: '20px',
  },
  backArrow: {
    cursor: 'pointer',
    marginRight: '10px',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: '600',
  },
  questionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    marginLeft: '10px',
  },
  navIcon: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #D1D1D1',
    padding: '7px 5px',
    backgroundColor: 'white',
  },
  questionNumber: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  questionMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  heartIcon: {
    color: '#888',
    cursor: 'pointer',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionBox: {
    padding: '15px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  radioCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    transition: 'all 0.3s ease',
  },
  purpleDot: {
    width: '14.5px',
    height: '14.5px',
    backgroundColor: '#6374FA',
    borderRadius: '50%',
  },
  icon: {
    width: '14px',
    height: '14px',
    color: 'white',
  },
  optionLabel: {
    marginRight: '10px',
  },
  optionText: {
    fontSize: '15px',
    fontWeight: '500',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  reportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    backgroundColor: 'white',
    color: '#ff0000',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  reportIcon: {
    marginRight: '5px',
  },
  submitButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 25px',
    backgroundColor: '#070519',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  discussButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    color: 'black',
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  discussIcon: {
    marginRight: '5px',
  },
  noDataContainer: {
    textAlign: 'center',
    padding: '20px',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    marginBottom: '20px',
  },
  noDataText: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  noDataSubtext: {
    color: '#888',
  },
};
