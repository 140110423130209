export const wordList = [
  'Space exploration milestones',
  'Globalization effects',
  'Effects of colonial rule',
  'Innovations in e-governance',
  'Water scarcity solutions',
  'Grassroot democratic reforms',
  "Indigenous peoples' rights",
  'Transboundary water conflict',
  'Sustainable agricultural innovations',
  'Enhancing public health systems',
  'International Relations',
  'Ancient Civilizations',
  'Mitigating soil degradation',
  'Revitalizing rural economies',
  'Advancing gender equality',
];
