import React, { createContext, useContext, useState, useMemo } from 'react';
import themeColors from '../themeColors';
import { MixpanelEvent, sendPostHogEvent } from '../utils/mixpanelUtil';

// Create the ThemeContext
const ThemeContext = createContext();

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider component
export const ThemeProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useState('light');

  const toggleTheme = () => {
    setColorScheme((prevScheme) => {
      const newScheme = prevScheme === 'light' ? 'dark' : 'light';
      MixpanelEvent('settings_dark_mode_toggled', { mode: newScheme });
      sendPostHogEvent('settings_dark_mode_toggled', { mode: newScheme });
      return newScheme;
    });
  };

  // Get the colors based on the current theme
  const colors = themeColors[colorScheme];

  // Memoize the context value to optimize performance
  const contextValue = useMemo(() => ({ colorScheme, toggleTheme, colors }), [colorScheme, colors]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
