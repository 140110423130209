import React from 'react';
import { Card, Flex, Stack, Text, Image } from '@mantine/core';
import RichText from '../../../molecules/RichText/RichText';
import { Download, ArrowRight2 } from '../../../assets/images';

const ChatCard = ({ chat, index, totalChats, onDownload, onSelect }) => {
  const convertStrongToMarkdown = (content) => {
    return content.replace(/<strong>(.*?)<\/strong>/g, '**$1**');
  };
  return (
    <Card
      key={index}
      shadow="sm"
      p="sm"
      radius="lg"
      withBorder
      style={{
        cursor: 'pointer',
        backgroundColor: 'white',
        borderColor: '#E5E7EB',
        fontFamily: 'Poppins',
        marginBottom: '10px',
      }}
    >
      <Flex justify="space-between" align="center">
        <Stack style={{ width: '100%' }} gap={'0px'}>
          <Flex justify="space-between" align="center" style={{ borderBottom: '1px solid #E5E7EB' }}>
            <Text fw={600} style={{ color: '#6B7280' }} fz={'0.9em'}>
              Tutor Chat
            </Text>
            <Text fw={600} fz={'0.9em'} style={{ color: '#6B7280' }}>
              {new Date(chat.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
          </Flex>
          <RichText
            content={convertStrongToMarkdown(
              (chat.messages[chat.messages.length - 1].ai || chat.messages[chat.messages.length - 1].content)?.substring(0, 65) + '...'
            )}
            streamEnd={true}
          />
          <Flex justify="space-between" align="center" style={{ width: '100%' }}>
            <div style={{ padding: '0.3em 0.3em', backgroundColor: '#F3F3F3', borderRadius: '8px' }}>
              <span style={{ color: '#464646', fontSize: '12px', fontWeight: 'bold' }}>CHAT #{totalChats - index}</span>
            </div>
            <Flex justify="space-between" align="center" gap={'md'}>
              <Image
                onClick={() => onDownload(chat.id)}
                src={Download}
                alt="Download Chat"
                style={{ padding: '0.3em', border: '1px solid #DCDCDC', borderRadius: '8px' }}
              />
              <Image
                src={ArrowRight2}
                onClick={() => onSelect(chat)}
                alt="Arrow Right"
                style={{ padding: '0.3em', border: '1px solid #DCDCDC', borderRadius: '8px' }}
              />
            </Flex>
          </Flex>
        </Stack>
      </Flex>
    </Card>
  );
};

export default ChatCard;
