import { NEWS_SOURCE } from "../constants/newsData";

export const getNewsPaperParamForApi = (newsSource) => {
  switch (newsSource) {
    case NEWS_SOURCE.THE_HINDU:
      return "The%20Hindu";
    case NEWS_SOURCE.THE_INDIAN_EXPRESS:
      return "The%20Indian%20Express";
    case NEWS_SOURCE.ALL:
      return "All%20Newspapers";
  }
};
