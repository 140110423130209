import { TheHinduLogo, IndianExpressLogo } from "../assets/images";

export const NEWS_SOURCE = {
  ALL: "ALL",
  THE_HINDU: "THE_HINDU",
  THE_INDIAN_EXPRESS: "THE_INDIAN_EXPRESS",
};

export const theHinduNews = [
  {
    id: 1,
    title: "The Cauvery Water Management Authority should...",
    likes: 30,
    dislikes: 30,
    imageSrc: TheHinduLogo,
  },
  {
    id: 2,
    title: "Government plans to introduce new policies for...",
    likes: 45,
    dislikes: 10,
    imageSrc: TheHinduLogo,
  },
  {
    id: 3,
    title: "Government plans to introduce new policies for...",
    likes: 45,
    dislikes: 10,
    imageSrc: TheHinduLogo,
  },
  {
    id: 4,
    title: "Government plans to introduce new policies for...",
    likes: 45,
    dislikes: 10,
    imageSrc: TheHinduLogo,
  },
  {
    id: 5,
    title: "Government plans to introduce new policies for...",
    likes: 45,
    dislikes: 10,
    imageSrc: TheHinduLogo,
  },
];

export const indianExpressNews = [
  {
    id: 1,
    title: "The Supreme Court ruled on the water dispute...",
    likes: 50,
    dislikes: 15,
    imageSrc: IndianExpressLogo,
  },
  {
    id: 2,
    title: "New regulations in the agricultural sector...",
    likes: 40,
    dislikes: 5,
    imageSrc: IndianExpressLogo,
  },
  {
    id: 3,
    title: "New regulations in the agricultural sector...",
    likes: 40,
    dislikes: 5,
    imageSrc: IndianExpressLogo,
  },
  {
    id: 4,
    title: "New regulations in the agricultural sector...",
    likes: 40,
    dislikes: 5,
    imageSrc: IndianExpressLogo,
  },
  {
    id: 5,
    title: "New regulations in the agricultural sector...",
    likes: 40,
    dislikes: 5,
    imageSrc: IndianExpressLogo,
  },
];

export const mockRespIndianHindu = [
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13500,
    bookmarks: 1,
    title:
      "Do we need a Central law for protection of healthcare professionals?",
    gpt_output: {
      summary: [
        {
          id: "10f72c1d-29ec-46da-9c7b-e66e845a4b8d",
          point:
            "The brutal rape and murder of a trainee doctor in Kolkata has brought the issue of violence against healthcare workers to the forefront, triggering calls for a Central law to protect them.",
          styling: [],
        },
        {
          id: "75c4bf00-3eb4-4476-8047-df787d6ac996",
          point:
            "In 2019, the Central government drafted a Bill to address violence against healthcare workers, but it was never enacted.",
          styling: [],
        },
        {
          id: "1c3722ad-8a11-496a-9b6f-73fcd2cd4c54",
          point:
            "25 States have enacted laws to protect medical professionals, but convictions are few due to various complexities like patients’ expectations and high out-of-pocket expenditure.",
          styling: [],
        },
        {
          id: "5f0b3a68-39fa-49c1-9093-89b569982ce8",
          point:
            "WHO states that countries should spend at least 6% of GDP on health; India spends less than 2%, which exacerbates healthcare issues.",
          styling: [],
        },
        {
          id: "2b5c03ca-8d3c-4233-882b-351ee7c08317",
          point:
            "Improving healthcare infrastructure, communication, security protocols in hospitals, and better working conditions for doctors are suggested to reduce violence.",
          styling: [],
        },
        {
          id: "8de5f6db-735b-4655-bd93-98283eb2a84a",
          point:
            "The Centre argued that existing State and Central laws are sufficient and emphasized the need for better security measures in hospitals.",
          styling: [],
        },
        {
          id: "bff3d79e-c7e7-4a27-8aed-1d63d7e0abdb",
          point:
            "The Supreme Court has set up a national task force to address healthcare worker safety, which the IMA has welcomed, though it doesn’t discuss a deterrent law.",
          styling: [],
        },
        {
          id: "c0eb7893-ae9b-41b5-8a6a-98d67fecfab2",
          point:
            "Kerala has enacted stringent laws following the murder of Vandana Das in 2023, which have led to a drop in violence.",
          styling: [],
        },
        {
          id: "a17216e8-643b-4165-86f6-4d70038f1d9f",
          point:
            "A stronger public health system and reduced out-of-pocket expenditure are essential to meet patient expectations and reduce violence.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13508,
    bookmarks: 0,
    title: "A look at ongoing Indian space missions",
    gpt_output: {
      summary: [
        {
          id: "e40bd592-cfc6-463c-8b49-37c097293837",
          point:
            "ISRO has made significant advances with its missions in the past year, particularly with Aditya L1 for solar research, Gaganyaan TV-D1 for crew safety, and XPoSat for X-ray studies.",
          styling: [["<strong>", 0, 4]],
        },
        {
          id: "e1a170f0-2ecd-4909-8b8a-e0ef6414820c",
          point:
            "Prime Minister Narendra Modi officially designated August 23 as India’s National Space Day in recognition of the Chandrayaan 3 mission's success.",
          styling: [],
        },
        {
          id: "6f194c14-ae5f-4740-9cd4-984c8fac46b0",
          point:
            "Key missions include the launch of the meteorological satellite INSAT-3DS and a series of tests on the Reusable Launch Vehicle (RLV-TD).",
          styling: [["<strong>", 64, 73]],
        },
        {
          id: "9a0d2e1c-8f72-4fb3-90d9-3c5034a4cff7",
          point:
            "ISRO outlined a 25-year roadmap focusing on missions like Gaganyaan, a new Next Generation Launch Vehicle, and the Bharatiya Antariksh Station planned by 2035.",
          styling: [["<strong>", 16, 31]],
        },
        {
          id: "323515d3-2888-464f-ae1d-9c5702c07598",
          point:
            "Several policies and licenses were introduced to boost private sector participation and foreign investment in India's space sector.",
          styling: [["<strong>", 88, 106]],
        },
        {
          id: "745c23db-5204-4040-b07d-e4b4369c3a78",
          point:
            "Private companies like Agnikul Cosmos and Skyroot Aerospace are achieving milestones in space technology.",
          styling: [],
        },
        {
          id: "313f62f9-e81e-4462-ac8c-4f48662a330e",
          point:
            "The first satellite broadband license was granted to Eutelsat OneWeb and a ground station license was issued to Dhruva Space.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-III Science & Technology",
        "GS-II International Relations",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13529,
    bookmarks: 0,
    title: "12 killed as rain, landslips wreak havoc in Tripura",
    gpt_output: {
      summary: [
        {
          id: "34631570-7151-4061-ba27-d8a24a8eebaa",
          point:
            "At least 12 people have died and two are missing in Tripura following flash floods and landslips due to torrential rain over the past few days.",
          styling: [["<strong>", 9, 28]],
        },
        {
          id: "1c668f1a-643a-402d-b798-a2f2faf93264",
          point:
            "The State government has opened nearly 450 relief camps where 65,400 people have taken shelter.",
          styling: [["<strong>", 39, 55]],
        },
        {
          id: "2e339f91-aad5-4526-823b-141305aa0ea7",
          point:
            "The Indian Air Force deployed two Mi-17 helicopters to evacuate stranded people, and multiple NDRF and SDRF teams are engaged in relief and rescue operations.",
          styling: [["<strong>", 85, 113]],
        },
        {
          id: "b44588a8-9cc2-4d9a-9ce6-a5144fbd734c",
          point:
            "Landslips occurred at 2,032 locations; 1,789 of these have been cleared, and restoration work is under way.",
          styling: [],
        },
        {
          id: "8f10db5d-5665-482c-b311-245df59882b5",
          point:
            "The water level of rivers is above the danger mark in several districts, and the India Meteorological Department has forecasted more rain.",
          styling: [],
        },
        {
          id: "d3097db3-b9bf-4c99-968f-43b28da2d78a",
          point:
            "Union Home Minister Amit Shah has assured Tripura CM Manik Saha of all support.",
          styling: [],
        },
        {
          id: "fa10bb38-ae01-43f4-94a5-cdc2dfefe37d",
          point:
            "State officials have reported expansive crop damage, with around 5,000 hectares of vegetable crops and 1.2 lakh hectares of paddy submerged.",
          styling: [["<strong>", 65, 129]],
        },
      ],
      mains_syllabus: [
        "GS-III Environment & Ecology",
        "GS-III Economic Development",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13513,
    bookmarks: 0,
    title:
      "When sweltering heat turns public hospitals into potential ‘death traps’",
    gpt_output: {
      summary: [
        {
          id: "5d2db46c-548d-42a1-b17d-d62a3e9f2816",
          point:
            "India&#x2019;s public health infrastructure is struggling with the current heatwave, turning hospitals into potential &#x2018;death traps&#x2019; for the poor and marginalized communities without access to cooling devices.",
          styling: [],
        },
        {
          id: "ac8e5cb7-df2a-476d-a7a9-47af376288bd",
          point:
            "Lack of proper ventilation, overcrowded wards, and failure to dissipate heat can exacerbate conditions for patients, causing heat-related illnesses or worsening existing conditions.",
          styling: [],
        },
        {
          id: "5f62bce4-b1d2-4c43-aefd-6112f8a35a0c",
          point:
            "Heat-related deaths are likely underreported due to insufficient knowledge among healthcare professionals and inadequate autopsy services.",
          styling: [],
        },
        {
          id: "2c332566-3de2-4f64-98a5-55c4cb4f2b97",
          point:
            "There are systemic challenges in public health infrastructure, highlighted by the issue of climate change and worsening heatwaves.",
          styling: [],
        },
        {
          id: "f494e065-0ec7-40a6-ba51-e81a2435e538",
          point:
            "Hospitals need yearly strategies to prevent heat-related illnesses, including better infrastructure like air-conditioned wards, shading, and hydration facilities for patients.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-II Social Justice", "GS-III Environment & Ecology"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13521,
    bookmarks: 0,
    title: "‘No issues can be solved on the battlefield’",
    gpt_output: {
      summary: [
        {
          id: "65291976-ed61-4ac7-ad63-cceb72e334d0",
          point:
            "Prime Minister Modi met Polish President Andrzej Duda at Belweder Palace, where they reviewed bilateral ties and discussed regional and global issues.",
          styling: [],
        },
        {
          id: "db95e469-4415-4f70-b3a9-c97184f9b85d",
          point:
            "The India-Poland action plan identified priority areas for cooperation, including political dialogue and security cooperation, trade and investment, climate, energy, mining, science and technology, transport, terrorism, cyber security, health, people-to-people ties, and cultural cooperation.",
          styling: [],
        },
        {
          id: "1a42e07c-19fd-40b0-8e7b-b807b67b3562",
          point:
            "PM Modi noted the negative impacts of the war in Ukraine on global food and energy security, especially for the Global South and emphasized that the use or threat of use of nuclear weapons is unacceptable.",
          styling: [],
        },
        {
          id: "f2c785bf-9641-49b9-abe2-ed99eb3b245a",
          point:
            "PM Modi announced the Jam Saheb of Nawanagar youth exchange programme, which will bring 20 youth from Poland to India yearly.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Economic Development",
        "GS-III Environment & Ecology",
        "GS-III Science & Technology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13511,
    bookmarks: 0,
    title: "Dharavikars: from home into the unknown",
    gpt_output: {
      summary: [
        {
          id: "d8e1f84f-3f0b-4b42-b4fe-c6ec0ff29472",
          point:
            "Dharavi, a Mumbai slum with high economic resilience, is set for redevelopment by the Adani Group, raising concerns among residents about their resettlement and future livelihoods.",
          styling: [],
        },
        {
          id: "6d0bfc8e-3502-4299-9a2a-0838aef93ee2",
          point:
            "Adani Properties won a ₹5,069 crore bid to develop a township which includes apartment blocks, business spaces, and community features on government land.",
          styling: [["<strong>", 0, 39]],
        },
        {
          id: "b89e2da5-2dbd-44f4-bbc7-f1fcff49f4ce",
          point:
            "Residents are worried about loss of homes and livelihoods, especially given the lack of clarity on their resettlement plans and potential for new homes to be far from their current location.",
          styling: [],
        },
        {
          id: "ec86798d-5d90-482f-a8bc-56dca6482274",
          point:
            "Three categories of eligibility have been defined for resettlement: those with proof of residence pre-2000, between 2000-2011, and those post-2011.",
          styling: [["<strong>", 131, 146]],
        },
        {
          id: "64ed31dc-308c-4c20-bcd4-bb350611b4b4",
          point:
            "Dharavi generates significant economic activity, with small and micro industries producing an annual revenue of $100 billion; residents fear that forced relocation will harm these industries.",
          styling: [],
        },
        {
          id: "aa75c311-701a-415a-96ee-06751867cdae",
          point:
            "Surveys and land measurements are ongoing, but no clear master plan yet, causing further anxiety among the community.",
          styling: [],
        },
        {
          id: "23cd12af-19f1-4071-b4fa-97a2d786d575",
          point:
            "Environmental and infrastructure concerns have been raised about the relocation sites, including salt-pan lands and flood-prone areas.",
          styling: [],
        },
        {
          id: "9459fab0-93ba-4b76-8ac0-36912ff696d4",
          point:
            "Local and political activist groups claim the project is a 'land grab' favoring the Adani Group and criticize the lack of transparent communication from authorities.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-II Social Justice", "GS-III Economic Development"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13527,
    bookmarks: 0,
    title: "NDMA to monitor 189 high-risk glacial lakes to prevent disasters",
    gpt_output: {
      summary: [
        {
          id: "09d750a1-7bc5-48fc-9011-9a89e9a1f62a",
          point:
            "The National Disaster Management Authority (NDMA) has identified 189 high-risk glacial lakes for mitigation measures to prevent disasters caused by overflowing glacial lakes in the Himalayas.",
          styling: [["<strong>", 69, 92]],
        },
        {
          id: "ddda23a2-a53f-4f91-8da6-ed6785116567",
          point:
            "Key mitigation steps include investigating the lakes, implementing “lake-lowering measures” to buffer against overflows, and reducing potential damage in downstream states.",
          styling: [["<strong>", 29, 52]],
        },
        {
          id: "bb43fb18-aaa6-47e8-b0cb-2eb3d0c08425",
          point:
            "The South Lhonak Lake overflow in Sikkim last October resulted in the loss of at least 40 lives and destruction of the Chungthang dam.",
          styling: [],
        },
        {
          id: "1046184e-5f86-4882-a7f2-1a2fe69a8fe2",
          point:
            "The National Glacial Lake Outburst Floods Risk Mitigation Programme was approved on July 25 and aims to conduct detailed hazard assessments and install early warning systems at the lakes.",
          styling: [["<strong>", 4, 67]],
        },
        {
          id: "5de890b6-eeba-4987-8d23-a9b66d2c09ed",
          point:
            "Nearly 7,500 glacial lakes in the Himalayan range are being monitored, with 902 tracked via satellite. Site visits are challenging due to the inhospitable terrain and are only possible from July to September.",
          styling: [],
        },
        {
          id: "92c4e1f3-02e7-480f-a891-334531cedc39",
          point:
            "Teams from the Arunachal Pradesh State Disaster Management Authority are currently studying six high-risk glacial lakes in the Tawang and Dibang Valley districts.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-III Environment & Ecology",
        "GS-II Social Justice",
        "GS-III Science & Technology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13526,
    bookmarks: 0,
    title: "Centre, States discuss new mechanism for gathering crop data",
    gpt_output: {
      summary: [
        {
          id: "842ac764-d3ed-4df7-906c-c22823d366ba",
          point:
            "The Centre and States discussed the implementation of the Digital General Crop Estimation Survey (DGCES) for improving crop production statistics.",
          styling: [["<strong>", 58, 104]],
        },
        {
          id: "97a76c1e-2dfc-4182-acae-348ac266db35",
          point:
            "The initiative aims to enhance the accuracy, reliability, and transparency of agricultural statistics, aiding in policy formulation, trade decisions, and agricultural planning.",
          styling: [["<strong>", 35, 74]],
        },
        {
          id: "bfada1ea-3e90-489c-b0f3-984f3dcfb40b",
          point:
            "There is a need for continuous collaboration between Union and State governments to enhance agricultural statistics quality.",
          styling: [],
        },
        {
          id: "e6559a90-cad3-4345-a3f8-ad8c9c7305ff",
          point:
            "The DGCES will aid in accurate crop-area estimation and provide plot-level data with geotagged areas of crops, acting as a single source of truth.",
          styling: [["<strong>", 22, 51]],
        },
        {
          id: "e3970e6d-11d8-4761-9bbf-44230507d275",
          point:
            "DGCES is designed to calculate yield based on scientifically designed crop cutting experiments for major crops, providing near real-time and reliable data directly from the field.",
          styling: [["<strong>", 46, 94]],
        },
        {
          id: "a82838f8-62d5-4186-8c63-e9ef8b962250",
          point:
            "Technologies like remote sensing, geospatial analysis, and artificial intelligence will be integrated through revamped FASAL to generate crop production statistics.",
          styling: [["<strong>", 102, 124]],
        },
      ],
      mains_syllabus: [
        "GS-III Economic Development",
        "GS-III Science & Technology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13516,
    bookmarks: 0,
    title:
      "Centre reviews ground rules with Manipur’s Kuki-Zo insurgent groups",
    gpt_output: {
      summary: [
        {
          id: "b1148c33-db78-4141-96a9-8917985c44d4",
          point:
            "The Union Ministry of Home Affairs is reviewing the ground rules applicable to the Kuki-Zo insurgent groups in Manipur, who have had a Suspension of Operation (SoO) agreement with the government since 2008.",
          styling: [["<strong>", 38, 64]],
        },
        {
          id: "7c0bead7-e20c-478f-926d-6df04695c4d3",
          point:
            "One proposed measure is the removal of SoO camps from locations close to the valley districts dominated by the Meitei people, with a proposal to relocate them further inside the hills.",
          styling: [["<strong>", 133, 183]],
        },
        {
          id: "5f9e6c63-ce97-49d4-8f0b-53460b612c9c",
          point:
            "This issue was discussed on June 17 when Home Minister Amit Shah reviewed the security situation in Manipur with State government officials, excluding Chief Minister N. Biren Singh.",
          styling: [],
        },
        {
          id: "48adeb5e-7f3f-4f47-9904-9b8a623955fc",
          point:
            "Meitei groups have been demanding the removal of the camps, and Chief Minister N. Biren Singh has called for the abrogation of the tripartite pact.",
          styling: [["<strong>", 113, 146]],
        },
        {
          id: "7a967b00-589a-43bb-8677-3411db09b037",
          point:
            "Since August 19, three rounds of meetings have been held between members of the Kuki-Zo insurgent groups, Adviser (Northeast) A.K. Mishra, and Ministry and Intelligence Bureau officials to discuss and review the ground rules.",
          styling: [],
        },
        {
          id: "37547485-2f63-47ca-bc3c-73150ed4919c",
          point:
            "After a peace pact, the cadres are to stay in earmarked camps where weapons and ammunitions are accounted for and checked regularly by a monitoring agency as per State government decisions.",
          styling: [],
        },
        {
          id: "41d8a0d7-e2cd-4785-8211-c1aa2af9ed8d",
          point:
            "Around 2,200 cadres of the SoO groups live in 14 designated camps in the hill districts of Manipur.",
          styling: [["<strong>", 7, 19]],
        },
        {
          id: "29ec322b-5c14-4b0a-818f-ed4465ab1fd0",
          point:
            "Ethnic violence between the Kuki-Zo and the Meitei people began on May 3, 2023, resulting in at least 224 deaths and over 60,000 displaced people in the past 15 months.",
          styling: [],
        },
        {
          id: "155452a9-f416-4ef9-b80a-9615c328b87b",
          point:
            "Before the violence, the SoO groups reached a political settlement with the Ministry for a self-governance model, but post-violence, they revised their demand to a separate administration for the Kuki-Zo people.",
          styling: [["<strong>", 164, 187]],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-III Defence & Security",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13520,
    bookmarks: 0,
    title: "Doctors call off strike as SC brokers peace",
    gpt_output: {
      summary: [
        {
          id: "e9bddf59-6737-403b-ad52-d774cfd359b8",
          point:
            "Following a Supreme Court appeal, doctors across many States called off their strike against the brutal rape and murder of a trainee doctor at a Kolkata hospital.",
          styling: [],
        },
        {
          id: "c70bc5f5-c190-439e-8c3d-ca13b858017b",
          point:
            "Chief Justice of India D.Y. Chandrachud emphasized the importance of doctors returning to duty to ensure the smooth functioning of public health infrastructure.",
          styling: [],
        },
        {
          id: "8a4ea26f-df04-46af-8afc-bfaaafe52a59",
          point:
            "The three-judge Bench, including Justices J.B. Pardiwala and Manoj Misra, urged doctors to return to work, likening it to the necessity of judges being present in courts to deliver justice.",
          styling: [],
        },
        {
          id: "6107dd7a-f54a-424a-9a63-9a9608e84cd6",
          point:
            "Doctors expressed concerns about potential legal actions from hospital authorities once they resumed duty, but the Chief Justice assured protection against any adverse actions.",
          styling: [],
        },
        {
          id: "a81b993e-5440-4ff2-9b84-c11f8fce7c9e",
          point:
            "The Supreme Court directed its National Task Force (NTF) to gather stakeholder suggestions for reforms in safety measures and better working conditions for medical professionals, including the creation of an online portal for anonymous suggestions.",
          styling: [],
        },
        {
          id: "ae12366a-5f70-4576-bdc8-0659f965bb94",
          point:
            "Interim safety measures were mandated, with the Union Health Secretary directed to meet with State Chief Secretaries and Director-Generals of Police within a week to implement these procedures.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13498,
    bookmarks: 0,
    title: "Building on favourable change in the 2024 waqf Bill",
    gpt_output: {
      summary: [
        {
          id: "b9088d12-2c4e-47e4-adb7-e56796679d13",
          point:
            "The Waqf (Amendment) Bill 2024 has been referred to a Joint Parliamentary Committee (JPC) due to concerns from allies and opposition parties.",
          styling: [],
        },
        {
          id: "b26f62f8-b8ed-4f54-ab52-d17de64a721e",
          point:
            "Changes such as abolition of waqf by a user and granting excess powers to the District Magistrate lower the protection to waqf properties.",
          styling: [],
        },
        {
          id: "5b543b73-ee34-4287-8429-68725d0e6c99",
          point:
            "The Bill proposes positive reforms like digitisation of waqfs and inclusion of women and non-Muslims in waqf boards.",
          styling: [],
        },
        {
          id: "e441bd79-c1b8-4f1b-8187-b38c879e32a7",
          point:
            "The concept of family waqf has historical and theological underpinnings, serving both charitable and protective functions.",
          styling: [],
        },
        {
          id: "3064b218-c7bc-4d07-982e-3d414beeae98",
          point:
            "Scholars have critiqued family waqfs, arguing they sometimes undermine women's inheritance rights.",
          styling: [],
        },
        {
          id: "541132bb-32cb-452b-b521-771454363a42",
          point:
            "Globally, various Muslim countries have restricted or abolished family waqfs; in India, family waqfs lack privileges and statutory supervision.",
          styling: [],
        },
        {
          id: "c1409d6e-613d-4c12-9264-716b25eca496",
          point:
            "The 2024 Bill's Section 3A(2) proposes that family waqfs should not deny inheritance rights to heirs, including women.",
          styling: [],
        },
        {
          id: "3d537898-2c49-4558-a2f4-56f9afce3915",
          point:
            "The Bill's provisions need improvement to ensure similar restrictions on testamentary powers of all, paving the way for a Uniform Civil Code.",
          styling: [],
        },
        {
          id: "e0f5a31b-327d-4d98-91dc-3462c56d12be",
          point:
            "The Joint Parliamentary Committee (JPC) needs to acknowledge concerns and improve the Bill to ensure positive features are upheld.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13503,
    bookmarks: 0,
    title: "Want FDI, but follow law of the land: Goyal to e-tailers",
    gpt_output: {
      summary: [
        {
          id: "373ca834-656f-44d2-8b23-ce6ea130ac62",
          point:
            "Commerce and Industry Minister Piyush Goyal emphasized that while the government encourages Foreign Direct Investment (FDI) and advanced technology, e-commerce platforms must adhere to the law and practice fair play and honesty in business.",
          styling: [],
        },
        {
          id: "51b6b8c3-a3c3-490b-a358-a736eb146cc6",
          point:
            "The Minister reiterated that FDI in online services is only permitted for business-to-business transactions, aimed at allowing small retailers to survive and coexist with online services.",
          styling: [],
        },
        {
          id: "55e333bf-9035-4a54-b071-777267a1dd2e",
          point:
            "Concerns have been raised about the impact of e-commerce on around 100 million small retailers across India, highlighting the need for fair competition.",
          styling: [],
        },
        {
          id: "cfd73a80-37b0-4142-bccb-6103308cce09",
          point:
            "The government desires fair play and honesty for both customers and suppliers in the e-commerce sector.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13512,
    bookmarks: 0,
    title: "PM to meet unions of govt. employees",
    gpt_output: {
      summary: [
        {
          id: "e674d2df-1325-4d6f-8836-8ebe252ba5c5",
          point:
            "For the first time in 10 years, Prime Minister Narendra Modi will meet staff representatives of the Joint Consultative Machinery (JCM) and the Personnel Ministry.",
          styling: [["<strong>", 22, 30]],
        },
        {
          id: "e029128c-a4fe-4f91-8509-eec0e707a788",
          point:
            "Issues like restoring the old pension scheme (OPS) and stopping privatisation and corporatisation of PSUs are likely to be discussed.",
          styling: [
            ["<strong>", 12, 50],
            ["<strong>", 64, 105],
          ],
        },
        {
          id: "8040aee0-c7ed-401b-9550-57b151e41abf",
          point:
            "The unions had planned an indefinite strike from May 1, which was deferred following discussions with the government.",
          styling: [["<strong>", 26, 43]],
        },
        {
          id: "ef6b858e-22e9-41a6-97aa-e60314bc0a19",
          point:
            "Employees have also been asking the Centre to fill vacant posts in various departments.",
          styling: [["<strong>", 46, 63]],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13499,
    bookmarks: 0,
    title: "The Kursk gambit, Ukrainian tactics and battle realities",
    gpt_output: {
      summary: [
        {
          id: "f88131bc-c4ab-4831-945e-3eb83b85f852",
          point:
            "Ukraine launched a surprise attack into the Kursk region of Russia, capturing several hundred Russian soldiers in a bold and risky move led by President Volodymyr Zelenskyy.",
          styling: [["<strong>", 116, 135]],
        },
        {
          id: "2826ce75-48f1-44aa-8bc5-a4f2e36da1bd",
          point:
            "Pokrovsk, a critical logistics hub in Eastern Ukraine, is under siege by Russian forces. Ukrainian forces are being ordered to withdraw, paving the way for Russia to capture the entire Donetsk region.",
          styling: [],
        },
        {
          id: "ee741518-91f5-435c-bd3e-1d18b217d9f9",
          point:
            "Ukraine's offensive into Kursk stands out as no country has invaded any part of Russia since Hitler’s Germany. This operation was prepared in secrecy and aimed at forcing Russia to redeploy its forces.",
          styling: [["<strong>", 45, 109]],
        },
        {
          id: "6185ab36-6681-4a69-a263-533028e25f17",
          point:
            "Despite Ukrainian gains in Kursk, Russia retains the advantage in overall territorial control, particularly in Eastern Ukraine where it controls approximately 100,000 square kilometers.",
          styling: [],
        },
        {
          id: "226c81df-936a-4cd5-bf52-cf1e7ec5c979",
          point:
            "While Kyiv’s gains in Kursk are impressive, it faces the risk of overstretching its forces and encountering a concentrated Russian counter-attack, potentially leading to significant losses and retreat.",
          styling: [],
        },
        {
          id: "655d6833-aa9f-4e74-bfdd-e291dcf092cd",
          point:
            "Ukraine's incursion is partially intended to signal its capability to its western allies, ensuring continued support.",
          styling: [["<strong>", 56, 116]],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Defence & Security",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13514,
    bookmarks: 0,
    title: "What is vaccine-derived polio?",
    gpt_output: {
      summary: [
        {
          id: "1b07df32-58de-4b3c-82c0-e0886eb6367a",
          point:
            "A two-year-old child in Meghalaya has been infected with vaccine-derived polio, which occurs when the weakened strain of poliovirus in the oral polio vaccine mutates and regains the ability to cause paralysis.",
          styling: [["<strong>", 57, 78]],
        },
        {
          id: "e04e1469-ae18-4a79-9ab4-ddef4a4b94c4",
          point:
            "Vaccine-derived polio spreads in areas with low immunisation, poor sanitation, or among immunocompromised individuals.",
          styling: [],
        },
        {
          id: "46aab345-12b6-481e-a070-a07b038ec9c7",
          point:
            "The World Health Organization classifies the virus as 'circulating' if it is detected in at least two different sources, two months apart, showing community transmission.",
          styling: [],
        },
        {
          id: "0d84ebf7-1e7d-4db5-a7f7-2f574633237d",
          point:
            "Three types of polioviruses exist: wild poliovirus type 1, type 2, and type 3, but symptomatically, all strains are identical.",
          styling: [],
        },
        {
          id: "5637e071-068e-4537-b149-f52d684f7e2b",
          point:
            "The first polio vaccine was developed by Jonas Salk, followed by the oral polio vaccine by Albert Sabin, which while easier to administer, carries a risk of reverting to a virulent form.",
          styling: [],
        },
        {
          id: "9d55e5b6-ccaf-4a22-a20b-d5acad958d2d",
          point:
            "On World Polio Day 2019, WHO declared WPV3 eradicated; WPV2 was declared eradicated in 2015, but vaccine-derived type 2 outbreaks have increased post-2016.",
          styling: [],
        },
        {
          id: "f1ae046b-f749-455a-91af-5c2fd6021a7a",
          point:
            "The WHO approved a genetically modified type 2 novel oral polio vaccine in 2020 that is less likely to revert to a harmful form.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Social Justice",
        "GS-III Science & Technology",
        "GS-III Environment & Ecology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13496,
    bookmarks: 0,
    title: "Death at work",
    gpt_output: {
      summary: [
        {
          id: "946ee94e-cdf1-4617-b39a-4098da77c84c",
          point:
            "The recent blast at pharma company Escientia's plant in the Atchutapuram SEZ, Andhra Pradesh, is the worst industrial accident in the region recently, with 17 workers dead and many injured.",
          styling: [["<strong>", 156, 188]],
        },
        {
          id: "da33af0e-845b-4f97-aaa2-9eafdb49f194",
          point:
            "The Atchutapuram SEZ, home to over 100 companies, has experienced multiple accidents, raising serious questions about industrial safety in Andhra Pradesh.",
          styling: [],
        },
        {
          id: "64e2c831-52a1-4b6f-9abd-f9ceaf1d2761",
          point:
            "State Home Minister V. Anitha stated that workers attempted to plug a solvent leak of MTBE, which is highly flammable and caused the fire and blast.",
          styling: [],
        },
        {
          id: "50364fe0-f44e-49a1-abfe-b39e510ffe34",
          point:
            "Trade unions and activists are advocating for stringent punishment for lax managements and demand an immediate and thorough safety audit of all SEZ units in Andhra Pradesh.",
          styling: [["<strong>", 46, 66]],
        },
        {
          id: "ba885830-c738-4765-8b87-0c8a42c5c142",
          point:
            "Exemptions from government inspections, introduced to ease business operations, are being scrutinized due to the series of accidents calling for a broader probe.",
          styling: [["<strong>", 113, 160]],
        },
        {
          id: "dc6c9176-4ed0-4fc7-bd87-610a8b02f3cc",
          point:
            "Experts call for swift justice, including punitive damages, to prevent the recurrence of such accidents and ensure compliance with safety norms.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-III Economic Development",
        "GS-III Environment & Ecology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13502,
    bookmarks: 0,
    title: "Shift in inflation goal can trip economic prospects: Patra",
    gpt_output: {
      summary: [
        {
          id: "6fdd3143-d682-4ad3-866f-9ce17a3c76f2",
          point:
            "The Monetary Policy Committee's (MPC) approach to align inflation with the 4% target influenced its decision to hold policy rates, minutes from the recent meeting reveal.",
          styling: [],
        },
        {
          id: "fd4aa43a-42d8-4e95-92ea-86e5445b6378",
          point:
            "Deputy Governor Michael Debabrata Patra highlighted that the gap between headline and food inflation is widening, making it difficult to align overall inflation with targets.",
          styling: [],
        },
        {
          id: "671ca9ac-96cc-4ab3-b244-87e5e8517bae",
          point:
            "Persistent double-digit inflation in key food categories such as cereals, pulses, and vegetables is causing food inflation to revert to its trend more slowly after shocks.",
          styling: [],
        },
        {
          id: "9fe1f5c9-064a-40b0-9740-9518088c2744",
          point:
            "RBI Governor Shaktikanta Das noted that while inflation is gradually slowing, the process is slow and uneven.",
          styling: [],
        },
        {
          id: "f18c9cdb-82b3-43bd-a244-4ab730632c2a",
          point:
            "Das also mentioned that discussing equilibrium natural interest rates is premature as durable disinflation is still ongoing.",
          styling: [],
        },
        {
          id: "0e94e31e-6012-47cc-b309-701c132a064c",
          point:
            "Policy decisions should not be based on abstract, theoretical constructs, as real-world policymaking requires observable and consistent factors.",
          styling: [],
        },
        {
          id: "927936f9-7b35-4416-888e-8d4721e594f6",
          point:
            "Any justification for policy easing based on high real rates can be misleading.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-III Economic Development"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13497,
    bookmarks: 0,
    title: "Intriguing silence",
    gpt_output: {
      summary: [
        {
          id: "9c3cc51a-0d2e-4a13-9d1f-ff89a4de917c",
          point:
            "A case of acute flaccid paralysis, likely due to polio, was reported in a child from Tikrikilla, Meghalaya, as confirmed by the State government on August 14.",
          styling: [["<strong>", 10, 33]],
        },
        {
          id: "ae0bd616-8b73-49d9-8646-49dde94e9a72",
          point:
            "In April 2022, a case of vaccine-derived poliovirus was detected from an environmental sample collected in Kolkata.",
          styling: [["<strong>", 25, 51]],
        },
        {
          id: "2461e1d3-24d8-4159-8adf-184571add2fc",
          point:
            "There is no official confirmation if the Meghalaya case is due to vaccine-derived or wild poliovirus.",
          styling: [["<strong>", 66, 100]],
        },
        {
          id: "2aa848e4-b48d-43bd-a6c9-55679ee0ccb3",
          point:
            "Globally, the wild poliovirus (WPV) type 2 was eradicated in 2015 and WPV type 3 in 2019, making it unlikely to be WPV type 1 unless imported into India.",
          styling: [],
        },
        {
          id: "e37b3c65-43f8-4b7f-9468-fd61496d1728",
          point:
            "As of August 13, Afghanistan and Pakistan have reported 14 WPV type 1 cases this year.",
          styling: [],
        },
        {
          id: "6e21961d-bd22-4bb5-aace-14b300f1c1ec",
          point:
            "It is unclear whether the poliovirus in the Meghalaya case is immunodeficiency-related vaccine-derived poliovirus (iVDPV) or circulating vaccine-derived poliovirus (cVDPV).",
          styling: [["<strong>", 62, 171]],
        },
        {
          id: "bf768977-11e3-4296-ab3e-24ff9b3f2a15",
          point:
            "India switched from trivalent to bivalent oral polio vaccine in 2016, which contains only live, attenuated type 1 and type 3 virus.",
          styling: [["<strong>", 33, 60]],
        },
        {
          id: "643a3f10-1369-4ae3-b06f-1e0da2df2c88",
          point:
            "Immunodeficient children are not supposed to be administered oral polio vaccine (OPV), which poses a challenge in India.",
          styling: [["<strong>", 61, 85]],
        },
        {
          id: "e60a45f9-ee66-4870-991a-4cbba0fa8e91",
          point:
            "The Meghalaya case highlights the need for India to switch exclusively to inactivated polio vaccine (IPV), a practice adopted by most developed countries.",
          styling: [["<strong>", 52, 105]],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-III Science & Technology",
        "GS-III Environment & Ecology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13505,
    bookmarks: 0,
    title: "‘Better reservoir levels may curb food inflation’",
    gpt_output: {
      summary: [
        {
          id: "9abd9ce7-79b4-4b53-80e7-d40497c56aa0",
          point:
            "Despite an erratic monsoon, India's reservoirs have been replenished, which is positive for kharif and rabi farm output, potentially curbing food inflation in the coming months.",
          styling: [["<strong>", 28, 68]],
        },
        {
          id: "d3c36ee1-6880-4736-a9e4-d6aef588188b",
          point:
            "The Finance Ministry projects real GDP growth of 6.5%-7% for this year and notes that inflation is moderating.",
          styling: [["<strong>", 30, 70]],
        },
        {
          id: "4403af2f-5749-4aaf-a826-1f4a8205630d",
          point:
            "The rise in exports and imports in the first four months of this fiscal year suggests a trend reversal with global demand recovering.",
          styling: [["<strong>", 86, 132]],
        },
        {
          id: "873ba87e-7789-4fef-9ab6-690d11dd32c6",
          point:
            "The Ministry emphasizes the need to monitor consumer confidence and industrial outlook surveys conducted by the central bank for future trends.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-III Economic Development"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13518,
    bookmarks: 0,
    title:
      "Modi’s Ukraine visit likely to explore deeper ties in defence manufacturing",
    gpt_output: {
      summary: [
        {
          id: "c6d694ae-d158-49c7-9225-82b573e262df",
          point:
            "Prime Minister Narendra Modi is scheduled to visit Ukraine, marking the first visit by an Indian Prime Minister to the country. The visit aims to enhance cooperation in defence manufacturing.",
          styling: [["<strong>", 154, 190]],
        },
        {
          id: "81bd6030-7e8e-4b3c-a700-4582fde9cc1b",
          point:
            "India relies on a significant inventory of military equipment from both Russia and Ukraine. The ongoing war in Ukraine has disrupted supply chains, pushing India to diversify its vendor base, including turning to domestic companies.",
          styling: [],
        },
        {
          id: "f05cf5f8-9c0b-4db3-b884-271aa02dc671",
          point:
            "Ukraine has expressed interest in forming joint ventures with Indian companies for several cutting-edge military technologies that are battle-tested.",
          styling: [["<strong>", 91, 125]],
        },
        {
          id: "8f5aee62-d043-4444-8290-94d72d32d6f3",
          point:
            "The Indian military, including the Army, Navy, and Air Force, has been impacted by the war in Ukraine, affecting air defence, armour, artillery, and naval systems.",
          styling: [],
        },
        {
          id: "5dbe068c-e5c8-4c92-8e5f-e3e2b775526b",
          point:
            "To mitigate the impact, the Indian Army has engaged with domestic vendors and diversified its vendor base to countries like Poland, Estonia, Bulgaria, and the Czech Republic for smaller components and sub-assemblies.",
          styling: [],
        },
        {
          id: "ac4d7c80-0efb-47dd-b5a6-a60c23a18510",
          point:
            "Issues related to payments have arisen with Russia, especially after being excluded from the global SWIFT system. This has affected the rupee-rouble trade.",
          styling: [],
        },
        {
          id: "180e7ee8-ccfa-4956-be26-7e97e7e984a2",
          point:
            "Prior to the war, Ukraine was involved in various defence contracts with India, including the upgrade of IAF's AN-32 transport aircraft and supplying engines for the Indian Navy's front-line warships.",
          styling: [],
        },
        {
          id: "f5bcc245-b465-45ad-afb8-13f91719f6e2",
          point:
            "India has made emergency procurements from Ukraine, such as R-27 air-to-air missiles for its SU-30MKI fighters following the Balakot airstrike in 2019.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-III Defence & Security",
        "GS-II International Relations",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13509,
    bookmarks: 0,
    title:
      "Slaying of the Sarkata: changing portrayal of the female protagonist in 2024",
    gpt_output: {
      summary: [
        {
          id: "7a2c1ec6-c338-4852-8bc1-6c9753d38887",
          point:
            "The article discusses the evolving portrayal of female protagonists in Indian cinema through movies like Laapataa Ladies, Ullozhukku, and Stree 2.",
          styling: [["<strong>", 105, 145]],
        },
        {
          id: "99245044-fc6a-45ca-91df-81630a720fb1",
          point:
            "It highlights how these films are challenging entrenched societal norms and exposing deep-seated patriarchy in society.",
          styling: [["<strong>", 34, 71]],
        },
        {
          id: "1504b5e3-a27f-4172-80b1-2469fab0376f",
          point:
            "Women in these films exhibit agency to dismantle social mores and fight injustice, reflecting broader societal shifts.",
          styling: [["<strong>", 0, 20]],
        },
        {
          id: "db74f7e8-4778-41c4-9f0f-9f8edb131aa1",
          point:
            "The release of the Justice Hema Committee report revealed rampant harassment and discrimination faced by women in the Malayalam film industry, encouraging wider discussions on gender issues.",
          styling: [["<strong>", 58, 95]],
        },
        {
          id: "2f04e640-de81-48d2-ae22-547cdf38bd04",
          point:
            "Complex relationships, patriarchal norms, and social issues are key themes in the movies discussed, showing women navigating and resisting these challenges.",
          styling: [["<strong>", 108, 138]],
        },
        {
          id: "ec57b187-12d9-4878-ad00-6520297a2733",
          point:
            "Highlighted real-life incidents from August, including the murder of a medical student and a wrestler being fat-shamed, underscore the ongoing challenges women face in society.",
          styling: [],
        },
        {
          id: "09e765b3-96e6-4296-8b60-fa24a4ad2bf8",
          point:
            "The films mentioned depict women taking control of their destinies and standing up against injustice, embodying progressive and empowered female characters.",
          styling: [["<strong>", 112, 155]],
        },
      ],
      mains_syllabus: [
        "GS-II Social Justice",
        "GS-I History of the World & Society",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13519,
    bookmarks: 0,
    title: "Tripura dam didn’t cause floods: MEA",
    gpt_output: {
      summary: [
        {
          id: "593dff6c-0afd-451e-80b7-1ea87ea5948e",
          point:
            "The Ministry of External Affairs (MEA) denied that the recent floods in eastern Bangladesh were caused by opening the Dumbur dam in Tripura.",
          styling: [["<strong>", 118, 139]],
        },
        {
          id: "71cf90cd-3c14-4864-bfce-d3a0553da0ac",
          point:
            "MEA clarified that heavy rainfall in western Tripura and parts of Bangladesh was responsible for flooding downstream in Bangladesh.",
          styling: [],
        },
        {
          id: "74d1b094-3314-4e59-b548-ecc610d92d75",
          point:
            "The Dumbur dam is on the Gumti river, and had been 'auto releasing' water due to rainfall, a standard safety feature.",
          styling: [
            ["<strong>", 4, 14],
            ["<strong>", 51, 67],
          ],
        },
        {
          id: "6e115d7e-17f3-4e4f-9fdd-11edff12336e",
          point:
            "Indian High Commissioner to Bangladesh, Pranay Verma, expressed concern over security threats to the Indian mission in Dhaka following the accusations.",
          styling: [],
        },
        {
          id: "faedf295-5bfd-41d2-b46d-8cd63edf03cc",
          point:
            "The MEA emphasized that the flooding was a result of heavy rainfall in the catchment areas downstream of the dam, not from the dam itself.",
          styling: [["<strong>", 4, 18]],
        },
        {
          id: "f68ce4ec-b05a-471f-9adb-8f9e5fb8998c",
          point:
            "The Dumbar dam, located over 120 km upstream of Bangladesh, is a low-height dam (about 30m) and generates power, part of which is supplied to Bangladesh.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Environment & Ecology",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13532,
    bookmarks: 0,
    title: "Centre announces simplified norms for seaplane operations",
    gpt_output: {
      summary: [
        {
          id: "0e7271ca-56b7-45e0-8b65-82792f591776",
          point:
            "The Civil Aviation Ministry has introduced new rules for seaplanes, allowing non-scheduled operators to provide such services and simplifying norms to boost regional air connectivity under the UDAN scheme.",
          styling: [],
        },
        {
          id: "92b700e7-a2d6-4578-827b-f51ff8755aad",
          point:
            "Key changes include the removal of the requirement for a waterdrome licence and reduced compliance requirements, allowing pilots with a Commercial Pilot Licence (CPL) to qualify as seaplane-rated pilots.",
          styling: [],
        },
        {
          id: "0f14c057-c28e-4153-be7d-6e22aeb44ce7",
          point:
            "The guidelines also define the roles of various stakeholders like State governments, seaplane operators, and the Centre.",
          styling: [],
        },
        {
          id: "50ecdf06-0818-4b3f-aaf0-ad3aa1ca3f50",
          point:
            "Seaplanes are seen as an opportunity to enhance connectivity in areas lacking conventional airport infrastructure due to land constraints, potentially fostering economic growth and tourism.",
          styling: [],
        },
        {
          id: "a22608ca-cbf3-4d5d-be55-91821e0ab362",
          point:
            "Civil Aviation Ministry has urged States to identify suitable waterbodies to activate these services.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-III Science & Technology",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13525,
    bookmarks: 0,
    title: "Centre will set up facilities for media creators: Minister",
    gpt_output: {
      summary: [
        {
          id: "5473fefb-50fd-41cf-a05b-ddcb5896c8d7",
          point:
            "The Centre is taking steps to establish world-class facilities in India to boost the capabilities of creators in the media and entertainment sector.",
          styling: [["<strong>", 40, 62]],
        },
        {
          id: "dbd3605f-35e7-4ca8-abcb-e094600b98c0",
          point:
            "The World Audio Visual & Entertainment Summit (WAVES) highlighted India’s new creators’ economy, recognized by Prime Minister Narendra Modi at the National Creators Award ceremony.",
          styling: [["<strong>", 74, 95]],
        },
        {
          id: "a2462967-4f5a-4250-9180-f7dd7c42dde9",
          point:
            "The creators’ economy is seen as an effective tool to showcase India’s cultural heritage, including way of life, yoga, traditional medicine, and diverse cuisines.",
          styling: [["<strong>", 71, 88]],
        },
        {
          id: "5f93a5fa-f6f1-4994-9702-32174150c5e4",
          point:
            "Measures are being taken by the Centre to encourage the ecosystem for media and entertainment creators.",
          styling: [["<strong>", 42, 65]],
        },
      ],
      mains_syllabus: [
        "GS-I Indian Culture & Heritage",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13531,
    bookmarks: 0,
    title: "FSSAI orders removal of A1, A2 milk claims from products",
    gpt_output: {
      summary: [
        {
          id: "65a689ae-c0fc-41c4-9897-43566c972831",
          point:
            "The Food Safety and Standards Authority of India (FSSAI) has directed food businesses and e-commerce players to remove misleading claims of 'A1' and 'A2' types of milk and milk products from their packaging.",
          styling: [["<strong>", 119, 136]],
        },
        {
          id: "6ba0ab34-01d8-4e54-948d-a7391dc9e6d2",
          point:
            "FSSAI stated that such claims do not conform to the Food Safety and Standards Act, 2006, as the A1 and A2 differentiation is linked to the structure of beta-casein protein in milk, which is not recognized by current regulations.",
          styling: [],
        },
        {
          id: "29517a1b-2c9a-4f1c-bb28-23066e7ad752",
          point:
            "Food business operators (FBOs) have been instructed to comply with this directive.",
          styling: [["<strong>", 55, 61]],
        },
      ],
      mains_syllabus: ["GS-II Constitution, Governance & Polity"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13515,
    bookmarks: 0,
    title: "Mamata writes to PM for stringent Central law on sexual assault",
    gpt_output: {
      summary: [
        {
          id: "38bf11cd-d255-46c1-8841-9cd891365fee",
          point:
            "West Bengal Chief Minister Mamata Banerjee wrote to Prime Minister Narendra Modi calling for a stringent Central legislation with exemplary punishment for perpetrators of sexual assault.",
          styling: [["<strong>", 130, 150]],
        },
        {
          id: "692aa845-dda6-49cd-8f3a-cc1e87441387",
          point:
            "Ms. Banerjee highlighted the regular and increasing incidents of sexual assault across the country, citing data that over 90 cases are occurring every day.",
          styling: [
            ["<strong>", 29, 51],
            ["<strong>", 122, 154],
          ],
        },
        {
          id: "93a1625d-22a7-43de-815e-475f0eba2d05",
          point:
            "She suggested the setting up of fast-track special courts for speedy trials, aiming to complete trials in such cases within 15 days.",
          styling: [
            ["<strong>", 32, 57],
            ["<strong>", 124, 131],
          ],
        },
        {
          id: "ae667561-045c-40bd-96d8-1f8659fd7c9d",
          point:
            "The Central Bureau of Investigation (CBI) sought permission from a court to conduct polygraph tests on medical professionals associated with the R.G. Kar Medical College and an accused civic police volunteer.",
          styling: [],
        },
        {
          id: "56dd3d0b-2da7-4551-93fc-da2d263ebd31",
          point:
            "The CBI's involvement and judicial actions underline the severity and seriousness of the ongoing cases.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13528,
    bookmarks: 0,
    title: "Will form panel to solve problems of farmers: SC",
    gpt_output: {
      summary: [
        {
          id: "f10a318e-a091-4371-8af9-0351226324ad",
          point:
            'The Supreme Court will form a multi-member committee to amicably resolve farmers\' grievances "for all times".',
          styling: [],
        },
        {
          id: "641c4e18-8dd4-4a6c-bf47-96ebc23e2e66",
          point:
            "A Bench of Justices Surya Kant, Dipankar Datta, and Ujjal Bhuyan has scheduled further hearings on September 2 and asked the Punjab and Haryana governments to submit tentative issues concerning farmers to the committee.",
          styling: [],
        },
        {
          id: "5a2e4972-79b0-4dae-8789-a36ce3117973",
          point:
            "The Punjab and Haryana governments are encouraged to engage with protesting farmers to persuade them to remove their tractors and trollies from the highway.",
          styling: [],
        },
        {
          id: "40e5cf55-96eb-4590-98e2-ee7ff3b3cba8",
          point:
            "The Punjab government reported to the Supreme Court that in compliance with its order, a meeting was held with protesting farmers, leading to a partial opening of the blocked highway.",
          styling: [],
        },
        {
          id: "c0740d06-6c5d-4d0d-8be8-5eab180d56b2",
          point:
            "The court was hearing Haryana government's plea challenging the high court's order to remove barricades at the Shambhu border near Ambala, where farmers have been camping since February 13.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13524,
    bookmarks: 0,
    title:
      "EC to deploy over 400 observers for J&K, Haryana Assembly elections",
    gpt_output: {
      summary: [
        {
          id: "b2a9285c-c125-4af1-807e-3f2945f68e06",
          point:
            "The Election Commission (EC) will deploy over 400 observers for the Assembly elections in Jammu and Kashmir and Haryana, including general observers, police observers, and expenditure observers.",
          styling: [["<strong>", 46, 59]],
        },
        {
          id: "3d2e26c0-20b1-4a53-be54-c87c56a0d863",
          point:
            "Observers are officers from the IAS, IPS, IRS, and other Central Services, deployed under Section 20B of the Representation of the People Act, 1951 and the Constitution's plenary powers.",
          styling: [],
        },
        {
          id: "9ecef453-ceb6-4c7a-a4d4-6551d179453f",
          point:
            "Chief Election Commissioner Rajiv Kumar emphasizes overcoming language barriers and ensuring access to all parties and voters.",
          styling: [],
        },
        {
          id: "02e46641-2941-4350-897f-5d32f1463b6a",
          point:
            "Election Commissioner Gyanesh Kumar stated that the role of observers is crucial in keenly contested Assembly elections.",
          styling: [],
        },
        {
          id: "bff16c1a-86b9-42fd-87a5-09b22821cb8d",
          point:
            "Observers are directed to remain accessible to all political parties, candidates, and voters to address grievances promptly.",
          styling: [["<strong>", 33, 43]],
        },
        {
          id: "f7f6febf-a226-46d3-be4e-4eeed016b84b",
          point:
            "The special summary revision of electoral rolls in J&K added 93,000 new voters and increased polling stations by 209.",
          styling: [["<strong>", 61, 78]],
        },
        {
          id: "ca7dbd3d-427d-4f10-90a3-98d4a45f07f1",
          point:
            "The number of young voters (18-19 age group) in J&K increased by 45,964, with 24,310 being women. Final electoral rolls for Haryana will be published on August 27.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-II Constitution, Governance & Polity"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13510,
    bookmarks: 0,
    title:
      "Modi attempts a tricky balance in Kyiv, as U.S., Russia watch closely",
    gpt_output: {
      summary: [
        {
          id: "cdeb97db-2b7f-4302-b8f1-02e7aaaa2eee",
          point:
            "Prime Minister Narendra Modi's visit to Kyiv marks the first high-level visit from India since the Russia-Ukraine war began in 2022, aimed at balancing India's position on the conflict.",
          styling: [],
        },
        {
          id: "84135580-d351-4b6b-8d9c-8c670f64bd9d",
          point:
            "Ukraine has expressed disappointment with India's stance in the past, particularly regarding reconstruction and equipment supplies requests, and India's abstention from critical UN resolutions.",
          styling: [["<strong>", 0, 68]],
        },
        {
          id: "27049d45-fdad-4b22-9d8a-43422510441f",
          point:
            "The visit is significant given the failure of the Swiss peace summit and the increasing concerns of European nations about the war's costs.",
          styling: [],
        },
        {
          id: "4b602710-e8de-4240-96ca-e6b3518ad503",
          point:
            "India has an opportunity to initiate a new global conversation on ending the conflict and bringing parties to the negotiation table.",
          styling: [["<strong>", 0, 85]],
        },
        {
          id: "da65c0aa-740e-4964-9da4-e265e014c2b3",
          point:
            "India's willingness to participate in Ukraine's reconstruction, especially in infrastructure, technology, and healthcare, will be key to future cooperation.",
          styling: [],
        },
        {
          id: "a67f42ca-2645-427e-bd23-c293f79de963",
          point:
            "India could play a crucial role in facilitating peace, reinforcing its global influence and commitment to a stable international order.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Economic Development",
        "GS-III Defence & Security",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13517,
    bookmarks: 0,
    title: "Resident doctors’ group in Delhi calls off strike",
    gpt_output: {
      summary: [
        {
          id: "118da380-d4d2-4802-835d-9d32cb876ff9",
          point:
            "The Federation of All India Medical Association (FAIMA) ended its 11-day strike following directions from the Supreme Court for protesting doctors to resume work with assured protections.",
          styling: [
            ["<strong>", 90, 123],
            ["<strong>", 150, 186],
          ],
        },
        {
          id: "e3b89b58-583c-4ce1-b743-b91182b1a02e",
          point:
            "The strike was called off by Resident Doctors’ Associations (RDAs) of AIIMS-Delhi, RML Hospital, Lady Hardinge Medical College, and Indira Gandhi Hospital.",
          styling: [],
        },
        {
          id: "f4632954-6a5a-4a8e-8f3d-a98fc8244064",
          point:
            "AIIMS RDA announced that doctors would continue their protest symbolically after duty hours until safety guidelines are implemented.",
          styling: [],
        },
        {
          id: "82dbfd6a-1473-4bd4-90a7-6f360542ea48",
          point:
            "FAIMA welcomed the interim protections and enhanced hospital security, emphasizing their continued legal struggle.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Social Justice",
        "GS-II Constitution, Governance & Polity",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13501,
    bookmarks: 0,
    title: "The second-hand grief of an editor",
    gpt_output: {
      summary: [
        {
          id: "9ff0d961-54ec-4439-80d5-33adcfa3c2d3",
          point:
            "The article discusses the emotional toll on editors, who experience second-hand grief while editing stories of tragedies.",
          styling: [["<strong>", 68, 85]],
        },
        {
          id: "d2dfaa8a-1aaa-47cf-adae-ab200fdd1e25",
          point:
            "A young reporter reflects on the irony of rising prices and diminishing value of human life after covering a wall collapse that killed nine children in Madhya Pradesh.",
          styling: [["<strong>", 109, 122]],
        },
        {
          id: "a67fe354-378d-47d3-9c99-9a73bb461e3f",
          point:
            "Editors aim to present the truth to readers while avoiding trauma triggers and holding those responsible accountable.",
          styling: [],
        },
        {
          id: "d4060465-4175-4d5f-914a-19d831ba7148",
          point:
            "Unlike reporters, editors deal with 'micro-grief' from various tragic stories, which accumulates over time.",
          styling: [],
        },
        {
          id: "251964cc-1435-40fa-86f3-987a75602021",
          point:
            "The article references the five stages of grief, highlighting the limited scope for journalists to process these stages fully.",
          styling: [["<strong>", 27, 47]],
        },
        {
          id: "6b6708c8-98af-42b2-a923-b53b416ed5e7",
          point:
            "Editors often manage multiple aspects of a story, including fact-checking and providing context, which adds to their emotional burden.",
          styling: [],
        },
        {
          id: "9eff7606-9fdc-4941-aa9e-d3b619dec501",
          point:
            "The emotional impact on editors is likened to the effect of second-hand smoke, affecting their well-being and sleep.",
          styling: [["<strong>", 60, 77]],
        },
      ],
      mains_syllabus: ["GS-II Governance"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13523,
    bookmarks: 0,
    title:
      "Opposition members raise an array of objections to Waqf Amendment Bill",
    gpt_output: {
      summary: [
        {
          id: "979c1ba7-ecba-4c8e-946b-1b49bfc0ed0d",
          point:
            "The Opposition raised objections to the Waqf (Amendment) Bill, citing 'excessive interference' by the government, inclusion of non-Muslim members, and the requirement for 'deed records' for Waqf properties.",
          styling: [
            ["<strong>", 70, 94],
            ["<strong>", 114, 145],
            ["<strong>", 171, 185],
          ],
        },
        {
          id: "a581440a-3d04-4065-a122-fa44ac0df95d",
          point:
            "The BJP's former ally, the YSR Congress, and other NDA partners either strongly opposed the amendments or took a middle path.",
          styling: [],
        },
        {
          id: "a36837cd-3e78-42c9-9336-18bfe5a91de0",
          point:
            "The Joint Committee's report is due before the Winter Session, likely in the last week of November.",
          styling: [],
        },
        {
          id: "5031583f-5928-4df3-a8ae-1e173438d785",
          point:
            "There was a near-unanimous demand among Opposition parties to amend the clause designating the District Collector as the primary authority for classifying Waqf or government land.",
          styling: [],
        },
        {
          id: "f5a883f3-a4d2-4b3e-a36c-3eb9eeab72e5",
          point:
            "AIMIM MP Asaduddin Owaisi criticized the government for 'cherry-picking' recommendations from the Sachar Committee report.",
          styling: [["<strong>", 56, 72]],
        },
        {
          id: "b4d959b5-af0e-48d0-b6cd-1e4c1e51efa8",
          point:
            "Samajwadi Party MP Mohibbullah argued against the property deed requirement for Waqf properties, citing different expectations for temples.",
          styling: [],
        },
        {
          id: "463795b6-f04a-4474-97f6-8335d35f6aad",
          point:
            "The YSR Congress also opposed allowing sub-sects like 'Aghakhani' and 'Bohra' Muslims to have their own Waqf Boards, calling it divisive.",
          styling: [],
        },
        {
          id: "1bbc5d59-16b8-411c-976b-3cefcbf7b1a1",
          point:
            "The Telugu Desam Party suggested that the law should be strengthened while addressing everyone's concerns.",
          styling: [],
        },
        {
          id: "8fbcd6d4-2dba-47d8-bc9d-e013ff8c647f",
          point:
            "The panel's Chairperson, Jagdambika Pal, stated that they aim to bring a good and comprehensive bill by the next session.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13506,
    bookmarks: 0,
    title: "‘Q1 GDP growth may be at 6%, a 6-quarter low’",
    gpt_output: {
      summary: [
        {
          id: "ae7405cf-6ada-4a3b-9b96-97be75f44d2b",
          point:
            "India’s GDP growth is likely to have slowed to a six-quarter low of 6% in the April-June period, driven by a decline in urban consumers’ confidence levels and a dip in government capital expenditure.",
          styling: [["<strong>", 68, 70]],
        },
        {
          id: "432a489e-1edb-451b-9b5f-c16ca8e72da8",
          point:
            "Gross Value Added (GVA) is expected to have eased to 5.7% in the first quarter (Q1), from 6.3% in the previous quarter, due to a slowdown in industrial activity.",
          styling: [["<strong>", 53, 57]],
        },
        {
          id: "6755eedd-76c4-4dd7-bb46-e63e71a22803",
          point:
            "Industrial GVA projection for Q1 is 6.4%, down from 8.4% in the January-March period, while services GVA expansion is pegged at 6.5%, compared with 6.7% in the preceding three months.",
          styling: [["<strong>", 36, 40]],
        },
        {
          id: "2938cd73-e8bf-4045-9054-4485b8c6c437",
          point:
            "ICRA projected a minor uptick in farm GVA from 0.6% in the final quarter of last fiscal year to 1% in Q1.",
          styling: [["<strong>", 47, 51]],
        },
        {
          id: "b71533c3-e1a1-462f-ad52-187b82e98336",
          point:
            "Urban consumer confidence reported a surprising downtick in May and July 2024 rounds of the central bank’s consumer confidence survey.",
          styling: [],
        },
        {
          id: "76fe33c8-fdd1-4a51-bc60-3cf8e083bfff",
          point:
            "Heat wave affected footfalls in various services sectors, but boosted electricity demand, resulting in a transient moderation in Q1 performance.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-III Economic Development"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13530,
    bookmarks: 0,
    title: "Railways to run 900 trains for Kumbh Mela in Prayagraj",
    gpt_output: {
      summary: [
        {
          id: "fe466941-cb64-4c68-a93e-f3441f860e8d",
          point:
            "Indian Railways is set to run 900 special trains to Prayagraj for the Kumbh Mela in January 2025.",
          styling: [
            ["<strong>", 30, 48],
            ["<strong>", 70, 80],
          ],
        },
        {
          id: "bcbbb283-98d7-4fc5-896d-24ca5ff6bd32",
          point:
            "An estimated 30 crore pilgrims are expected, with trains being the primary mode of transport.",
          styling: [["<strong>", 13, 30]],
        },
        {
          id: "62852ebf-a0e0-4db6-976b-5071f65e96b9",
          point:
            "Jaya Verma Sinha, Railway Board Chairperson, has inspected preparations at various Prayagraj railway stations.",
          styling: [],
        },
        {
          id: "6a756ec3-8634-4110-8624-fae3e64b801f",
          point:
            "Development of 21 Railway road overbridges and under bridges in Prayagraj is underway to manage the additional crowd.",
          styling: [["<strong>", 15, 60]],
        },
      ],
      mains_syllabus: ["GS-II Constitution, Governance & Polity"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13522,
    bookmarks: 0,
    title: "Delhi police bust ‘terror network’ in three States",
    gpt_output: {
      summary: [
        {
          id: "0289f681-440f-4037-8d0c-bf553e962ce4",
          point:
            "Delhi police have busted an ‘Al-Qaeda-inspired’ terror network active in Rajasthan, Jharkhand, and Uttar Pradesh, detaining 14 suspects.",
          styling: [["<strong>", 28, 62]],
        },
        {
          id: "654be6ce-7aac-4484-b1ef-137c19105812",
          point:
            "The operation was intelligence-based and coordinated by Delhi Police’s Special Cell with the police from three states.",
          styling: [["<strong>", 18, 36]],
        },
        {
          id: "96a4a460-96d8-4a09-b50b-be100f550470",
          point:
            "The network leader, Dr. Ishtiyaq from Ranchi, aspired to declare Khilafat and execute serious terrorist activities within India.",
          styling: [],
        },
        {
          id: "a750c392-7442-4e90-a179-d5a57b8de75c",
          point:
            "Suspects were indoctrinated and trained at various locations for weapon-handling.",
          styling: [["<strong>", 14, 39]],
        },
        {
          id: "0dabe524-ad67-4567-8dab-5732ee0ced3c",
          point:
            "Police have recovered arms, ammunition, documents, and literature from multiple locations and further seizures are ongoing.",
          styling: [],
        },
        {
          id: "c131ff81-75cc-4b5d-99c1-74946a996206",
          point:
            "In Jharkhand, 16 searches were conducted in three districts—Ranchi, Hazaribagh, and Lohardarga—with the involvement of multiple police forces.",
          styling: [],
        },
        {
          id: "be5fbab6-83ac-4bb7-8ce2-13af2c2ff11c",
          point:
            "Illegal weapons, laptops, mobile phones, and other incriminating documents were seized, and further interrogations and searches are planned.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-III Defence & Security",
        "GS-II Constitution, Governance & Polity",
      ],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13504,
    bookmarks: 0,
    title: "Flash PMI signals slight dip in private sector activity this month",
    gpt_output: {
      summary: [
        {
          id: "fa6cd84a-674c-41f9-a6b3-ce17aa78a9a8",
          point:
            "Private sector activity levels likely eased for the second successive month this August.",
          styling: [],
        },
        {
          id: "a4629c55-09e4-4d0a-8bcc-2decd1ac77e3",
          point:
            "New factory orders are rising at the slowest pace since February.",
          styling: [],
        },
        {
          id: "b544b465-1307-4975-8eeb-fcca57e046ae",
          point:
            "Goods prices are being raised at the highest rate in 11 years.",
          styling: [],
        },
        {
          id: "2b043613-053e-4c1d-9a70-589b89afc1a4",
          point:
            "The HSBC Flash India Composite Output Index stood at 60.5 in August, slightly down from 60.7 in July.",
          styling: [],
        },
        {
          id: "5bbefa78-6f69-4a98-8c1a-e2462f00a4f2",
          point:
            "A PMI reading of over 50 indicates an uptick in activity levels.",
          styling: [],
        },
        {
          id: "d4c2f4d8-a7a4-49bd-9017-8b90c9eff935",
          point:
            "The Flash PMI scores are based on responses from about 75% to 85% of the 800 firms surveyed for the PMI.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-III Economic Development"],
      prelims_syllabus: [],
    },
  },
  {
    likes: 0,
    created_at: "2024-08-23T00:00:00+05:30",
    news_paper: {
      id: 1,
      name: "The Hindu",
      last_added: "2024-08-23T00:00:00+05:30",
    },
    id: 13507,
    bookmarks: 0,
    title: "‘Add new airports in India air-service pact’",
    gpt_output: {
      summary: [
        {
          id: "4ddbb758-f522-4ee4-b364-237188bd740f",
          point:
            "Singapore International Airlines’ low-cost arm Scoot wants new airports and markets to be added in India’s air-service agreements, allowing it to expand its India network.",
          styling: [],
        },
        {
          id: "45e32052-2fe0-4dd3-ade8-7b46ca2c1d7e",
          point:
            "Current bilateral agreements between India and Singapore do not include new airports, restricting growing markets with potential.",
          styling: [],
        },
        {
          id: "cce500a3-d28d-493f-90ff-aff340a41cac",
          point:
            "Singapore Airlines has exhausted the number of seats allowed on Indian routes under the existing pact.",
          styling: [],
        },
        {
          id: "d65db9b6-d404-46f3-9838-1bff90879c70",
          point:
            "The demand coincides with the upcoming launch of second airports in the national capital region and Mumbai metropolitan region at Noida and Navi Mumbai.",
          styling: [],
        },
        {
          id: "dcad5ed1-f564-4b49-bc50-39841e43aa61",
          point:
            "Brian Torrey, GM for India and West Asia, expressed optimism about future changes to the agreement, enabling Scoot to access new markets.",
          styling: [],
        },
        {
          id: "2e8c5a21-dfa4-47e1-a4a3-59a9cccbefad",
          point:
            'Noida and Navi Mumbai are considered "dream destinations" for further expansion.',
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
  },
];

export const mockRespIndianExpress = [
  {
    bookmarks: 6,
    gpt_output: {
      summary: [
        {
          id: "7a613b76-0ad5-443e-aa7d-a9065609de5e",
          point:
            "Karnataka Governor Thawarchand Gehlot permitted three private persons to pursue investigations against Chief Minister Siddaramaiah regarding corruption allegations linked to the allocation of 14 housing sites in Mysuru to the CM’s wife.",
          styling: [["<strong>", 0, 37]],
        },
        {
          id: "c4a1e6a4-eb30-459c-9751-1eddb7a7ecfa",
          point:
            "The allegations suggest these sites were given as an exchange for 3.16 acres of land supposedly acquired illicitly by a local authority in Mysuru.",
          styling: [["<strong>", 66, 114]],
        },
        {
          id: "e7e622ec-8071-43e0-86f1-46470fca4bee",
          point:
            "Three anti-corruption activists claim that Siddaramaiah's wife was an unlawful beneficiary of a contentious land exchange program.",
          styling: [],
        },
        {
          id: "e4a01809-c744-4581-9d11-a40790ab8d98",
          point:
            "The said scheme was established by the Mysore Urban Development Authority (MUDA) in 2020 to reconcile cases where the authority had acquired land without informing the owners.",
          styling: [["<strong>", 39, 80]],
        },
        {
          id: "eee4cac1-5c46-4b34-9b17-3a4947142f28",
          point:
            "Critics question the original ownership of the 3.16-acre parcel, which was allegedly acquired by Siddaramaiah’s brother-in-law in 2004 and later bequeathed to his wife.",
          styling: [],
        },
        {
          id: "13a2e53f-bba3-4fe4-93bf-4c568f94d2a2",
          point:
            "The criticism suggests that MUDA acquired the land while it was under the CM's brother-in-law’s possession.",
          styling: [],
        },
        {
          id: "0166ff8d-5c3b-4313-beda-08cf47afb041",
          point:
            "Siddaramaiah and Congress have repudiated the assertions, mentioning that the commencement of the '50:50' scheme and the decision to allocate land to Siddaramaiah's wife happened in 2020 and 2021 under BJP's governance in the state.",
          styling: [],
        },
        {
          id: "38f68ee3-c2b4-410d-8b09-f8f4b8c104f9",
          point:
            "In 2023, Siddaramaiah’s government suspended the program after the urban development department claimed it infringed the Karnataka Urban Development Authorities Act.",
          styling: [],
        },
        {
          id: "bd55e28e-cba7-4e10-8de1-f3bafe73debb",
          point:
            "People alleged that some individuals were given alternative MUDA sites in developed localities, though their land was not wrongfully acquired.",
          styling: [],
        },
        {
          id: "f52a62a8-755f-4547-a943-196c6373f3ab",
          point:
            "There are three main allegations against the Chief Minister: issues with the original ownership of the land, allegations of him setting up MUDA’s 50:50 scheme, and the value of the land exchanged under the scheme.",
          styling: [
            ["<strong>", 77, 107],
            ["<strong>", 128, 158],
            ["<strong>", 168, 195],
          ],
        },
        {
          id: "81d2fed4-a4d9-49d2-84d1-b53f4a67c064",
          point:
            "The Governor has permitted investigations against Siddaramaiah under Section 17A of the Prevention of Corruption Act, 1988 and Section 218 of the Bharatiya Nagarik Suraksha Sanhita, 2023.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-II Constitution, Governance & Polity"],
      prelims_syllabus: [],
    },
    id: 14276,
    title: "The alleged MUDA scam",
    likes: 2,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 5,
    gpt_output: {
      summary: [
        {
          id: "8ab7392e-cbcd-4b8c-a1d3-a36a1980c7bf",
          point:
            "Malaysia's Plantation and Commodities Minister, Johari Ghani, modified his proposal of gifting endangered orangutans to countries purchasing palm oil. Instead, Malaysian palm oil importers will now be invited to sponsor orangutans, using the funds for their conservation within Malaysia.",
          styling: [["<strong>", 0, 46]],
        },
        {
          id: "96e3e092-7c41-4937-8261-840bb5201fcb",
          point:
            "The revision came after the initial proposal was widely critiqued as 'obscene' and 'repugnant', especially by wildlife conservationists.",
          styling: [],
        },
        {
          id: "9bbf6fc1-1df1-4fdf-970b-88249c0166b9",
          point:
            "The original policy, announced in May, was modelled on China's 'panda diplomacy', a tactic combining wildlife conservation and diplomacy.",
          styling: [],
        },
        {
          id: "b2d794f2-f930-4781-a4c1-c521d1858f64",
          point:
            "Malaysia, the second-largest global palm oil producer, has faced considerable pressure to make its industry more sustainable due to deforestation's repercussions on natives like orangutans.",
          styling: [["<strong>", 0, 53]],
        },
        {
          id: "370e27a7-831d-4bd6-bcc2-1a1a462f2125",
          point:
            "The orangutan population is currently approximately 120,000 and is endangered due to rapid deforestation, mainly from palm oil plantations according to a report by the World Wildlife Fund.",
          styling: [],
        },
        {
          id: "07d8fb75-8235-4f1e-9a31-de1c7aec0292",
          point:
            "Through 'orangutan diplomacy', Ghani wanted to relieve concerns about palm oil production's effects on orangutans and present Malaysia as a sustainable palm oil producer committed to environmental sustainability.",
          styling: [],
        },
        {
          id: "b973a069-9974-4476-ba6f-9cc58db8b7c5",
          point:
            "The minister's announcement followed the European Union ban on importing goods tied to deforestation.",
          styling: [],
        },
        {
          id: "bf95f84b-6ccc-42b9-9f00-67e3e26f2bbf",
          point:
            "Animal welfare groups heavily criticized the initial strategy, stating it was hypocritical to destroy orangutan habitats for palm oil production and then offer the primates as gifts to earn favours from trading partners.",
          styling: [],
        },
        {
          id: "93c2a4c2-1a6e-4be3-81e8-c144e32315b0",
          point:
            "Under the updated policy, conservation programmes will be financed through sponsors' funds. These programmes will work with a team of rangers and experts to monitor these wild animals in their natural habitats.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II International Relations",
        "GS-III Environment & Ecology",
      ],
      prelims_syllabus: [],
    },
    id: 14275,
    title: "Why Malaysia has changed tack on its “Orangutan diplomacy”",
    likes: 1,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 3,
    gpt_output: {
      summary: [
        {
          id: "a5d1af11-fc99-47a5-a53a-c416bd8ebeb9",
          point:
            "Mahatma Gandhi was greatly influenced by Christ’s ethic of love. In today’s India, the vast majority are enduring the costs of environmental degradation that is serving the narrow interests of a small group of rich and powerful people.",
          styling: [["<strong>", 0, 14]],
        },
        {
          id: "68da1b28-6f28-4e1e-9367-df07d5f44986",
          point:
            "Environmental hazards like landslides have increased due to human interventions such as quarrying of rocks, mining, construction of roads and buildings on slopes in hilly regions. The Western Ghats Ecology Expert Panel (WGEEP) suggested a ban on such activities after taking local communities' feedback.",
          styling: [],
        },
        {
          id: "61411fed-df61-4f73-9ddc-38a312e6751e",
          point:
            "The WGEEP report initially faced governmental suppression for being anti-development, but later it was made public and sparked protests as well as support. The report revealed the major health issues and environmental damage caused by the industries like granite quarrying and crushing.",
          styling: [],
        },
        {
          id: "5a09d480-a075-4f33-912e-fd1f0ac3d8e4",
          point:
            "Despite the Geological Survey's recommendation against blasting, the district administration continued supporting quarry activities, leading to adverse ecological effects and instigating local protests.",
          styling: [],
        },
        {
          id: "a63226e7-1e13-4fce-9998-6c05e4c95798",
          point:
            "With the rise in technology accessibility, like smartphones, rural communities have become more connected and able to stake their claims against exploitative practices.",
          styling: [],
        },
        {
          id: "17704f64-4639-4cea-830f-d99c8c4bd925",
          point:
            "The author proposes four steps towards economic development and resource management: handover rock quarries to women's savings groups, promote homestays organized by tribals instead of luxury resorts, transfer tea state management to well-organized labour cooperatives and challenge the Wildlife Protection Act legally.",
          styling: [],
        },
        {
          id: "684f056f-3f6e-4cfa-97d4-e696e197653c",
          point:
            "The author concludes with a hopeful note for a new dawn where common people shed their meekness and claim their rightful inheritance. This signifies a need for a shift towards sustainable practices and greater democratic control over resources.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-I Geography of the World & Society",
        "GS-II Constitution, Governance & Polity",
        "GS-III Environment & Ecology",
        "GS-III Economic Development",
      ],
      prelims_syllabus: [],
    },
    id: 14279,
    title: "The land is their land",
    likes: 1,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 1,
    gpt_output: {
      summary: [
        {
          id: "9612ab76-74c4-4654-9d6e-f25628c4700d",
          point:
            "The author criticizes a recent advertisement for lateral entry into UPSC for 45 posts without any provision for reservation. The author argues that this lack of reservation will reduce marginalized groups to beneficiaries and prevent them from participating in decision-making processes.",
          styling: [["<strong>", 49, 72]],
        },
        {
          id: "4c9f47df-e754-4f3b-bb85-848bc3d26033",
          point:
            "The UPSC advertisement emphasizes seeking 'talented and motivated Indian nationals', implying that selection will be solely based on merit. The author expresses concern that without quotas, candidates from Dalit and ST communities will be overlooked.",
          styling: [["<strong>", 133, 138]],
        },
        {
          id: "67e04d00-f7e3-43f8-a987-c63ec3704cc9",
          point:
            "Lateral entry in UPSC, while potentially enriching for diversity and nuance of understanding in governance, the author questions who these 'technical experts' will be, suggesting possible continuation of power concentration within a certain caste or societal segment.",
          styling: [["<strong>", 0, 21]],
        },
        {
          id: "90486471-47f1-42c9-b24f-945cfe8a73d1",
          point:
            "The author stresses the need for interviewers to separate themselves from their social positions to ensure a fair recruitment process. Furthermore, she mentions the need to routinely assess the social composition of those recruited.",
          styling: [["<strong>", 109, 133]],
        },
        {
          id: "945b26f5-eb3d-4570-82b2-48d27e85f5db",
          point:
            "The author believes that the introduction of lateral entry in UPSC could undermine the democratization of the recruitment process and challenges the concept of reservation, which she views as not only a mechanism for justice but also a challenge to hegemony.",
          styling: [["<strong>", 73, 129]],
        },
        {
          id: "4266708c-f3ce-4637-accb-d54b9d525c0f",
          point:
            "The author further critiques the prevalence of the 'Not Found Suitable' condition in Indian academia, suggesting that it reflects deep-seated inequality. She calls for more transparency, accountability and clarity in the recruitment process, including in the establishment of eligibility criteria.",
          styling: [["<strong>", 51, 71]],
        },
        {
          id: "9175ee3b-5c3e-40fd-baf3-069f6eeb3383",
          point:
            "In the face of global multilateralism, the author suggests the need to shift towards more creative and inclusive dialogues, and calls for a move away from 'lateral-ism' in recruitment, representation and reservation.",
          styling: [],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
    id: 14278,
    title: "Another Glass ceiling",
    likes: 1,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 2,
    gpt_output: {
      summary: [
        {
          id: "85907ab6-2b38-4719-b518-0157e6cf6184",
          point:
            "Union Minister for Department of Personnel and Training (DoPT) Jitendra Singh asked UPSC Chairperson to withdraw the advertisement for lateral entry to 45 posts, reflecting the constitutional mandate towards social justice.",
          styling: [["<strong>", 0, 148]],
        },
        {
          id: "8a81cbde-bb0d-47ed-96b0-e6cfdb0a5ea5",
          point:
            "Lateral entry proposition was introduced in 2018 relying on a 1978 DoPT instruction that lateral entry was a close approximation of deputation and mandatory reservation for SC/ST/OBC was not deemed necessary.",
          styling: [["<strong>", 147, 207]],
        },
        {
          id: "962bf8bd-6325-4197-8312-8d1f1c3c27b2",
          point:
            "During 2018, the government targeted filling 50 posts under lateral entry, where each post was considered to be a 'Single Post' so that quotas did not apply.",
          styling: [],
        },
        {
          id: "3c50bf8b-7c12-47e3-9220-b6abea637187",
          point:
            "Despite specific instruction in 1978 that in significant deputation cases, the government should aim to fill a fair proportion of such posts by SCs/STs, this clause was not addressed.",
          styling: [["<strong>", 79, 151]],
        },
        {
          id: "f51f5a02-7b65-4756-b108-e8fed491eb21",
          point:
            "It was suggested that priority should be given to eligible SC/ST/OBC candidates in case they are available, to ensure holistic representation.",
          styling: [["<strong>", 0, 79]],
        },
        {
          id: "519f64ea-57d5-4263-9d00-1ddd41db721d",
          point:
            "The DoPT argued that under the lateral entry scheme, each post requires special qualification and experience to suit the requirement of each department/ministry where the post is to be filled up, and reservation does not apply to such Single Posts.",
          styling: [],
        },
        {
          id: "9c0b23c4-ac7a-4cf1-ac52-d0b7781988a1",
          point:
            "So far, 63 posts have been filled under lateral entry scheme.",
          styling: [["<strong>", 8, 60]],
        },
      ],
      mains_syllabus: [
        "GS-II Constitution, Governance & Polity",
        "GS-II Social Justice",
      ],
      prelims_syllabus: [],
    },
    id: 14273,
    title:
      "Govt cites ‘social justice’ to cancel lateral entry ad, 6 yrs ago it structured scheme to avoid reservation",
    likes: 2,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 6,
    gpt_output: {
      summary: [
        {
          id: "ad7432d1-acbe-4c3e-bed5-f54daef9ef02",
          point:
            "Chandrayaan-3 mission studies support the predominant hypothesis about the Moon's early evolution, proposing that the lunar surface was initially covered with molten material, or magma.",
          styling: [["<strong>", 0, 21]],
        },
        {
          id: "c4ad6ab7-45b9-4976-b18e-829d6e51985f",
          point:
            "The Alpha Particle X-ray Spectrometer (APXS), an instrument on the rover of Chandrayaan-3, has offered first information on the top soil composition in the Moon's southern latitudes.",
          styling: [],
        },
        {
          id: "73213b6f-9e3c-4a57-82cb-b23351cf2c75",
          point:
            "Analysis of the data shows that the unexplored region's soil is mainly composed of two types of rocks—one from the magma, and the other likely from the lunar surface's deeper layers—offering potential new insights into the Moon's evolution.",
          styling: [],
        },
        {
          id: "ac2b3588-4e81-4551-ada8-185f25e7b8da",
          point:
            "The formation of the Moon is believed to have resulted from a large asteroid’s collision with Earth about 4.2 to 4.3 billion years ago.",
          styling: [["<strong>", 62, 134]],
        },
        {
          id: "f77607f1-69d6-487b-a700-87999a604776",
          point:
            "In its early phase, the Moon was entirely a magma ocean, which as it cooled over millions of years, heavier silicon and magnesium-rich minerals sank and formed the inner lunar layers, while the lighter minerals with calcium and sodium-based compounds formed the outer layer.",
          styling: [],
        },
      ],
      mains_syllabus: ["GS-III Science & Technology"],
      prelims_syllabus: [],
    },
    id: 14274,
    title:
      "Chandrayaan-3 rover findings suggest layered crust formation on the Moon",
    likes: 1,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
  {
    bookmarks: 1,
    gpt_output: {
      summary: [
        {
          id: "65943120-10d8-4f7f-b954-63c678955e52",
          point:
            "Prime Minister Narendra Modi’s visit to Kyiv is a historic move that signifies India's efforts towards disentangling its engagement with Central Europe from the Russia-West conflict. It indicates India's aim to establish a strategic presence in Europe.",
          styling: [["<strong>", 0, 44]],
        },
        {
          id: "aa5d808b-342e-4c22-a26e-c9969246980d",
          point:
            "The conflict between Russia and Ukraine highlights the struggle of Ukraine in seeking greater strategic autonomy from Russia and trying to form diverse ties with Europe and America. The conflict has escalated following Russia's invasion of Ukraine.",
          styling: [],
        },
        {
          id: "6f459010-389d-4338-b352-d34101e47a43",
          point:
            "India's reluctance to condem Russia's aggression against Ukraine stems from its long-standing relationship with Moscow and its desire not to interfere in the country's alleged historical claim to Ukraine. There is strong opposition within Russia's western periphery to the country's imperial ambitions.",
          styling: [],
        },
        {
          id: "22f374f8-f1b7-45d6-8d64-9320bd2e2fbf",
          point:
            "India's strategic view of Central Europe should not eternally be through the Russian prism. PM Modi's visit to Ukraine is an attempt to balance relations with both Russia and Ukraine. It is not a compensatory move for his recent Russia trip.",
          styling: [["<strong>", 0, 90]],
        },
        {
          id: "f81c2247-17b9-4c43-b300-9c83a2707b71",
          point:
            "India aims for 'strengthening bilateral cooperation' and sharing 'perspectives on the peaceful resolution' of the conflict between Russia and Ukraine. The expectations for an Indian initiative for ceasefire between Kyiv and Moscow might seem unrealistic considering India’s geopolitical possibilities and limitations.",
          styling: [["<strong>", 0, 149]],
        },
      ],
      mains_syllabus: ["GS-II International Relations"],
      prelims_syllabus: [],
    },
    id: 14277,
    title: "The Kyiv Opportunity",
    likes: 1,
    news_paper: {
      id: 2,
      name: "The Indian Express",
      last_added: "2024-08-22T00:00:00+05:30",
    },
    created_at: "2024-08-22T00:00:00+05:30",
  },
];
