import axios from 'axios';

export const callGETAPI = async (url, onSuccess = (response) => {}, onError = (error) => {}) => {
  try {
    const response = await axios.get(url);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export const callPOSTAPI = async (url, params, onSuccess = (response) => {}, onError = (error) => {}) => {
  try {
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 10000,
    });

    if (response.status === 200 || response.status === 201) {
      onSuccess(response);
    } else {
      onError({ message: 'Unexpected status code', status: response.status });
    }
  } catch (error) {
    onError(parseAxiosError(error));
  }
};

export const callPUTAPI = async (url, params, onSuccess = (response) => {}, onError = (error) => {}) => {
  try {
    const response = await axios.put(url, params);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export const callPATCHAPI = async (url, params, onSuccess = (response) => {}, onError = (error) => {}) => {
  try {
    const response = await axios.patch(url, params);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export const callDELETEAPI = async (url, onSuccess = (response) => {}, onError = (error) => {}) => {
  try {
    const response = await axios.delete(url);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

const parseAxiosError = (error) => {
  if (error.response) {
    // Server responded with a status outside 2xx
    return {
      message: error.response.data?.message || 'An error occurred on the server',
      status: error.response.status,
      data: error.response.data,
    };
  } else if (error.request) {
    // No response received (network issue)
    return { message: 'No response received', status: null, error: error.request };
  } else {
    // Something else happened while setting up the request
    return { message: error.message, status: null, error };
  }
};
