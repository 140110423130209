import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart } from 'recharts';
import { DuelNoData, totalStats, wonStats, ratingStats, Question, Correct, HoursGlass } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let value = payload[0].value !== undefined ? payload[0].value : '-';
    const dataKey = payload[0].dataKey;

    let valueLabel;
    switch (dataKey) {
      case 'elo':
        valueLabel = 'Rating';
        break;
      case 'accuracy':
        valueLabel = 'Accuracy';
        break;
      case 'average_time':
        valueLabel = 'Avg Time';
        break;
      case 'number_of_questions':
        valueLabel = 'Questions';
        break;
      default:
        valueLabel = dataKey;
    }

    const date = new Date(payload[0].payload.date);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    return (
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          padding: '6px 10px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          color: 'white',
          fontSize: '12px',
          minWidth: '120px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <p style={{ margin: 0, fontWeight: '600' }}>{value}</p>
        <p style={{ margin: 0, fontSize: '10px' }}>{formattedDate}</p>
      </div>
    );
  }
  return null;
};

export default function MyStatsPage({ selectedSubject, userId }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('All');
  const [statsData, setStatsData] = useState({
    total_questions_done: 0,
    correct_answers: 0,
    average_time_per_question: 0,
    elo_graph_stats: [],
    accuracy_stats: [],
    average_time_stats: [],
    total_duels: 0,
    duels_won: 0,
    rating: 0,
    top: 0,
  });

  const handleTabClick = (tab) => {
    const isDefaultSelection = tab === 'All';
    const hasGraph = statsData.total_questions_done > 5;

    setActiveTab(tab);
    MixpanelEvent('duel_stats_show', {
      tab: tab,
      source: hasGraph ? 'graph' : 'no_graph',
      category: selectedSubject,
      default_selection: isDefaultSelection,
    });
    sendPostHogEvent('duel_stats_show', {
      tab: tab,
      source: hasGraph ? 'graph' : 'no_graph',
      category: selectedSubject,
      default_selection: isDefaultSelection,
    });
  };

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        let day = 'all';
        if (activeTab === '1m') {
          day = 'month';
        } else if (activeTab === '1w') {
          day = 'week';
        } else if (activeTab === '1d') {
          day = 'day';
        }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/track/user-stats/?profile=${userId}&category=${selectedSubject}&date=${day}`
        );
        const data = await response.json();
        setStatsData(data);
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    fetchUserStats();
  }, [userId, selectedSubject, activeTab]);

  const handleClick = () => {
    navigate('/duel');
  };

  const sortDataByDate = (data) => {
    const dateMap = data.reduce((acc, curr) => {
      const dateStr = new Date(curr.date).toISOString().split('T')[0];
      if (!acc[dateStr]) {
        acc[dateStr] = { ...curr };
      } else {
        acc[dateStr] = {
          ...curr,
          elo: (acc[dateStr].elo + curr.elo) / 2,
          accuracy: (acc[dateStr].accuracy + curr.accuracy) / 2,
          average_time: (acc[dateStr].average_time + curr.average_time) / 2,
          number_of_questions: acc[dateStr].number_of_questions + curr.number_of_questions,
        };
      }
      return acc;
    }, {});
    return Object.values(dateMap).sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const renderChart = (data, dataKey, stroke, fill, XAxisTitle, yAxisTitle, domain, tickCount) => {
    const validData = data.filter((item) => item && item.date && typeof item[dataKey] !== 'undefined' && !isNaN(item[dataKey]));

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={validData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis
            stroke="#888"
            label={{
              value: XAxisTitle,
              position: 'bottom',
              offset: -10,
              style: { textAnchor: 'middle' },
            }}
            tick={false}
          />
          <YAxis
            stroke="#888"
            label={{
              value: yAxisTitle,
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' },
            }}
            domain={domain}
            ticks={Array.from({ length: tickCount }, (_, i) => domain[0] + i * ((domain[1] - domain[0]) / (tickCount - 1)))}
          />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey={dataKey} stroke={stroke} fill={fill} strokeWidth={2} />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const renderChartOrNoData = (data, title, description, dataKey, stroke, fillColor, XAxisTitle, yAxisTitle, domain, tickCount) => (
    <div style={cardContainerStyle}>
      <div style={sectionTitleStyle}>{title}</div>
      <div style={descriptionStyle}>{description}</div>
      {statsData.total_questions_done > 5 ? (
        <div style={chartContainerStyle}>{renderChart(data, dataKey, stroke, fillColor, XAxisTitle, yAxisTitle, domain, tickCount)}</div>
      ) : (
        <div style={noDataContainerStyle}>
          <img src={DuelNoData} alt="Chart icon" style={imageStyle} />
          <p style={{ color: 'black', fontWeight: '600', fontSize: '12px', lineHeight: '16.8px' }}>NO DATA TO SHOW</p>
          <p style={{ color: '#747474' }}>You've not had any duels to view your {title}.</p>
          <button style={buttonStyle} onClick={handleClick}>
            Play Duel ▶
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div style={mainContainerStyle}>
      <div style={cardContainerStyle}>
        <div style={sectionTitleStyle}>Overall Duel Stats</div>
        <div style={descriptionStyle}>Performance statistics for your duels, aiding progress tracking and goal setting.</div>
        <div style={statBoxStyle}>
          <div style={statItemStyle}>
            <div style={{ ...iconContainerStyle, backgroundColor: '#e6f2ff' }}>
              <img src={ratingStats} alt="Rating" />
            </div>
            <div style={statLabelStyle}>RATING</div>
            <div style={statValueStyle}>{statsData.rating}</div>
          </div>
          <div style={statItemStyle}>
            <div style={{ ...iconContainerStyle, backgroundColor: '#e6ffe6' }}>
              <img src={wonStats} alt="Won" />
            </div>
            <div style={statLabelStyle}>WON</div>
            <div style={statValueStyle}>{statsData.duels_won}</div>
          </div>
          <div style={statItemStyle}>
            <div style={{ ...iconContainerStyle, backgroundColor: '#fff2e6' }}>
              <img src={totalStats} alt="Total" />
            </div>
            <div style={statLabelStyle}>TOTAL</div>
            <div style={statValueStyle}>{statsData.total_duels}</div>
          </div>
        </div>
        <div>
          <div style={detailItemStyle}>
            <span style={{ ...iconStyle, color: '#4a90e2' }}>
              <img src={Question} alt="question" />
            </span>
            Questions Answered
            <span style={{ marginLeft: 'auto' }}>{statsData.total_questions_done}</span>
          </div>
          <div style={detailItemStyle}>
            <span style={{ ...iconStyle, color: '#4ae24a' }}>
              <img src={Correct} alt="correct" />
            </span>
            Correct Answers
            <span style={{ marginLeft: 'auto' }}>{statsData.correct_answers}</span>
          </div>
          <div style={detailItemStyle}>
            <span style={{ ...iconStyle, color: '#e2a64a' }}>
              <img src={HoursGlass} alt="hours glass" />
            </span>
            Avg. Time per Question
            <span style={{ marginLeft: 'auto' }}>{statsData.average_time_per_question.toFixed(2)}sec</span>
          </div>
        </div>
      </div>

      <div style={tabContainerStyle}>
        <button style={activeTab === 'All' ? activeTabStyle : tab} onClick={() => handleTabClick('All')}>
          All
        </button>
        <button style={activeTab === '1m' ? activeTabStyle : tab} onClick={() => handleTabClick('1m')}>
          1M
        </button>
        <button style={activeTab === '1w' ? activeTabStyle : tab} onClick={() => handleTabClick('1w')}>
          1W
        </button>
        <button style={activeTab === '1d' ? activeTabStyle : tab} onClick={() => handleTabClick('1d')}>
          1D
        </button>
      </div>

      {renderChartOrNoData(
        sortDataByDate(statsData.elo_graph_stats),
        'Duel Rating Stats',
        'Explore your Rating progression over time.',
        'elo',
        '#6374FA',
        'rgba(136, 132, 216, 0.1)',
        'date',
        'Rating',
        [1000, 2000],
        11
      )}

      {renderChartOrNoData(
        sortDataByDate(statsData.accuracy_stats),
        'Total Questions Attempted',
        'Visualizing the amount of questions you attempted in the selected time duration.',
        'number_of_questions',
        '#82ca9d',
        'rgba(130, 202, 157, 0.1)',
        'date',
        'No. of Questions',
        [0, 20],
        11
      )}

      {renderChartOrNoData(
        sortDataByDate(statsData.accuracy_stats),
        'Accuracy Stats',
        'Measure accuracy of your duel answers.',
        'accuracy',
        '#82ca9d',
        'rgba(130, 202, 157, 0.1)',
        'date',
        'Accuracy %',
        [0, 100],
        11
      )}

      {renderChartOrNoData(
        sortDataByDate(statsData.average_time_stats),
        'Average Answer Time (sec)',
        'Measure the avg. time taken by you to answer the duel questions.',
        'average_time',
        '#82ca9d',
        'rgba(130, 202, 157, 0.1)',
        'date',
        'Avg. Time Taken per Question (sec)',
        [0, 20],
        11
      )}
    </div>
  );
}

const mainContainerStyle = {
  width: '90%',
  margin: '20px auto',
  color: '#333',
  overflow: 'scroll',
  height: '80vh',
  marginBottom: '3em',
  scrollbarWidth: 'none',
  fontFamily: 'Poppins, sans-serif',
};

const cardContainerStyle = {
  border: '1px solid #e0e0e0',
  borderRadius: '12px',
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#fff',
};

const sectionTitleStyle = {
  fontWeight: 'bold',
  fontSize: '18px',
  marginBottom: '5px',
  color: '#333',
};

const descriptionStyle = {
  fontSize: '14px',
  color: 'black',
  marginBottom: '20px',
};

const chartContainerStyle = {
  height: '314px',
};

const statBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
};

const statItemStyle = {
  textAlign: 'center',
  width: '30%',
};

const iconContainerStyle = {
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto 10px',
};

const statLabelStyle = {
  fontSize: '12px',
  color: '#666',
  marginBottom: '5px',
};

const statValueStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
};

const detailItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  fontSize: '14px',
};

const iconStyle = {
  marginRight: '10px',
  fontSize: '20px',
};

const noDataContainerStyle = {
  borderRadius: '12px',
  marginBottom: '20px',
  backgroundColor: '#fff',
  textAlign: 'center',
};

const tabContainerStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  backgroundColor: '#F3F3F3',
  padding: '10px',
  zIndex: 1,
  marginBottom: '20px',
  borderRadius: '20px',
};

const tab = {
  padding: '10px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  color: '#64748b',
  fontSize: '14px',
  width: '100px',
  textAlign: 'center',
};

const activeTabStyle = {
  padding: '10px',
  border: 'none',
  background: 'white',
  borderRadius: '200px',
  cursor: 'pointer',
  color: 'black',
  fontSize: '14px',
  width: '100px',
  textAlign: 'center',
};

const imageStyle = {
  width: '100px',
  height: '100px',
  margin: '0 auto 20px',
};

const buttonStyle = {
  backgroundColor: 'white',
  color: 'black',
  border: '1px solid black',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  width: '100%',
  borderRadius: '200px',
};
