import React, { useState, useEffect, useRef } from 'react';
import { IconCheck, IconX, IconExclamationCircle, IconMessageCircle2 } from '@tabler/icons-react';
import { Button, Text, Badge, Title, Stack, Radio, Textarea, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftFilled, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';

export default function PrelimsQuestionsTab({ questions, colors, userId }) {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const containerRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;

  useEffect(() => {
    setQuestionStates(questions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [questions]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleOptionChange = (index) => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[index];
    MixpanelEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
    sendPostHogEvent('question_option_selected', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });
    const optionsIndex = ['A', 'B', 'C', 'D'];
    const selectedOption = optionsIndex[questionStates[currentQuestionIndex].answerIndex];
    MixpanelEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
    sendPostHogEvent('question_answer_submitted', {
      question_number: currentQuestion.id,
      option_selected: selectedOption,
      is_correct: isAnswerCorrect,
      source: 'news_pyq',
    });
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = {
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: currentQuestion.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: currentQuestion.options.map((option, index) => ({
          [String.fromCharCode(97 + index)]: option,
        })),
        category: 'current_affairs',
        question: currentQuestion.question,
        question_id: currentQuestion.id,
        user_answer: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
        user_option: String.fromCharCode(65 + questionStates[currentQuestionIndex].answerIndex),
        correct_answer: String.fromCharCode(65 + currentQuestion.answer),
        options: currentQuestion.options,
      },
      category: 'current_affairs',
      preSelectAnswer: currentQuestion.options[questionStates[currentQuestionIndex].answerIndex],
    };
    navigate(`/chat/new/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_pyq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_pyq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
    MixpanelEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
  };

  const handleReport = (questionId) => {
    setIsOpen(true);
    setSelectedQuestionId(questionId);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        if (res.status === 400) {
          MixpanelEvent('report_submitted', { content_id: selectedQuestion, error: json });
          sendPostHogEvent('report_submitted', { content_id: selectedQuestion, error: json });
        } else {
          MixpanelEvent('report_submitted', { content_id: selectedQuestion });
          sendPostHogEvent('report_submitted', { content_id: selectedQuestion });
        }
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  return (
    <div style={{ ...styles.outerContainer, background: colors.cardBackground }}>
      <div style={{ ...styles.topContainer, background: colors.cardBackground }}>
        {questions.length > 0 && (
          <div style={styles.questionNavigation}>
            <img
              src={ArrowLeftFilled}
              style={{
                ...styles.navIcon,
                color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
                cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
              }}
              onClick={handlePreviousQuestion}
              alt="Arrow Left"
            />
            <div style={styles.qNavigationWrapper}>
              <div style={styles.qNavigation}>
                {questions.map((_, index) => (
                  <Text
                    key={index}
                    style={{
                      ...styles.questionNumber,
                      backgroundColor: index === currentQuestionIndex ? colors.highlight : 'transparent',
                      color: index === currentQuestionIndex ? colors.primary : colors.text,
                      cursor: 'pointer',
                    }}
                    onClick={() => setCurrentQuestionIndex(index)}
                  >
                    Q{index + 1}
                  </Text>
                ))}
              </div>
            </div>
            <img
              src={ArrowLeftFilled}
              style={{
                ...styles.navIcon,
                color: currentQuestionIndex !== questions.length - 1 ? colors.arrowColor : 'gray',
                cursor: currentQuestionIndex !== questions.length - 1 ? 'pointer' : 'not-allowed',
                transform: 'rotate(180deg)',
              }}
              onClick={handleNextQuestion}
              alt="Arrow Right"
            />
          </div>
        )}
      </div>

      {questions.length === 0 ? (
        <div style={styles.noDataContainer}>
          <img src={NoDataImage} alt="no data" style={styles.noDataImage} />
          <Text style={{ ...styles.noDataText, color: colors.text }}>No Related Questions Found</Text>
          <Text style={{ ...styles.noDataSubtext, color: colors.subtleText }}>
            We were unable to generate any PYQs on this article. Please tap on try again below.
          </Text>
        </div>
      ) : (
        <div
          style={{
            ...styles.container,
            background: colors.cardBackground,
            border: `1px solid ${colors.border}`,
          }}
          ref={containerRef}
        >
          <div
            style={{
              ...styles.bottomContainer,
              backgroundColor: colors.cardBackground,
              height: '85%',
              overflowY: 'auto',
            }}
          >
            <div style={styles.questionMeta}>
              <Badge color={colors.subtleText}>{currentQuestion?.exam.toUpperCase()}</Badge>
              <Badge color={colors.subtleText}>{currentQuestion?.year}</Badge>
              <Badge color={colors.subtleText}>{currentQuestion?.category?.toUpperCase()}</Badge>
            </div>

            <Text style={{ ...styles.questionText, color: colors.text }}>
              {currentQuestion.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Text>

            <div style={styles.optionsContainer}>
              {currentQuestion.options.map((option, index) => {
                const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                const isCorrectAnswer = index === currentQuestion.answer;
                const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                return (
                  <div
                    key={index}
                    onClick={() => handleOptionChange(index)}
                    style={{
                      ...styles.optionBox,
                      backgroundColor: isSubmitted
                        ? isCorrectAnswer
                          ? colors.correctAnswer
                          : isSelectedAnswer
                          ? colors.incorrectAnswer
                          : colors.cardBackground
                        : isSelectedAnswer
                        ? colors.highlight
                        : colors.cardBackground,
                      border: isSubmitted
                        ? isCorrectAnswer
                          ? `1px solid ${colors.correctAnswer}`
                          : isSelectedAnswer
                          ? `1px solid ${colors.incorrectAnswer}`
                          : `1px solid ${colors.border}`
                        : isSelectedAnswer
                        ? `1px solid ${colors.primary}`
                        : `1px solid ${colors.border}`,
                    }}
                  >
                    <div style={styles.optionContent}>
                      <div
                        style={{
                          ...styles.radioCircle,
                          backgroundColor: questionStates[currentQuestionIndex]?.isSubmitted
                            ? index === currentQuestion.answer
                              ? '#34d399'
                              : index === questionStates[currentQuestionIndex].answerIndex
                              ? '#ff9999'
                              : 'transparent'
                            : 'transparent',
                          border:
                            questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                              ? 'none'
                              : '2px solid #6374FA',
                        }}
                      >
                        {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                          <div style={styles.purpleDot}></div>
                        )}
                        {questionStates[currentQuestionIndex]?.isSubmitted &&
                          (index === currentQuestion.answer ? (
                            <IconCheck style={styles.icon} />
                          ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                            <IconX style={styles.icon} />
                          ) : null)}
                      </div>
                      <Text style={{ ...styles.optionLabel, color: colors.text }}>{String.fromCharCode(65 + index)}.</Text>
                      <Text style={{ ...styles.optionText, color: colors.text }}>{option}</Text>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
              width: '100%',
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.border}`,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              boxSizing: 'border-box',
              margin: 0,
              marginBottom: '5px',
            }}
          >
            <Button variant="subtle" color="red" onClick={() => handleReport(currentQuestion.id)}>
              <IconExclamationCircle style={{ marginRight: '0.25rem' }} height={18} width={18} />
              Report
            </Button>
            {!questionStates[currentQuestionIndex]?.isSubmitted ? (
              <Button
                style={{
                  ...styles.submitButton,
                  backgroundColor: questionStates[currentQuestionIndex]?.selectedOption ? '#070519' : '#F3F3F3',
                  color: questionStates[currentQuestionIndex]?.selectedOption ? '#FFFFFF' : '#B9B9B9',
                }}
                onClick={handleSubmit}
                disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
              >
                Submit
              </Button>
            ) : (
              <Button
                style={{
                  ...styles.discussButton,
                  backgroundColor: 'transparent',
                  color: colors.text,
                  border: `1px solid ${colors.border}`,
                }}
                onClick={handleDiscussWithAi}
              >
                Discuss with AI
              </Button>
            )}
          </div>
        </div>
      )}
      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        colors={colors}
      />
    </div>
  );
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
  styles,
  colors,
}) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div
        style={{
          ...styles.modalContainer,
          maxWidth: isMobile ? '28rem' : '22vw',
          backgroundColor: colors.cardBackground,
          color: colors.text,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span
            style={{
              ...styles.topLine,
              background: colors.border,
            }}
            onClick={closeModal}
          ></span>
        </div>
        <button
          onClick={closeModal}
          style={{
            position: 'relative',
            right: '5px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '20px',
            fontWeight: 'bold',
            color: colors.text,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title fz="h3" m="1em" style={{ textAlign: 'left', color: colors.text }}>
              Report Question
            </Title>
            <Stack spacing="lg">
              <Radio.Group
                value={resportData}
                onChange={setResportData}
                size="1em"
                styles={{
                  label: {
                    color: colors.text,
                  },
                }}
              >
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio
                    key={index}
                    value={item}
                    label={item}
                    p="md"
                    styles={{
                      label: { color: colors.text },
                      radio: {
                        backgroundColor: colors.cardBackground,
                        borderColor: colors.border,
                      },
                    }}
                  />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text style={{ textAlign: 'left', color: colors.text }}>Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    style={{
                      marginTop: '10px',
                      fontSize: '14px',
                      backgroundColor: colors.cardBackground,
                      color: colors.text,
                      borderColor: colors.border,
                    }}
                    onChange={onChange}
                    styles={{
                      input: {
                        backgroundColor: colors.cardBackground,
                        color: colors.text,
                        '&::placeholder': {
                          color: colors.subtleText,
                        },
                      },
                    }}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                style={{
                  backgroundColor: resportData ? colors.text : colors.disabled,
                  color: colors.cardBackground,
                  fontWeight: 500,
                }}
                disabled={!resportData}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title fz="h3" m="1em" style={{ color: colors.text }}>
              Report Submitted
            </Title>
            <Title m="1em" fz="md" style={{ color: colors.text }}>
              Thank you! Your report has been submitted.
            </Title>
            <Lottie
              options={{
                ...defaultOptions,
                rendererSettings: {
                  ...defaultOptions.rendererSettings,
                  preserveAspectRatio: 'xMidYMid slice',
                  clearCanvas: true,
                },
              }}
              height={200}
              width={200}
            />
          </>
        )}
      </div>
    </div>
  );
};
const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

const styles = {
  outerContainer: {
    fontFamily: 'Poppins, Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    height: '89dvh',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  container: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    touchAction: 'pan-y',
    border: '1px solid #E8E8E8',
    margin: '10px',
    height: 'calc(100dvh - 180px)',
  },
  topContainer: {
    padding: '15px',
    background: 'white',
    boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
  },
  bottomContainer: {
    margin: '0 15px',
    background: 'white',
    marginTop: '7px',
    borderRadius: '12px',
    height: '88%',
    scrollbarWidth: 'none', // Firefox
    msOverflowStyle: 'none', // IE 10+
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none', // Safari and Chrome
    },
  },
  questionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  qNavigationWrapper: {
    flex: 1,
    overflow: 'hidden',
    margin: '0 10px',
  },
  qNavigation: {
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none', // Firefox
    msOverflowStyle: 'none', // IE 10+
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none', // Safari and Chrome
    },
  },
  questionNumber: {
    minWidth: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    margin: '0 5px',
    flexShrink: 0,
  },
  navIcon: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #D1D1D1',
    padding: '7px 5px',
    backgroundColor: 'white',
  },
  questionMeta: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionBox: {
    padding: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  radioCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    transition: 'all 0.3s ease',
  },
  purpleDot: {
    width: '14.5px',
    height: '14.5px',
    backgroundColor: '#6374FA',
    borderRadius: '50%',
  },
  icon: {
    width: '14px',
    height: '14px',
    color: 'white',
  },
  optionLabel: {
    marginRight: '10px',
  },
  optionText: {
    fontSize: '15px',
    fontWeight: '500',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  submitButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 25px',
    backgroundColor: '#070519',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  discussButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    color: 'black',
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  discussIcon: {
    marginRight: '5px',
  },
  noDataContainer: {
    textAlign: 'center',
    padding: '20px',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    marginBottom: '20px',
  },
  noDataText: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  noDataSubtext: {
    color: '#888',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
};
