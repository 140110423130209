import { useEffect, useRef } from 'react';
import packageInfo from '../package.json';

const CacheClearer = () => {
  const timerRef = useRef(null);

  useEffect(() => {
    const performCacheClear = async () => {
      try {
        const lastClearTime = localStorage.getItem('lastCacheClearTime');
        const savedVersion = localStorage.getItem('appVersion');

        const currentTime = Date.now();
        const thirtyMinutes = 30 * 60 * 1000; // 30 minutes in milliseconds

        if (!lastClearTime || currentTime - parseInt(lastClearTime, 10) >= thirtyMinutes) {
          // console.log("Checking and clearing cache...");

          const cleared = await clearAllCaches();

          localStorage.setItem('lastCacheClearTime', currentTime.toString());

          if (cleared) {
            if (savedVersion !== packageInfo.version) {
              localStorage.setItem('appVersion', packageInfo.version);
              // console.log("App version updated.");
            }
          } else {
            // console.log("No caches to clear. Scheduling next check.");
            scheduleNextCheck(thirtyMinutes);
          }
        } else {
          const timeUntilNextClear = thirtyMinutes - (currentTime - parseInt(lastClearTime, 10));
          // console.log(`Next cache check in ${Math.ceil(timeUntilNextClear / 1000)} seconds.`);
          scheduleNextCheck(timeUntilNextClear);
        }
      } catch (error) {
        console.error('Error during cache clear process:', error);
      }
    };

    const scheduleNextCheck = (delay) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(performCacheClear, delay);
    };

    performCacheClear();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const clearAllCaches = async () => {
    if ('caches' in window) {
      try {
        const cacheNames = await caches.keys();
        if (cacheNames.length > 0) {
          await Promise.all(cacheNames.map((name) => caches.delete(name)));
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error deleting caches:', error);
        return false;
      }
    } else {
      // console.log('Cache API not available.');
      return false;
    }
  };

  return null;
};

export default CacheClearer;
