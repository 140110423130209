import React, { useState, useEffect } from 'react';
import { Button, Flex, Image, Text, Box, Popover, ScrollArea } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { BookChatUpsc, BookChatNcrt, HelpCircle, ArrowDown, NoHistory, Book1, Book2 } from '../../../assets/images';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTheme } from '../../../context/themeProvider';

const BookChatHistory = ({ chatHistorys, handleCallBack }) => {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const [chatHistory, setChatHistory] = useState(chatHistorys);
  const [filteredChats, setFilteredChats] = useState(chatHistorys);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { colors } = useTheme();
  const windowHeight = window.innerHeight;

  const isResponsiveMobile = windowHeight <= 700;

  // Date Range State
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (!isFilterApplied) {
      setFilteredChats(chatHistory);
    }
  }, [chatHistory, isFilterApplied]);

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
  };

  const formatDateRange = (startDate, endDate) => {
    const optionsWithoutYear = { day: '2-digit', month: 'short' };
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', optionsWithoutYear);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', optionsWithYear);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const handleApplyFilter = () => {
    const { startDate, endDate } = dateRange[0];
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() === endDate.getTime()) {
      const filtered = chatHistory.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate.toDateString() === startDate.toDateString();
      });
      setFilteredChats(filtered);
    } else {
      const filtered = chatHistory.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate >= startDate && chatDate <= adjustedEndDate;
      });
      setFilteredChats(filtered);
    }

    setIsFilterApplied(true);
    setPopoverOpened(false);
  };

  const handleClearFilter = () => {
    setFilteredChats(chatHistory);
    setIsFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };

  const handleCancel = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setPopoverOpened(false);
  };

  const handle = (bookid, chatId, chatCategory, chatIndex) => {
    const url = `/book/${bookid}`;
    navigate(url, {
      state: {
        chatHistoryId: chatId,
        flowType: chatCategory,
        chatIndex,
      },
    });
  };

  const BookQueryCard = ({ isNcert }) => {
    return (
      <div
        onClick={() => navigate('/book-chat/select-book', { state: { bookFlowType: isNcert ? 'ncert' : 'upsc' } })}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          position: 'relative',
          flex: '1 1 calc(50% - 0.5rem)',
          minWidth: '150px',
          maxWidth: '250px',
        }}
      >
        <img
          src={isNcert ? Book2 : Book1}
          alt={isNcert ? 'NCERT Book' : 'UPSC Book'}
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '12px',
            objectFit: 'cover',
          }}
        />
        {isNcert && (
          <>
            <div style={styles.newTag}>New</div>
            <div style={styles.polImg}>
              <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 8.5L10 0H0V8.5Z" fill="#6374FA" />
              </svg>
            </div>
          </>
        )}
        <div style={{ padding: '10px' }}>
          <Button h={'3em'} radius={'5em'} variant="outline" color="#070519">
            <Flex align={'center'} justify={'center'}>
              <div style={{ width: '1.5em', marginRight: '0.3em' }}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.5 11.5039H15.5C15.8417 11.5039 16.125 11.2206 16.125 10.8789C16.125 10.5372 15.8417 10.2539 15.5 10.2539H5.5C5.15833 10.2539 4.875 10.5372 4.875 10.8789C4.875 11.2206 5.15833 11.5039 5.5 11.5039Z"
                    fill="black"
                  />
                  <path
                    d="M10.5 16.5039C10.8417 16.5039 11.125 16.2206 11.125 15.8789V5.87891C11.125 5.53724 10.8417 5.25391 10.5 5.25391C10.1583 5.25391 9.875 5.53724 9.875 5.87891V15.8789C9.875 16.2206 10.1583 16.5039 10.5 16.5039Z"
                    fill="black"
                  />
                </svg>
              </div>
              <Text fw={500} fz={'1em'} style={{ fontFamily: 'Poppins' }}>
                New Query
              </Text>
            </Flex>
          </Button>
        </div>
      </div>
    );
  };

  // Component for rendering individual chat history cards
  const ChatHistoryCard = ({ chat, reverseIndex }) => {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '16px',
          padding: '20px',
          marginBottom: '1rem',
          border: '1px solid #E8E8E8',
        }}
        onClick={() => handle(chat?.book_id, chat?.id, chat?.book_category, reverseIndex)}
      >
        <Flex direction="column" gap="md">
          <Flex justify="space-between" align="center">
            <div
              style={{
                backgroundColor: '#DCF9FF',
                padding: '8px 12px',
                borderRadius: '8px',
                width: '100%',
                color: '#055160',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {chat.querystatus}
            </div>
          </Flex>
          {!chat.user_seen && (
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                padding: '10px 20px',
                width: '60px',
                color: '#6374FA',
                background: '#EBF3FF',
                borderRadius: '4px',
              }}
            >
              New
            </Button>
          )}

          <Flex justify="space-between" align="center">
            <Text style={{ fontSize: '14px', fontWeight: '600' }} color="#747474">
              QUERY #{reverseIndex}
            </Text>
            <Text style={{ fontSize: '14px', fontWeight: '600' }} color="#747474">
              {new Date(chat.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </Text>
          </Flex>

          <Text size="md" lineClamp={2} style={{ color: '#333' }}>
            {chat.query[chat.query.length - 1]}
          </Text>

          <Flex justify="space-between" align="center">
            <Flex gap="md" align="center">
              <div
                style={{
                  backgroundColor: chat.book_category === 'upsc' ? '#A76319' : 'rgb(8 105 196)',
                  color: 'white',
                  padding: '4px 12px',
                  borderRadius: '4px',
                  fontSize: '14px',
                }}
              >
                {chat.book_category.toUpperCase()}
              </div>
              <Text
                size="sm"
                color="dimmed"
                style={{
                  maxWidth: '220px',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {chat.book_name}
              </Text>
            </Flex>
            <Button
              variant="subtle"
              styles={{
                root: { padding: 0, height: 'auto' },
                label: { color: '#6374FA', fontSize: '20px' },
              }}
            >
              →
            </Button>
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100dvh - 215px)',
        maxHeight: '85dvh',
        minHeight: '400px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        msOverflowStyle: 'none', // For IE and Edge
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // For Chrome, Safari, and Opera
        },
      }}
    >
      <Flex direction="column" align="center" mb="12px" mt="12px" style={{ width: '100%' }}>
        <Flex align="center" gap="xs" style={{ width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Text fw={500} fz="xl" style={{ textAlign: 'center' }}>
            What if you could talk to a book?
          </Text>
          <Image src={HelpCircle} alt="Help icon" w="2.5em" style={{ cursor: 'pointer' }} onClick={handleCallBack} />
        </Flex>
      </Flex>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'center',
          margin: '9px',
          marginBottom: '5px',
          width: '97%',
          flexWrap: 'wrap',
        }}
      >
        <BookQueryCard isNcert={true} />
        <BookQueryCard isNcert={false} />
      </div>

      <Flex
        justify="space-between"
        align="center"
        mb="10px"
        mt="10px"
        style={{
          padding: '0 1rem',
          color: '#747474',
          width: '100%',
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        <Text fw={500} size="12px">
          ASK A BOOK HISTORY
        </Text>
        {isFilterApplied ? (
          <Button
            style={{
              backgroundColor: 'white',
              borderColor: '#E5E7EB',
              color: '#1A1A1A',
              border: '1px solid #E5E7EB',
              padding: '0.5em 1em',
            }}
            onClick={handleClearFilter}
          >
            {formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}
            <Text ml="xs" style={{ color: 'red' }}>
              ×
            </Text>
          </Button>
        ) : (
          <Popover width={300} position="bottom" withArrow shadow="md" opened={popoverOpened} onChange={setPopoverOpened}>
            <Popover.Target>
              <Button
                onClick={() => setPopoverOpened((o) => !o)}
                fullWidth
                styles={{
                  root: {
                    backgroundColor: 'white',
                    color: '#747474',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    height: '36px',
                    width: 'auto',
                    padding: '0.5em 1em',
                    '&:hover': { backgroundColor: 'transparent' },
                  },
                }}
              >
                Sort by Date
                <Image src={ArrowDown} alt="Sort Icon" style={{ marginLeft: '0.4em' }} />
              </Button>
            </Popover.Target>
            <Popover.Dropdown
              style={{
                zIndex: 1001,
                position: 'fixed',
                top: '60%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: window.innerWidth <= 400 ? '22em' : '28em',
                boxSizing: 'border-box',
                padding: '1em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <DateRangePicker
                ranges={dateRange}
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                rangeColors={[colors.coloredButton]}
                maxDate={new Date()}
              />
              <Flex mt="md" justify="space-between" w="100%">
                <Button
                  variant="outline"
                  color="gray"
                  onClick={handleCancel}
                  style={{
                    flex: 1,
                    marginRight: '8px',
                    color: 'black',
                    border: 'none',
                    padding: '0.5em',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleApplyFilter}
                  style={{
                    flex: 1,
                    backgroundColor: 'black',
                    borderRadius: '200px',
                    color: 'white',
                    padding: '0.5em',
                  }}
                >
                  Select Range
                </Button>
              </Flex>
            </Popover.Dropdown>
          </Popover>
        )}
      </Flex>

      <div style={{ flex: 1 }}>
        {filteredChats.length === 0 ? (
          <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <img src={NoHistory} style={{ width: '150px', height: '150px' }} alt="No history" />
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <h3 style={{ margin: '0px', padding: '0px' }}>No history found</h3>
              <h4 style={{ color: 'gray', margin: '5px' }}>You've not had any chat for the selected topic & date</h4>
            </div>
          </div>
        ) : (
          filteredChats.slice().map((chat, index) => <ChatHistoryCard key={index} chat={chat} reverseIndex={filteredChats.length - index} />)
        )}
      </div>

      {showInfoTips && <div>Info Tips Modal</div>}
    </div>
  );
};
const styles = {
  newTag: {
    position: 'absolute',
    top: '-2px',
    left: '-13px',
    backgroundColor: '#EBF3FF',
    color: '#6374FA',
    padding: '0.3em 0.8em',
    fontSize: '0.8em',
    fontWeight: '500',
    fontFamily: 'Poppins',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 15% 100%, 0 85%)',
    borderRadius: '4px 4px 4px 0',
    zIndex: 1,
  },
  polImg: {
    position: 'absolute',
    top: '1.35em',
    left: '-0.8em',
    width: '11%',
    transform: 'rotate(80deg)',
  },
};
export default BookChatHistory;
