import React, { useState, useEffect } from 'react';
import { HelpCircle, Add, AskBookImg2, NcertBook1 } from '../../../assets/images';
import { useTheme } from '../../../context/themeProvider';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Image, Text } from '@mantine/core';
import InfoTips from './InfoTips';
import BookChatHistoryCard from './BookChatHistoryCard';
import { userStore } from '../../../stores/userStore';

const AskABook = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [showInfoTips, setShowInfoTips] = useState(false);
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (!userId) return; // Ensure userId exists before making the request
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/bookchat/?user=${userId}`);
        const data = await response.json();
        setChatHistory(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    fetchChatHistory();
  }, [userId]);

  const BookQueryCard = ({ title, img, isNew, bookType }) => {
    return (
      <div style={styles.cardContainer}>
        <div style={styles.header}>
          <Image src={img} alt={title} style={styles.cardImg} />
          {isNew && (
            <>
              <div style={styles.newTag}>New</div>
              <div style={styles.polImg}>
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.5L10 0H0V8.5Z" fill="#6374FA" />
                </svg>
              </div>
            </>
          )}
        </div>
        <div style={styles.footer}>
          <div style={styles.cardTitle}>{title}</div>
          <Button
            onClick={() => navigate('/book-chat/select-book', { state: { bookFlowType: bookType } })}
            h={'3em'}
            border={`1px solid #070519`}
            style={{ backgroundColor: '#070519' }}
            radius={'5em'}
          >
            <Flex align={'center'} justify={'center'}>
              <Image src={Add} alt="Add icon" w={'1.5em'} mr={'0.3em'} />
              <Text
                fw={500}
                fz={'1em'}
                style={{
                  color: 'white',
                  fontFamily: 'Poppins',
                }}
              >
                New Query
              </Text>
            </Flex>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {chatHistory.length === 0 ? (
        <div style={styles.centerContainer}>
          <div
            style={{
              height: '75dvh',
              overflow: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Flex align="center" justify="center" mb={'0.5em'}>
              <Text fw={500} fz={'1.5em'} style={{ fontFamily: 'Poppins', color: '#070519' }}>
                Ask a Book
              </Text>
              <Image ml={'0.3em'} src={HelpCircle} alt="Help icon" w={'2.5em'} style={{ cursor: 'pointer' }} onClick={() => setShowInfoTips(true)} />
            </Flex>
            <Text fw={400} fz={'1em'} mb={'1.5em'} style={{ fontFamily: 'Poppins', color: '#747474' }} align="center">
              Get reliable answers from top UPSC and NCERT books.
            </Text>
            <BookQueryCard title="Ask a NCERT Book" img={NcertBook1} isNew={true} bookType={'ncert'} />
            <BookQueryCard title="Ask a UPSC Book" img={AskBookImg2} isNew={false} bookType={'upsc'} />
            <div style={{ height: '80px' }} />
          </div>
        </div>
      ) : (
        <div style={styles.chatHistoryContainer}>
          <BookChatHistoryCard chatHistorys={chatHistory} handleCallBack={() => setShowInfoTips(true)} />
        </div>
      )}
      {showInfoTips && (
        <InfoTips
          onClose={() => {
            setShowInfoTips(false);
          }}
        />
      )}
    </>
  );
};

const styles = {
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    position: 'relative',
    marginBottom: '1.5em',
    width: '96%',
  },
  cardImg: {
    height: '150px',
    width: '100%',
    borderRadius: '12px',
  },
  newTag: {
    position: 'absolute',
    top: '0px',
    right: '-0.8em',
    backgroundColor: '#EBF3FF',
    color: '#6374FA',
    padding: '0.3em 0.8em',
    fontSize: '0.8em',
    fontWeight: '500',
    fontFamily: 'Poppins',
    zIndex: 1,
    border: '1px solid #6374FA80',
    borderRadius: '4px 4px 0px 4px',
  },
  polImg: {
    position: 'absolute',
    top: '1.35em',
    right: '-0.7em',
    width: '3%',
  },
  cardTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#070519',
    fontSize: '1em',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3em 0.9em',
  },
};

export default AskABook;
