import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { throttle } from 'lodash';
import { AnswerComponent } from './AnswerComponent';

const AnswerComponentWrapper = forwardRef((props, ref) => {
  const [item, setItem] = useState(props.item);
  const shouldAutoScroll = useRef(true);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  useImperativeHandle(ref, () => ({
    updateConversationData(conversation) {
      throttledRender(conversation[conversation.length - 1]);
    },
  }));

  const updateConvo = (conversation) => {
    setItem(conversation);
  };

  const onTouchedAnswer = () => {
    shouldAutoScroll.current = false;
  };

  const throttledRender = useCallback(throttle(updateConvo, 250, { trailing: false }), []);

  return (
    <>
      {item && (
        <div style={{ cursor: 'pointer', opacity: 1 }} onMouseDown={onTouchedAnswer}>
          <AnswerComponent
            richText={true}
            index={props.index}
            item={item}
            isLast={props.isLast}
            streamEnd={props.streamEnd}
            type={props.flowType}
            callBack={props.callBack}
            hasAttachments={props.attachments}
          />
        </div>
      )}
    </>
  );
});

export default AnswerComponentWrapper;
