import React from 'react';
import { Select } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export function ReadingTime() {
  const { colors } = useTheme();
  const { readingTime, setReadingTime } = useNews();

  const readingTimeOptions = [
    { value: '5', label: '5 min read' },
    { value: '10', label: '10 min read' },
    { value: '15', label: '15 min read' },
    { value: '30', label: '30 min read' },
    { value: '60', label: '60 min read' },
  ];

  const handleReadingTimeChange = (value) => {
    setReadingTime(value ? parseInt(value, 10) : null);
    MixpanelEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
    sendPostHogEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', width: '40%', zIndex: 100 }}>
      <Select
        value={readingTime ? readingTime.toString() : null}
        placeholder="Reading Time"
        onChange={handleReadingTimeChange}
        data={readingTimeOptions}
        clearable
        styles={{
          dropdown: {
            zIndex: 2000,
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: colors.cardBackground,
            color: colors.text,
          },
          item: {
            padding: '10px',
            color: colors.text,
            '&[data-selected]': {
              backgroundColor: colors.primary,
              color: colors.cardBackground,
            },
          },
          placeholder: { color: colors.subtleText },
          input: {
            color: colors.text,
            backgroundColor: colors.cardBackground,
            borderColor: colors.borderColor,
            borderRadius: '8px',
          },
        }}
      />
    </div>
  );
}
