export const discussWithAi = (question, selectedOption) => {
  const optionKey = ['a', 'b', 'c', 'd'];

  const aiTutorParams = {
    flowType: 'postGame',
    source: 'pyq_search',
    id: null,
    chatType: 'ai_tutor',
    node_id: null,
    chatgraph_id: null,
    messages: [
      {
        role: 'user',
        convo_id: '',
        type: 'Question',
        content: question.question,
      },
      {
        role: 'user',
        convo_id: '',
        type: 'My Answer',
        content: question.options[selectedOption],
      },
    ],
    preSelectQuestion: {
      answer: null,
      choices: question.options.map((option, idx) => ({
        [optionKey[idx]]: option,
      })),
      category: question.category,
      question: question.question,
      question_id: question.question_id,
      user_answer: question.options[selectedOption],
      user_option: optionKey[selectedOption],
      correct_answer: optionKey[question.answer],
      options: question.options,
    },
    category: question.category,
    preSelectAnswer: question.options[selectedOption],
  };

  return aiTutorParams;
};
