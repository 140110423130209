import React, { useState } from 'react';
import { DuelOnlineEmpty, HelpCircle } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import DuelInfoTips from './InfoTip/DuelOnlineInfoTip';

export default function DuelFirstTime() {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);

  const buttonStyle = {
    padding: '1.25rem 1rem',
    borderRadius: '24px',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    right: '1rem',
    bottom: '1rem',
    width: '-webkit-fill-available',
  };

  const handleInfoTip = () => {
    setShowInfoTips(true);
  };

  const closeModal = () => {
    setShowInfoTips(false);
  };

  return (
    <div style={{ flexDirection: 'column', height: '72vh', overflowY: 'scroll', scrollbarWidth: 'none' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '100px' }}>
          <h2 style={{ margin: '0px', padding: '0px' }}>
            Duel Online
            <img
              src={HelpCircle}
              alt="Help icon"
              style={{ marginLeft: '8px', width: '20px', cursor: 'pointer', height: '20px' }}
              onClick={handleInfoTip}
            />
          </h2>

          <p style={{ color: '#747474', margin: '5px' }}>
            Compete online against other UPSC aspirants in 1-on-1 UPSC prelims quizzes.
          </p>
        </div>
        <div
          style={{
            borderRadius: '16px',
            width: '100%',
            height: '224px',
          }}
        >
          <img
            src={DuelOnlineEmpty}
            alt="Another image"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '16px',
            }}
          />
          <button style={buttonStyle} onClick={() => navigate('/online-match-game')}>
            Play Now
          </button>
        </div>
      </div>
      <DuelInfoTips opened={showInfoTips} onClose={closeModal} />
    </div>
  );
}
