import React from 'react';
import NoDataLatestLight from '../../assets/illustrations/noDataLatestNewsLight.svg';
import NoDataLatestDark from '../../assets/illustrations/noDataLatestNewsDark.svg';
import { useMantineTheme } from '@mantine/core';

export default function NoDataLatestNews() {
  const theme = useMantineTheme(); // Get the current theme

  return (
    <div
      style={{
        height: 'aut50vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={theme.colorScheme === 'dark' ? NoDataLatestDark : NoDataLatestLight} />
    </div>
  );
}
