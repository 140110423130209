import React from 'react';
import { Polygon } from '../../assets/images';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';

const QuestionsComp = ({ item, flowType }) => {
  if (!item || !item.content) {
    return null;
  }

  let backgroundColor = '#6374FA'
  if (flowType === 'ncert') {
    backgroundColor = '#206AB3'
  }
  if (flowType === 'upsc') {
    backgroundColor = '#EAB14D'
  }

  // Clean up content and prepare for rendering
  let content = item.content.trim();
  content = content.replace(/<br><br>/g, '\n'); // Convert double <br> to new lines



  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          width: 'fit-content',
          backgroundColor: backgroundColor,
          position: 'relative',
          padding: '1em',
          borderRadius: '12px 12px 0px 12px',
          display: 'flex',
        }}
      >
        <div style={{
          position: 'absolute',
          bottom: '-0.55em',
          right: '-0.9em',
        }}>
          <svg width="25" height="25" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.17157 12.5C8.95338 12.5 9.84572 10.3457 8.58579 9.08579L-5.24537e-07 0.500001L0 12.5L7.17157 12.5Z" fill={backgroundColor} />
          </svg>
        </div>
        {/* Render Markdown content with LaTeX and other features */}
        <div
          style={{
            fontSize: '16px',
            color: '#FFFFFF',
            wordBreak: 'break-word',
            margin: 0,
            flex: 1,
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default QuestionsComp;
