import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { LatexRenderer } from './LatexRenderer';

function ensureNewlineBeforeHash(str) {
  return str.replace(/([^\n])#/g, '$1\n#');
}

function replacePatterns(inputString) {
  const regexPattern = /(^|[\r\n])([*-])(\s+)([^\r\n]+)/g;
  return inputString.replace(regexPattern, (match, p1, p2, p3, p4) => {
    return `${p1}&nbsp;&nbsp;--${p3}${p4}`;
  });
}

// Helper function to extract LaTeX content safely
const extractLatexContent = (children) => {
  if (typeof children === 'string') {
    return children;
  }

  if (Array.isArray(children)) {
    return children.map(extractLatexContent).join('');
  }

  if (children && children.props && children.props.children) {
    return extractLatexContent(children.props.children);
  }

  return '';
};

export const MarkdownWithLatex = ({ content }) => {
  let cleanedMd = ensureNewlineBeforeHash(content);
  cleanedMd = replacePatterns(cleanedMd);
  cleanedMd = cleanedMd.replaceAll("\n=\n", "=");

  return (
    <div>
      <ReactMarkdown
        components={{
          img: ({node, ...props}) => (
            <img 
              style={{ width: '100%', height: 'auto' }} 
              {...props} 
              alt={props.alt || ''} 
            />
          ),
          inlineMath: ({ children }) => {
            const latex = extractLatexContent(children); 
            const escapedLatex = latex.replace(/\\/g, '\\\\');
            return <LatexRenderer content={escapedLatex} />;
          },
          math: ({ children }) => {
            const latex = extractLatexContent(children);
            const escapedLatex = latex.replace(/\\/g, '\\\\');
            return (
              <div style={{ textAlign: 'center', margin: '1em 0' }}>
                <LatexRenderer content={escapedLatex} />
              </div>
            );
          },
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            if (inline && typeof children === 'string' && children.startsWith('$') && children.endsWith('$')) {
              const content = children.slice(1, -1);
              const escapedLatex = content.replace(/\\/g, '\\\\');
              return <LatexRenderer content={escapedLatex} />;
            }

            return !inline && match ? (
              <pre className={className} {...props}>
                <code className={className} {...props}>
                  {children}
                </code>
              </pre>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
      >
        {cleanedMd}
      </ReactMarkdown>
    </div>
  );
};
