import React, { useState } from 'react';
import { MobileDevelopmentIcon } from '../../assets/images'; // Ensure this icon is available

const CompletionModal = ({ onGoBack }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = window.innerWidth <= 2500;

  const closeModal = () => {
    setIsOpen(false);
    onGoBack(); // Close the parent modal
  };
  const handleGoBack = () => {
    closeModal(); 
  };

  return (
    <>
      {isOpen && (
        <div style={styles.modalOverlay}>
          <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <span style={styles.topLine} onClick={closeModal}></span> {/* Close modal on click */}
            </div>
            <h2 style={styles.heading}>Thank you!</h2>
            <p style={styles.subText}>
              Your feedback is valuable to us. The Padhai team is working on making the app better every day.
            </p>
            <img src={MobileDevelopmentIcon} alt="Thank You Illustration" style={styles.backgroundImage} />

            <button onClick={closeModal} style={styles.completeButton}>
               Back to Profile
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '20px',
  },
  subText: {
    fontSize: '1rem',
    marginBottom: '20px',
  },
  backgroundImage: {
    marginBottom: '20px',
  },
  completeButton: {
    backgroundColor: '#FFFFFF',
    color: 'black',
    padding: '10px',
    borderRadius: '10px',
    border: '2px solid black',
    width: '100%',
    cursor: 'pointer',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginRight: '8px',
    width: '20px',
    height: '20px',
  },
};

export default CompletionModal;
