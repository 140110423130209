import { create } from 'zustand';

const useInstallPromptStore = create((set) => ({
  deferredPrompt: null,
  isPromptFired: false,

  setDeferredPrompt: (event) => set({ deferredPrompt: event, isPromptFired: true }),

  clearDeferredPrompt: () => set({ deferredPrompt: null, isPromptFired: false }),
}));

export default useInstallPromptStore;
