import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs
import TutorChat from './TutorChat';
import { useTheme } from '../../context/themeProvider';
import { useLocation } from 'react-router-dom';
import AskABook from './AskABook';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    top: 100,
    zIndex: 1000,
    overflow: 'hidden',
    padding: '10px',
  },
  tabs: {
    width: '100%',
    marginTop: '3rem',
  },
  tabList: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    listStyleType: 'none',
    boxShadow: '0 1px 0px rgba(0, 0, 0, 0.1)',
  },
  tab: {
    fontWeight: 500,
    fontSize: '1em',
    padding: '10px 20px',
    backgroundColor: 'transparent',
    border: 'none',
    position: 'relative',
    listStyleType: 'none',
    cursor: 'pointer',
  },
  activeTab: {
    color: '#000',
    borderBottom: '3px solid #6374FA',
  },
  inactiveTab: {
    color: '#888',
  },
};

const Chat = () => {
  const location = useLocation();
  const { colors } = useTheme(); // Always call useTheme at the top
  const isNewsRoute = location.pathname.startsWith('/news'); // Check if it's a news route
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' }; // Conditionally apply colors
  const [activeTab, setActiveTab] = useState(0);

  const handleTabSelect = (index) => {
    setActiveTab(index);
    const tabName = index === 0 ? 'ask_a_book' : 'tutor_chat';
    const eventData = {
      tab: tabName,
      default_tab: index === 0 ? true : false,
    };
    MixpanelEvent('chat_header_selection', eventData);
    sendPostHogEvent('chat_header_selection', eventData);
  };

  return (
    <div className="chat-container" style={styles.container}>
      <Tabs className="chat-tabs" style={styles.tabs} selectedIndex={activeTab} onSelect={handleTabSelect}>
        <TabList style={styles.tabList}>
          <Tab
            className={`chat-tab ${activeTab === 0 ? 'active' : ''}`}
            style={activeTab === 0 ? { ...styles.tab, ...styles.activeTab } : { ...styles.tab, ...styles.inactiveTab }}
          >
            Ask a Book
          </Tab>
          <Tab
            className={`chat-tab ${activeTab === 1 ? 'active' : ''}`}
            style={activeTab === 1 ? { ...styles.tab, ...styles.activeTab } : { ...styles.tab, ...styles.inactiveTab }}
          >
            Tutor Chat
          </Tab>
        </TabList>
        <TabPanel>
          <AskABook />
        </TabPanel>
        <TabPanel>
          <TutorChat />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Chat;
