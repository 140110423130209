import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLogo, HomeBanner } from '../assets/images';

const HomePage = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 1500);

    const redirectTimer = setTimeout(() => {
      navigate('/onboarding');
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        background: 'radial-gradient(123.73% 50% at 50% 50%, #585AED 0%, #2325A0 63.03%)',
        height: '100vh',
        opacity: fadeOut ? 0 : 1, // Apply fade-out effect
        transition: 'opacity 0.5s ease-in-out', // Smooth transition
      }}
    >
      <div style={{ fontSize: '1.25rem', fontWeight: 700 }}>
        <img src={AppLogo} alt="App Logo" />
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: 20, fontWeight: 400, color: 'white' }}>Study smarter with AI in</span>
        <span style={{ fontSize: 20, fontWeight: 400, color: 'white' }}>UPSC PadhAI.</span>
      </div>
      <img src={HomeBanner} alt="Home Banner" style={{ width: '100%', marginTop: 20 }} />
    </div>
  );
};

export default HomePage;
