import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import '@mantine/core/styles.css';
import { Container, Group, Button, Select, Title, Text, Paper, Divider } from '@mantine/core';
import { IconSearch, IconArrowLeft, IconX } from '@tabler/icons-react';
import { callPOSTAPI, callGETAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import PrelimsContent from './PrelimContent';
import { ReactComponent as NoDataImage } from '../../assets/images/NoData.svg';
import Lottie from 'react-lottie';
import pyqLoadingAnimation from '../../assets/json/pyq_loading.json';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import CustomSelect from '../../molecules/CustomMultiSelect';
import { wordList } from '../../constants/wordList';
import { CustomDivider } from '../../molecules/OR';
import moment from 'moment/moment';
import { ReactComponent as IconFilterCheck } from '../../assets/images/IconFilterCheck.svg';
import { CloseSquare, FilterRemove, SearchDark, SearchNormal, SendIcon } from '../../assets/images';

const PyqSearch = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use location to get passed state
  const authUserDetails = userStore((state) => state.userData);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchInitiated, setIsSearchInitiated] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showWordList, setShowWordList] = useState(true);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 768;
  const isResponsiveMobile = windowHeight <= 700;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Detect if there's a passed state from AutoScrollLists
  useEffect(() => {
    if (location.state?.selectedWord) {
      const word = location.state.selectedWord;
      setSearchTerm(word); // Set the passed word as search term
      handleSearch(word); // Trigger the search
    }
  }, [location.state]);

  useEffect(() => {
    if (authUserDetails?.username) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/pyq-recent-searches/?username=${authUserDetails?.username}`,
        (resp) => {
          setRecentSearches(resp.data.data.recent_searches || []);
        },
        () => {
          setRecentSearches([]);
        }
      );
    }
  }, [authUserDetails?.username]);

  const handleSearch = async (term = searchTerm) => {
    if (!term.trim()) {
      setSearchResults([]); // If search term is empty, clear the results
      return;
    }

    MixpanelEvent('pyq_retrieved', {
      question_id: searchResults[0]?.id,
      action: isSearchInitiated ? 'search_success' : 'submit',
      year: selectedYear,
      search_id: authUserDetails.username,
      page_num: searchResults.length,
      search_query: searchTerm,
      last_pyq: searchResults.length === 0 ? false : true,
    });
    sendPostHogEvent('pyq_retrieved', {
      question_id: searchResults[0]?.id,
      action: isSearchInitiated ? 'search_success' : 'submit',
      year: selectedYear,
      search_id: authUserDetails.username,
      page_num: searchResults.length,
      search_query: searchTerm,
      last_pyq: searchResults.length === 0 ? false : true,
    });
    setIsSearchInitiated(true);
    setLoading(true);
    setShowWordList(false);
    setError(null);

    const payload = {
      query: term, // Use passed term or current searchTerm
      username: authUserDetails.username,
      category: selectedCategory.length > 0 ? selectedCategory : [], // Include category if selected
      year: selectedYear || undefined, // Include year if selected
    };

    callPOSTAPI(
      `${process.env.REACT_APP_BASE_URL}/api/pyq/`,
      JSON.stringify(payload),
      (resp) => {
        setSearchResults(resp?.data.data?.high_relevance_questions || []);
        setLoading(false);
      },
      (error) => {
        console.error('Error:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    );
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]); // Clear search results
    setIsSearchInitiated(false); // Hide PrelimsContent and stop showing results
    setShowWordList(false); // Clear selected year
  };

  const handleClearFilter = () => {
    setSelectedCategory([]); // Clear selected categories
    setSelectedYear(null);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const topicsData = [
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'polity', label: 'Polity' },
    { value: 'current_affairs', label: 'Current Affairs' },
    { value: 'economy', label: 'Economy' },
    { value: 'science', label: 'Science' },
    { value: 'environment', label: 'Environment' },
  ];

  const currentDate = moment();
  const currentYear = currentDate.year();
  const lastYear = currentYear - 1;
  const last3Years = `${currentYear - 3}-${lastYear}`;
  const last5Years = `${currentYear - 5}-${lastYear}`;
  const last10Years = `${currentYear - 10}-${lastYear}`;

  if (window.ResizeObserver) {
    const resizeObserverErrSilencer = () => {
      const resizeObserverErr = window.ResizeObserver;
      window.ResizeObserver = function (callback) {
        const ro = new resizeObserverErr(callback);
        ro.observe = (target) => {
          try {
            resizeObserverErr.prototype.observe.call(ro, target);
          } catch (e) {
            // Ignore error
          }
        };
        return ro;
      };
    };
    resizeObserverErrSilencer();
  }

  // Add this useEffect hook to handle clearing searchResults automatically when searchTerm is empty
  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults([]); // Reset searchResults when searchTerm is empty
      setIsSearchInitiated(false); // Also reset the isSearchInitiated state
    }
  }, [searchTerm]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pyqLoadingAnimation, // The Lottie JSON animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const list1Ref = useRef(null);
  const list2Ref = useRef(null);
  const list3Ref = useRef(null);

  // Splitting the word list into 3 different groups
  const list1Words = wordList.slice(0, 5);
  const list2Words = wordList.slice(5, 10);
  const list3Words = wordList.slice(10, 15);

  useEffect(() => {
    const scrollSpeed1 = 1;
    const scrollSpeed2 = 1;
    const scrollSpeed3 = 1;

    const scrollHorizontally = (list, speed) => {
      if (list) {
        list.scrollLeft += speed;
        if (list.scrollLeft >= list.scrollWidth - list.clientWidth) {
          list.scrollLeft = 0; // Reset to the beginning for infinite scrolling
        }
      }
    };

    const interval1 = setInterval(() => scrollHorizontally(list1Ref.current, scrollSpeed1), 50);
    const interval2 = setInterval(() => scrollHorizontally(list2Ref.current, scrollSpeed2), 50);
    const interval3 = setInterval(() => scrollHorizontally(list3Ref.current, scrollSpeed3), 50);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  const handleWordClick = (word) => {
    // Navigate to the /pyqSearch route and pass the word as a parameter
    navigate('/pyqSearch', { state: { selectedWord: word } });
  };

  const renderWordItems = (listRef, words) => (
    <Paper
      ref={listRef}
      style={{
        overflowX: 'scroll', // Allow manual scrolling
        display: 'flex',
        whiteSpace: 'nowrap', // Keep words in a single line
        backgroundColor: 'transparent',
        gap: 2,
        flexWrap: 'nowrap', // Prevent wrapping
        width: '100%',
      }}
    >
      {/* Render words and clone them for infinite scrolling */}
      {[...words, ...words].map((item, index) => (
        <div
          key={index}
          style={{
            marginRight: '16px',
            backgroundColor: '#EBF3FF',
            color: '#3C45CD',
            padding: '8px 12px 8px 16px',
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            fontWeight: '500',
            fontSize: '12.5px',
            cursor: 'pointer',
            fontFamily: 'Poppins',
          }}
          onClick={() => handleWordClick(item)} // Navigate on click
        >
          {item}
          <img src={SendIcon} size={16} style={{ marginLeft: '8px' }} /> {/* Correct arrow direction */}
        </div>
      ))}
    </Paper>
  );

  const LowerSection = () => (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
        <CustomDivider />
        <Title order={3} style={{ fontSize: '16px', fontWeight: '600', color: '#5A5A5A', marginBottom: '20px' }}>
          Try any of the topics below
        </Title>
      </div>
      <Group position="apart" noWrap style={{ display: 'flex', gap: 0, flexDirection: 'column', overflowX: 'auto' }}>
        {renderWordItems(list1Ref, list1Words)}
        {renderWordItems(list2Ref, list2Words)}
        {renderWordItems(list3Ref, list3Words)}
      </Group>
    </>
  );

  const toggleTooltip = () => {
    setTooltipVisible((prev) => !prev);
  };

  return (
    <Container
      size="sm"
      py="lg"
      style={{
        textAlign: 'center',
        minHeight: '100dvh', // Added to ensure full height
        position: 'relative',
      }}
    >
      {isSearchInitiated && searchResults.length > 0 && tooltipVisible && (
        <div style={{ position: 'relative', width: '100%' }}>
          {/* Custom Tooltip */}
          {tooltipVisible && (
            <div
              style={{
                position: 'absolute',
                top: '15dvh', // Changed from '7rem'
                right: '0', // Align with the button's right
                backgroundColor: '#2B2B40', // Dark background for tooltip
                color: '#fff',
                padding: '12px',
                borderRadius: '8px',
                width: '260px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                zIndex: 1000, // Ensure it is above other elements
              }}
            >
              <Group position="apart" style={{ marginBottom: '8px' }}>
                {selectedCategory.length + (selectedYear ? 1 : 0) > 0 ? (
                  <>
                    <Text weight={500} color="white" style={{ width: '80%' }}>
                      Applied Filters
                    </Text>
                    <IconX size={16} style={{ cursor: 'pointer' }} onClick={toggleTooltip} />
                  </>
                ) : (
                  <>
                    <Text weight={500} color="white" style={{ width: '80%' }}>
                      No Filters Applied
                    </Text>
                    <IconX size={16} style={{ cursor: 'pointer' }} onClick={toggleTooltip} />
                  </>
                )}
              </Group>
              {selectedCategory.length > 0 && (
                <div>
                  <Text size="sm" weight={400} color="white">
                    Topics: {selectedCategory.join(', ')}
                  </Text>
                </div>
              )}
              {selectedYear && (
                <div>
                  <Text size="sm" weight={400} color="white" style={{ marginTop: '8px' }}>
                    Year: {selectedYear}
                  </Text>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {isSearchInitiated && searchResults.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: '40dvh', // Changed from '25rem'
            left: 0,
            right: 0,
            zIndex: 0,
            bottom: 0,
          }}
        >
          <Text size="lg" weight={500}>
            No PYQs found for
          </Text>
          <Text size="sm" color="dimmed" style={{ marginBottom: '20px' }}>
            {searchTerm}
          </Text>
          <NoDataImage style={{ marginBottom: '20px' }} />
          <Text size="lg" weight={500}>
            Try searching something else
          </Text>
        </div>
      )}
      {loading && (
        <Lottie
          options={defaultOptions}
          height="100dvh" // Changed from "100%"
          width="100%"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        />
      )}
      {/* Show "No PYQs found" only if search is initiated, there are no results, and it's not loading */}
      {isSearchInitiated && searchResults.length === 0 && !loading && (
        <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: '40dvh', // Changed from '25rem'
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          <Text size="lg" weight={500}>
            No PYQs found for
          </Text>
          <Text size="sm" color="dimmed" style={{ marginBottom: '20px' }}>
            {searchTerm}
          </Text>
          <NoDataImage style={{ marginBottom: '20px' }} />
          <Text size="lg" weight={500}>
            Try searching something else
          </Text>
        </div>
      )}
      <Group position="apart" mb="lg">
        <Button
          variant="subtle"
          size="sm"
          onClick={() => {
            navigate('/pyq');
            MixpanelEvent('navigation_back', { source_screen: searchResults.length > 0 ? 'search_results' : 'search_pyqs' });
            sendPostHogEvent('navigation_back', { source_screen: searchResults.length > 0 ? 'search_results' : 'search_pyqs' });
          }}
          style={{ color: 'black', padding: 0 }}
        >
          <IconArrowLeft size={20} />
        </Button>
        <h2 style={{ margin: 0, fontSize: '20px', fontWeight: '500', zIndex: 2, fontFamily: 'Poppins' }}>
          {searchResults.length > 0 ? 'Search Results' : 'Search PYQs'}
        </h2>
      </Group>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '16px' }}>
        {/* Input and icons container */}
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flex: 1 }}>
          {/* Left Section Icon (Search Icon) */}
          <div
            style={{
              position: 'absolute',
              left: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#6374FA',
              borderRadius: '50%',
              padding: '0.4em',
            }}
          >
            <img src={SearchNormal} style={{ width: '100%' }} />
          </div>

          {/* Input field */}
          <input
            ref={inputRef}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            style={{
              width: '100%',
              padding: '12px',
              paddingLeft: '50px', // Leave space for search icon
              paddingRight: '50px', // Leave space for the clear icon
              fontSize: '16px',
              border: searchTerm ? '1px solid #6374FA' : '1px solid #D1D1D1',
              borderRadius: '8px',
              outline: 'none',
              fontFamily: 'Poppins',
            }}
          />

          {/* Cross Icon (Clear Search Icon) */}
          {searchTerm && (
            <div
              style={{
                position: 'absolute',
                right: '10px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleClearSearch}
            >
              <img src={CloseSquare} style={{ width: '100%' }} />
            </div>
          )}
        </div>

        {/* Filter Button (outside the input box) */}
        {isSearchInitiated && searchResults.length > 0 && (
          <Button
            variant="light"
            radius="sm"
            size="xs"
            style={{
              marginLeft: '10px', // Margin between input and filter button
              backgroundColor: 'transparent',
              color: '#61BD85',
              border: '1px solid lightgray',
              height: '50px',
              width: '50px',
              padding: '4px',
            }}
            onClick={toggleTooltip}
          >
            {/* Show Filter Count */}
            {selectedCategory.length > 0 || selectedYear ? (
              <div
                style={{
                  position: 'absolute',
                  right: '1.8rem',
                  color: 'gray',
                  borderRadius: '50%',
                  padding: '4px',
                  fontSize: '14px',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 4,
                }}
              >
                {/* Show count: number of selected categories + year */}
                {selectedCategory.length + (selectedYear ? 1 : 0)}
              </div>
            ) : null}
            <IconFilterCheck width={20} height={20} style={{ marginLeft: 6 }} />
          </Button>
        )}
      </div>

      {loading ||
        (searchResults.length === 0 && (
          <>
            <Group mb="lg" style={{ display: 'flex', gap: '10px', alignItems: 'center', zIndex: 100 }}>
              <CustomSelect options={topicsData} onChange={setSelectedCategory} value={selectedCategory} />
              <Select
                placeholder="Select Year"
                data={[
                  { label: 'Last year', value: lastYear.toString() },
                  { label: 'Last 3 years', value: last3Years },
                  { label: 'Last 5 years', value: last5Years },
                  { label: 'Last 10 years', value: last10Years },
                ]}
                radius="md"
                style={{ flex: 1 }}
                clearable
                styles={() => ({
                  input: {
                    borderColor: '#D1D1D1',
                    fontFamily: 'Poppins',
                  },
                })}
                value={selectedYear}
                onChange={setSelectedYear}
              />
              <Button
                variant="light"
                style={{ backgroundColor: '#FFEDED', color: '#D95353', border: '1px solid #FFB6B6', borderRadius: '8px', padding: '5px' }}
                onClick={handleClearFilter}
              >
                <img src={FilterRemove} alt="filter remove" style={{ width: '100%' }} />
              </Button>
            </Group>
            <Button
              fullWidth
              radius="md"
              style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold', borderRadius: '25px' }}
              onClick={() => handleSearch()}
              size="lg"
              disabled={!searchTerm.trim()}
            >
              Search
            </Button>
          </>
        ))}
      {searchResults.length > 0 && <PrelimsContent prelimsQuestions={searchResults} />}
      {!isSearchInitiated && !loading && searchResults.length === 0 && (
        <>
          {recentSearches.length > 0 && (
            <>
              <Divider style={{ margin: '2em 0' }} />
              <Title order={6} style={{ margin: '20px 0', color: '#747474', textAlign: 'start', fontFamily: 'Poppins' }}>
                RECENT SEARCHES
              </Title>
            </>
          )}

          <Group
            direction="column"
            spacing="lg"
            position="center"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: recentSearches.length > 0 ? 'column' : '',
              alignItems: 'flex-start',
              height: 'calc(70dvh - 180px)', // Changed from '25rem' to account for header and other elements
              overflowY: 'auto',
              padding: '0px',
              flexWrap: recentSearches.length > 0 ? 'nowrap' : '',
              gap: recentSearches.length > 0 ? '6px' : '',
              msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
              scrollbarWidth: 'none', // Hide scrollbar in Firefox
              '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar in Chrome/Safari/Opera
              },
            }}
          >
            {recentSearches.length > 0 ? (
              recentSearches.map((searchItem, index) => (
                <Group
                  key={index}
                  style={{ cursor: 'pointer', width: '100%', padding: '10px' }}
                  onClick={() => {
                    setSearchTerm(searchItem);
                    handleSearch(searchItem);
                    setIsSearchInitiated(true);
                  }}
                >
                  <img src={SearchDark} alt="Search icon" style={{ width: '5%' }} />
                  <Text style={{ fontFamily: 'Poppins', color: '#070519' }}>{searchItem}</Text>
                </Group>
              ))
            ) : (
              <LowerSection />
            )}
          </Group>
        </>
      )}
    </Container>
  );
};

export default PyqSearch;
