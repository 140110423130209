import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import { ArrowLeft, BlueCrown, RedCrown, Timer, Versus2 } from '../../../assets/images';
import { Button, Divider, Flex, Group, Radio, Stack, Text, Textarea, Title } from '@mantine/core';
import ReportQuestionModal from './ReportQuestionModal';
import { useTheme } from '../../../context/themeProvider';
import ReconnectingWebSocket from 'reconnecting-websocket';
import Lottie from 'react-lottie';
import * as successAnimation from '../../../assets/json/report_success.json';
import { MarkdownWithLatex } from '../../../molecules/RichText/MarkdownLatex';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import correctSoundFile from '../../../assets/raw/correct.mp3';
import wrongSoundFile from '../../../assets/raw/wrong.mp3';

const DuelStart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { bot, opponent, currentUser, topic, room_id } = location.state || {};
  const socketRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loaderText, setLoaderText] = useState([]);
  const [timer, setTimer] = useState(60);
  const [userAnswered, setUserAnswered] = useState(false);
  const [opponentAnswered, setOpponentAnswered] = useState(false);
  const [botAnswerTime, setBotAnswerTime] = useState(null);
  const [botAnswer, setBotAnswer] = useState(null);
  const [gameEnded, setGameEnded] = useState(false);
  const userId = currentUser.user;
  const [answerStatus, setAnswerStatus] = useState(Array(5).fill(null));
  const [opponentAnswerStatus, setOpponentAnswerStatus] = useState(Array(5).fill(null));
  const [userCorrectAnswers, setUserCorrectAnswers] = useState(0);
  const [botCorrectAnswers, setBotCorrectAnswers] = useState(0);
  const [eloData, setEloData] = useState(null);
  const [userEloChange, setUserEloChange] = useState(0);
  const [botEloChange, setBotEloChange] = useState(0);
  const [isSpinnerLoader, setSpinnerLoader] = useState(false);
  const [opponentAnswer, setOpponentAnswer] = useState(null);
  const [opponentCorrectAnswers, setOpponentCorrectAnswers] = useState(0);
  const [hasExited, setHasExited] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const correctSound = new Audio(correctSoundFile);
  const wrongSound = new Audio(wrongSoundFile);
  const currentQuestion = questions[currentQuestionIndex];
  const isResponsiveMobile =
    window.innerHeight <= 700 && window.innerWidth <= 400 ? '23em' : window.innerWidth > 400 && window.innerWidth < 900 ? '26em' : '28em';
  const [opened, setOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReportModal, setReportModal] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [reportData, setReportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const [opponentQuestionIndex, setOpponentQuestionIndex] = useState(0);
  const opponentCurrentQIndex = useRef(opponentQuestionIndex);

  useEffect(() => {
    opponentCurrentQIndex.current = opponentQuestionIndex;
  }, [opponentQuestionIndex]);

  const storeQuestionData = (question) => {
    const storedData = JSON.parse(localStorage.getItem('questionData')) || [];
    const updatedData = [
      ...storedData,
      {
        question_id: question.question_id,
        question: question.question,
        user_option: question.user_answer,
        user_answer: question.user_option,
        correct_answer: question.correct_answer,
        opponent_answer: question.opponent_answer,
        bot_time: question.bot_time,
        choices: [{ a: question.choices[0] }, { b: question.choices[1] }, { c: question.choices[2] }, { d: question.choices[3] }],
        remaining_time: timer,
        explanation: question.explanation || '',
      },
    ];
    const limitedData = updatedData.slice(-5);
    localStorage.setItem('questionData', JSON.stringify(limitedData));
  };

  const generateWebSocketUrl = (isBot) => {
    const baseUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/one-vs-one`;
    return isBot
      ? `${baseUrl}/bot/${userId}/${room_id}/?category=${topic}&render_type=text`
      : `${baseUrl}/${room_id}/${userId}/?category=${topic}&render_type=text`;
  };

  useEffect(() => {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const wssUrl = generateWebSocketUrl(isBotUser);
    socketRef.current = new ReconnectingWebSocket(wssUrl);

    socketRef.current.onopen = () => {
      setStartTime(Date.now());
      console.log('WebSocket connected');
      if (isBotUser) {
        const message = {
          bot_data: bot,
          isBot: true,
          challenge: false,
          userId,
        };
        socketRef.current.send(JSON.stringify(message));
      }
      localStorage.setItem('user_current_question', 0);
    };
    const handleNewQuestion = () => {
      if (topic === 'csat') {
        setTimer(180);
      } else {
        setTimer(60);
      }
    };

    socketRef.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.question_id && data.question && data.options) {
          setQuestions((prev) => [
            ...prev,
            {
              id: data.question_id,
              text: data.question,
              options: data.options,
              correctAnswer: data.correct_answer,
              bot_time: data.bot_time,
              bot_answer: data.bot_answer,
              choices: [{ a: data.options[0] }, { b: data.options[1] }, { c: data.options[2] }, { d: data.options[3] }],
            },
          ]);
          handleNewQuestion();
          setAnswerStatus((prev) => [...prev, null]);
          setOpponentAnswerStatus((prev) => [...prev, null]);
          setUserAnswered(false);
          setOpponentAnswered(false);
        }
        if (data.message_type === 'answer') {
          const currentQuestionNumber = parseInt(localStorage.getItem('user_current_question')) || 0;

          const isCorrect = data.user_answer === data.correct_answer;

          if (data.sender_id === userId) {
            setAnswerStatus((prevStatus) => {
              const newStatus = [...prevStatus];
              newStatus[currentQuestionNumber] = isCorrect;
              return newStatus;
            });
            setUserAnswered(true);
          } else {
            setOpponentAnswer(data.user_answer);
            setOpponentAnswerStatus((prevStatus) => {
              const newStatus = [...prevStatus];
              newStatus[opponentCurrentQIndex.current] = isCorrect;
              return newStatus;
            });
            setOpponentQuestionIndex(opponentCurrentQIndex.current + 1);
            if (isCorrect) {
              setOpponentCorrectAnswers((prev) => prev + 1);
            }
            setOpponentAnswered(true);
          }
        } else {
          setAnswerStatus((prev) => [...prev, null]);
          setOpponentAnswerStatus((prev) => [...prev, null]);
        }
        if (data.message_type === 'elo' || data.message_type === 'end') {
          const userElo = data.elo[userId];
          const opponentId = Object.keys(data.elo).find((id) => id !== userId);
          const opponentElo = data.elo[opponentId];

          const userScoreChange = data.score[userId];
          const opponentScoreChange = data.score[opponentId];
          if (bot) {
            setEloData({ userElo, botElo: opponentElo });
          } else {
            setEloData({ userElo, opponentElo });
          }

          setUserEloChange(userScoreChange);
          setBotEloChange(opponentScoreChange);
          setGameEnded(true);
        }
        if (data.message_type === 'end') {
          setHasExited(true);
        }
      } catch (error) {
        console.error('Error parsing WebSocket data:', error);
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket closed');
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      localStorage.removeItem('questionData');
      localStorage.removeItem('user_current_question');
    };
  }, [bot, currentUser, room_id, userId, topic]);

  const updateQuestionTracking = (questionIndex, userAnswer, opponentAnswer) => {
    localStorage.setItem('user_current_question', questionIndex);

    // Update the question data in localStorage
    const storedQuestions = JSON.parse(localStorage.getItem('questionData')) || [];
    if (storedQuestions[questionIndex]) {
      storedQuestions[questionIndex] = {
        ...storedQuestions[questionIndex],
        user_answer: userAnswer,
        opponent_answer: opponentAnswer,
      };
      localStorage.setItem('questionData', JSON.stringify(storedQuestions));
    }
  };

  useEffect(() => {
    if (gameEnded && eloData) {
      const competitorType = bot && Object.keys(bot).length > 0 ? 'bot' : 'real';
      MixpanelEvent('duel_completed', { category: topic, mode: 'online', competitor_type: competitorType });
      sendPostHogEvent('duel_completed', { category: topic, mode: 'online', competitor_type: competitorType });
      saveHistoryData(opponentCorrectAnswers)
        .then((responseData) => {
          navigate('/duel-results', {
            state: {
              userProfile: currentUser,
              botProfile: bot,
              userCorrectAnswers,
              botCorrectAnswers,
              userAnswerStatus: answerStatus,
              opponentAnswerStatus: opponentAnswerStatus,
              duel: responseData,
              userElo: eloData.userElo,
              botElo: eloData.botElo,
              userEloChange: userEloChange,
              botEloChange: botEloChange,
              hasExited,
            },
          });
        })
        .catch((error) => {
          console.error('Error while saving history data:', error);
        });
    }
  }, [gameEnded, eloData]);

  const handleExit = () => {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const opponentId = isBotUser ? bot.id : opponent.user;
    const endTime = Date.now();
    const loadingTime = Math.round((endTime - startTime) / 1000);
    MixpanelEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'post_initiate',
      no_ques: currentQuestionIndex,
    });
    sendPostHogEvent('duel_cancel', {
      category: topic,
      mode: 'online',
      loading_time: loadingTime,
      cancel_time: 'post_initiate',
      no_ques: currentQuestionIndex,
    });
    setHasExited(true);
    if (socketRef.current) {
      const exitMessage = {
        message_type: 'end',
        id: opponentId,
        render_type: 'text',
      };
      socketRef.current.send(JSON.stringify(exitMessage));
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const saveHistoryData = async (opponentCorrectAnswers) => {
    let userScore = userCorrectAnswers;
    const isBotUser = bot && Object.keys(bot).length > 0;
    const opponentScore = isBotUser ? botCorrectAnswers : opponentCorrectAnswers;
    const storedQuestions = JSON.parse(localStorage.getItem('questionData')) || [];

    if (storedQuestions.length === 0) {
      console.error('No question data available. Ensure questions are stored in local storage.');
      return;
    }

    const formattedQuestionData = storedQuestions.map((question) => {
      const formattedQuestion = {
        answer: question.correct_answer,
        choices: question.choices,
        category: topic,
        question: question.question,
        explanation: question.explanation,
        question_id: question.question_id,
        user_answer: question.user_answer || '',
        user_option: question.user_option || '',
        correct_answer: question.correct_answer,
        question_split: [],
        remaining_time: question.remaining_time,
      };
      return formattedQuestion;
    });

    const formattedOpponentQuestionData = storedQuestions.map((question, index) => ({
      question_id: question.question_id,
      user_option: question.opponent_answer,
      correct_answer: question.correct_answer,
    }));

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/postgame/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        body: JSON.stringify({
          profile: currentUser.user,
          eloScore: Number(eloData?.userElo || currentUser.elo),
          quizScore: userEloChange,
          category: topic,
          questionData: formattedQuestionData,
          history_type: '1v1',
          victory: userScore > botEloChange,
          created_at: new Date().toISOString(),
          correct_answers: userScore,
          user_info: {
            elo: Number(eloData?.userElo || currentUser.elo),
            name: currentUser.name,
            image: currentUser.image,
          },
          opponent_info: {
            elo: bot ? Number(eloData?.botElo || bot.elo) : Number(eloData?.opponentElo || opponent.elo),
            name: bot.name || opponent.name,
            image: bot.image || opponent.image,
          },
          opponent_question_data: formattedOpponentQuestionData,
          user_correct_answer: userScore,
          opponent_correct_answer: opponentScore,
          render_type: 'text',
        }),
      });

      const json = await response.json();
      if (response.ok) {
        localStorage.removeItem('questionData');
        localStorage.removeItem('user_current_question');
        console.log('Post game data saved and localStorage cleared');
        return json;
      } else {
        console.error('Error saving post game data:', json);
        throw new Error(json);
      }
    } catch (err) {
      console.error('Error saving post game data', err);
      throw err;
    }
  };

  useEffect(() => {
    if (currentQuestion) {
      setBotAnswerTime(currentQuestion.bot_time);
      setBotAnswer(currentQuestion.bot_answer);
    }
  }, [currentQuestion]);

  const sendAnswerData = () => {
    let selectedIndex = currentQuestion.options.indexOf(selectedOption);
    if (selectedIndex === -1) {
      selectedIndex = 9;
    }
    const timeElapsed = topic === 'csat' ? 180 - timer : 60 - timer;
    const data = {
      time: timeElapsed,
      render_type: 'text',
      name: currentUser.name,
      answer: selectedIndex,
    };

    if (socketRef.current) {
      try {
        socketRef.current.send(JSON.stringify(data));
      } catch (error) {
        console.error('WebSocket send error:', error);
      }
    } else {
      console.error('WebSocket is not open');
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTime) => {
          const newTime = prevTime - 1;
          const botAnswerThreshold = topic === 'csat' ? 180 : 60;
          if (newTime === botAnswerThreshold - botAnswerTime && !opponentAnswered) {
            handleBotAnswer();
          }
          if (newTime === 0 && !userAnswered) {
            handleSubmit();
          }
          checkBothAnswered();
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer, botAnswerTime, userAnswered, opponentAnswered, topic]);

  useEffect(() => {
    if (timer === 1) {
      setUserAnswered(true);
      setAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = false;
        return newStatus;
      });
      setIsSubmitted(true);
      setSpinnerLoader(false);
    }
  }, [timer, currentQuestionIndex]);

  const handleBotAnswer = () => {
    if (!opponentAnswered) {
      const opponentIsCorrect = botAnswer === currentQuestion.correctAnswer;

      setOpponentAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = opponentIsCorrect;
        return newStatus;
      });

      if (opponentIsCorrect) {
        setBotCorrectAnswers((prev) => prev + 1);
      }

      setOpponentAnswered(true);
      checkBothAnswered();
    }
  };

  const checkBothAnswered = () => {
    const isBotOpponent = bot && Object.keys(bot).length > 0;

    if (userAnswered && (isBotOpponent ? opponentAnswered : true)) {
      updateQuestionTracking(currentQuestionIndex, selectedOption, opponentAnswer);

      const selectedIndex = currentQuestion.options.indexOf(selectedOption);
      const answerMaps = ['a', 'b', 'c', 'd'];

      const questionData = {
        question_id: currentQuestion.id,
        question: currentQuestion.text,
        user_option: selectedOption,
        user_answer: answerMaps[selectedIndex],
        correct_answer: answerMaps[currentQuestion.correctAnswer],
        opponent_answer: isBotOpponent ? answerMaps[currentQuestion.bot_answer] : answerMaps[opponentAnswer] || null,
        choices: currentQuestion.options,
        bot_time: isBotOpponent ? currentQuestion.bot_time : null,
        remaining_time: timer,
      };

      storeQuestionData(questionData);
      setTimer(0);
      let countdownValue = 3;
      sendAnswerData();

      const countdownInterval = setInterval(() => {
        setLoaderText(`Next Question in ${countdownValue} seconds...`);
        if (countdownValue <= 0) {
          setSpinnerLoader(true);
          setTimeout(() => {
            countdownValue = 3;
            setSpinnerLoader(false);
            const newTimer = topic === 'csat' ? 180 : 60;
            setTimer(newTimer);
            clearInterval(countdownInterval);
            setShowLoader(false);
            setIsSubmitted(false);
            setSelectedOption('');
            setUserAnswered(false);
            setOpponentAnswered(false);
            if (currentQuestionIndex < 4) {
              setCurrentQuestionIndex(currentQuestionIndex + 1);
              localStorage.setItem('user_current_question', currentQuestionIndex + 1);
            } else {
              console.log('Quiz ended');
              setSpinnerLoader(true);
              setTimeout(() => {
                requestEloScore();
              }, 2000);
            }
          }, 3000);
        }
        countdownValue -= 1;
      }, 1000);
    }
  };

  const requestEloScore = () => {
    if (socketRef.current) {
      const isBotUser = bot && Object.keys(bot).length > 0;
      const userCompleted = currentQuestionIndex >= 4 && userAnswered;
      const opponentCompleted = isBotUser || (opponentQuestionIndex >= 4 && opponentAnswered);
      if (userCompleted && opponentCompleted) {
        setTimeout(() => {
          if (!eloData) {
            const isBotUser = bot && Object.keys(bot).length > 0;
            const opponentId = isBotUser ? bot.id : opponent.user;

            if (socketRef.current.readyState === WebSocket.OPEN) {
              const exitMessage = {
                message_type: 'end',
                id: opponentId,
                render_type: 'text',
              };
              socketRef.current.send(JSON.stringify(exitMessage));
            } else {
              console.error('WebSocket is not open. Current state:', socketRef.current.readyState);
            }
          }
        }, 1000);
      }
    }
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setIsSubmitted(true);
      setShowLoader(true);
      if (!opponentAnswered) {
        setLoaderText('Waiting for Opponent to answer...');
      }

      const selectedIndex = currentQuestion.options.indexOf(selectedOption);
      const isCorrect = selectedIndex === currentQuestion.correctAnswer;

      setAnswerStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[currentQuestionIndex] = isCorrect;
        return newStatus;
      });

      if (isCorrect) {
        correctSound.play().catch((error) => console.log('Error playing sound:', error));
        setUserCorrectAnswers((prev) => prev + 1);
      } else {
        wrongSound.play().catch((error) => console.log('Error playing sound:', error));
      }

      setUserAnswered(true);
      checkBothAnswered();
    }
  };

  const renderLoader = () => (
    <div style={styles.loader}>
      <button style={styles.loadingButton}>
        <>
          <span className="spinner" style={styles.spinner}></span>
          {loaderText}
        </>
      </button>
    </div>
  );

  if (!currentQuestion) {
    const isBotUser = bot && Object.keys(bot).length > 0;
    const hasQuestions = questions && questions.length > 0;

    return (
      <div style={styles.spinnerContainer}>
        <div style={styles.spinnerLoader}></div>
        {!isBotUser && hasQuestions ? <div style={styles.waitingMessage}>Waiting for opponent answer.</div> : null}
      </div>
    );
  }

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setReportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'duel',
      question_id: selectedQuestion,
      user: userId,
      option: reportData,
      reason: '',
    };

    if (reportData === 'Other') {
      postPayload.reason = details;
    }
    MixpanelEvent('submit_feedback', {
      selection: reportData,
      question_id: selectedQuestion,
      category: topic,
      source: 'duel_quiz',
    });
    sendPostHogEvent('submit_feedback', {
      selection: reportData,
      question_id: selectedQuestion,
      category: topic,
      source: 'duel_quiz',
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
        setReportModal(false);
      }, 3000);
    } catch (error) {}
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Flex align="center" style={{ padding: '1.2em 0em', backgroundColor: '#fff' }}>
        <img src={ArrowLeft} alt="Arrow Left" style={{ cursor: 'pointer', marginLeft: '1em' }} onClick={() => setOpened(true)} />
        <Text fz="xl" style={{ marginLeft: '0.5em' }}>
          Online Duel
        </Text>
      </Flex>
      <div
        style={{
          ...styles.container,
          height: window.innerHeight <= 700 ? '75vh' : '85vh',
        }}
      >
        {/* Header Section */}
        <div style={styles.statCard}>
          <div style={styles.playersContainer}>
            <div style={styles.playerInfo}>
              {currentUser.photo ? (
                <img src={currentUser.photo} alt="User" style={styles.avatar} />
              ) : (
                <div style={{ ...styles.defaultImage, backgroundColor: '#E1EBFF', color: '#555' }}>{getInitial(currentUser.name)}</div>
              )}

              <div>
                <div style={styles.playerName}>
                  {currentUser?.name ? (currentUser.name.length > 12 ? `${currentUser.name.slice(0, 12)}...` : currentUser.name) : 'UserName'}
                </div>
                <div style={styles.playerScore}>
                  <img src={BlueCrown} alt="Crown" style={styles.crownIcon} />
                  <span>{currentUser?.elo || '1980'}</span>
                </div>
              </div>
            </div>

            <div style={styles.vsWrapper}>
              <img src={Versus2} alt="VS" style={styles.vsImage} />
              <div style={styles.diagonalLine}></div>
            </div>
            <div style={styles.playerInfo}>
              <div>
                <div style={{ ...styles.playerName, textAlign: 'end' }}>
                  {bot?.name
                    ? bot.name.length > 12
                      ? `${bot.name.slice(0, 12)}...`
                      : bot.name
                    : opponent?.name
                    ? opponent.name.length > 12
                      ? `${opponent.name.slice(0, 12)}...`
                      : opponent.name
                    : 'Opponent'}
                </div>

                <div style={{ ...styles.playerScore, justifyContent: 'flex-end' }}>
                  <span>{bot?.elo || opponent?.elo || '1980'}</span>
                  <img src={RedCrown} alt="Crown" style={styles.crownIcon} />
                </div>
              </div>
              {bot?.photo ? (
                <img src={bot.photo} alt="User" style={styles.avatar} />
              ) : opponent?.photo ? (
                <img src={opponent.photo} alt="User" style={styles.avatar} />
              ) : (
                <div
                  style={{
                    ...styles.defaultImage,
                    backgroundColor: '#E1EBFF',
                    color: '#555',
                  }}
                >
                  {getInitial(bot?.name || opponent?.name || 'Opponent')}
                </div>
              )}
            </div>
          </div>
          <Divider my="md" />

          <div style={styles.answerSummary}>
            {answerStatus.slice(0, 5).map((status, index) => (
              <div
                key={index}
                style={{
                  ...styles.answerIndicator,
                  backgroundColor: status === null ? 'transparent' : status ? '#61BD85' : '#FFB6B6',
                }}
              >
                {status === null ? (
                  <span>
                    {index < 10 ? 0 : ''}
                    {index + 1}
                  </span>
                ) : status ? (
                  <IconCheck color="#fff" size={16} />
                ) : (
                  <IconX color="#fff" size={16} />
                )}
              </div>
            ))}

            <div style={{ width: '10%' }} />

            <div style={styles.answerSummary}>
              {opponentAnswerStatus.slice(0, 5).map((status, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.answerIndicator,
                    backgroundColor: status === null ? 'transparent' : status ? '#61BD85' : '#FFB6B6',
                  }}
                >
                  {status === null ? (
                    <span>
                      {index < 10 ? 0 : ''}
                      {index + 1}
                    </span>
                  ) : status ? (
                    <IconCheck color="#fff" size={16} />
                  ) : (
                    <IconX color="#fff" size={16} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Current Question Info */}
        <div style={styles.questionHeader}>
          <p style={styles.questionCategory}>
            {topic.toUpperCase()} • Q {currentQuestionIndex + 1}/5
          </p>
          {!isSubmitted && (
            <div style={styles.timerBox}>
              <img src={Timer} alt="timer" style={styles.timerIcon} />
              <span style={styles.timerText}>{timer}</span>
            </div>
          )}
        </div>

        {/* Question Text */}
        <div style={styles.questionText}>
          <MarkdownWithLatex content={currentQuestion.text} />
        </div>
        {/* Options Section */}
        <div style={styles.optionsContainer}>
          {currentQuestion.options.map((option, index) => {
            const isSelected = selectedOption === option;
            const isCorrect = currentQuestion.correctAnswer === index;
            let backgroundColor = colors.cardBackground;
            let color = colors.text;

            if (isSubmitted) {
              if (isSelected) {
                if (isCorrect) {
                  backgroundColor = '#61BD85';
                  color = '#fff';
                } else {
                  backgroundColor = '#FFB6B6';
                  color = '#781818';
                }
              } else if (isCorrect) {
                backgroundColor = '#fff';
                color = '#2D8F54';
              } else {
                color = '#747474';
              }
            } else if (isSelected) {
              backgroundColor = '#EBF3FF';
            } else {
              backgroundColor = '#fff';
            }

            return (
              <label
                key={index}
                style={{
                  ...styles.optionBox,
                  border: isSelected ? 'none' : '1px solid #E8E8E8',
                  backgroundColor,
                }}
              >
                <input
                  type="radio"
                  name="option"
                  value={option}
                  checked={isSelected}
                  onChange={() => handleOptionChange(option)}
                  disabled={isSubmitted}
                  style={styles.radioInput}
                />
                <div style={{ marginTop: '4px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span style={{ color: 'blue', marginRight: '4px' }}>{String.fromCharCode(65 + index)}&nbsp;</span>
                  <div style={styles.optionText}>
                    <MarkdownWithLatex content={option} />
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      </div>
      {showLoader ? (
        renderLoader()
      ) : (
        <div style={{ ...styles.footer, width: isResponsiveMobile }}>
          <button style={styles.reportButton} onClick={() => setReportModal(true)}>
            <IconInfoCircle style={{ marginRight: '4px' }} />
            Report
          </button>
          <button
            onClick={handleSubmit}
            style={{
              ...styles.submitButton,
              backgroundColor: selectedOption ? '#070519' : '#F3F3F3',
              color: selectedOption ? '#FFFFFF' : '#B9B9B9',
              cursor: selectedOption ? 'pointer' : 'not-allowed',
              border: selectedOption ? 'none' : '1px solid #D1D1D1',
            }}
            disabled={isSubmitted}
          >
            Submit Answer
          </button>
        </div>
      )}
      {opened && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={styles.modalTitle}>Exit Duel?</h2>
            <p style={styles.modalDescription}>Are you sure you want to exit the ongoing Duel? Your earned rating will be lost.</p>
            <div style={styles.modalActions}>
              <button onClick={() => setOpened(false)} style={{ ...styles.modalButton, ...styles.cancelButton }}>
                Cancel
              </button>
              <button onClick={handleExit} style={{ ...styles.modalButton, ...styles.exitButton }}>
                {' '}
                Yes, Exit
              </button>
            </div>
          </div>
        </div>
      )}
      <ReportQuestionModal isOpened={isReportModal} onCancel={() => setReportModal(false)} onReport={() => handleReport(currentQuestion.id)} />
      {isSpinnerLoader && (
        <div style={styles.spinnerContainer}>
          <div style={styles.spinnerLoader}></div>
        </div>
      )}
      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        reportData={reportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setReportData={setReportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        defaultOptions={defaultOptions}
      />
    </div>
  );
};

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  reportData,
  setReportData,
  details,
  onChange,
  handleResportSubmit,
  styles,
  defaultOptions,
}) => {
  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span style={styles.topLine} onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          style={{
            position: 'relative',
            right: '5px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'rgb(0, 0, 0)',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          &times;
        </button>

        {!isReportSubmited ? (
          <>
            <Title fz="h3" m="1em" style={{ textAlign: 'left' }}>
              Report Question
            </Title>
            <Stack spacing="lg">
              <Radio.Group value={reportData} onChange={setReportData} size="1em">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} p="md" />
                ))}
              </Radio.Group>

              {reportData === 'Other' && (
                <>
                  <Text style={{ textAlign: 'left' }}>Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    style={{ marginTop: '10px', fontSize: '14px' }}
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!reportData}
                style={{
                  backgroundColor: reportData ? '#000' : '#ccc',
                  fontWeight: 500,
                }}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title fz="h3" m="1em">
              Report Submitted
            </Title>
            <Title m="1em" fz="md">
              Thank you! Your report has been submitted.
            </Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};

// Styles for the DuelStart component
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    position: 'relative',
    backgroundColor: '#fff',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  containerScrollHide: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loadingButton: {
    padding: '24px',
    backgroundColor: '#6374FA',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  spinner: {
    border: '4px solid #ffffff',
    borderTop: '4px solid #6374FA',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    animation: 'spin 1s linear infinite',
  },
  loaderText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  answerSummary: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.6em',
  },
  answerIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  questionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1em 0em',
  },
  questionCategory: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#888',
  },
  timerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F3F3F3',
    padding: '4px 8px',
    borderRadius: '8px',
  },
  timerIcon: {
    width: '24px',
    height: '24px',
    marginRight: '4px',
  },
  timerText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#070519',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: '20px',
  },
  optionBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '12px',
    gap: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background-color 0.2s, border 0.2s',
  },
  radioInput: {
    marginRight: '8px',
    transform: 'scale(1.5)',
  },
  optionText: {
    fontSize: '1em',
    fontWeight: 'bold',
    maxWidth: '100%',
    textAlign: 'start',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    position: 'fixed', // Change from 'absolute' to 'fixed' for consistent positioning
    gap: '1.7em',
    backgroundColor: '#fff',
    bottom: '0em',
    boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.1)',
  },
  reportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    color: '#D95353',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: '#fff',
  },
  submitButton: {
    padding: '1.2em',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '60%',
    justifyContent: 'center',
  },
  statCard: {
    border: '1px solid #E8E8E8',
    borderRadius: '8px',
    padding: '1em 0em',
  },
  playersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0em 1em',
  },
  playerInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.4em',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
  },
  playerName: {
    fontWeight: 'bold',
  },
  playerScore: {
    fontSize: '14px',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    gap: '0.4em',
  },
  crownIcon: {
    width: '16px',
    height: '16px',
  },
  vsIcon: {
    width: '32px',
    height: '32px',
  },
  defaultImage: {
    fontSize: '24px',
    fontWeight: 'bold',
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    textAlign: 'center',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '12px',
    width: '90%',
    maxWidth: '400px',
    padding: '1.5rem',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  modalTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: '0.75rem',
    textAlign: 'center',
    color: '#070519',
  },
  modalDescription: {
    color: '#747474',
    marginBottom: '1.5rem',
    textAlign: 'center',
    fontSize: '0.9rem',
    lineHeight: 1.4,
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.75rem',
  },
  modalButton: {
    flex: 1,
    padding: '0.75rem 1rem',
    borderRadius: '25px',
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'background-color 0.2s, transform 0.1s',
    border: 'none',
  },
  modalButtonActive: {
    transform: 'scale(0.98)',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#070519',
    border: '1px solid #070519',
  },
  exitButton: {
    backgroundColor: '#070519',
    color: 'white',
  },
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    marginTop: '4em',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  spinnerLoader: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #3498db',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
  waitingMessage: {
    marginTop: '20px', // Add space between spinner and message
    fontSize: '18px',
    color: '#555',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  vsWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '40px',
    height: '40px',
  },
  vsImage: {
    width: '100%',
    height: '100%',
  },
  diagonalLine: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '1.47px',
    height: '80px',
    backgroundColor: '#E1EBFF',
    transform: 'translate(-50%, -50%) rotate(14deg)',
    opacity: 0.7,
    zIndex: 2,
  },
};

export default DuelStart;
