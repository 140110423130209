import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Paper, Box, Image, Text, Flex, Space, Button, ScrollArea, Stack, Modal, ActionIcon, Title, Menu, Notification } from '@mantine/core';
import {
  ProfilePic,
  Copy,
  TelegramLogo,
  ShareAppLogo,
  Logout,
  Trash,
  FeedbackIcon,
  Edit2,
  ArrowCircleRight,
  Instagram,
  XLogo,
  YouTube,
  Option,
  ArrowLeft,
} from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useClipboard } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { callDELETEAPI } from '../../utils/NetworkUtil';
import { useTheme } from '../../context/themeProvider';
import DeletingAccount from './DeletingAccount'; // Import the new component
import FeedbackForm from './FeedbackForm'; // Import the new FeedbackForm component
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const ProfilePage = () => {
  const clipboard = useClipboard({ timeout: 500 });
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const setUserData = userStore((state) => state.setUserData);
  const [activeButton, setActiveButton] = useState(0);
  const buttonRefs = useRef([]);
  // const [selectedSubject, setSelectedSubject] = useState('Current Affairs');
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  // const { colors } = useTheme();
  const location = useLocation();
  const { colors, colorScheme } = useTheme(); // Always call useTheme at the top
  const isNewsRoute = location.pathname.startsWith('/news'); // Check if it's a news route
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' }; // Conditionally apply colors
  const closeLogoutModal = () => setLogoutModalOpen(false);
  const scrollContainerRef = useRef(null);
  const [MenuModalOpen, setMenuModalOpen] = useState(false);
  const openMenuModal = () => setMenuModalOpen(true);
  const closeMenuModal = () => setMenuModalOpen(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const accountModalClose = () => setAccountModalOpen(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false);
  const [profilePic, setProfilePic] = useState(userData.photo || ProfilePic);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleShareFeedClick = () => {
    setIsFeedbackFormVisible(true);
    MixpanelEvent('profile_share_clicked');
    sendPostHogEvent('profile_share_clicked');
  };

  const handleCopyClick = () => {
    clipboard.copy(userData.username);
    setNotificationMessage('User ID copied');
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handleLogout = () => {
    MixpanelEvent('profile_log_out_clicked');
    sendPostHogEvent('profile_log_out_clicked');
    setMenuModalOpen(false);
    setUserData({});
    localStorage.clear();
    navigate('/login');
    window.location.reload();
  };
  const openLogoutModal = () => {
    setMenuModalOpen(false);
    setLogoutModalOpen(true);
  };
  const openAccountDeleteModal = () => {
    openLogoutModal(false);
    setLogoutModalOpen(false);
    setAccountModalOpen(true);
  };
  const buttons = useMemo(
    () => [
      { icon: TelegramLogo, text: 'Telegram', link: 'https://t.me/Padhai_Daily_News' },
      { icon: Instagram, text: 'Instagram', link: 'https://www.instagram.com/padhai_upsc_official/' },
      { icon: XLogo, text: 'X', link: 'https://x.com/padhai_upsc' },
      { icon: YouTube, text: 'YouTube', link: 'https://www.youtube.com/@UPSCPadhAI2024' },
      { icon: ShareAppLogo, text: 'Website', link: 'https://padhai.ai/' },
    ],
    []
  );

  const handleButtonClick = (index) => {
    setActiveButton(index);
    if (buttonRefs.current[index]) {
      buttonRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  const handleDeleteAccount = () => {
    setIsDeleting(true);
    const url = `https://dev-android.server.sigiq.ai/admin/delete-account/?uuid=${userData.user}`;
    callDELETEAPI(
      url,
      (response) => {
        localStorage.clear();
        window.location.reload();
      },
      (error) => {
        setIsDeleting(false);
        console.error('An error occurred while deleting the account:', error);
      }
    );
    MixpanelEvent('profile_delete_account_clicked');
    sendPostHogEvent('profile_delete_account_clicked');
  };

  const backClick = () => {
    navigate(-1);
    MixpanelEvent('navigation_back', { source_screen: 'profile' });
    sendPostHogEvent('navigation_back', { source_screen: 'profile' });
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const scrollButtons = () => {
      if (scrollContainer) {
        scrollContainer.scrollLeft += 1;
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          scrollContainer.scrollLeft = 0;
        }
      }
    };
    const scrollInterval = setInterval(scrollButtons, 25);
    return () => clearInterval(scrollInterval);
  }, []);

  if (isDeleting) {
    return <DeletingAccount />;
  }

  return (
    <Stack h={'100%'}>
      {/* Notification */}
      {showNotification && (
        <Notification
          color="green"
          style={{
            position: 'fixed',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 100,
            width: '300px',
            textAlign: 'center',
          }}
        >
          {notificationMessage}
        </Notification>
      )}
      {/*  Profile Header */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '3.9em',
          padding: '10px 0',
          backgroundColor: appliedColors.cardBackground,
          color: appliedColors.text,
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      >
        <Flex align="center">
          <img onClick={backClick} src={ArrowLeft} alt="Arrow Left" style={{ cursor: 'pointer', marginLeft: '1em' }} />
          <Text fz="xl" style={{ marginLeft: '0.5em' }}>
            My Profile
          </Text>
        </Flex>
        {/* Option Icon */}
        <Menu width={200} radius="md" shadow="md" position="bottom-end" withArrow transition="pop">
          {/* Trigger button (three dots) */}
          <Menu.Target>
            <img src={Option} alt="Edit" style={{ cursor: 'pointer', marginRight: '1em' }} />
          </Menu.Target>
          {/* Dropdown content */}
          <Menu.Dropdown>
            <Stack spacing="md">
              <Menu.Item onClick={openLogoutModal}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <img src={Logout} alt="Log out" width={20} height={20} style={{ marginRight: '8px' }} />
                  <Text fw={500} fz="1.1em" color="#e53935">
                    Log Out
                  </Text>
                </div>
              </Menu.Item>
              {/* Delete Account section */}
              <Menu.Item onClick={openAccountDeleteModal}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <img src={Trash} alt="Delete my account" width={20} height={20} style={{ marginRight: '8px' }} />
                  <Text fw={500} fz="1.1em" color="#e53935">
                    Delete my account
                  </Text>
                </div>
              </Menu.Item>
            </Stack>
          </Menu.Dropdown>
        </Menu>
      </Box>
      {/* Profile details */}
      <Flex direction="column" h="25%" w="100%" align="center" justify="center" mt="3em">
        <Paper
          shadow="sm"
          radius="lg"
          p="md"
          style={{
            width: '90%',
            maxWidth: '400px',
            margin: '2rem auto',
            backgroundColor: '#FFF',
            padding: '1.5em',
          }}
        >
          <Flex align="center">
            <Box mr="md">
              <img src={profilePic} alt="Profile" width={60} height={60} />
            </Box>

            <Box style={{ flex: 1 }}>
              <Text fw={600} fz="lg">
                {userData.name || 'User Name'}
              </Text>
              <Flex align="center" mt={4} style={{ backgroundColor: '#F5F5F5', width: '80%', padding: '0.5em', borderRadius: '0.5em' }}>
                <Text fz="sm" c="dimmed" mr={4}>
                  USER {userData.username || ''}
                </Text>
                <ActionIcon
                  size="sm"
                  variant="transparent"
                  onClick={handleCopyClick}
                  style={{
                    transition: 'color 0.3s ease',
                    cursor: 'pointer',
                  }}
                >
                  <img src={Copy} alt="Copy" width={16} height={16} />
                </ActionIcon>
              </Flex>
            </Box>
            <ActionIcon
              size="lg"
              variant="transparent"
              style={{ alignSelf: 'flex-start', cursor: 'pointer' }}
              onClick={() => {
                navigate('/edit-profile');
                MixpanelEvent('profile_edit_clicked');
                sendPostHogEvent('profile_edit_clicked');
              }}
            >
              <img src={Edit2} alt="Edit" width={24} height={24} />
            </ActionIcon>
          </Flex>
        </Paper>
      </Flex>
      {/* Connect with us section */}
      <Box style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <hr style={{ border: '0', borderTop: '1px solid #d3d3d3', flex: '1', margin: '0 10px' }} />
        <Text c="dimmed" style={{ margin: '0 1rem' }}>
          Connect with us
        </Text>
        <hr style={{ border: '0', borderTop: '1px solid #d3d3d3', flex: '1', margin: '0 10px' }} />
      </Box>
      {/* Horizontally scrollable buttons */}
      <Box w="100%" overflow="hidden">
        <ScrollArea type="never" viewportRef={scrollContainerRef}>
          <Flex
            gap="xs"
            p="0.2rem 1rem"
            style={{
              width: 'max-content',
              justifyContent: 'center',
              animation: 'scroll 1s linear infinite',
            }}
          >
            {buttons.map((button, index) => (
              <Box
                p={'0.5em 1em'}
                key={index}
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={() => handleButtonClick(index)}
                size="md"
                style={{
                  borderRadius: '50px',
                  border: '1px solid rgba(185, 185, 185, 1)',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Flex
                  align={'center'}
                  justify={'center'}
                  onClick={() => {
                    window.open(button.link, '_blank');
                    MixpanelEvent('profile_social_link_clicked', { platform: button.text });
                    sendPostHogEvent('profile_social_link_clicked', { platform: button.text });
                  }}
                >
                  <img w={'1.3em'} src={button.icon} alt={button.text} />
                  <Space w={'0.5em'} />
                  <Text fw={500} fz={'1em'} c={'#070519'}>
                    {button.text}
                  </Text>
                  <Space w={'0.5em'} />
                </Flex>
              </Box>
            ))}
          </Flex>
        </ScrollArea>
      </Box>
      {/* Share feedback section */}
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={handleShareFeedClick}
          style={{
            background: '#E4FFEF',
            border: 'none',
            borderRadius: '8px',
            padding: '12px 24px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '90%',
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={FeedbackIcon} alt="Feedback Icon" />
            <span
              style={{
                color: '#000',
                marginLeft: '8px',
                marginRight: '75px',
                fontFamily: 'Poppins',
                fontSize: '14px',
                lineHeight: '22.4px',
                textAlign: 'left',
                fontWeight: 500,
              }}
            >
              Share your feedback
            </span>
          </div>
          <img
            src={ArrowCircleRight}
            alt="Feedback Arrow Icon"
            style={{
              width: '26px',
              height: '26px',
            }}
          />
        </button>
      </div>

      {/* --------------------------------------------Don't remove this will be added later -----------------------------------------*/}

      {/* <Tabs defaultValue="duel" mt="1rem" grow={false}>
        <ScrollArea type="scroll" scrollbarSize={4}>
          <Tabs.List style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
            <Tabs.Tab value="padhai">PadhAI List</Tabs.Tab>
            <Tabs.Tab value="duel">Duel</Tabs.Tab>
            <Tabs.Tab value="newsfeed">News Feed</Tabs.Tab>
            <Tabs.Tab value="friendlist">Friend List</Tabs.Tab>
            <Tabs.Tab value="other">Other</Tabs.Tab>
          </Tabs.List>
        </ScrollArea>

        <Tabs.Panel value="padhai" pt="xs">
          <Center>
            <Text>Padhai List</Text>
          </Center>
        </Tabs.Panel>
        <Tabs.Panel value="duel" pt="xs">
          <Paper p="md" radius="md">
            <Select
              value={selectedSubject}
              onChange={setSelectedSubject}
              data={['Current Affairs', 'History', 'Geography', 'Science']}
              placeholder="Select a subject"
              leftSection={<Image src={Geography} alt="Dropdown" />}
              rightSection={<Image src={ArrowDown} alt="Dropdown" />}
              styles={{}}
            />
            <Space h="md" />
            <Stack justify="space-between" align="left">
              <Box>
                <Flex align="center">
                  <Image src={Crown} alt="Rating" width={24} height={24} />
                  <Text weight={700} size="xl" ml={8}>
                    1900
                  </Text>
                </Flex>
                <Text size="xs" color="dimmed">
                  RATING POINTS
                </Text>
              </Box>
              <Box>
                <Flex align="center">
                  <Image src={DuelGreen} alt="Rating" width={24} height={24} />
                  <Text weight={700} size="xl" ml={8}>
                    18
                  </Text>
                </Flex>
                <Text size="xs" color="dimmed">
                  DUELS WON
                </Text>
              </Box>
              <Box>
                <Flex align="center">
                  <Image src={DuelBlue} alt="Rating" width={24} height={24} />
                  <Text weight={700} size="xl" ml={8}>
                    65
                  </Text>
                </Flex>
                <Text size="xs" color="dimmed">
                  TOTAL DUELS
                </Text>
              </Box>
            </Stack>
            <Space h="md" />
            <Box
              w="100%"
              align="center"
              style={{
                cursor: 'pointer',
                border: '1px solid #070519',
                borderRadius: '5em',
                padding: '0.5em 1em',
              }}
            >
              <Flex justify="center" align="center">
                <Text>See all</Text>
                <Image src={ArrowRightFilled} alt="Arrow Right" width={24} height={24} />
              </Flex>
            </Box>
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="newsfeed" pt="xs">
          <Center>
            <Text>News Feed</Text>
          </Center>
        </Tabs.Panel>
        <Tabs.Panel value="friendlist" pt="xs">
          <Stack spacing="md">
            <Flex gap="md" justify="center" pl={'1em'} pr={'1em'}>
              <Button radius={'2em'} variant="outline" style={{ flex: 1, border: '1px solid #070519' }}>
                <Text fw={500} fz={'1em'} c={'#070519'} style={{ display: 'flex', alignItems: 'center' }}>
                  Duel with friends
                </Text>
              </Button>
              <Button bg={'#070519'} radius={'2em'} variant="outline" style={{ flex: 1, border: '1px solid #070519' }}>
                <Flex align="center" justify="center">
                  <Image src={Add} alt="Add" />
                  <Text fw={500} fz={'1em'} c={'#fff'} style={{ display: 'flex', alignItems: 'center' }}>
                    Add friends
                  </Text>
                </Flex>
              </Button>
            </Flex>

            <Paper p="md" radius="md">
              {['John Doe', 'Jane Smith', 'Alice Johnson'].map((name, index) => (
                <>
                  <Box
                    gap
                    key={index}
                    w="100%"
                    style={{
                      cursor: 'pointer',
                      border: '0.93px solid #E8E8E8',
                      borderRadius: '0.5em',
                      padding: '0.5em ',
                    }}
                  >
                    <Flex align="center" justify="space-between">
                      <Flex align="center">
                        <Image h={'3em'} src={ProfilePic} alt="Profile" width={24} height={24} />
                        <Space w="0.5em" />
                        <Text fw={400} fz={'1em'} c={'#070519'}>
                          {name}
                        </Text>
                      </Flex>
                      <ActionIcon color="red" variant="subtle">
                        <Image src={Trash} alt="Trash" />
                      </ActionIcon>
                    </Flex>
                  </Box>
                  <Space h="md" />
                </>
              ))}
            </Paper>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="other" pt="xs">
          <Paper p="md" radius="md">
            <Stack>
              <Box
                w="100%"
                style={{
                  cursor: 'pointer',
                  border: '0.93px solid #E8E8E8',
                  borderRadius: '0.5em',
                  padding: '0.5em 1em',
                }}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="left">
                    <Image src={Tnc} alt="Tnc" />
                    <Space w="0.5em" />
                    <Text fw={400} fz={'1em'} c={'#070519'} style={{ display: 'flex', alignItems: 'center' }}>
                      Terms and Conditions
                    </Text>
                  </Flex>
                  <Image src={ArrowRight2} alt="Arrow Right" width={24} height={24} />
                </Flex>
              </Box>
              <Box
                w="100%"
                style={{
                  cursor: 'pointer',
                  border: '0.93px solid #E8E8E8',
                  borderRadius: '0.5em',
                  padding: '0.5em 1em',
                }}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="left">
                    <Image src={Tnc} alt="Tnc" />
                    <Space w="0.5em" />
                    <Text fw={400} fz={'1em'} c={'#070519'} style={{ display: 'flex', alignItems: 'center' }}>
                      Privacy Policy
                    </Text>
                  </Flex>
                  <Image src={ArrowRight2} alt="Arrow Right" width={24} height={24} />
                </Flex>
              </Box>
              <Space h="md" />
              <Box align="center">
                <Flex align="center" justify="center">
                  <Box
                    onMouseEnter={(e) => (e.currentTarget.style.cursor = 'pointer')}
                    onMouseLeave={(e) => (e.currentTarget.style.cursor = 'default')}
                    onClick={openLogoutModal}
                  >
                    <Flex align="center">
                      <Image src={Logout} alt="Logout" />
                      <Space w="0.5em" />
                      <Text fw={600} fz={'1em'} c={'#5D5D5D'}>
                        Logout
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Stack>
          </Paper>
        </Tabs.Panel>
      </Tabs> */}

      {/* Menu modal */}
      <Modal size="auto" radius={'1.5em'} withCloseButton={false} opened={MenuModalOpen} onClose={closeMenuModal}>
        <Stack spacing="md">
          {/* Log Out section */}
          <Text align="left" fw={500} fz={'1.1em'} c={'#e53935'}>
            <div style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }} onClick={openLogoutModal}>
              <Image src={Logout} alt="Log out" width={20} height={20} style={{ marginRight: '8px' }} />
              Log Out
            </div>
          </Text>
          {/* Delete Account section */}
          <Text align="left" fw={500} fz={'1.1em'} c={'#e53935'} onClick={openAccountDeleteModal}>
            <div style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
              <Image src={Trash} alt="Delete my account" width={20} height={20} style={{ marginRight: '8px' }} />
              Delete my account
            </div>
          </Text>
        </Stack>
      </Modal>
      {/* Logout modal */}
      <Modal size="xs" radius={'1.5em'} withCloseButton={false} opened={logoutModalOpen} onClose={closeLogoutModal} centered>
        <Stack>
          <Text align="center" fw={600} fz={'1.2em'} c={'#070519'}>
            Log out?
          </Text>
          <Text fw={400} fz={'0.9em'} align="center" c={'#747474'}>
            Are you sure you want to log out?
          </Text>
          <Flex justify="space-between" ml="1em" mr="1em" pb={'1em'}>
            <Button
              radius={'1.5em'}
              style={{ background: 'transparent', color: 'black' }}
              color="black"
              variant={'outline'}
              className={'cancel-logout-modal'}
              onClick={closeLogoutModal}
              mr="md"
            >
              Cancel
            </Button>
            <Button
              radius={'1.5em'}
              style={{ background: '#D95353', border: '1px solid #D95353' }}
              className={'confrm-logout-modal'}
              onClick={handleLogout}
            >
              Yes, Log out
            </Button>
          </Flex>
        </Stack>
      </Modal>
      {/* Account Delete modal */}
      <Modal
        opened={accountModalOpen}
        onClose={accountModalClose}
        withCloseButton={false}
        centered
        size="320px"
        radius="12px"
        closeButtonProps={{ 'aria-label': 'Close modal' }}
      >
        <Stack spacing="md">
          <Title size="h4" align="center">
            Permanently Delete Account?
          </Title>

          <Text align="center" color="dimmed" style={{ fontSize: '14px', marginBottom: '16px' }}>
            Are you sure you want to permanently delete your account? This action cannot be undone.
          </Text>
          <div
            style={{
              backgroundColor: '#FFEDED',
              padding: '16px',
              borderRadius: '8px',
              marginBottom: '16px',
              borderLeft: '2px solid red',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '8px',
              }}
            >
              <div style={{ opacity: '0.7' }}>
                <Text style={{ color: 'red', fontSize: '14px', marginBottom: '8px' }}>All your data will be permanently removed:</Text>
                <ul
                  style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                    fontSize: '14px',
                  }}
                >
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>
                    • Profile information such as Phone number, email id, name, password
                  </li>
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>• Chat history</li>
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>• All bookmarks/notes</li>
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>• Duel Bank points</li>
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>• Added friends</li>
                  <li style={{ marginLeft: '14px', marginBottom: '4px', color: 'red' }}>• Saved PadHai lists</li>
                </ul>
                <Text align="start" color="dimmed" style={{ fontSize: '14px', color: 'red', marginBottom: '16px' }}>
                  Please confirm if you wish to proceed.
                </Text>
              </div>
            </div>
          </div>
          <Flex justify="space-between" ml="1em" mr="1em" pb={'1em'}>
            <Button
              radius={'1.5em'}
              style={{ background: 'transparent', color: 'black' }}
              color="black"
              variant={'outline'}
              onClick={accountModalClose}
            >
              Cancel
            </Button>
            <Button
              radius={'1.5em'}
              style={{ background: '#D95353', border: '1px solid #D95353' }}
              className={'confrm-logout-modal'}
              onClick={handleDeleteAccount}
            >
              Yes, Delete
            </Button>
          </Flex>
        </Stack>
      </Modal>

      {isFeedbackFormVisible && (
        <FeedbackForm isVisible={isFeedbackFormVisible} onClose={() => setIsFeedbackFormVisible(false)} userData={userData} />
      )}
    </Stack>
  );
};

export default ProfilePage;
