import React, { useState, useEffect } from 'react';
import LeaderBoardListModal from './LeaderBoardList';
import LeaderBoardTower from './LeaderBoardTower';
import TimeSwitch from './TimeSwitch';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function LeaderboardPage({ selectedSubject, userId }) {
  const [globalLeaderboard, setGlobalLeaderboard] = useState([]);
  const [userRanking, setUserRanking] = useState(null);
  const [activeTab, setActiveTab] = useState('Rating');
  const [activeTimeFrame, setActiveTimeFrame] = useState('all');
  const [loading, setLoading] = useState(false);
  const isRespMobile = window.innerHeight <= 975;

  const fetchLeaderboardData = async (endpoint) => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`);
      const data = await response.json();
      if (endpoint.includes('performance')) {
        setGlobalLeaderboard(data.leaderboard);
        setUserRanking(data.current_user_rank);
      } else {
        setGlobalLeaderboard(data.global || data.leaderboard);
        setUserRanking(data.user_ranking || data.current_user_rank);
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const fetchData = (tab) => {
    let endpoint;
    if (tab === 'Rating') {
      endpoint = `/api/leaderboard/user-global-ranking/${userId}/?category=${selectedSubject}`;
    } else if (tab === 'Performance') {
      endpoint = `/api/leaderboard/performance/${userId}/?category=${selectedSubject}&date_filter=${activeTimeFrame}`;
    } else if (tab === 'Friends') {
      endpoint = `/api/leaderboard/user-global-ranking/${userId}/?category=${selectedSubject}&friends_only=true`;
    }

    if (endpoint) {
      fetchLeaderboardData(endpoint);
    }
  };

  const handleTimeFrameChange = (timeFrame) => {
    setActiveTimeFrame(timeFrame);
    if (activeTab === 'Performance') {
      fetchLeaderboardData(`/api/leaderboard/performance/${userId}/?category=${selectedSubject}&date_filter=${timeFrame}`);
    }
  };

  const handleTabChange = (tab) => {
    const isDefaultSelection = tab === 'Rating' && activeTab !== 'Rating';
    setActiveTab(tab);
    MixpanelEvent('leaderboard_show', {
      category: selectedSubject,
      tab: tab.toLowerCase(),
      default_selection: isDefaultSelection ? 'true' : 'false',
    });
    sendPostHogEvent('leaderboard_show', {
      category: selectedSubject,
      tab: tab.toLowerCase(),
      default_selection: isDefaultSelection ? 'true' : 'false',
    });
    fetchData(tab);
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [selectedSubject, userId, activeTab, activeTimeFrame]);

  const FriendsContainer = ({ containerStyle }) => (
    <div style={{ ...styles.friendsContainer, ...containerStyle }}>
      <p style={styles.friendsHeading}>Performance Leaderboard</p>
      <p style={styles.friendsParagraph}>Find out how many questions you answered correctly amongst others.</p>
    </div>
  );

  return (
    <>
      {activeTab === 'Rating' && (
        <>
          <div
            style={{
              ...styles.backgroundContainer,
              top: '210px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 210px - 50px)',
            }}
          >
            {loading ? (
              <div style={styles.spinnerContainer}>
                <div style={styles.spinner}></div>
              </div>
            ) : (
              <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
            )}
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}
      <div style={styles.container}>
        <div style={styles.tabContainer}>
          <button style={activeTab === 'Rating' ? styles.activeTab : styles.tab} onClick={() => handleTabChange('Rating')}>
            Rating
          </button>
          <button style={activeTab === 'Performance' ? styles.activeTab : styles.tab} onClick={() => handleTabChange('Performance')}>
            Performance
          </button>
          <button style={activeTab === 'Friends' ? styles.activeTab : styles.tab} onClick={() => handleTabChange('Friends')}>
            Friends
          </button>
        </div>
      </div>

      {activeTab === 'Performance' && (
        <>
          <div
            style={{
              ...styles.backgroundContainer,
              ...(isRespMobile && { height: '550px', overflow: 'scroll', scrollbarWidth: 'none' }),
            }}
          >
            <div style={styles.content}>
              <TimeSwitch activeTime={activeTimeFrame} onTimeChange={handleTimeFrameChange} />
              <FriendsContainer />
              {loading ? (
                <div style={{ ...styles.spinnerContainer, marginTop: '50px' }}>
                  {' '}
                  {/* Adjust marginTop as needed */}
                  <div style={styles.spinner}></div>
                </div>
              ) : (
                <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
              )}
            </div>
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}

      {activeTab === 'Friends' && (
        <>
          <div
            style={{
              ...styles.backgroundContainer,
              top: '210px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 210px - 50px)',
            }}
          >
            {loading ? (
              <div style={styles.spinnerContainer}>
                <div style={styles.spinner}></div>
              </div>
            ) : (
              <LeaderBoardTower globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />
            )}
          </div>
          {loading ? null : <LeaderBoardListModal globalLeaderboard={globalLeaderboard} userRanking={userRanking} activeTab={activeTab} />}
        </>
      )}
    </>
  );
}

const styles = {
  backgroundContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 0,
  },
  container: {
    fontFamily: 'Arial, sans-serif',
    marginLeft: '30px',
    marginRight: '30px',
    backgroundColor: '#f0f2f5',
    borderRadius: '200px',
    overflow: 'hidden',
    zIndex: 1,
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#F3F3F3',
    padding: '8px',
    zIndex: 1,
    borderRadius: '20px 20px 0 0',
  },
  tab: {
    padding: '10px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: '#64748b',
    fontSize: '14px',
    width: '100px',
    textAlign: 'center',
  },
  activeTab: {
    padding: '10px',
    border: 'none',
    background: 'white',
    borderRadius: '200px',
    cursor: 'pointer',
    color: 'black',
    fontSize: '14px',
    width: '100px',
    textAlign: 'center',
  },
  content: {
    padding: '10px 20px',
  },
  friendsContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '10px',
    margin: '0px 10px 0px 2px',
    border: '1px solid #E0E0E0',
    borderLeft: '3px solid green',
    marginBottom: '35px',
  },
  friendsHeading: {
    color: '#1E1E1E',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'start',
    marginBottom: '0',
    textTransform: 'uppercase',
  },
  friendsParagraph: {
    color: '#1E1E1E',
    fontSize: '12px',
    textAlign: 'start',
    margin: '0',
    lineHeight: '1.6',
  },
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #3498db',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
};

// Add keyframes for spinner animation
const styleElement = document.createElement('style');
styleElement.innerHTML = `
@keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
}`;
document.head.appendChild(styleElement);
