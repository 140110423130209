import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Stack,
  Text,
  Card,
  Flex,
  ScrollArea,
  Image,
  TextInput,
  Divider,
  Group,
  Container,
  Paper,
  Radio,
  Textarea,
  Button,
  Title,
  Loader,
  Box,
} from '@mantine/core';
import {
  SendMsg,
  NewChat,
  ArrowRight2,
  ChevronUp,
  ChevronDown,
  Edit2,
  DisLike,
  Like,
  LikeFill,
  DisLikeFill,
  ArrowLeft,
  Download,
  TheHindu,
  AiAvatar,
} from '../../../assets/images';
import 'katex/dist/katex.min.css';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/userStore';
import { useTheme } from '../../../context/themeProvider';
import ReconnectingWebSocket from 'reconnecting-websocket';
import AnswerComponentWrapper from '../AnswerComponentWrapper';
import QuestionsComp from '../QuestionsComp';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';
import LoadingGif from './loading.gif';

const McqDiscussAi = () => {
  const location = useLocation();
  const { colors, colorScheme } = useTheme();
  const isNewsRoute = location.pathname.startsWith('/news');
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' };
  const { chat, aiTutorParams } = location.state || {};
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = window.innerWidth <= 2500;
  const isRespMobile = window.innerWidth <= 700;

  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [expression, setMessage] = useState('');
  const [ws, setWs] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [showSuggestionButton, setShowSuggestionButton] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const wsRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const defaultCategory = 'history';

  const [isFeedbackShown, setFeedbackShown] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [details, setDetails] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const [chatIdState, setChatIdState] = useState({
    chatId: aiTutorParams?.id || null,
    nodeId: aiTutorParams?.node_id || null,
    chatgraphId: aiTutorParams?.chatgraph_id || null,
  });
  const [chatType, setchatType] = useState('ai_newsmcq');

  const [selectedQuestion, setSelectedQuestion] = useState(aiTutorParams?.preSelectQuestion || {});
  const [endpoint, setEndpoint] = useState(null);

  const chatIdStateRef = useRef(chatIdState);
  const selectedQuestionRef = useRef(selectedQuestion);

  const [isStreamEnd, setStreamEnd] = useState(false);

  const [loading, setLoading] = useState(false);
  const [promptType, setPromptType] = useState({
    isTypedPrompt: false,
    isHybridPrompt: false,
  });

  const userOptions = selectedQuestion ? '' : null;
  const userAnswere = selectedQuestion ? '' : null;

  const [feedbackStates, setFeedbackStates] = useState({});
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(null);
  const [newsTitles, setNewsTitles] = useState({});

  const scrollToBottom = useCallback(() => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('.mantine-ScrollArea-viewport');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, []);

  useEffect(() => {
    if (isStreaming) {
      scrollToBottom();
    }
  }, [chatData, isStreaming, scrollToBottom]);

  useEffect(() => {
    if (aiTutorParams) {
      setChatData({
        id: aiTutorParams.id,
        messages: aiTutorParams?.messages || [],
      });
      setchatType(aiTutorParams.chatType);
    } else if (chat && chatType) {
      setChatData(chat);
      setChatIdState({
        chatId: chat.id,
      });
      setchatType(chatType);
    }
  }, [aiTutorParams, chat, ws, chatType]);

  const [calledInitialRequest, setCalledInitialRequest] = useState(false);

  useEffect(() => {
    if (!userId) return;

    let socketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/livec/${userId}/`;

    if (aiTutorParams?.chatType === 'ai_tutor') {
      const aiTutorEndpoint =
        aiTutorParams.category === 'csat'
          ? `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/asyncai/csat_chat/`
          : `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/asyncai/`;
      setEndpoint(aiTutorEndpoint);
      socketUrl = aiTutorEndpoint;
    } else {
      setEndpoint('');
    }

    if (chatData?.id) {
      socketUrl = `${socketUrl}?chat_id=${chatData.id}`;
    }

    const socket = new ReconnectingWebSocket(socketUrl);
    wsRef.current = socket;
    setWs(socket);
    socket.onopen = () => {
      if (socket && socket.readyState && socket.readyState === 1) {
        if ((aiTutorParams?.flowType === 'postGame' || aiTutorParams?.flowType === 'history') && !calledInitialRequest) {
          if (aiTutorParams.chatType === 'ai_tutor') {
            callInitialRequest(socket);
          }
        }
      }
    };
    socket.onmessage = (event) => {
      const responseData = JSON.parse(event.data);

      if (responseData) {
        const message = responseData.first_response || responseData.message || '';
        const result = responseData.result || '';

        if (responseData.chat_id) {
          setChatData((prevData) => ({
            ...prevData,
            id: responseData.chat_id,
          }));
          setChatIdState({
            chatId: responseData.chat_id,
            nodeId: responseData.node_id || null,
            chatgraphId: responseData.chatgraph_id || null,
          });
        }

        if (result === 'stream_end' || message === 'stream_end') {
          setStreamEnd(true);
          setLoading(true);
          setIsStreaming(false);

          if (chatIdState.chatId !== null) {
            const eventData = {
              source: chatType,
              chat_id: responseData.chat_id,
              msg_id: responseData.node_id,
              answer_status: 'Finished',
              category: defaultCategory,
            };
            MixpanelEvent('chat_answer', eventData);
            sendPostHogEvent('chat_answer', eventData);
          }
        } else {
          setIsLoading(false);
          setIsStreaming(true);

          setChatData((prevData) => {
            const lastMessage = prevData.messages[prevData.messages.length - 1];

            if (lastMessage?.role === 'assistant') {
              const updatedMessages = prevData.messages.map((msg, idx) => {
                if (idx === prevData.messages.length - 1) {
                  if (responseData['mime-type'] === 'reference') {
                    return {
                      ...msg,
                      content: [...msg.content, responseData],
                    };
                  } else {
                    return {
                      ...msg,
                      content: [...msg.content, message + result],
                    };
                  }
                }
                return msg;
              });
              return { ...prevData, messages: updatedMessages };
            } else {
              if (responseData['mime-type'] === 'reference') {
                return {
                  ...prevData,
                  messages: [
                    ...prevData.messages,
                    {
                      role: 'assistant',
                      content: [responseData],
                    },
                  ],
                };
              } else {
                return {
                  ...prevData,
                  messages: [
                    ...prevData.messages,
                    {
                      role: 'assistant',
                      content: [message + result],
                    },
                  ],
                };
              }
            }
          });
        }

        if (responseData.suggestions && responseData.suggestions.length > 0) {
          setLoading(false);
          setShowSuggestionButton(true);
          setSuggestions(responseData.suggestions);
        }
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socket.onerror = (error) => {
      console.error('WebSocket error ', error);
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [userId]);

  const optionKey = ['a', 'b', 'c', 'd'];

  const createPayload = (messageToSend = null) => {
    let c_answer = null;
    if (selectedQuestionRef.current && selectedQuestionRef.current.correct_answer) {
      c_answer =
        aiTutorParams?.chatType === 'ai_tutor' ? selectedQuestionRef.current.correct_answer : optionKey[selectedQuestionRef.current.correct_answer];
    }
    const payload = {
      assoc_news: aiTutorParams.assoc_news,
      question_id: selectedQuestion?.question_id || null,
      question: selectedQuestionRef.current?.question || null,
      user_option: userOptions,
      user_answer: userAnswere,
      profile_id: userId,
      choices: selectedQuestionRef.current?.choices || null,
      options: selectedQuestionRef.current?.options || null,
      correct_answer: c_answer,
      chat_id: chatIdState.chatId || null,
      node_id: chatIdStateRef.nodeId || null,
      chatgraph_id: chatIdStateRef.chatgraphId || null,
      category: aiTutorParams?.category || defaultCategory,
      render_type: aiTutorParams?.category === 'csat' ? 'mathjax' : 'text',
      expression: messageToSend,
      source: aiTutorParams.source,
    };

    return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null));
  };

  const callInitialRequest = (socket) => {
    if (socket && socket.readyState && socket.readyState === 1) {
      setIsLoading(true);
      if ((aiTutorParams.flowType === 'postGame' || aiTutorParams.flowType === 'history') && !calledInitialRequest) {
        if (aiTutorParams.chatType === 'ai_tutor') {
          const payload = createPayload();
          setCalledInitialRequest(true);
          socket.send(JSON.stringify(payload));
        }
      }
    }
  };

  const handleSendMessage = (messageToSend = expression, type) => {
    setFeedbackStates({});
    if (ws && messageToSend) {
      setIsLoading(true);
      setShowSuggestionButton(false);
      setShowSuggestions(false);
      setStreamEnd(false);

      if (type === 'PrePrompt') {
        MixpanelEvent('chat_start', {
          source: 'ai_newsmcq',
          type: 'preprompt',
        });
        sendPostHogEvent('chat_start', {
          source: 'ai_newsmcq',
          type: 'preprompt',
        });
      }

      if (promptType.isTypedPrompt === true) {
        MixpanelEvent('chat_start', {
          source: 'ai_newsmcq',
          type: 'typed',
        });
        sendPostHogEvent('chat_start', {
          source: 'ai_newsmcq',
          type: 'typed',
        });
      }

      if (type === 'SuggestionPrompt') {
        const eventData = {
          source: chatType,
          category: defaultCategory,
          chat_method: 'suggestions',
          msg_id: chatIdState.nodeId,
          chat_id: chatIdState.chatId,
        };
        MixpanelEvent('chat_question', eventData);
        sendPostHogEvent('chat_question', eventData);
      }

      if (promptType.isHybridPrompt === true) {
        const eventData = {
          source: chatType,
          category: defaultCategory,
          chat_method: 'hybrid',
          msg_id: chatIdState.nodeId,
          chat_id: chatIdState.chatId,
        };
        MixpanelEvent('chat_question', eventData);
        sendPostHogEvent('chat_question', eventData);
      }

      const payload = createPayload(messageToSend);

      ws.send(JSON.stringify(payload));
      setChatData((prevData) => ({
        ...prevData,
        messages: [
          ...(prevData?.messages || []),
          {
            role: 'user',
            content: messageToSend,
          },
        ],
      }));
      setMessage('');
      setPromptType({
        isTypedPrompt: false,
        isHybridPrompt: false,
      });
    }
  };

  const fetchSuggestedQuestions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/chat-suggestions/`);
      const data = await response.json();
      setSuggestedQuestions(data.suggestions);
    } catch (error) {
      console.error('Error fetching suggested questions:', error);
    }
  };

  useEffect(() => {
    fetchSuggestedQuestions();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMessageChange = (event) => {
    setMessage(event.currentTarget.value);
    setPromptType({ isTypedPrompt: true });
  };

  const toggleSuggestions = () => {
    setShowSuggestions(!showSuggestions);
  };

  const handleDislikeModal = (index) => {
    setFeedbackShown(!isFeedbackShown);
    setCurrentInteractionIndex(index);
  };

  const submitFeedbackReaction = (data) => {
    if (ws && ws.readyState && ws.readyState === WebSocket.OPEN) {
      if (data.suggestion_text) {
        ws.send(JSON.stringify({ suggestions_feedback: data.suggestion_text, chat_id: chatData.id, profile_id: userId }));
      } else {
        ws.send(JSON.stringify({ ...data, chat_id: chatData.id, profile_id: userId }));
      }
    }
  };

  const getNewsTitle = useCallback(
    async (id) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${id}/?user=${userData.user}`);
        const data = await response.json();
        return { title: data.title, created_at: data.created_at };
      } catch (error) {
        console.error('Error fetching news:', error);
        return { title: 'Unable to fetch news title', created_at: null };
      }
    },
    [userData.user]
  );

  useEffect(() => {
    const fetchNewsTitles = async () => {
      const titles = { ...newsTitles };
      let hasNewTitles = false;
      for (const message of chatData?.messages || []) {
        if (message.role === 'assistant') {
          for (const chunk of message.content) {
            if (typeof chunk === 'object' && chunk['mime-type'] === 'reference') {
              const parts = chunk.message.split('/');
              if (parts[parts.length - 2] === 'news') {
                const newsId = parts[parts.length - 1];
                if (!titles[newsId]) {
                  titles[newsId] = await getNewsTitle(newsId);
                  hasNewTitles = true;
                }
              }
            }
          }
        }
      }
      if (hasNewTitles) {
        setNewsTitles(titles);
      }
    };

    fetchNewsTitles();
  }, [chatData, getNewsTitle]);

  const handleFeedbackSubmit = async () => {
    const feedbackData = {
      feedback_remark: feedback,
      additionalNotes: feedback === 'Other reason (not mentioned above).' ? details : '',
      feedback: -1,
    };
    submitFeedbackReaction({ suggestion_text: feedbackData });
    setFeedbackShown(false);

    setFeedbackStates((prevStates) => {
      const newStates = { ...prevStates };
      newStates[currentInteractionIndex] = newStates[currentInteractionIndex] === 'dislike' ? null : 'dislike';
      return newStates;
    });

    setFeedback('');
    setDetails('');
  };

  const handleLikeSuggestion = async (index) => {
    const feedbackData = {
      feedback_remark: '',
      additionalNotes: '',
      feedback: 1,
    };
    submitFeedbackReaction({ suggestion_text: feedbackData });
    setFeedbackStates((prevStates) => {
      const newStates = { ...prevStates };
      newStates[index] = newStates[index] === 'like' ? null : 'like';
      return newStates;
    });
  };

  const DownloadPdf = () => {
    const downloadChatType = aiTutorParams?.chatType || 'history';
    const url = `${process.env.REACT_APP_BASE_URL}/api/download-pdf/?chat_id=${chatIdState.chatId}&chat_type=${downloadChatType}`;
    window.open(url, '_blank');
  };

  const renderAttachment = useCallback(
    (attachment) => {
      const newsId = attachment.message.split('/').pop();
      const newsData = newsTitles[newsId] || { title: 'Loading...', created_at: null };
      const formattedDate = newsData.created_at
        ? new Date(newsData.created_at)
            .toLocaleDateString('en-US', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })
            .replace(/,/g, '')
            .toUpperCase()
        : 'Date unavailable';

      const handleClick = () => {
        window.open(`/news/${newsId}`, '_blank');
      };

      return (
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          style={{
            maxWidth: '100%',
            margin: '1rem 0',
            backgroundColor: 'white',
            borderColor: '#e0e0e0',
          }}
          onClick={handleClick}
        >
          <Flex direction="column" gap="md">
            <Flex align="center" gap="sm">
              <Box
                style={{
                  padding: '11px',
                  backgroundColor: '#FCECC4',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '30px',
                }}
              >
                TH
              </Box>
              <Flex direction="column">
                <Text size="lg" weight={700} lineClamp={2}>
                  {newsData.title}
                </Text>
                <Text size="sm" color="dimmed">
                  {formattedDate}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="space-between" align="center">
              <img src={TheHindu} alt="The Hindu" />
              <img src={ArrowRight2} alt="Arrow Right" />
            </Flex>
          </Flex>
        </Card>
      );
    },
    [newsTitles]
  );

  return (
    <Stack spacing={0} style={{ height: `${windowHeight}px`, maxHeight: `${windowHeight}px`, overflow: 'none', width: '100%' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px 14px 12px 0px ',
          backgroundColor: appliedColors.cardBackground,
          color: appliedColors.text,
          height: '4em',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      >
        <Flex align="center">
          <img src={ArrowLeft} alt="Arrow Left" style={{ cursor: 'pointer', marginLeft: '1em' }} onClick={() => navigate(-1)} />
          <Text fz="x" style={{ marginLeft: '0.5em', fontWeight: '500', fontSize: '16px' }}>
            New Chat
          </Text>
        </Flex>
        {chatIdState.chatId ? <img src={Download} onClick={DownloadPdf} alt="Download chat" style={{ cursor: 'pointer' }} /> : null}
      </Box>
      <ScrollArea ref={scrollAreaRef} style={{ flex: 1 }} p="md" offsetScrollbars>
        <Container size="md" px="xs" mt={'3em'}>
          <Stack>
            {chatData?.messages.map((item, index) => (
              <React.Fragment key={index}>
                {item.role === 'user' && <QuestionsComp item={item} />}
                {item.role === 'assistant' && (
                  <AnswerComponentWrapper
                    componentName={'answer'}
                    scrollToBottom={scrollToBottom}
                    index={index}
                    item={{
                      ...item,
                      content: item.content.map((chunk) =>
                        typeof chunk === 'object' && chunk['mime-type'] === 'reference' ? renderAttachment(chunk) : chunk
                      ),
                    }}
                    callBack={handleDislikeModal}
                    isLast={index === chatData.messages.length - 1}
                    streamEnd={isStreamEnd}
                    type={aiTutorParams?.flowType}
                    attachments={true}
                  />
                )}
              </React.Fragment>
            ))}
            {isLoading && (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#F3F3F3',
                  borderRadius: '12px',
                  padding: '1em',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '0px',
                  }}
                >
                  <img src={AiAvatar} alt="Ai Avatar" style={{ height: '2.5em', width: '2.5em' }} />
                </div>
                <div
                  style={{
                    marginTop: '0.3em',
                  }}
                >
                  <img src={LoadingGif} alt="Loader" style={{ width: '15%', height: '100%', marginLeft: '30px' }} />
                </div>
              </div>
            )}
          </Stack>
        </Container>
      </ScrollArea>

      {(!chatData?.messages || chatData.messages.length === 0) && (
        <Container size="sm" px="xs" style={{ overflowY: 'auto', maxHeight: `${windowHeight - 150}px`, width: '100%' }}>
          <Stack spacing="xl" align="center" p={isRespMobile ? '0 0 0 10px' : '15px'}>
            <Image src={NewChat} alt="New Chat" style={{ width: '100%', maxWidth: '10em' }} />
            <Text fw={800} fz={'1em'} align="center">
              Ask AI directly
            </Text>
            <Text fw={500} fz={'1em'} align="center">
              Ask directly by typing your question below
            </Text>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#A0A0A0',
                }}
              />
              <div style={{ flex: 1, borderBottom: '1px solid #A0A0A0', marginRight: '8px' }} />
              <span style={{ fontWeight: 'bold', color: '#A0A0A0' }}>OR</span>
              <div style={{ flex: 1, borderBottom: '1px solid #A0A0A0', marginLeft: '8px' }} />
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#A0A0A0',
                }}
              />
            </div>

            <Text fw={800} fz={'1em'} align="center">
              Try asking these
            </Text>
            <Stack spacing="md" style={{ width: '100%' }}>
              {Array.isArray(suggestedQuestions) &&
                suggestedQuestions.map((question, index) => (
                  <Card
                    key={index}
                    shadow="sm"
                    p="lg"
                    radius="md"
                    withBorder
                    onClick={() => {
                      setMessage(question.content);
                      handleSendMessage(question.content, 'PrePrompt');
                    }}
                    style={{
                      backgroundColor: appliedColors.cardBackground,
                      borderColor: appliedColors.borderColor,
                      cursor: 'pointer',
                    }}
                  >
                    <Flex justify="space-between" align="center">
                      <Text fw={400} style={{ color: appliedColors.text }} fz={'0.9em'} lineClamp={3}>
                        {question.content}
                      </Text>
                      <Image src={ArrowRight2} alt="Arrow Right" style={{ width: '1.5em', color: appliedColors.arrowColor }} />
                    </Flex>
                  </Card>
                ))}
            </Stack>
          </Stack>
        </Container>
      )}

      <Stack style={{ boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)' }} p="md">
        {showSuggestionButton && (
          <>
            <Group
              style={{
                width: '100%',
                cursor: 'pointer',
              }}
              justify="center"
              onClick={toggleSuggestions}
            >
              <Text style={{ color: '#8B8B8B', fontSize: '12px', fontWeight: '600' }}>
                {showSuggestions ? 'Hide Suggestions'.toUpperCase() : 'View Suggestions'.toUpperCase()}
              </Text>
              <Image src={showSuggestions ? ChevronDown : ChevronUp} alt="Chevron Icon" width="0.6em" />
            </Group>

            {showSuggestions && (
              <ScrollArea
                style={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  width: 'auto',
                }}
              >
                <Flex
                  style={{
                    gap: '16px',
                    padding: '16px',
                    minWidth: 'max-content',
                  }}
                >
                  {suggestions.map((suggestion, index) => (
                    <Card
                      key={index}
                      shadow="sm"
                      radius="md"
                      withBorder
                      style={{
                        width: '250px',
                        flex: '0 0 auto',
                        padding: '20px',
                      }}
                    >
                      <Text
                        size="sm"
                        style={{
                          marginBottom: '8px',
                          cursor: 'pointer',
                          fontFamily: 'Poppins',
                          color: 'black',
                          fontSize: '16px',
                        }}
                        onClick={() => {
                          setMessage(suggestion);
                          handleSendMessage();
                        }}
                      >
                        {suggestion}
                      </Text>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <img
                          src={Edit2}
                          alt="Edit"
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => {
                            setMessage(suggestion);
                            handleSendMessage();
                            setPromptType({ isHybridPrompt: true });
                          }}
                        />
                        <img
                          src={ArrowRight2}
                          alt="Arrow Right"
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => handleSendMessage(suggestion, 'SuggestionPrompt')}
                        />
                      </div>
                      <Divider my="md" />
                      <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <img
                            src={feedbackStates[index] === 'dislike' ? DisLikeFill : DisLike}
                            alt="Dislike"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleDislikeModal(index)}
                          />
                          <img
                            src={feedbackStates[index] === 'like' ? LikeFill : Like}
                            alt="Like"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleLikeSuggestion(index)}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </Flex>
              </ScrollArea>
            )}
          </>
        )}
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader color="blue" height={'3em'} />
          </div>
        )}

        <Flex align="center">
          <TextInput
            bg={'#F3F3F3'}
            placeholder="Type a message..."
            value={expression}
            onChange={handleMessageChange}
            style={{ flex: 1, marginRight: '10px', backgroundColor: '#F5F5F5' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSendMessage(expression);
              }
            }}
          />
          <Image onClick={() => handleSendMessage(expression)} src={SendMsg} alt="Send" style={{ width: '2.4em', cursor: 'pointer' }} />
        </Flex>
      </Stack>

      {isFeedbackShown && (
        <Paper
          shadow="sm"
          padding="lg"
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            borderRadius: '15px 15px 0 0',
            backgroundColor: '#fff',
            boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1)',
            padding: '3em',
            boxSizing: 'border-box',
            maxWidth: isMobile ? '28em' : '22vw',
          }}
        >
          <button
            onClick={() => setFeedbackShown(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            &times;
          </button>
          <Title order={4} style={{ marginBottom: '15px', textAlign: 'center', fontWeight: 600 }}>
            Please provide your feedback
          </Title>
          <Stack spacing="lg">
            <Radio.Group value={feedback} onChange={setFeedback} size="1em">
              <Stack spacing="lg">
                {[
                  'Factual inaccuracy.',
                  'Does not answer what i asked.',
                  'Unnecessarily long without the exact information.',
                  'This answer is ok, but the next answer did not come up.',
                  'Other reason (not mentioned above).',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} p={'md'} />
                ))}
              </Stack>
            </Radio.Group>

            {feedback === 'Other reason (not mentioned above).' && (
              <>
                <Text>Other reason*</Text>
                <Textarea
                  placeholder="Provide details about your issue here..."
                  value={details}
                  rows={4}
                  radius="sm"
                  style={{ marginTop: '10px', fontSize: '14px' }}
                  onChange={(e) => setDetails(e.currentTarget.value)}
                />
              </>
            )}

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!feedback}
                style={{
                  backgroundColor: feedback ? '#000' : '#ccc',
                  fontWeight: 500,
                }}
                onClick={handleFeedbackSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

export default McqDiscussAi;
