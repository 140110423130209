import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';
import './RichText.css';

// Helper functions (implement these based on your needs)
import {
  convertMarkdownInHTMLSupport,
  hasLaTeX,
  stripHtmlTags,
  cleanMarkdownContent,
  cleanLatexContent,
  ensureNewlineBeforeHash,
  removeLeadingSpacesBeforeComments,
  replaceDoubleDollarsWithSingleDollars,
  splitTextByLatex,
  extractCodeBlockTypeAndContent,
} from '../../utils/RichText.js';
import { MarkdownWithLatex } from './MarkdownLatex.jsx';

// const htmlPattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/gi;
// const imagePattern = /\!\[.*?\]\((.*?)\)/g;

const RichText = ({ content, streamEnd, isLast, type, screen = 'default' }) => {
  // Replace <br> tags and fix $$ for LaTeX
  content = content.replaceAll('<br>', '\n');
  content = replaceDoubleDollarsWithSingleDollars(content);

  const [loading, setLoading] = useState(type === 'history' ? false : true);

  useEffect(() => {
    if (loading && streamEnd) {
      setLoading(false);
    }
    if (!loading && !streamEnd) {
      setLoading(true);
    }
  }, [loading, streamEnd]);

  const isContainLatexOrHTMLOrCodeBlockOrImage = hasLaTeX(content) || content.includes('```') || content.includes('!');

  const isContainOnlyHTML = /<[a-z][\s\S]*>/i.test(content) && !hasLaTeX(content) && !content.includes('```') && !content.includes('!');
  const containsDollarsLatex = content.includes('$');

  if (!isContainLatexOrHTMLOrCodeBlockOrImage && !containsDollarsLatex) {
    const cleanedMd = ensureNewlineBeforeHash(cleanMarkdownContent(content));
    return (
      <div className="markdown-container">
        <Markdown>{cleanedMd}</Markdown>
      </div>
    );
  } else if (isContainOnlyHTML) {
    content = ensureNewlineBeforeHash(content);
    return <div className="html-container" dangerouslySetInnerHTML={{ __html: convertMarkdownInHTMLSupport(content) }} />;
  } else {
    const parts = splitTextByLatex(content);

    if (parts.length >= 1 && (parts[0].type === 'markdown' || parts[0].type === 'markdown_latex')) {
      const parts_first = splitTextByLatex(parts[0].content);

      parts.splice(0, 1, ...parts_first);
    }
    return <div className="rich-text-wrapper">{parts.map((item, index) => renderItem({ item, index }))}</div>;
  }
};

const renderItem = ({ item, index }) => {
  switch (item.type) {
    case 'markdown_latex':
      const cleanedMd = cleanMarkdownContent(item.content);
      return (
        <div key={index} className="markdown-latex-container">
          <MarkdownWithLatex content={cleanedMd} />
        </div>
      );

    case 'latex':
    case 'latex_array':
      const cleanedLatex = item.type === 'latex' ? cleanLatexContent(item.content) : item.content;
      return (
        <div key={index} className="latex-container">
          <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
            {`$${cleanedLatex}$`}
          </Markdown>
        </div>
      );

    case 'html':
      return <div key={index} className="html-container" dangerouslySetInnerHTML={{ __html: convertMarkdownInHTMLSupport(item.content) }} />;

    case 'image':
      return <img key={index} src={item.content} alt="Content" className="content-image" />;

    case 'markdown':
      const cleanedMarkdown = cleanMarkdownContent(item.content);
      return (
        <div key={index} className="markdown-container">
          <Markdown>{cleanedMarkdown}</Markdown>
        </div>
      );

    case 'markdown_code':
      const { type: codeType, remainingText } = extractCodeBlockTypeAndContent(item.content);
      const cleanedCode = removeLeadingSpacesBeforeComments(cleanMarkdownContent(remainingText).trim());
      return (
        <div key={index} className="code-block-container">
          <div className="code-block-header">
            <span>{codeType}</span>
            <button onClick={() => copyToClipboard(stripHtmlTags(cleanedCode))}>Copy</button>
          </div>
          <pre>
            <code>{cleanedCode}</code>
          </pre>
        </div>
      );

    case 'newline':
      return <br key={index} />;

    default:
      return null;
  }
};

const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('Copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
};

export default RichText;
