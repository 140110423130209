import { ReactComponent as HistoryBook } from '../assets/images/HistoryBook.svg';
import { ReactComponent as GeographyBook } from '../assets/images/GeographyBook.svg';
import { ReactComponent as PolityBook } from '../assets/images/Polity.svg';
import { ReactComponent as CurrentAffairs } from '../assets/images/CurrentAffairs.svg';
import { ReactComponent as EconomyBook } from '../assets/images/Economy.svg';
import { ReactComponent as EnvironmentBook } from '../assets/images/environment_book.svg';
import { ReactComponent as ScienceBook } from '../assets/images/science_book.svg';
import { ReactComponent as AllSubjects } from '../assets/images/all_subject_book.svg';
import { ReactComponent as CSATBook } from '../assets/images/csat_book.svg';

export const iconProps = {
  stroke: 1.5,
  color: 'currentColor',
  opacity: 0.6,
  size: 18,
};

// Define the icons object
export const icons = {
  all_subjects: <AllSubjects {...iconProps} />,
  csat: <CSATBook {...iconProps} />,
  history: <HistoryBook {...iconProps} />,
  geography: <GeographyBook {...iconProps} />,
  polity: <PolityBook {...iconProps} />,
  current_affairs: <CurrentAffairs {...iconProps} />,
  economy: <EconomyBook {...iconProps} />,
  science: <ScienceBook {...iconProps} />,
  environment: <EnvironmentBook {...iconProps} />,
};
