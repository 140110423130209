import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../molecules/Navbar';
import Header from '../molecules/Header';
import Breadcrumbs from '../molecules/BreadCrumbs/breadcrumbs';
import { useTheme } from '../context/themeProvider';
import { hideFooterRoutes, hideHeaderRoutes } from '../constants/hideRoutes';

const LayoutWrapper = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { colorScheme, colors } = useTheme();
  const location = useLocation();
  const isNewsRoute = location.pathname.startsWith('/news');
  const appliedColors = isNewsRoute ? colors : { background: '#fff', text: '#000', cardBackground: '#f9f9f9' };

  const [activeTab, setActiveTab] = useState('latest');
  const shouldHideHeader = hideHeaderRoutes.some((route) => new RegExp(`^${route.replace(':id', '[^/]+')}$`).test(location.pathname));
  const shouldHideFooter =
    hideFooterRoutes.some((route) => new RegExp(`^${route.replace(':id', '[^/]+')}$`).test(location.pathname)) ||
    location.pathname.startsWith('/news/') ||
    location.pathname.startsWith('/chat/') ||
    location.pathname.includes('online-match-game') ||
    location.pathname.includes('matching') ||
    location.pathname.includes('duel-start') ||
    location.pathname.includes('duel-review') ||
    activeTab === 'todays-questions'; // Hide footer when "Today's Questions" tab is active

  const showBreadCrumbs = ['/news'];

  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const layoutStyles = {
    maxWidth: windowDimensions.width <= 768 ? '100vw' : '28rem',
    height: windowDimensions.height,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
    backgroundColor: appliedColors.background,
    color: appliedColors.text,
    position: 'relative',
    overflow: 'hidden',
  };

  const contentStyles = {
    flex: 1,
    width: '100%',
    backgroundColor: appliedColors.cardBackground,
  };

  return (
    <div style={layoutStyles}>
      {!shouldHideHeader && <Header />}
      {showBreadCrumbs.includes(location.pathname) && <Breadcrumbs setActiveTab={setActiveTab} />}
      <div style={contentStyles}>{children}</div>
      {!shouldHideFooter && <Navbar />}
    </div>
  );
};

export default LayoutWrapper;
