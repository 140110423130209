// DeletingPage.jsx
import React from 'react';
import { DeletingAccountImage } from '../../assets/images';

const DeletingPage = () => {
  return (
    <div style={styles.container}>
      <img src={DeletingAccountImage} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f9f9f9',
  },
};

export default DeletingPage;
