import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, Image, Text, Group, Box, Avatar, Notification, Modal, Button, Flex } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import {
  Star,
  CopyIcon,
  EraserIcon,
  HighlighterIcon,
  Like2,
  Bookmark,
  Share,
  Download,
  LikeActive,
  BookmarkActive,
  ArrowUpRight,
  TheHindu,
  NewsDetailsCloseIcon,
} from '../../assets/images';
import { NEWS_SOURCE } from '../../constants/newsData';
import { callGETAPI, callPOSTAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import branch from 'branch-sdk';
import { useDisclosure } from '@mantine/hooks';
import { CopyButton } from '@mantine/core';
import { IconCopy, IconCheck } from '@tabler/icons-react';
import InfoHighlight from './infoHighlights'; // Import the modal
import ChatCard from '../Chat/TutorChat/ChatCard';
import ReactMarkdown from 'react-markdown';

export default function NewsDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log('newsId', id);
  const { colors, colorScheme } = useTheme();
  const { setNewsDetailData } = useNews();
  const [newsItem, setNewsItem] = useState(null);
  const [highlightedPoints, setHighlightedPoints] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [imageModalOpened, setImageModalOpened] = useState(false);
  const [showHighlightModal, setShowHighlightModal] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const isMobile = window.innerWidth <= 2500;
  const isResponsiveMobile =
    window.innerWidth > 1200
      ? '28em'
      : window.innerHeight <= 700 && window.innerWidth <= 400
      ? '23em'
      : window.innerWidth <= 400 && window.innerWidth <= 900
      ? '24em'
      : '26.5em';

  const [selection, setSelection] = useState({
    selectedText: '',
    startIndex: 0,
    endIndex: 0,
    summaryId: null,
  });
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const { newsType, currentIndex, totalArticles, selectedDate, scrollPosition, readingTime, streakCount } = location.state || {
    newsType: NEWS_SOURCE.THE_HINDU,
    currentIndex: 1,
    totalArticles: 1,
    selectedDate: new Date(),
    scrollPosition: 0,
    readingTime: null,
  };

  const userData = userStore((state) => state.userData);

  const [link, setLink] = useState(null);
  const [error, setError] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [chats, setChats] = useState([]);

  const timeoutRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/tutorhistory/?user_id=${userData.user}&source=news_feed&assoc_news=${id}`, (resp) => {
      setChats(resp.data.data);
      console.log('chats', resp.data.data);
    });
  }, [id, userData.user]);

  const handleDownloadPdf = (chatId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/download-pdf/?chat_id=${chatId}&chat_type=history`;
    window.open(url, '_blank');
  };

  const handleChatSelect = (chat) => {
    if (chat.id) {
      navigate(`/chat/${chat.id}`, { state: { chat, chatTypeFlow: chat.source } });
    } else {
      navigate(`/chat/new/`, { state: { userId: userData.user, chatTypeFlow: 'ai_tutor' } });
    }
  };

  useEffect(() => {
    const { newsItem: stateNewsItem } = location.state || {};
    const Newsid = stateNewsItem?.id || id; // Use state newsId or URL newsId

    if (Newsid) {
      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${id}/?user=${userData.user}`, (resp) => {
        const updatedNewsItem = {
          ...resp.data,
          user_liked: resp.data.user_liked || stateNewsItem?.user_liked,
          likes: resp.data.likes || stateNewsItem?.likes || 0,
          user_bookmarked: resp.data.user_bookmarked || stateNewsItem?.user_bookmarked,
          bookmarks: resp.data.bookmarks || stateNewsItem?.bookmarks || 0,
        };

        setNewsItem(updatedNewsItem);
        setNewsDetailData(updatedNewsItem);

        const highlights = stateNewsItem?.highlights || resp.data.highlights || [];
        console.log(highlights, 'hgh');
        setHighlightedPoints(
          highlights.map((h) => ({
            id: h.id,
            highlighted_content_text: h.highlighted_content_text || h.text,
          }))
        );

        setLiked(updatedNewsItem.user_liked);
        setLikeCount(updatedNewsItem.likes);
        setBookmarked(updatedNewsItem.user_bookmarked);
        setBookmarkCount(updatedNewsItem.bookmarks);
      });
    } else {
      navigate('/news');
    }
  }, [location.state, id, userData.user, navigate]);

  useEffect(() => {
    const infoHighLightModal = localStorage.getItem('infoHighLightModal');
    if (!infoHighLightModal) {
      setShowHighlightModal(true);
    }
  }, []);

  useEffect(() => {
    const checkReadStatus = async () => {
      try {
        await callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/update-summary-read/`, {
          article: id,
          user: userData.user,
          is_read: true,
        });
        setIsRead(true);
      } catch (error) {
        console.error('Failed to mark as read:', error);
      }
    };

    checkReadStatus();
  }, [id, userData]);

  const closeModal = () => {
    setShowHighlightModal(false);
    localStorage.setItem('infoHighLightModal', 'true');
  };
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const handlePYQClick = () => {
    MixpanelEvent('pyq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('pyq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem?.id) {
      navigate(`/news/pyq/${newsItem.id}`);
    }
  };

  const handleMCQClick = () => {
    MixpanelEvent('mcq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('mcq_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem?.id) {
      navigate(`/news/mcq/${newsItem.id}`);
    }
  };

  const handleBack = () => {
    navigate('/news', { state: { scrollPosition, selectedDate, readingTime } });
    MixpanelEvent('navigation_back', { source_screen: 'news_detail' });
    sendPostHogEvent('navigation_back', { source_screen: 'news_detail' });
  };
  const handleTouchStart = (e) => {
    touchStartRef.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
      time: Date.now(),
    };
  };

  const handleTouchEnd = (e) => {
    setTimeout(() => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelection({
          selectedText,
          startIndex: range.startOffset,
          endIndex: range.endOffset,
          summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
        });

        setMenuPosition({
          x: rect.left + rect.width / 2 - 100,
          y: rect.top - 60,
        });

        setMenuVisible(true);
      }
    }, 0);
  };
  const handleTextSelection = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText) {

      const startLi = findAncestor(selection.anchorNode, 'li');
      const endLi = findAncestor(selection.focusNode, 'li');
      if (startLi !== endLi) {
        selection.removeAllRanges();
        setNotificationMessage('Please select text from a single point only');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        return;
      }
      
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      setSelection({
        selectedText,
        startIndex: range.startOffset,
        endIndex: range.endOffset,
        summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
      });

      const menuX = rect.left + rect.width / 2 - 100;
      const menuY = rect.top - 60 + window.scrollY;

      setMenuPosition({
        x: menuX,
        y: menuY,
      });

      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    const selectionChangeHandler = () => {
      handleTextSelection();
    };

    document.addEventListener('selectionchange', selectionChangeHandler);

    return () => {
      document.removeEventListener('selectionchange', selectionChangeHandler);
    };
  }, []);

  const handleSelectionChange = useCallback(() => {
    setTimeout(() => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelection({
          selectedText,
          startIndex: range.startOffset,
          endIndex: range.endOffset,
          summaryId: findAncestor(range.startContainer, 'li')?.getAttribute('data-id') || null,
        });

        setMenuPosition({
          x: rect.left + rect.width / 2 - 100,
          y: rect.top - 60,
        });

        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    }, 0);
  }, []);

  const handleHighlight = () => {
    MixpanelEvent('feature_selection', {
      feature_type: 'highlights',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('feature_selection', {
      feature_type: 'highlights',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    if (newsItem && selection.selectedText) {
      const payload = {
        article: newsItem.id,
        user: userData.user_id,
        point_id: selection.summaryId,
        content_start_index: selection.startIndex,
        content_end_index: selection.endIndex,
        highlighted_content_text: selection.selectedText,
      };

      callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/highlight/`, payload, (response) => {
        const newHighlight = {
          id: response.id,
          highlighted_content_text: selection.selectedText,
        };
        setNotificationMessage('Highlight created successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        setHighlightedPoints((prevHighlights) => [...prevHighlights, newHighlight]);

        setSelection({
          selectedText: '',
          startIndex: 0,
          endIndex: 0,
          summaryId: null,
        });
        setMenuVisible(false);
        window.getSelection().removeAllRanges();
      });
    }
  };

  const findAncestor = (node, tagName) => {
    while (node) {
      if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === tagName.toLowerCase()) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  };

  const handleDeleteHighlight = useCallback(async (highlightId) => {
    if (highlightId) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/highlight/delete/${highlightId}/`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setNotificationMessage('Highlight deleted successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
        setHighlightedPoints((prevHighlightedPoints) => prevHighlightedPoints.filter((highlight) => highlight.id !== highlightId));
      } catch (error) {
        console.error('Error deleting highlight:', error);
      }
    } else {
      console.log('No highlight ID provided for deletion');
    }
  }, []);

  const handleCopyHighlight = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setNotificationMessage('Your highlighted text has been copied successfully!');
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }, []);

  const HighlightedText = ({ text, highlightId }) => (
    <span className="highlighted">
      {Array.isArray(text) ? text : <span>{text}</span>}
      {highlightId && (
        <div className="highlight-actions">
          <button className="delete-highlight-btn" onClick={() => handleDeleteHighlight(highlightId)}>
            <img src={EraserIcon} alt="delete" /> Clear Highlight
          </button>
          <button
            className="copy-highlight-btn"
            onClick={() => {
              const plainText = Array.isArray(text)
                ? text
                    .map((element) => {
                      if (React.isValidElement(element)) {
                        return element.props.children;
                      }
                      return element;
                    })
                    .join('')
                : text;
              handleCopyHighlight(plainText);
            }}
          >
            <img src={CopyIcon} alt="Copy" />
          </button>
          <div className="action-polygon"></div>
        </div>
      )}
    </span>
  );

  const renderHighlightedContent = (content) => {
    let lastIndex = 0;
    const elements = [];

    const removeMarkdown = (text) => text.replace(/\*\*(.*?)\*\*/g, '$1');
    const cleanContent = removeMarkdown(content);

    const markdownBoldSegments = findBoldPatterns(content);
    const summaryPoint = newsItem.gpt_output.summary.find((point) => point.point === content);
    const apiStylingRanges = summaryPoint?.styling || [];

    const apiBoldSegments = apiStylingRanges.map(([tag, start, end]) => ({
      start,
      end,
      isBold: true,
    }));

    const allBoldSegments = [...markdownBoldSegments, ...apiBoldSegments].sort((a, b) => a.start - b.start);

    const sortedHighlights = [...highlightedPoints].sort((a, b) => {
      return cleanContent.indexOf(a.highlighted_content_text) - cleanContent.indexOf(b.highlighted_content_text);
    });

    sortedHighlights.forEach((highlight) => {
      const startIndex = cleanContent.indexOf(highlight.highlighted_content_text, lastIndex);

      if (startIndex !== -1) {
        if (startIndex > lastIndex) {
          const beforeText = content.slice(lastIndex, startIndex);
          const boldifiedBeforeText = applyBoldStyling(beforeText, allBoldSegments, lastIndex);
          elements.push(...boldifiedBeforeText);
        }

        const highlightLength = highlight.highlighted_content_text.length;
        const highlightedContent = content.slice(startIndex, startIndex + highlightLength);
        const boldifiedHighlightedContent = applyBoldStyling(highlightedContent, allBoldSegments, startIndex);

        elements.push(<HighlightedText key={`highlight-${highlight.id}`} text={boldifiedHighlightedContent} highlightId={highlight.id} />);

        lastIndex = startIndex + highlightLength;
      }
    });

    if (lastIndex < content.length) {
      const remainingText = content.slice(lastIndex);
      const boldifiedRemainingText = applyBoldStyling(remainingText, allBoldSegments, lastIndex);
      elements.push(...boldifiedRemainingText);
    }

    return elements;
  };
  const findBoldPatterns = (text) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const boldSegments = [];
    let match;

    while ((match = boldPattern.exec(text)) !== null) {
      boldSegments.push({
        start: match.index,
        end: match.index + match[0].length,
        text: match[1],
        isMarkdown: true,
      });
    }
    return boldSegments;
  };

  const applyBoldStyling = (text, boldSegments, offset) => {
    if (!text) return [];

    const elements = [];
    let currentIndex = 0;
    const sortedSegments = [...boldSegments].sort((a, b) => a.start - offset - (b.start - offset));

    for (let i = 0; i < sortedSegments.length; i++) {
      const segment = sortedSegments[i];
      const segmentStart = segment.start - offset;
      const segmentEnd = segment.end - offset;

      if (segmentEnd <= 0 || segmentStart >= text.length) continue;
      if (segmentStart > currentIndex) {
        elements.push(<span key={`text-${currentIndex}-${segmentStart}`}>{text.slice(currentIndex, segmentStart)}</span>);
      }

      // Add bold text
      const actualStart = Math.max(0, segmentStart);
      const actualEnd = Math.min(text.length, segmentEnd);
      const boldContent = segment.isMarkdown ? segment.text : text.slice(actualStart, actualEnd);

      if (boldContent) {
        elements.push(<strong key={`bold-${actualStart}-${actualEnd}`}>{boldContent}</strong>);
      }

      currentIndex = actualEnd;
    }

    // Add remaining non-bold text
    if (currentIndex < text.length) {
      elements.push(<span key={`text-${currentIndex}-end`}>{text.slice(currentIndex)}</span>);
    }

    return elements;
  };

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  const createDeepLink = () => {
    MixpanelEvent('share_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('share_clicked', {
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    const domain = window.location.origin;

    const linkData = {
      channel: 'web',
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        '$og_redirect	': domain,
        $fallback_url: domain,
        $og_title: 'Share News',
        $og_description: newsItem.title || 'A basic example of the Branch Web SDK.',
        navigate_to: 'news/id',
        data: newsItem,
        newsType: newsType || NEWS_SOURCE.THE_HINDU,
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
        setError('Failed to create link');
        open();
      } else {
        setLink(link);
        open();
      }
    });
  };

  const DownloadSummary = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/summary-pdf/${newsItem.id}`;
    window.open(url, '_blank');
    setTimeout(() => {
      MixpanelEvent('download_clicked', {
        newspaper_name: newsType,
        date: selectedDate?.split('-').reverse().join('') || new Date(),
        reading_time: readingTime || null,
        streak: streakCount,
        news_id: newsItem.id,
        tab: 'Latest',
        dark_mode: colorScheme === 'dark',
        topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
      });
      sendPostHogEvent('download_clicked', {
        newspaper_name: newsType,
        date: selectedDate?.split('-').reverse().join('') || new Date(),
        reading_time: readingTime || null,
        news_id: newsItem.id,
        tab: 'Latest',
      });
    }, 1000);
  };

  const handleLike = async () => {
    if (!newsItem) {
      console.error('handleLike: newsItem is null or undefined');
      return;
    }

    const payload = {
      summary: newsItem.id,
      user: userData.user_id,
    };

    try {
      const url = newsItem.user_liked ? `${process.env.REACT_APP_BASE_URL}/api/unlike/` : `${process.env.REACT_APP_BASE_URL}/api/like/`;
      const newLiked = !newsItem.user_liked;
      if (newLiked) {
        MixpanelEvent('feature_selection', {
          feature_type: 'like',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          streak: streakCount,
          news_id: newsItem.id,
          tab: 'Latest',
          dark_mode: colorScheme === 'dark',
          topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
        });
        sendPostHogEvent('feature_selection', {
          feature_type: 'like',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          news_id: newsItem.id,
          tab: 'Latest',
        });
      }

      setLiked(newLiked);
      setLikeCount((prevCount) => (newLiked ? prevCount + 1 : prevCount - 1));

      const response = await fetch(url, {
        method: newsItem.user_liked ? 'DELETE' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to like/unlike article');
      }

      setNotificationMessage(newLiked ? 'Article liked successfully!' : 'Article unliked successfully!');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);

      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${newsItem.id}/?user=${userData.user}`, (resp) => {
        setNewsItem(resp.data);
        setNewsDetailData(resp.data);
      });
    } catch (error) {
      console.error('Error handling like/unlike:', error);
      setNotificationMessage('Error handling like/unlike action.');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const handleBookmark = async () => {
    if (!newsItem) {
      console.error('handleBookmark: newsItem is null or undefined');
      return;
    }

    const payload = {
      summary: newsItem.id,
      user: userData.user_id,
    };

    try {
      const url = newsItem.user_bookmarked
        ? `${process.env.REACT_APP_BASE_URL}/api/remove-bookmark/`
        : `${process.env.REACT_APP_BASE_URL}/api/bookmark/`;

      const newBookmarked = !newsItem.user_bookmarked;
      setBookmarked(newBookmarked);
      setBookmarkCount((prevCount) => (newBookmarked ? prevCount + 1 : prevCount - 1));

      const response = await fetch(url, {
        method: newsItem.user_bookmarked ? 'DELETE' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to bookmark/remove-bookmark article');
      }
      if (newBookmarked) {
        MixpanelEvent('feature_selection', {
          feature_type: 'bookmark',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          streak: streakCount,
          news_id: newsItem.id,
          tab: 'Latest',
          dark_mode: colorScheme === 'dark',
          topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
        });
        sendPostHogEvent('feature_selection', {
          feature_type: 'bookmark',
          newspaper_name: newsType,
          date: selectedDate?.split('-').reverse().join('') || new Date(),
          reading_time: readingTime || null,
          news_id: newsItem.id,
          tab: 'Latest',
        });
      }
      setNotificationMessage(newBookmarked ? 'Article bookmarked successfully!' : 'Bookmark removed successfully!');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);

      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/news-summary/${newsItem.id}/?user=${userData.user}`, (resp) => {
        setNewsItem(resp.data);
        setNewsDetailData(resp.data);
      });
    } catch (error) {
      console.error('Error handling bookmark/remove-bookmark:', error);
      // Set notification for error
      setNotificationMessage('Error handling bookmark action.');
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const openImageModal = () => {
    MixpanelEvent('feature_selection', {
      feature_type: 'view full image',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'Latest',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('feature_selection', {
      feature_type: 'view full image',
      newspaper_name: newsType,
      date: selectedDate?.split('-').reverse().join('') || new Date(),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'Latest',
    });
    const isScrolled = window.scrollY > 0;
    setImageModalOpened(true);

    if (isScrolled) {
      document.body.style.marginTop = '120px';
    } else {
      document.body.style.marginTop = '';
    }

    document.body.style.overflow = 'hidden';
  };
  const handleReadStatusToggle = async () => {
    try {
      await callPOSTAPI(`${process.env.REACT_APP_BASE_URL}/api/update-summary-read/`, {
        article: id,
        user: userData.user,
        is_read: !isRead,
      });

      setIsRead(!isRead);

      setNotificationMessage(`Article marked as ${!isRead ? 'read' : 'unread'}`);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      console.error('Failed to update read status:', error);
    }
  };

  const closeImageModal = () => {
    setImageModalOpened(false);
    document.body.style.overflow = '';
    document.body.style.marginTop = '';
  };
  const height =
    window.innerWidth <= 400 && window.innerHeight <= 700
      ? '80dvh'
      : window.innerWidth <= 400 && window.innerHeight > 700
      ? '79dvh'
      : window.innerWidth > 400 && window.innerHeight > 700 && window.innerWidth <= 1500
      ? '84dvh'
      : '85dvh';
  return (
    <>
      <style>
        {`
    .highlighted {
            background-color: #E1EBFF;
            position: relative;
          }
         .highlighted strong {
            font-weight: bold !important;
            margin-left: 0.9px;
        }
          .highlight-actions {
            position: absolute;
            bottom: 100%;
            left: 0;
            background-color: white;
            display: flex;
            align-items: center;
            border-radius: 8px;
            box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.33);
            width: 200px;
            justify-content: space-between;
            padding: 5px 10px;
            font-size: 14px;
            z-index: 1000;
            transform: translateY(-10px);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
          }
          .highlighted:hover .highlight-actions {
            opacity: 1;
            visibility: visible;
          }
          .copy-highlight-btn, .delete-highlight-btn {
            background-color: white;
            border: none;
            cursor: pointer;
            padding: 5px;
          }
          .copy-highlight-btn {
            border-right: 1px solid #E1EBFF;
          }
          .delete-highlight-btn {
            color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
          }
          .action-polygon {
            position: absolute;
            bottom: -10px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid white;
          }
          .no-select {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        `}
      </style>
      <div onContextMenu={(e) => e.preventDefault()}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 0',
            backgroundColor: colors.cardBackground,
            top: 0,
            zIndex: 1000,
            fontFamily: 'Poppins',
          }}
        >
          {showNotification && (
            <Notification
              color="green"
              style={{
                position: 'fixed',
                top: '10%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 100,
                width: '300px',
                textAlign: 'center',
              }}
            >
              {notificationMessage}
            </Notification>
          )}
          <p
            style={{
              margin: 0,
              fontSize: '16px',
              color: colors.text,
              marginLeft: 16,
              fontWeight: '600',
            }}
          >
            {currentIndex && totalArticles ? `Article ${currentIndex} / ${totalArticles}` : 'Article'}
          </p>
          <img
            src={NewsDetailsCloseIcon}
            style={{ cursor: 'pointer', width: 24, height: 24, marginRight: 16 }}
            onClick={handleBack}
            alt="Close Icon"
          />
        </div>

        <div
          onMouseUp={handleSelectionChange}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onSelectionChange={handleSelectionChange}
          style={{
            backgroundColor: colors.background,
            minHeight: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '16px',
            paddingLeft: '0.6em',
            paddingRight: '0.6em',
            marginBottom: '5em',
            fontFamily: 'Poppins',
            WebkitUserSelect: 'text',
            userSelect: 'text',
            WebkitTouchCallout: 'none',
            touchAction: 'manipulation',
            cursor: 'text',
          }}
        >
          <Card
            padding="lg"
            radius="md"
            withBorder
            style={{
              position: 'relative',
              maxWidth: isMobile ? '28rem' : '22vw',
              overflow: 'auto',
              scrollbarWidth: 'none',
              height: height,
              backgroundColor: colors.cardBackground,
              border: 'none',
            }}
          >
            <button
              style={{
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px',
                gap: '10px',
                width: '150px',
                height: '35px',
                fontSize: '12px',
                backgroundColor: 'transparent',
                border: '1px solid #DCDCDC',
                cursor: 'pointer',
              }}
              onClick={handleReadStatusToggle}
            >
              <svg width="18" height="18" viewBox="0 0 16 17" fill={colorScheme === 'dark' ? 'white' : '#6374FA'} xmlns="http://www.w3.org/2000/svg">
                <path d="M12.667 6.71338C11.287 6.71338 10.167 5.59338 10.167 4.21338C10.167 2.83338 11.287 1.71338 12.667 1.71338C14.047 1.71338 15.167 2.83338 15.167 4.21338C15.167 5.59338 14.047 6.71338 12.667 6.71338ZM12.667 2.71338C11.8403 2.71338 11.167 3.38671 11.167 4.21338C11.167 5.04005 11.8403 5.71338 12.667 5.71338C13.4937 5.71338 14.167 5.04005 14.167 4.21338C14.167 3.38671 13.4937 2.71338 12.667 2.71338Z" />
                <path d="M8.00033 10.0469H4.66699C4.39366 10.0469 4.16699 9.82021 4.16699 9.54688C4.16699 9.27354 4.39366 9.04688 4.66699 9.04688H8.00033C8.27366 9.04688 8.50033 9.27354 8.50033 9.54688C8.50033 9.82021 8.27366 10.0469 8.00033 10.0469Z" />
                <path d="M10.667 12.7134H4.66699C4.39366 12.7134 4.16699 12.4867 4.16699 12.2134C4.16699 11.94 4.39366 11.7134 4.66699 11.7134H10.667C10.9403 11.7134 11.167 11.94 11.167 12.2134C11.167 12.4867 10.9403 12.7134 10.667 12.7134Z" />
                <path d="M9.99967 16.0467H5.99967C2.37967 16.0467 0.833008 14.5 0.833008 10.88V6.88005C0.833008 3.26005 2.37967 1.71338 5.99967 1.71338H9.33301C9.60634 1.71338 9.83301 1.94005 9.83301 2.21338C9.83301 2.48671 9.60634 2.71338 9.33301 2.71338H5.99967C2.92634 2.71338 1.83301 3.80671 1.83301 6.88005V10.88C1.83301 13.9534 2.92634 15.0467 5.99967 15.0467H9.99967C13.073 15.0467 14.1663 13.9534 14.1663 10.88V7.54671C14.1663 7.27338 14.393 7.04671 14.6663 7.04671C14.9397 7.04671 15.1663 7.27338 15.1663 7.54671V10.88C15.1663 14.5 13.6197 16.0467 9.99967 16.0467Z" />
              </svg>
              <span style={{ color: colorScheme === 'dark' ? 'white' : '#6374FA' }}>{isRead ? 'Mark as unread' : 'Mark as read'}</span>
            </button>

            <Text className="no-select"  style={{ color: colors.text }}>
              <img src={TheHindu} alt="The Hindu" />
              <span style={{ color: '#747474', fontSize: '14px', marginLeft: '8px' }}>
                •{' '}
                {(() => {
                  const [year, month, day] = newsItem.created_at.split('T')[0].split('-');
                  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                  return `${day} ${months[parseInt(month, 10) - 1]} ${year}`;
                })()}
              </span>
            </Text>

            <Text fw={600}  className="no-select" fz={'18px'} align="center" style={{ marginTop: 10, marginBottom: 10, lw: '29px', textAlign: 'start' }} color={colors.text}>
              {newsItem.title}
            </Text>

            <Group className="no-select" position="center" direction="column">
              <Avatar />
              <Box style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Text size="sm" align="start" fw={600} style={{ color: colors.text }}>
                  {newsItem.author || 'Unknown Author'}
                </Text>
                <Text size="sm" align="start" style={{ color: colors.subtleText }}>
                  Author
                </Text>
              </Box>
            </Group>

            <div
              onClick={handlePYQClick}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 4,
                marginTop: '1em',
                alignItems: 'center',
                width: '100%',
                height: '46px',
                backgroundColor: colors.coloredButton,
                padding: '16px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <Image src={Star} />
                <Text className="no-select"  style={{ color: 'white', fontSize: '14px' }}>Get UPSC PYQs Related to Article</Text>
              </div>
              <IconArrowRight color="white" />
            </div>

            <Box component="ol" style={{ fontSize: '15px', marginLeft: '-25px' }}>
              {newsItem.gpt_output.summary.map((item) => (
                <li key={item.id} data-id={item.id} style={{ color: colors.subtleText }}>
                  {renderHighlightedContent(item.point)}
                </li>
              ))}
            </Box>
            <div
              onClick={handleMCQClick}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 4,
                marginTop: '1em',
                alignItems: 'center',
                width: '100%',
                height: '46px',
                backgroundColor: '#61BD85',
                padding: '16px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.6666 1.33331H5.33325C2.66659 1.33331 1.33325 2.66665 1.33325 5.33331V14C1.33325 14.3666 1.63325 14.6666 1.99992 14.6666H10.6666C13.3333 14.6666 14.6666 13.3333 14.6666 10.6666V5.33331C14.6666 2.66665 13.3333 1.33331 10.6666 1.33331ZM9.33325 10.1666H4.66659C4.39325 10.1666 4.16659 9.93998 4.16659 9.66665C4.16659 9.39331 4.39325 9.16665 4.66659 9.16665H9.33325C9.60659 9.16665 9.83325 9.39331 9.83325 9.66665C9.83325 9.93998 9.60659 10.1666 9.33325 10.1666ZM11.3333 6.83331H4.66659C4.39325 6.83331 4.16659 6.60665 4.16659 6.33331C4.16659 6.05998 4.39325 5.83331 4.66659 5.83331H11.3333C11.6066 5.83331 11.8333 6.05998 11.8333 6.33331C11.8333 6.60665 11.6066 6.83331 11.3333 6.83331Z"
                    fill="white"
                  />
                </svg>
                <Text className="no-select" style={{ color: 'white', fontSize: '14px' }}>Generate MCQs from the article</Text>
              </div>
              <IconArrowRight color="white" />
            </div>

            <div
              style={{
                position: 'fixed',
                top: menuPosition.y,
                left: menuPosition.x,
                backgroundColor: 'white',
                border: 'none',
                padding: '10px',
                zIndex: 1000,
                color: 'black',
                width: '200px',
                display: menuVisible ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                boxShadow: '0px -8px 24px 0px rgba(0, 0, 0, 0.33)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent',
                  borderTop: '10px solid white',
                }}
              />

              <button
                onClick={handleHighlight}
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '5px',
                }}
              >
                <img src={HighlighterIcon} alt="highlight" /> Add to Notes
              </button>

              <button
                onClick={() => handleCopyHighlight(selection.selectedText)}
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '5px',
                }}
              >
                <img src={CopyIcon} alt="Copy" />
              </button>
            </div>

            <Box mt="md" style={{ color: colors.text }}>
              <Text fz="lg" align="center">
                {renderHighlightedContent(newsItem.content)}
              </Text>
            </Box>

            <Box className="no-select"
              style={{
                marginTop: '0.5rem',
              }}
            >
              {/* Topic Tags Section */}
              <Text style={{ color: colors.text, fontWeight: '700', marginBottom: '0.5rem' }}>Topic Tags</Text>

              {newsItem.prelims_syllabus && (
                <>
                  <Text weight={500} size="sm" style={{ color: colors.text, marginBottom: '0.5rem', opacity: '0.7' }}>
                    Prelims Syllabus
                  </Text>
                  <Group spacing="xs" style={{ marginBottom: '1rem', flexWrap: 'wrap' }}>
                    {newsItem.prelims_syllabus.split(',').map((item, index) => (
                      <Text
                        key={index}
                        size="xs"
                        style={{
                          opacity: '0.7',
                          color: colors.text,
                          padding: '8px 12px 8px 12px',
                          borderRadius: '4px',
                          border: `1px solid #DCDCDC`,
                        }}
                      >
                        {item.trim()}
                      </Text>
                    ))}
                  </Group>
                </>
              )}

              {/* Mains Syllabus - Render if available */}
              {newsItem.mains_syllabus && (
                <>
                  <Text weight={500} size="sm" style={{ color: colors.text, marginBottom: '0.5rem', opacity: '0.7' }}>
                    Mains Syllabus
                  </Text>
                  <Group spacing="xs" style={{ flexWrap: 'wrap' }}>
                    {newsItem.mains_syllabus.split('GS-').map(
                      (item, index) =>
                        item && (
                          <Text
                            key={index}
                            size="xs"
                            style={{
                              opacity: '0.7',
                              color: colors.text,
                              padding: '8px 12px 8px 12px',
                              borderRadius: '4px',
                              border: `1px solid #DCDCDC`,
                            }}
                          >
                            {`GS-${item.trim()}`}
                          </Text>
                        )
                    )}
                  </Group>
                </>
              )}
              <Text style={{ color: colors.text, fontWeight: '700', marginBottom: '0.5rem', marginTop: '1rem' }}>
                Tutor Chat related to the Article
              </Text>
              <div>
                {chats.map((chat, index) => (
                  <ChatCard
                    key={index}
                    chat={chat}
                    index={index}
                    totalChats={chats.length}
                    onDownload={handleDownloadPdf}
                    onSelect={handleChatSelect}
                  />
                ))}
              </div>
            </Box>
          </Card>
          {/* Footer */}
          <div
            className="no-select"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 16px',
              backgroundColor: colors.cardBackground,
              borderTop: `1px solid ${colors.border}`,
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              maxWidth: isMobile ? '28rem' : '22vw',
              margin: '0 auto',
              boxSizing: 'border-box',
              zIndex: 1000,
              marginBottom: '5px',
            }}
          >
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: '0.5em', cursor: 'pointer', alignItems: 'center' }} onClick={handleLike}>
                <img
                  src={liked || newsItem.user_liked ? LikeActive : Like2}
                  alt={liked || newsItem.user_liked ? 'Active Like Icon' : 'Like Icon'}
                  style={{ height: '1.5em', width: '1.5em' }}
                />
                <span>{newsItem.likes}</span>
              </div>
              <div style={{ display: 'flex', gap: '0.5em', cursor: 'pointer', alignItems: 'center' }} onClick={handleBookmark}>
                <img
                  src={bookmarked || newsItem.user_bookmarked ? BookmarkActive : Bookmark}
                  alt={bookmarked || newsItem.user_bookmarked ? 'Active Bookmark Icon' : 'Bookmark Icon'}
                  style={{ height: '1.5em', width: '1.5em' }}
                />
                <span>{newsItem.bookmarks}</span>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={createDeepLink}>
                <img src={Share} alt="Share Icon" style={{ height: '1.5em', width: '1.5em' }} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={DownloadSummary}>
                <img src={Download} alt="Download Icon" style={{ height: '1.5em', width: '1.5em' }} />
              </div>
            </div>
          </div>
          <div style={{ height: '60px' }} />
        </div>

        {imageModalOpened && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999999999,
              overflow: 'hidden',
              height: '100dvh',
              width: isMobile ? '28rem' : '22vw',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '4%',
                left: '45%',
                transform: 'translateX(-50%)',
                color: 'white',
                borderRadius: '8px',
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '80%',
                maxWidth: isMobile ? '28rem' : '22vw',
              }}
            >
              <h2
                style={{
                  margin: '0',
                  fontSize: '18px',
                  fontWeight: '500',
                  color: 'white',
                  whiteSpace: 'normal',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  flex: 1,
                }}
              >
                {newsItem.title.length > 30 ? `${newsItem.title.substring(0, 30)}...` : newsItem.title}
              </h2>
              <button
                onClick={closeImageModal}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: 'white',
                  fontSize: '24px',
                  cursor: 'pointer',
                  marginLeft: '12px',
                  borderRadius: '50%',
                  padding: '5px',
                  flexShrink: 0,
                }}
              >
                ✕
              </button>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100dvh',
                boxSizing: 'border-box',
              }}
            >
              <img
                src={newsItem.image}
                alt="Full-size news image"
                style={{
                  maxWidth: isMobile ? '28rem' : '22vw',
                  maxHeight: '80vh',
                  objectFit: 'contain',
                }}
              />
            </div>
          </div>
        )}

        <Modal opened={opened} onClose={close} title="Share Link" centered zIndex="9999" size="sm">
          {error ? (
            <Text color="red">{error}</Text>
          ) : (
            <Flex align="center" gap="md">
              <Box style={{ flex: 1, overflow: 'hidden' }}>
                <Text truncate>{link}</Text>
              </Box>
              <CopyButton value={link} timeout={2000}>
                {({ copied, copy }) => (
                  <Button
                    color={copied ? 'teal' : 'blue'}
                    onClick={() => {
                      copy();
                      MixpanelEvent('feature_selection', {
                        feature_type: 'news share',
                        newspaper_name: newsType,
                        date: selectedDate?.split('-').reverse().join('') || new Date(),
                        reading_time: readingTime || null,
                        streak: streakCount,
                        news_id: newsItem.id,
                        tab: 'Latest',
                        dark_mode: colorScheme === 'dark',
                        topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
                      });
                      sendPostHogEvent('feature_selection', {
                        feature_type: 'news share',
                        newspaper_name: newsType,
                        date: selectedDate?.split('-').reverse().join('') || new Date(),
                        reading_time: readingTime || null,
                        news_id: newsItem.id,
                        tab: 'Latest',
                      });
                    }}
                  >
                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                    {copied ? 'Copied' : 'Copy'}
                  </Button>
                )}
              </CopyButton>
            </Flex>
          )}
        </Modal>
      </div>
      {showHighlightModal && <InfoHighlight onGoBack={closeModal} />}
    </>
  );
}
