export const hideHeaderRoutes = ['/onboarding', '/login', '/', '/signup', '/signupdetails', '/pyqSearch', '/duel-review', '/online-match-game', '/matching', '/duel-start', '/duel-results', '/news/mcq/:id', '/news/:id', '/book-chat/select-book','/book/:id'];

export const hideFooterRoutes = [
  '/onboarding',
  '/login',
  '/',
  '/profile',
  '/news/',
  '/signup',
  '/signupdetails',
  '/chat/:id',
  '/edit-profile',
  '/pyqSearch',
  '/duelState',
  '/news/mcq/',
  '/book-chat/select-book',
  '/book/:id',
  '/duel-results',
  '/news/:id'
];
