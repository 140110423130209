import React from 'react';
import ComingSoonPage from '../../molecules/ComingSoon';
import { PracticeCommingSoon } from '../../assets/images';

export default function PracticePage() {
  return (
    <div>
      <ComingSoonPage img={PracticeCommingSoon} description={'Our UPSC practice module is launching soon! Stay tuned!'}/>
    </div>
  );
}
