import React, { useState } from 'react';
import RichText from '../../molecules/RichText/RichText';
import { AiAvatar, Copy, DisLike, DisLikeFill, Like, LikeFill } from '../../assets/images';
import { LatexRenderer } from '../../molecules/RichText/LatexRenderer';
import { Divider, Notification, Stack } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { hasLaTeX, isHTML } from '../../utils/RichText';

export const AnswerComponent = ({ item, index, streamEnd, isLast, type, richText = true, callBack, hasAttachments = false }) => {
  const { copy, copied } = useClipboard();
  const [feedbackStates, setFeedbackStates] = useState({});

  const handleCopy = () => {
    if (hasAttachments) {
      const textContent = item.content.filter((chunk) => typeof chunk === 'string').join('');
      copy(textContent);
    } else {
      copy(item.content);
    }
    MixpanelEvent('chat_response_react', {
      type: 'copy',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
    sendPostHogEvent('chat_response_react', {
      type: 'copy',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
  };

  const handleDislike = () => {
    setFeedbackStates((prevState) => ({
      ...prevState,
      [index]: prevState[index] === 'dislike' ? null : 'dislike',
    }));
    if (callBack) callBack('dislike', index);
    MixpanelEvent('chat_response_react', {
      type: 'dislike',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
    sendPostHogEvent('chat_response_react', {
      type: 'dislike',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
  };

  const handleLike = () => {
    setFeedbackStates((prevState) => ({
      ...prevState,
      [index]: prevState[index] === 'like' ? null : 'like',
    }));
    MixpanelEvent('chat_response_react', {
      type: 'like',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
    sendPostHogEvent('chat_response_react', {
      type: 'like',
      chat_type: 'closed_book',
      source: 'ask_ai',
    });
  };

  const renderContent = () => {
    if (!hasAttachments) {
      return (
        <PreviewTextContent content={item.content} streamEnd={item.renderFeedback || streamEnd} isLast={isLast} type={type} richText={richText} />
      );
    }

    let textBuffer = '';
    const elements = [];

    item.content.forEach((chunk, chunkIndex) => {
      if (typeof chunk === 'string') {
        textBuffer += chunk;
      } else {
        if (textBuffer) {
          elements.push(
            <PreviewTextContent
              key={`text-${chunkIndex}`}
              content={textBuffer}
              streamEnd={item.renderFeedback || streamEnd}
              isLast={isLast}
              type={type}
              richText={richText}
            />
          );
          textBuffer = '';
        }
        elements.push(<React.Fragment key={`attachment-${chunkIndex}`}>{chunk}</React.Fragment>);
      }
    });

    if (textBuffer) {
      elements.push(
        <PreviewTextContent
          key={`text-last`}
          content={textBuffer}
          streamEnd={item.renderFeedback || streamEnd}
          isLast={isLast}
          type={type}
          richText={richText}
        />
      );
    }

    return elements;
  };

  return (
    <>
      {copied && (
        <Notification title="Copied" style={{ position: 'absolute', top: '3%', zIndex: 100, left: '8%' }}>
          The content has been copied to your clipboard.
        </Notification>
      )}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F3F3F3',
          borderRadius: '12px',
          padding: '1em',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-10px',
            left: '0px',
          }}
        >
          <img src={AiAvatar} alt="Ai Avatar" style={{ height: '2.5em', width: '2.5em' }} />
        </div>
        <div
          style={{
            marginTop: '0.3em',
          }}
        >
          {renderContent()}
        </div>
        {streamEnd && (
          <>
            <Divider my="md" />
            <Stack
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <img
                src={Copy}
                alt="Copy icon"
                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                onClick={handleCopy}
              />
              <img
                src={feedbackStates[index] === 'dislike' ? DisLikeFill : DisLike}
                alt="Dislike icon"
                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                onClick={handleDislike}
              />
              <img
                src={feedbackStates[index] === 'like' ? LikeFill : Like}
                alt="Like icon"
                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                onClick={handleLike}
              />
            </Stack>
          </>
        )}
      </div>
    </>
  );
};

const PreviewTextContent = ({ content, streamEnd, isLast, type, richText }) => {
  if (hasLaTeX(content)) {
    return <LatexRenderer content={content} />;
  } else if (isHTML(content)) {
    return (
      <div className="html-content" style={{ marginTop: '0.8em' }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  } else {
    return <RichText content={content.trim()} streamEnd={streamEnd} isLast={isLast} type={type} />;
  }
};
