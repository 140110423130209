import React, { useEffect, useState, useMemo, useRef } from 'react';
import CustomDatePicker from './datePicker';
import NewsType from './newsType';
import { ReadingTime } from './readingTime';
import NewsListItem from './newsCard';
import { callGETAPI } from '../../utils/NetworkUtil';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNewsPaperParamForApi } from '../../utils/NewsFeedUtils';
import { useNews } from '../../context/newsProvide';
import NoDataLatestNews from '../../molecules/NoData/noDataLatestNews';
import { useTheme } from '../../context/themeProvider';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function LatestNews() {
  const { colors, colorScheme } = useTheme();
  const location = useLocation();
  const newsListRef = useRef(null);
  const windowHeight = window.innerHeight;
  const isResponsiveMobile = windowHeight <= 700;

  const userData = useMemo(() => JSON.parse(localStorage.getItem('userData')), []);
  const { newsData, setNewsData, selectedDate, setSelectedDate, readingTime, resetFilters, streakCount } = useNews();
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const navigate = useNavigate();
  const [apiDate, setApiDate] = useState(null);
  const [readIds, setReadIds] = useState([]);

  useEffect(() => {
    window.addEventListener('beforeunload', resetFilters);
    return () => {
      window.removeEventListener('beforeunload', resetFilters);
    };
  }, [resetFilters]);

  const getPreviousDay = (date) => {
    const prevDay = new Date(date + 'T00:00:00Z');
    prevDay.setUTCDate(prevDay.getUTCDate() - 1);
    return prevDay.toISOString().split('T')[0];
  };

  useEffect(() => {
    const fetchNewsData = (date) => {
      if (date && userData) {
        const formattedDate = new Date(date + 'T00:00:00Z').toISOString().split('T')[0];
        let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/news-summary/?news_paper=${getNewsPaperParamForApi(
          selectedNews
        )}&date=${formattedDate}&user=${userData.user}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (readingTime) {
          apiUrl += `&reading_time=${readingTime}`;
        }

        callGETAPI(
          apiUrl,
          (resp) => {
            if (resp.data.length === 0 && date === selectedDate) {
              const previousDate = getPreviousDay(date);
              fetchNewsData(previousDate);
            } else {
              setNewsData(resp.data);
              if (date !== selectedDate) {
                setSelectedDate(date);
              }
              setApiDate(date);
            }
          },
          (err) => {
            console.error('API error:', err);
            setNewsData([]);
          }
        );
      }
    };

    fetchNewsData(selectedDate);
  }, [selectedNews, selectedDate, readingTime, userData, setNewsData, setSelectedDate]);

  useEffect(() => {
    if (userData && userData.user) {
      const idsString = userData.user;
      const summaryCountUrl = `${process.env.REACT_APP_BASE_URL}/api/user-summary-data/?user=${idsString}`;

      callGETAPI(
        summaryCountUrl,
        (resp) => {
          setReadIds(resp.data.read || []); // Store just the read IDs
        },
        (err) => {
          console.error('Summary count API error:', err);
        }
      );
    }
  }, [userData]);

  useEffect(() => {
    if (location.state && location.state.scrollPosition) {
      setTimeout(() => {
        newsListRef.current.scrollTo(0, location.state.scrollPosition);
      }, 0);
    }
  }, [location]);

  const onSetDate = (date) => {
    MixpanelEvent('news_date_selector_submitted', { selected_date: date });
    sendPostHogEvent('news_date_selector_submitted', { selected_date: date });
    if (date !== selectedDate) {
      const dateObj = new Date(date + 'T00:00:00Z');
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      setSelectedDate(formattedDate);
    }
  };

  const handleNewsClick = (newsItem, index) => {
    const scrollPosition = newsListRef.current.scrollTop;

    MixpanelEvent('news_clicked', {
      newspaper_name: selectedNews,
      source: 'LatestNews',
      date: apiDate.split('-').reverse().join(''),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'LatestNews',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });
    sendPostHogEvent('news_clicked', {
      newspaper_name: selectedNews,
      source: 'LatestNews',
      date: apiDate.split('-').reverse().join(''),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'LatestNews',
    });
    navigate(`/news/${newsItem.id}`, {
      state: {
        newsItem,
        newsType: selectedNews,
        currentIndex: index + 1,
        totalArticles: newsData.length,
        scrollPosition,
        selectedDate: apiDate,
        readingTime,
        streakCount,
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: colors.background,
        color: colors.text,
        height: windowHeight > 900 ? '100vh' : isResponsiveMobile ? '33em' : '45em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '12px',
          zIndex: 10,
          backgroundColor: colors.cardBackground,
          color: colors.cardText,
        }}
      >
        <NewsType />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
          justifyContent: 'space-evenly',
          padding: '8px',
          zIndex: 10,
          backgroundColor: colors.cardBackground,
          color: colors.cardText,
        }}
      >
        <CustomDatePicker selectedNews={selectedNews} onSetDate={onSetDate} apiDate={apiDate} />
        <ReadingTime />
      </div>
      <div
        ref={newsListRef}
        style={{
          padding: '15px',
          overflowY: 'scroll',
          zIndex: 1,
          height: '100vh',
          scrollbarWidth: 'none',
        }}
      >
        {newsData.length > 0 ? (
          newsData.map((newsItem, index) => (
            <NewsListItem
              key={newsItem.id}
              tags={newsItem.gpt_output.mains_syllabus}
              title={newsItem.title}
              likes={newsItem.likes}
              bookmarks={newsItem.bookmarks}
              imageSrc={newsItem.imageSrc}
              newsItem={newsItem}
              newsType={selectedNews}
              onClick={() => handleNewsClick(newsItem, index)}
              isLastItem={index === newsData.length - 1}
              readIds={readIds}
            />
          ))
        ) : (
          <div
            style={{
              textAlign: 'center',
              color: colors.noDataText,
              paddingTop: '2rem',
            }}
          >
            <NoDataLatestNews />
          </div>
        )}
      </div>
    </div>
  );
}
