import React, { useState, useCallback, useEffect } from 'react';
import { Badge, Box, Button, Group, Radio, Stack, Tabs, Text, Textarea, Title } from '@mantine/core';
import { IconArrowLeft, IconExclamationCircle, IconEye } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNews } from '../../context/newsProvide';
import { useTheme } from '../../context/themeProvider';
import { userStore } from '../../stores/userStore';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeftFilled, NoDataImage } from '../../assets/images';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import PrelimsQuestionsTab from './PrelimsQuestionsTab';

const NewsPyqPage = () => {
  const { colors } = useTheme();
  const { id } = useParams();
  const { newsDetailData } = useNews();
  console.log(newsDetailData.id, 'the news id here');
  const navigate = useNavigate();

  const mainsQuestions = newsDetailData.pyqs?.mains_questions || [];
  const prelimsQuestions = newsDetailData.pyqs?.prelims_questions || [];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnserIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('mains');
  const [showSolution, setShowSolution] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');

  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [questionStates, setQuestionStates] = useState(
    prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null }))
  );

  const handlePrevQuestion = useCallback(
    (questions) => {
      if (currentQuestionIndex > 0) {
        const questionIndex = currentQuestionIndex - 1;
        setCurrentQuestionIndex(questionIndex);
        if (questionStates[questionIndex]?.isSubmitted) {
          setSelectedAnserIndex(questionStates[questionIndex].answerIndex);
        }
      }
    },
    [currentQuestionIndex, questionStates]
  );

  const handleNextQuestion = useCallback(
    (questions) => {
      if (currentQuestionIndex < questions.length - 1) {
        const questionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(questionIndex);
        if (questionStates[questionIndex]?.isSubmitted) {
          setSelectedAnserIndex(questionStates[questionIndex].answerIndex);
        }
      }
    },
    [currentQuestionIndex, questionStates]
  );

  const handleQuestionClick = useCallback(
    (index) => {
      setCurrentQuestionIndex(index);
      if (questionStates[index]?.isSubmitted) {
        setSelectedAnserIndex(questionStates[index].answerIndex);
      }
    },
    [questionStates]
  );

  useEffect(() => {
    setQuestionStates(prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
  }, [prelimsQuestions]);

  const trackTabChange = (tab) => {
    MixpanelEvent('pyq_tab_selection', {
      type: tab,
      newspaper_name: 'The Hindu',
      news_id: id,
      tab: 'Latest',
    });
    sendPostHogEvent('pyq_tab_selection', {
      type: tab,
      newspaper_name: 'The Hindu',
      news_id: id,
      tab: 'Latest',
    });
  };

  const toggleSolution = (index) => {
    setShowSolution((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    MixpanelEvent('pyq_solution_clicked', {
      tab: 'Latest',
      newspaper_name: newsDetailData?.news_paper?.name || 'Unknown',
      news_id: id,
      type: activeTab,
      topic: newsDetailData?.gpt_output?.mains_syllabus?.join(', ') || 'N/A',
      action: showSolution[currentQuestionIndex] ? 'hidden' : 'shown',
      question_number: currentQuestionIndex,
    });
    sendPostHogEvent('pyq_solution_clicked', {
      tab: 'Latest',
      newspaper_name: newsDetailData?.news_paper?.name || 'Unknown',
      news_id: id,
      type: activeTab,
      topic: newsDetailData?.gpt_output?.mains_syllabus?.join(', ') || 'N/A',
      action: showSolution[currentQuestionIndex] ? 'hidden' : 'shown',
      question_number: currentQuestionIndex,
    });
  };

  const NoData = () => (
    <Box
      style={{
        textAlign: 'center',
        padding: '20px',
        height: '60dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={NoDataImage} alt="no data" />
      <Text color={colors.subtleText} fw={600}>
        {'No Related Questions Found'}
      </Text>
      <Text color={colors.subtleText}>
        We couldn't find any related Mains questions for this article. Feel free to explore different articles for PYQs.
      </Text>
    </Box>
  );

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
    MixpanelEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
    sendPostHogEvent('screen_closed', { source_screen: 'Report Question', close_type: 'modal' });
  };

  const handleReport = (prelimsQuestions) => {
    setIsOpen(true);
    setSelectedQuestionId(prelimsQuestions);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {}
  };

  const QuestionNavigation = ({ questions }) => (
    <div style={styles.questionNavigation}>
      <img
        src={ArrowLeftFilled}
        style={{
          ...styles.navIcon,
          color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
          cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
        }}
        onClick={() => handlePrevQuestion(questions)}
        alt="prev"
      />
      <div style={styles.qNavigationWrapper}>
        <div style={styles.qNavigation}>
          {questions.map((_, index) => (
            <Text
              key={index}
              style={{
                ...styles.questionNumber,
                backgroundColor: index === currentQuestionIndex ? colors.highlight : 'transparent',
                color: index === currentQuestionIndex ? colors.primary : colors.text,
              }}
              onClick={() => handleQuestionClick(index)}
            >
              Q{index + 1}
            </Text>
          ))}
        </div>
      </div>
      <img
        src={ArrowLeftFilled}
        style={{
          ...styles.navIcon,
          color: currentQuestionIndex !== questions.length - 1 ? colors.arrowColor : 'gray',
          cursor: currentQuestionIndex !== questions.length - 1 ? 'pointer' : 'not-allowed',
          transform: 'rotate(180deg)',
        }}
        onClick={() => handleNextQuestion(questions)}
        alt="next"
      />
    </div>
  );

  const styles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end', // Aligns modal at the bottom
      zIndex: 1000,
    },
    modalContainer: {
      backgroundColor: colors.cardBackground,
      color: colors.text,
      padding: '20px',
      borderRadius: '10px 10px 0 0', // Rounded only on top corners
      width: '100%',
      textAlign: 'center',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      marginBottom: '0', // Modal is at the bottom
    },
    topLine: {
      height: '6px',
      width: '89px',
      display: 'block',
      background: colors.border,
      borderRadius: '50px',
      cursor: 'pointer',
    },
    outerContainer: {
      fontFamily: 'Poppins, Arial, sans-serif',
      color: colors.text,
      maxWidth: '600px',
      margin: '0 auto',
      height: '89dvh',
      display: 'flex',
      flexDirection: 'column',
      background: colors.cardBackground,
    },
    topContainer: {
      padding: '15px',
      background: colors.cardBackground,
      boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
    },
    header: {
      marginBottom: '20px',
    },
    backArrow: {
      cursor: 'pointer',
      marginRight: '10px',
    },
    headerText: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    questionNavigation: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    qNavigationWrapper: {
      flex: 1,
      overflow: 'hidden',
      margin: '0 10px',
    },
    qNavigation: {
      display: 'flex',
      overflowX: 'auto',
      scrollbarWidth: 'none', // Firefox
      msOverflowStyle: 'none', // IE 10+
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        display: 'none', // Safari and Chrome
      },
    },
    questionNumber: {
      minWidth: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      margin: '0 5px',
      flexShrink: 0,
      color: colors.text,
    },
    navIcon: {
      cursor: 'pointer',
      borderRadius: '8px',
      border: `1px solid ${colors.border}`,
      padding: '7px 5px',
      backgroundColor: 'white',
    },
    container: {
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      border: `1px solid ${colors.border}`,
      margin: '15px',
      height: 'calc(100dvh - 180px)',
    },
    bottomContainer: {
      margin: '0 15px',
      background: colors.cardBackground,
      marginTop: '20px',
      marginBottom: '20px',
      borderRadius: '12px',
      height: '88%',
    },
    questionMeta: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
      gap: '10px',
    },
    questionText: {
      fontSize: '16px',
      fontWeight: '500',
      marginBottom: '20px',
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    optionBox: {
      padding: '15px',
      borderRadius: '8px',
      border: `1px solid ${colors.border}`,
      cursor: 'pointer',
    },
    optionContent: {
      display: 'flex',
      alignItems: 'center',
    },
    radioCircle: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '10px',
      transition: 'all 0.3s ease',
    },
    icon: {
      width: '14px',
      height: '14px',
      color: 'white',
    },
    optionLabel: {
      marginRight: '10px',
    },
    optionText: {
      fontSize: '15px',
      fontWeight: '500',
      flex: 1,
      color: colors.text,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      borderRadius: '12px',
      boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: colors.cardBackground,
    },
    submitButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '7px 15px',
      backgroundColor: colors.text,
      color: colors.cardBackground,
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    discussButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '7px 15px',
      backgroundColor: colors.text,
      color: colors.cardBackground,
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    discussIcon: {
      marginRight: '5px',
    },
    viewSolutionButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: colors.text,
      backgroundColor: 'transparent',
      border: `1px solid ${colors.border}`,
      borderRadius: '1.5625rem',
      padding: '7px 15px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    solutionText: {
      marginTop: '10px',
      color: colors.subtleText,
      fontSize: '14px',
    },
    solutionLabel: {
      color: colors.text,
      fontWeight: 'bold',
      marginBottom: '5px',
      display: 'block',
    },
  };

  const MainsContent = React.memo(() => (
    <div style={styles.outerContainer}>
      <div style={styles.topContainer}>{mainsQuestions.length > 0 && <QuestionNavigation questions={mainsQuestions} />}</div>
      {mainsQuestions.length > 0 ? (
        <div style={styles.container}>
          <div
            style={{
              ...styles.bottomContainer,
              backgroundColor: colors.cardBackground,
              padding: '15px',
              height: '88%',
              overflowY: 'auto',
            }}
          >
            <div style={styles.questionMeta}>
              <Badge color={colors.subtleText}>{mainsQuestions[currentQuestionIndex]?.exam.toUpperCase()}</Badge>
              <Badge color={colors.subtleText}>{mainsQuestions[currentQuestionIndex]?.year}</Badge>
            </div>

            <Text style={styles.questionText}>
              {mainsQuestions[currentQuestionIndex]?.question.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Text>

            {showSolution[currentQuestionIndex] && (
              <Text style={styles.solutionText}>
                <Text style={styles.solutionLabel}>SOLUTION</Text>
                {mainsQuestions[currentQuestionIndex]?.solution
                  ? mainsQuestions[currentQuestionIndex].solution.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : 'No solution available.'}
              </Text>
            )}
          </div>
          {/* footer section */}
          {mainsQuestions.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px',
                boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
                width: '100%',
                backgroundColor: colors.cardBackground,
                border: `1px solid ${colors.border}`,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                boxSizing: 'border-box',
                margin: 0,
              }}
            >
              <Button variant="subtle" color="red" onClick={() => handleReport(mainsQuestions[currentQuestionIndex].id)}>
                <IconExclamationCircle style={{ marginRight: '0.25rem' }} height={18} width={18} />
                Report
              </Button>
              <Button style={styles.viewSolutionButton} onClick={() => toggleSolution(currentQuestionIndex)}>
                <IconEye style={{ marginRight: '0.25rem' }} />
                {showSolution[currentQuestionIndex] ? 'Hide Solution' : 'View Solution'}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <NoData />
      )}

      <ReportModaContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
      />
    </div>
  ));

  const ReportModaContent = ({
    isOpen,
    isMobile,
    closeModal,
    isReportSubmited,
    resportData,
    setResportData,
    details,
    onChange,
    handleResportSubmit,
    styles,
  }) => {
    if (!isOpen) return null;

    return (
      <div style={styles.modalOverlay}>
        <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <span style={styles.topLine} onClick={closeModal}></span>
          </div>
          <button
            onClick={closeModal}
            style={{
              position: 'relative',
              right: '5px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'rgb(0, 0, 0)',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            &times;
          </button>

          {!isReportSubmited ? (
            <>
              <Title fz="h3" m="1em" style={{ textAlign: 'left' }}>
                Report Question
              </Title>
              <Stack spacing="lg">
                <Radio.Group value={resportData} onChange={setResportData} size="1em">
                  {[
                    'Wrong category',
                    'Answer is incorrect',
                    'Incomplete question',
                    'Mistake in question',
                    'Mistake in options',
                    'Poorly formatted question',
                    'Poorly formatted options',
                    'Other',
                  ].map((item, index) => (
                    <Radio key={index} value={item} label={item} p="md" />
                  ))}
                </Radio.Group>

                {resportData === 'Other' && (
                  <>
                    <Text style={{ textAlign: 'left' }}>Other reason*</Text>
                    <Textarea
                      placeholder="Provide details about your issue here..."
                      value={details}
                      rows={4}
                      radius="sm"
                      style={{ marginTop: '10px', fontSize: '14px' }}
                      onChange={onChange}
                    />
                  </>
                )}
              </Stack>

              <Group position="center" mt="lg">
                <Button
                  fullWidth
                  radius="xl"
                  size="md"
                  variant="filled"
                  color="dark"
                  disabled={!resportData}
                  style={{
                    backgroundColor: resportData ? colors.text : colors.disabled,
                    color: colors.cardBackground,
                    fontWeight: 500,
                  }}
                  onClick={handleResportSubmit}
                >
                  Submit Feedback
                </Button>
              </Group>
            </>
          ) : (
            <>
              <Title fz="h3" m="1em">
                Report Submitted
              </Title>
              <Title m="1em" fz="md">
                Thank you! Your report has been submitted.
              </Title>
              <Lottie options={defaultOptions} height={200} width={200} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          padding: '10px 10px',
          backgroundColor: colors.cardBackground,
          position: 'absolute',
          top: 0,
          zIndex: 1000,
          height: '3.25em',
          color: colors.text,
        }}
      >
        <IconArrowLeft
          onClick={() => {
            navigate(-1);
            MixpanelEvent('navigation_back', { source_screen: 'news_pyq' });
            sendPostHogEvent('navigation_back', { source_screen: 'news_pyq' });
          }}
          height={24}
          width={24}
          style={{ cursor: 'pointer' }}
        />
        <h1
          style={{
            margin: 0,
            fontSize: '16px',
            color: colors.text,
            marginLeft: 16,
          }}
        >
          Related UPSC PYQs
        </h1>
      </div>
      <div
        style={{
          marginTop: '60px',
          width: '100%',
          backgroundColor: colors.cardBackground,
          zIndex: 999,
          position: 'relative',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(value) => {
            setActiveTab(value);
            setCurrentQuestionIndex(0);
            trackTabChange(value);
          }}
          style={{ width: '100%' }}
        >
          <Tabs.List
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: 24,
              justifyContent: 'center',
            }}
          >
            <Tabs.Tab value="mains" style={{ fontSize: '12px', width: '50%' }}>
              Mains
            </Tabs.Tab>
            <Tabs.Tab value="prelims" style={{ fontSize: '12px', width: '50%' }}>
              Prelims
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="mains">
            <MainsContent />
          </Tabs.Panel>
          <Tabs.Panel value="prelims">
            <PrelimsQuestionsTab questions={prelimsQuestions} colors={colors} userId={userId} />
          </Tabs.Panel>
        </Tabs>
      </div>
    </>
  );
};

export default NewsPyqPage;
const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
