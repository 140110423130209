import React from 'react';
import { Center, Stack, Text, Image } from '@mantine/core';
import { ComingSoon } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const NoHighlightPage = () => {
  const { colors } = useTheme();

  const poppinsMedium16 = {
    fontSize: 16,
    fontWeight: 600,
    fontFamily : "Poppins"
  };

  const poppinsRegular14 = {
    fontSize: 14,
    fontWeight: 500,
    fontFamily : "Poppins"
  };

  const errorTextWrapper = {
    textAlign: 'center',
    whiteSpace: 'pre-line', 
  };

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 1000,
        overflow: 'hidden',
      }}
    >
      <Center style={{ height: '72vh', backgroundColor: colors.background, overflow : "scroll" }}>
        <Stack align="center" spacing="md">
          <Image src={ComingSoon} alt=" Nothing here" style={{width: '80%', maxWidth: '600px'}} />
          <Text style={{ ...poppinsMedium16, ...errorTextWrapper, color: colors.text }}>
            Nothing here!
          </Text>
          <Text style={{ ...poppinsRegular14, ...errorTextWrapper, color: colors.subtleText }}>
            Highlight your favourite News {'\n'} Summary detailsfor personalized Notes.
          </Text>
        </Stack>
      </Center>
    </div>
  );
};

export default NoHighlightPage;
