// analytics.js
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

// Initialize Mixpanel
const initMixpanel = () => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
      debug: true,
      track_pageview: false,
      persistence: 'localStorage',
      loaded: (mixpanel) => {
        console.log('Mixpanel loaded');
        window.mixpanel = mixpanel;
      },
    });
  } catch (error) {
    console.error('Error initializing Mixpanel:', error);
  }
};

const initPostHog = () => {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
  });
};

const sendPostHogEvent = (eventName, eventProps = {}) => {
  try {
    let props = { ...eventProps };
    props.mixpanelID = mixpanel?.get_distinct_id();
    posthog?.capture(eventName, props);
  } catch (error) {
    console.error('PostHog event error:', error);
  }
};

// Send a page change event
const sendPageChangeEvent = (pageName = '', breakpoint = '') => {
  let params = { pageName: pageName };
  if (breakpoint !== '') {
    params.breakpoint = breakpoint;
  }
  sendEvent('Page_Enter', params);
};

// Send a generic event
const sendEvent = (eventName = '', params = {}) => {
  console.log('sendEvent', eventName, params);
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    console.log('sendEvent', eventName, params);
    mixpanel?.track(eventName, params);
  } catch (error) {
    console.error('Mixpanel event error:', error);
  }
};

// Identify a user
const identifyUser = (user_id = '') => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    mixpanel?.identify(user_id);
  } catch (error) {
    console.error('Mixpanel identify error:', error);
  }
};

// Set user properties
const setMixpanelUserProperties = (params = {}) => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    mixpanel?.people?.set(params);
  } catch (error) {
    console.error('Mixpanel properties error:', error);
  }
};

const setPostHogUserProperties = (params = {}) => {
  try {
    posthog?.identify(params);
  } catch (error) {
    console.error('PostHog properties error:', error);
  }
};

// Get Mixpanel distinct ID
const getMixpanelDistinctID = () => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return '';
  }
  try {
    return mixpanel?.get_distinct_id() || '';
  } catch (error) {
    console.error('Mixpanel ID error:', error);
    return '';
  }
};

const MixpanelEvent = (eventName, properties = {}) => {
  try {
    mixpanel?.track(eventName, properties);
  } catch (error) {
    console.error('Mixpanel event error:', error);
  }
};

// Export the Mixpanel utilities
export {
  initMixpanel,
  sendPageChangeEvent,
  sendEvent,
  identifyUser,
  setMixpanelUserProperties,
  getMixpanelDistinctID,
  MixpanelEvent,
  initPostHog,
  sendPostHogEvent,
  setPostHogUserProperties,
};
