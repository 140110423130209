export const routeTitles = {
  '/': 'Home',
  '/news': 'News Feed',
  '/duel': 'Duel',
  '/chat': 'Chat',
  '/pyq': 'PYQs',
  '/practice': 'Practice',
  '/accountDetails': 'Account Details',
  '/login': 'Login',
  '/signup': 'Sign Up',
  '/onboarding': 'Onboarding',
  '/profile': 'My Profile',
  '/chat/:id': 'New Chat',
  '/edit-profile':'Edit Profile'
};
