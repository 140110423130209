import React, { useEffect, useState } from 'react';
import { Text, Image, Modal, Stack, Button, Paper, Flex } from '@mantine/core';
import { Google, LineOrLine, Whatsapp } from '../../assets/images';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { SignInWithSupabasePostGoogleSignIn, SignInWithSupabasePostWhatsAppSignIn } from '../../utils/AuthUtils';
import { userStore } from '../../stores/userStore';
import { initOTPless } from '../../utils/initOtpless';
import axios from 'axios';

const SignUpPage = () => {
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const setUserData = userStore((state) => state.setUserData);

  const [opened, setOpened] = useState(false);

  const handleOtplessUser = (otplessUser) => {
    const identities = otplessUser.identities;
    if (identities && identities.length > 0) {
      let mobileItem = identities.find((item) => item.identityType === 'MOBILE' && item.identityValue !== null && item.identityValue !== undefined);
      if (mobileItem && mobileItem.identityValue) {
        SignInWithSupabasePostWhatsAppSignIn({
          name: mobileItem.name ?? '',
          number: mobileItem.identityValue ?? '',
          setUserData,
        });
      }
    } else {
      console.log('No identities found');
    }
    setUserData(localStorage.getItem('userData'));
    setTimeout(() => {
      const isNewUser = localStorage.getItem('new_user');
      if (isNewUser === 'true') {
        navigate('/signupdetails');
      } else {
        navigate('/news');
      }
    }, 2000);
  };

  const openModalAndInitializeOtpless = () => {
    setOpened(true);
    setTimeout(() => {
      initOTPless(handleOtplessUser);
    }, 300);
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      // Exchange the code for tokens
      const tokens = await exchangeCodeForTokens(codeResponse.code);
      // Here you can call your backend authentication function
      SignInWithSupabasePostGoogleSignIn(tokens.id_token, 'google', tokens.access_token, setUserData);

      // Navigate to the next screen after successful login
      setTimeout(() => {
        const isNewUser = localStorage.getItem('new_user');
        if (isNewUser === 'true') {
          navigate('/signupdetails');
        } else {
          navigate('/news');
        }
      }, 2000);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const exchangeCodeForTokens = async (code) => {
    const response = await axios.post('https://oauth2.googleapis.com/token', {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: window.location.origin,
      grant_type: 'authorization_code',
    });
    return response.data;
  };

  useEffect(() => {
    if (userData) {
      console.log('User Data', userData);
    }
  }, [userData]);

  return (
    <Stack
      spacing={0}
      align="center"
      style={{
        width: '100%',
        backgroundColor: 'white',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Paper
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderTopLeftRadius: '1.25em',
          borderTopRightRadius: '1.25em',
          marginTop: '-2em',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Stack
          spacing="xl"
          align="center"
          style={{
            width: '100%',
            padding: '32px 16px',
          }}
        >
          <Text
            fw={600}
            fz={'1.25em'}
            c={'#070519'}
            style={{
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Sign Up
          </Text>

          <Button
            h={'3.2em'}
            onClick={openModalAndInitializeOtpless}
            leftIcon={<Image src={Whatsapp} alt="WhatsApp Logo" width={24} height={24} />}
            fullWidth
            variant="outline"
            color="gray"
            radius="xl"
            size="lg"
            styles={(theme) => ({
              root: {
                backgroundColor: 'white',
                borderColor: theme.colors.gray[3],
                '&:hover': {
                  backgroundColor: theme.colors.gray[0],
                },
                boxShadow: '0px 1px 1px 0px #0000002B, 0px 0px 4px 0px #00000015',
                border: '1px solid #0000002B',
              },
              label: {
                color: theme.colors.gray[7],
              },
            })}
          >
            <Flex align="center" justify="center" gap="xs">
              <Image src={Whatsapp} alt="WhatsApp Logo" width={48} height={48} />
              <Text fw={400} fz={'0.9em'} c={'#747474'}>
                Login with WhatsApp
              </Text>
            </Flex>
          </Button>

          <Image src={LineOrLine} alt="Or Line" style={{ width: '100%' }} />

          <Button
            h={'3.2em'}
            fullWidth
            variant="outline"
            color="gray"
            styles={(theme) => ({
              root: {
                backgroundColor: 'white',
                borderColor: theme.colors.gray[3],
                '&:hover': {
                  backgroundColor: theme.colors.gray[0],
                },
                boxShadow: '0px 1px 1px 0px #0000002B, 0px 0px 4px 0px #00000015',
                border: '1px solid #0000002B',
              },
              label: {
                color: theme.colors.gray[7],
              },
            })}
            radius="xl"
            size="lg"
            onClick={() => handleGoogleLogin()}
          >
            <Flex align="center" justify="center" gap="xs">
              <Image src={Google} alt="Google Logo" width={48} height={48} />
              <Text fw={400} fz={'0.9em'} c={'#747474'}>
                Login With Google
              </Text>
            </Flex>
          </Button>

          <Text
            mt={'1.5em'}
            fw={500}
            fz={'0.9em'}
            c={'#747474'}
            align="center"
            role="button"
            onClick={() => navigate('/signup')} // Wrap navigate call in a function
          >
            By Signing Up , you agreee to our Privacy Policy and Terms of Service
            <Text size="sm" component="span" color="blue" underline>
              Learn More
            </Text>
          </Text>
        </Stack>
      </Paper>
      <Text
        mt={'1.5em'}
        fw={500}
        fz={'0.9em'}
        c={'#747474'}
        align="center"
        role="button"
        onClick={() => navigate('/login')}
        style={{ position: 'absolute', bottom: '20px' }}
      >
        Already Have an Account ?
        <Text
          size="sm"
          component="span"
          color="blue"
          underline
          style={{
            cursor: 'pointer',
          }}
        >
          Login
        </Text>
      </Text>
      <Modal size={'26em'} opened={opened} onClose={() => setOpened(false)} title="Login with WhatsApp" centered>
        <div id="otpless-login-page"></div>
      </Modal>
    </Stack>
  );
};

export default SignUpPage;
