import React from 'react';
import { Switch, Group, rem } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import { IconMoonFilled } from '@tabler/icons-react';

const ThemeToggle = () => {
  const { colorScheme, toggleTheme, colors } = useTheme();

  const sunIcon = <IconMoonFilled style={{ width: rem(16), height: rem(16) }} stroke={2.5} color={'white'} />;
  const moonIcon = <IconMoonFilled style={{ width: rem(16), height: rem(16) }} stroke={2.5} color={'white'} />;

  return (
    <Group position="center" spacing="xs" mr={16}>
      <Switch
        checked={colorScheme === 'dark'}
        onChange={toggleTheme}
        size="lg"
        offLabel={<span style={{ color: colors.text, marginLeft: rem(2) }}>Dark</span>}
        onLabel={<span style={{ color: colors.text, marginLeft: rem(4) }}>Dark</span>}
        styles={{
          track: {
            backgroundColor: colorScheme === 'dark' ? colors.background : colors.cardBackground,
            border: `1px solid ${colors.primary}`,
            width: rem(60),
            height: rem(30),
            borderRadius: rem(30),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          thumb: {
            width: rem(26),
            height: rem(26),
            backgroundColor: '#92A1FF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
          },
        }}
        thumbIcon={colorScheme === 'dark' ? moonIcon : sunIcon} // Center the icon inside the thumb
      />
    </Group>
  );
};

export default ThemeToggle;
