import React from 'react';
import PropTypes from 'prop-types';
import { IconArrowRight, IconCalendar } from '@tabler/icons-react';
import { BlueCrown, CrownGeneral, RedCrown, Versus } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const DuelHistoryCard = ({ duel, cardIndex }) => {
  const {
    created_at: date,
    id: duelNumber,
    user_info: { name: userName, elo: userElo },
    opponent_info: { name: opponentName, elo: opponentElo },
    eloScore,
    user_correct_answer: correctAnswer,
    opponent_correct_answer: opponentCorrectAnswer,
    quizScore,
  } = duel;

  const eloChange = eloScore - userElo;
  const resultStatus = correctAnswer > opponentCorrectAnswer ? 'VICTORY' : correctAnswer < opponentCorrectAnswer ? 'DEFEAT' : 'DRAW';

  const getHeaderStyles = (status) => {
    switch (status) {
      case 'VICTORY':
        return { backgroundColor: '#61BD85', color: '#fff', text: 'VICTORY' };
      case 'DEFEAT':
        return { backgroundColor: '#FFEDED', color: '#D95353', text: 'DEFEAT' };
      case 'DRAW':
        return { backgroundColor: '#d5ddf9', color: '#747474', text: 'DRAW' };
      default:
        return { backgroundColor: '#61BD85', color: '#fff', text: 'VICTORY' };
    }
  };
  const formatEloChange = (eloChange) => {
    return Math.abs(eloChange).toString();
  };

  const getScoreChangeDetails = (status, change) => {
    let sign = change >= 0 ? '+' : '-';

    switch (status) {
      case 'VICTORY':
        return { sign: '+', color: '#38915B' };
      case 'DRAW':
        return { sign: '+', color: '#4A90E2' };
      case 'DEFEAT':
        return { sign: '-', color: '#D95353' };
      default:
        return { sign, color: '#38915B' };
    }
  };

  const headerStyles = getHeaderStyles(resultStatus);
  const scoreChangeDetails = getScoreChangeDetails(resultStatus, eloChange);
  const getInitials = (name) => name.charAt(0).toUpperCase();
  const navigate = useNavigate();

  const handleTabClick = () => {
    console.log(duel.category, 'duel.category');
    MixpanelEvent('duel_review_clicked', {
      category: duel.category,
      source: 'duel_homepage',
      mode: 'online',
    });
    sendPostHogEvent('duel_review_clicked', {
      category: duel.category,
      source: 'duel_homepage',
      mode: 'online',
    });
    navigate('/duel-review', { state: { duel } });
  };
  return (
    <div style={styles.card}>
      <div style={{ ...styles.header, backgroundColor: headerStyles.backgroundColor }}>
        <div style={{ ...styles.headerText, color: headerStyles.color }}>{headerStyles.text}</div>
        <div style={{ ...styles.headerScore, color: headerStyles.color }}>
          <IconCalendar style={{ ...styles.icon, color: headerStyles.color }} />
          <span>{correctAnswer}</span> - <span>{opponentCorrectAnswer}</span>
          <IconCalendar style={{ ...styles.icon, color: headerStyles.color }} />
        </div>
        <div style={{ ...styles.headerText, color: headerStyles.color }}>Duel #{cardIndex}</div>
      </div>

      <div style={styles.playersContainer}>
        <div style={styles.player}>
          <div style={styles.avatar}>
            <span style={styles.avatarText}>{getInitials(userName)}</span>
          </div>
          <div>
            <div style={{ ...styles.playerName, justifyContent: 'start' }}>{userName.length > 10 ? `${userName.substring(0, 10)}...` : userName}</div>

            <div style={{ ...styles.playerScore, justifyContent: 'start' }}>
              <img src={BlueCrown} style={styles.crownIcon} alt="Blue Crown" />
              <span>{userElo}</span>
            </div>
          </div>
        </div>

        <img src={Versus} alt="Versus" style={styles.vsImage} />

        <div style={styles.player}>
          <div>
            <div style={{ ...styles.playerName, justifyContent: 'end' }}>
              {opponentName.length > 10 ? `${opponentName.substring(0, 10)}...` : opponentName}
            </div>
            <div style={{ ...styles.playerScore, justifyContent: 'end' }}>
              <span>{opponentElo}</span>
              <img src={RedCrown} style={styles.crownIcon} alt="Red Crown" />
            </div>
          </div>
          <div style={styles.avatar}>
            <span style={styles.avatarText}>{getInitials(opponentName)}</span>
          </div>
        </div>
      </div>

      <div style={styles.bottomContainer}>
        <div style={{ ...styles.scoreChange, color: scoreChangeDetails.color }}>
          {scoreChangeDetails.sign}
          <img src={CrownGeneral} style={styles.crownIcon} alt="Crown General" />
          <span>{formatEloChange(quizScore)}</span>
        </div>

        <div onClick={handleTabClick} style={styles.arrowContainer}>
          <IconArrowRight style={{ color: '#6374FA', width: '24px', height: '24px' }} />
        </div>
      </div>
    </div>
  );
};

DuelHistoryCard.propTypes = {
  duel: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    user_info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      elo: PropTypes.number.isRequired,
    }).isRequired,
    opponent_info: PropTypes.shape({
      name: PropTypes.string.isRequired,
      elo: PropTypes.number.isRequired,
    }).isRequired,
    eloScore: PropTypes.number.isRequired,
    user_correct_answer: PropTypes.number.isRequired,
    opponent_correct_answer: PropTypes.number.isRequired,
    quizScore: PropTypes.number.isRequired, // New addition
  }).isRequired,
};

// Custom styles
const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    width: '100%',
    marginTop: '1rem',
    border: `1px solid #E5E7EB`,
    backgroundColor: '#fff',
    marginBottom: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '16px 16px 0 0',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  headerScore: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '14px',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  playersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  playerName: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  playerScore: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '14px',
  },
  vsContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'lightgray',
    color: 'white',
    padding: 8,
    borderRadius: '24px',
  },
  vsText: {
    color: 'white',
    fontWeight: 'bold',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  scoreChange: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: '#E8F1FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarText: {
    color: '#6374FA',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  crownIcon: {
    width: '20px',
    height: '20px',
  },
};

export default DuelHistoryCard;
