import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import '@mantine/core/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import branch from 'branch-sdk';

function Main() {
  useEffect(() => {
    try {
      branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
        if (err) {
          console.error('Branch initialization failed:', err);

          if (err.code === 'NO_CONNECTION' || err.message.includes('Request blocked by client, probably adblock')) {
            if (!sessionStorage.getItem('branch_redirected')) {
              sessionStorage.setItem('branch_redirected', 'true');
              window.location.href = window.location.origin;
            }
          }
        }
        console.log('Branch initialized successfully');
      });
    } catch (error) {
      console.error('Error initializing branch:', error);
      window.location.reload();
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

// Register the service worker
// serviceWorkerRegistration.register();
