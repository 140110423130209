import React from 'react';
import AutoScrollLists from './AutoScrollLists';
// import ComingSoonPage from '../../molecules/ComingSoon';

export default function PyqPage() {
  return (
    <div>
      {/* <ComingSoonPage /> */}
      <AutoScrollLists />
    </div>
  );
}
