import React, { useState } from 'react';
import { BookChatAvatar, Copy, DisLike, DisLikeFill, Like, LikeFill, NcertAiAvatar } from '../../../assets/images';
import { Divider, Notification, Stack } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import LoadingGif from '../TutorChat/loading.gif';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const CitationButton = ({ nodeIndex, onClick }) => (
  <span onClick={onClick} style={styles.citationButton}>
    {`[${nodeIndex}]`}
  </span>
);

const CustomMarkdownRenderer = ({ content, citationOnClick }) => {
  const processContent = (text) => {
    const parts = [];
    const citationRegex = /\[ Citation (\d+) \]/g;
    let lastIndex = 0;
    let match;

    while ((match = citationRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push({
          type: 'text',
          content: text.slice(lastIndex, match.index),
        });
      }

      parts.push({
        type: 'citation',
        nodeIndex: parseInt(match[1], 10),
      });

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      parts.push({
        type: 'text',
        content: text.slice(lastIndex),
      });
    }

    return parts;
  };

  const inlineTextStyle = {
    display: 'inline',
  };

  const components = {
    p: ({ children }) => (
      <p style={inlineTextStyle}>
        {React.Children.map(children, (child) => {
          if (typeof child === 'string') {
            const parts = processContent(child);
            return parts.map((part, index) => (
              <React.Fragment key={index}>
                {part.type === 'text' ? (
                  <span>{part.content}</span>
                ) : (
                  <CitationButton nodeIndex={part.nodeIndex} onClick={() => citationOnClick(part.nodeIndex)} />
                )}
              </React.Fragment>
            ));
          }
          return child;
        })}
      </p>
    ),
    li: ({ children }) => (
      <li>
        {React.Children.map(children, (child) => {
          if (typeof child === 'string') {
            const parts = processContent(child);
            return parts.map((part, index) => (
              <React.Fragment key={index}>
                {part.type === 'text' ? (
                  <span>{part.content}</span>
                ) : (
                  <CitationButton nodeIndex={part.nodeIndex} onClick={() => citationOnClick(part.nodeIndex)} />
                )}
              </React.Fragment>
            ));
          }
          return child;
        })}
      </li>
    ),
  };

  return (
    <ReactMarkdown rehypePlugins={[rehypeKatex]} components={components}>
      {content}
    </ReactMarkdown>
  );
};

export const AnswerComponent = ({ book, item, index, streamEnd, callBack, isLast, flowType, resoureCallBack }) => {
  const { copy, copied } = useClipboard();
  const [feedbackStates, setFeedbackStates] = useState({});

  const handleCopy = () => {
    copy(item.content);
    MixpanelEvent('chat_response_react', {
      type: 'copy',
      chat_type: 'closed_book',
      source: 'ask_book',
      book: book.book_name,
      book_id: book.book_id,
      book_category: flowType,
    });
    sendPostHogEvent('chat_response_react', {
      type: 'copy',
      chat_type: 'closed_book',
      source: 'ask_book',
      book: book.book_name,
      book_id: book.book_id,
      book_category: flowType,
    });
  };

  // const handleDislike = () => {
  //     setFeedbackStates((prevState) => ({
  //         ...prevState,
  //         [index]: prevState[index] === 'dislike' ? null : 'dislike',
  //     }));
  //     if (callBack) callBack('dislike', index);
  // };

  // const handleLike = () => {
  //     setFeedbackStates((prevState) => ({
  //         ...prevState,
  //         [index]: prevState[index] === 'like' ? null : 'like',
  //     }));
  // };

  return (
    <>
      {copied && (
        <Notification title="Copied" style={{ position: 'absolute', top: '3%', zIndex: 100, left: '5%' }}>
          The content has been copied to your clipboard.
        </Notification>
      )}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F3F3F3',
          borderRadius: '12px',
          padding: '1em',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-10px',
            left: '0px',
          }}
        >
          <img src={flowType === 'ncert' ? NcertAiAvatar : BookChatAvatar} alt="Ai Avatar" style={{ height: '2.5em', width: '2.5em' }} />
        </div>
        <div style={{ marginTop: '1.5em' }}>
          <CustomMarkdownRenderer content={item.content} citationOnClick={resoureCallBack} />
          {!streamEnd && isLast && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={LoadingGif} alt="Loader" style={{ width: '15%', height: '100%', marginLeft: '30px' }} />
            </div>
          )}
        </div>
        {streamEnd && (
          <>
            <Divider my="md" />
            <Stack
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <img
                src={Copy}
                alt="Copy icon"
                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                onClick={handleCopy}
              />
              {/* <img
                                src={feedbackStates[index] === 'dislike' ? DisLikeFill : DisLike}
                                alt="Dislike icon"
                                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                                onClick={handleDislike}
                            />
                            <img
                                src={feedbackStates[index] === 'like' ? LikeFill : Like}
                                alt="Like icon"
                                style={{ width: '20px', height: '20px', cursor: 'pointer', marginRight: '0.6em' }}
                                onClick={handleLike}
                            /> */}
            </Stack>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  // citationButton: {
  //     display: 'inline-flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     height: "20px",
  //     width: "20px",
  //     border: '1px solid #92A1FF',
  //     borderRadius: '2px',
  //     backgroundColor: '#F4FAFF',
  //     fontSize: '0.75em',
  //     fontFamily: 'Poppins',
  //     cursor: 'pointer',
  //     verticalAlign: 'middle',
  //     marginRight: "8px",
  //     color: '#3C45CD'

  // },
  citationButton: {
    fontSize: '0.75em',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    color: '#3C45CD',
  },
  markdownContainer: {},
};
