import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import './LatexRenderer.css';

const mathJaxConfig = {
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
    packages: { '[+]': ['base', 'ams', 'array', 'bbox'] }, // 'bbox' for \boxed, 'array' for arrays
  },
};

function removeBoxed(text) {
  const boxedPattern = /\\boxed\{([^}]*)\}/g;
  return text.replace(boxedPattern, '$1');
}

function modifyArrayString(inputString) {
  let modifiedString = inputString;

  const beginArrayPattern = /(\\begin\{array\}\{[^}]+\})\s*\\hline/;
  modifiedString = modifiedString.replace(beginArrayPattern, '$1');

  const hlinePattern = /\\hline/g;
  modifiedString = modifiedString.replace(hlinePattern, ' \\\\ \\hline ');

  return modifiedString;
}

export const LatexRenderer = ({ content }) => {
  let cleanContent = removeBoxed(content);
  cleanContent = cleanContent.replace(/\\but/g, '\\text{ but }');
  cleanContent = modifyArrayString(cleanContent);
  const environmentPlaceholders = {};
  let counter = 0;

  const preservedContent = cleanContent.replace(/(\\\[.*?\\\])/gs, (match) => {
    const placeholder = `###LATEX_ENVIRONMENT_${counter}###`;
    environmentPlaceholders[placeholder] = match;
    counter++;
    return placeholder;
  });

  const paragraphs = preservedContent.split(/\n(?!###LATEX_ENVIRONMENT_\d+###)/);

  const finalParagraphs = paragraphs.map((part) => {
    return part.replace(/###LATEX_ENVIRONMENT_\d+###/g, (match) => {
      return environmentPlaceholders[match];
    });
  });

  return (
    <MathJaxContext config={mathJaxConfig}>
      <div className="responsive-latex">
        {finalParagraphs.map((paragraph, index) => (
          <Paragraph key={index} content={paragraph} />
        ))}
      </div>
    </MathJaxContext>
  );
};

export const Paragraph = ({ content }) => {
  const parts = content.split(/(\$\$.*?\$\$|\$.*?\$|\\\[.*?\\\])/gs);
  return (
    <p className="latex-paragraph">
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {isMathExpression(part) ? <MathJaxComponent mathExpression={part} /> : <span className="latex-text">{part}</span>}
        </React.Fragment>
      ))}
    </p>
  );
};

const isMathExpression = (part) => {
  const hasMathDelimiters = part.startsWith('$') || part.startsWith('\\[') || part.startsWith('$$');
  const containsMathCommands = /\\(frac|sqrt|pm|int|sum|prod|lim|theta|alpha|beta|gamma|delta|epsilon|pi|sigma|lambda|rho|tau|phi|psi|omega)/.test(
    part
  );
  return hasMathDelimiters || containsMathCommands;
};

const MathJaxComponent = ({ mathExpression }) => {
  if (!mathExpression.startsWith('$') && !mathExpression.startsWith('\\[') && !mathExpression.startsWith('$$')) {
    mathExpression = `\\(${mathExpression}\\)`;
  } else if (mathExpression.startsWith('$$') && mathExpression.endsWith('$$')) {
    mathExpression = `\\[${mathExpression.slice(2, -2)}\\]`;
  } else if (mathExpression.startsWith('$') && mathExpression.endsWith('$')) {
    mathExpression = `\\(${mathExpression.slice(1, -1)}\\)`;
  } else if (mathExpression.startsWith('\\[') && mathExpression.endsWith('\\]')) {
    mathExpression = `\\[${mathExpression.slice(2, -2)}\\]`;
  }

  return (
    <MathJax dynamic hideUntilTypeset={'first'}>
      <span className="latex-math">{mathExpression}</span>
    </MathJax>
  );
};
