import React, { useState } from 'react';
import DuelOnline from './DuelOnline';
import Tournaments from './Tournaments';
import DuelWithFriends from './DuelWithFriends';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export default function DuelPage() {
  const [activeTab, setActiveTab] = useState('duelOnline');
  const windowHeight = window.innerHeight;
  const isShortWindow = windowHeight < 900 && window.innerWidth < 400;
  const fontSize = isShortWindow ? '15px' : '15px';

  const getTabBorderStyle = (tab) => ({
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: activeTab === tab ? '1px solid blue' : '1px solid transparent',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: 'none',
    outline: 'none',
    color: activeTab === tab ? 'black' : 'gray',
  });
  const handleTabClick = (tab) => {
    const isDefaultTab = tab === 'online';
    MixpanelEvent('duel_header_selection', { tab: tab, default_tab: isDefaultTab });
    sendPostHogEvent('duel_header_selection', { tab: tab, default_tab: isDefaultTab });
  };

  return (
    <div>
      {/* Tab Navigation */}
      <div style={{ display: 'flex', marginTop: '4rem', borderBottom: '1px solid gray', fontSize: fontSize, justifyContent: 'space-evenly' }}>
        <button
          onClick={() => {
            setActiveTab('duelOnline');
            handleTabClick('online');
          }}
          style={getTabBorderStyle('duelOnline')}
        >
          Duel Online
        </button>
        <button
          onClick={() => {
            setActiveTab('tournaments');
            handleTabClick('tournaments');
          }}
          style={getTabBorderStyle('tournaments')}
        >
          Tournaments
        </button>
        <button
          onClick={() => {
            setActiveTab('duelWithFriends');
            handleTabClick('friends');
          }}
          style={getTabBorderStyle('duelWithFriends')}
        >
          Duel with Friends
        </button>
      </div>

      {/* Tab Content */}
      <div style={{ padding: '1rem' }}>
        {activeTab === 'duelOnline' && <DuelOnline />}
        {activeTab === 'tournaments' && <Tournaments />}
        {activeTab === 'duelWithFriends' && <DuelWithFriends />}
      </div>
    </div>
  );
}
