import React, { useState, useEffect } from 'react';
import { TextInput, Button, Text, Box, Stack, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../stores/userStore';

const AccountDetailsForm = () => {
  const navigate = useNavigate();
  const setUserData = userStore((state) => state.setUserData);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userData = userStore((state) => state.userData);

  useEffect(() => {
    if (userData) {
      const fullName = userData.name || '';
      const nameParts = fullName.split(' ');
      setFirstName(nameParts[0] || '');
      setLastName(nameParts.slice(1).join(' ') || '');
      setPhoneNumber(userData.mobileNumber || '');
    }
  }, [userData]);

  const isFormValid = () => {
    if (firstName.trim() === '') {
      setError('Please enter your first name');
      return false;
    }
    if (lastName.trim() === '') {
      setError('Please enter your last name');
      return false;
    }
    if (phoneNumber.trim() === '') {
      setError('Please enter your phone number');
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    setError('');
    if (!isFormValid()) {
      return;
    }
  
    const userProfileData = {
      user_id: userData.user,
      name: `${firstName.trim()} ${lastName.trim()}`,
      phone: phoneNumber.trim(),
    };
  
    setIsSubmitting(true);
  
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/update-profile/`
  
      const res = await fetch(apiUrl, {
        method: 'PATCH',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.accessToken}` // Include the access token
        },
        body: JSON.stringify(userProfileData),
      });
  
      const json = await res.json();  
      if (res.ok && json.data) {
        localStorage.setItem('is_signup_newUser', 'false');
        setUserData({
          ...userData,
          name: json.data.name,
          mobileNumber: json.data.phone,
          username: json.data.username,
        });
        window.location.reload()
        navigate('/news');
      } else {
        setError(json.error || json.detail || "We're unable to update your profile at the moment. Please try again later.");
      }
    } catch (err) {
      setError("We're unable to update your profile at the moment. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <Box p="xl" style={{ maxWidth: 400, margin: 'auto', paddingTop: 50 }}>
      <Stack spacing="md">
        <Text align="center" fw={700} size="lg" style={{ fontSize: 22 }}>
          Account details
        </Text>
        <Text align="center" size="sm" color="gray">
          Update your profile details below
        </Text>

        <TextInput
          label="First Name"
          placeholder="Enter your first name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          styles={{
            input: {
              height: '50px',
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
              fontSize: '16px',
              paddingLeft: '15px',
            },
            label: {
              fontWeight: '500',
              fontSize: '14px',
              color: '#333333',
              marginBottom: '8px',
            },
          }}
        />

        <TextInput
          label="Last Name"
          placeholder="Enter your last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          styles={{
            input: {
              height: '50px',
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
              fontSize: '16px',
              paddingLeft: '15px',
            },
            label: {
              fontWeight: '500',
              fontSize: '14px',
              color: '#333333',
              marginBottom: '8px',
            },
          }}
        />

        <TextInput
          label={<Group><Text>Phone Number</Text></Group>}
          placeholder="+91 00000 00000"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          styles={{
            input: {
              height: '50px',
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
              fontSize: '16px',
              paddingLeft: '15px',
            },
            label: {
              fontWeight: '500',
              fontSize: '14px',
              color: '#333333',
              marginBottom: '8px',
            },
          }}
        />

        {error && (
          <Text color="red" size="sm" align="center">
            {error}
          </Text>
        )}

        <Button
          fullWidth
          mt="lg"
          size="lg"
          onClick={onSubmit}
          disabled={isSubmitting || !firstName || !lastName || !phoneNumber}
          styles={{
            root: {
              height: '55px',
              borderRadius: '30px',
              fontSize: '18px',
              backgroundColor: !firstName || !lastName || !phoneNumber ? '#E0E0E0' : 'black',
              color: '#9E9E9E',
            },
          }}
        >
          {isSubmitting ? 'Updating...' : 'Update Profile'}
        </Button>
      </Stack>
    </Box>
  );
};

export default AccountDetailsForm;
