import React, { useState } from 'react';
import { Card, Text, Group, ActionIcon, Button, Image, Flex, Box } from '@mantine/core';
import { NEWS_SOURCE } from '../../constants/newsData';
import { TheHinduLogo, IndianExpressLogo, NewsLikeIcon, NewsBookMarkIcon, NewsTagDropDownIcon, ArrowRight2, doubleTik } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';
import dayjs from 'dayjs';

const splitTitle = (title, maxLength = 15) => {
  if (title.length <= maxLength) return [title];
  const firstLine = title.slice(0, maxLength);
  const secondLine = title.slice(maxLength);
  return [firstLine, secondLine];
};

const NewsListItem = ({ title, likes, bookmarks, newsItem, imageSrc, tags = [], onClick, newsType, created_at, isLastItem, readIds }) => {
  const { colors } = useTheme(); // Access the centralized theme colors
  const [firstLine, secondLine] = splitTitle(title);
  const [showTags, setShowTags] = useState(false);
  const formattedDate = created_at ? dayjs(created_at).format('DD MMM YYYY') : null;
  const isRead = readIds?.includes(newsItem.id);

  return (
    <Card
      withBorder
      radius="0.5rem"
      padding="1rem"
      mt="0.5rem"
      onClick={onClick}
      style={{
        ...styles(colors).card,
        marginBottom: isLastItem ? '45%' : '0',
      }}
    >
      <Group noWrap>
        <Image src={newsType === NEWS_SOURCE.THE_HINDU ? TheHinduLogo : IndianExpressLogo} alt="News Logo" style={styles(colors).image} />
        <div style={{ flex: 1 }}>
          <Text weight={600} size="1rem" mb="0.5rem" color={colors.text} lh="1.2">
            {firstLine && <>{firstLine}</>}
            {secondLine && <>{secondLine}</>}
          </Text>
          <Group position="apart" align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Group spacing="0.3rem">
              <Group spacing={0} style={{ gap: '0.1rem' }}>
                <Text size="0.75rem" color={colors.subtleText}>
                  {likes}
                </Text>
                <ActionIcon variant="transparent" style={{ marginLeft: '0.1rem' }}>
                  <Image src={NewsLikeIcon} alt="Like" style={styles(colors).iconImage} />
                </ActionIcon>
              </Group>
              <Group spacing={0} style={{ gap: '0.1rem' }}>
                <Text size="0.75rem" color={colors.subtleText}>
                  {bookmarks}
                </Text>
                <ActionIcon variant="transparent" style={{ marginLeft: '0.1rem' }}>
                  <Image src={NewsBookMarkIcon} alt="Bookmark" style={styles(colors).iconImage} />
                </ActionIcon>
              </Group>
            </Group>

            <Text weight={500} size="0.75rem" color={colors.subtleText}>
              {formattedDate}
            </Text>
          </Group>
        </div>
      </Group>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
        <Flex direction="column">
          {showTags &&
            tags.map((item, index) => (
              <Box key={index} style={styles(colors).tagBox}>
                <Text style={styles(colors).text}>{item}</Text>
              </Box>
            ))}
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowTags(!showTags);
            }}
            style={styles(colors).tagsButton}
            variant="subtle"
            color={colors.subtleText}
            size="xs"
            compact
          >
            <Group spacing="0.2rem" style={styles(colors).tagsGroup}>
              <Text size="0.75rem" style={{ fontWeight: 'bold', color: colors.subtleText }}>
                {showTags ? 'SHOW LESS' : 'VIEW TAGS'}
              </Text>
              <Image
                src={NewsTagDropDownIcon}
                alt="Dropdown"
                style={{
                  ...styles(colors).iconImage,
                  transform: showTags ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s ease',
                }}
              />
            </Group>
          </Button>
        </Flex>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {isRead && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <img src={doubleTik} alt="Read" style={{ width: '16px', height: '16px' }} />
              <Text size="xs" color={colors.subtleText}>
                Read
              </Text>
            </div>
          )}
          <Button variant="subtle" size="xs" compact>
            <img src={ArrowRight2} alt="Arrow" style={styles(colors).iconImage} />
          </Button>
        </div>
      </div>
    </Card>
  );
};

const styles = (colors) => ({
  card: {
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: 'Poppins',
    backgroundColor: colors.cardBackground,
    borderColor: colors.borderColor,
  },
  image: {
    width: '4rem',
    height: 'auto',
  },
  text: {
    textAlign: 'center',
    fontSize: '12px',
    color: colors.text,
    padding: '4px',
  },
  tagBox: {
    width: '100%',
    maxWidth: '20rem',
    borderStyle: 'solid',
    borderColor: colors.borderColor,
    borderWidth: '1px',
    borderRadius: '3px',
    marginBottom: '0.3em',
    boxSizing: 'border-box',
    backgroundColor: colors.cardBackground,
  },
  tagsButton: {
    marginLeft: '-10px',
    alignSelf: 'start',
  },
  tagsGroup: {
    borderBottom: `1px solid ${colors.borderColor}`,
  },
  iconImage: {
    width: '1rem',
    height: '1rem',
  },
  readStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: colors.subtleText,
    fontSize: '12px',
  },
});
export default NewsListItem;
