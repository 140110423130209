import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Text, Stack, Box, Textarea, ScrollArea, Image, Container } from '@mantine/core';
import { ArrowLeft, SendMsg, ArrowRight2, BookChatAvatar, NcertAiAvatar, ChapterBook, BookSection, BookSubSection, Download } from '../../../assets/images';
import Markdown from 'react-markdown';
import { callPOSTAPI } from '../../../utils/NetworkUtil';
import ReconnectingWebSocket from 'reconnecting-websocket';
import QuestionsComp from '../QuestionsComp';
import { AnswerComponent } from './AnswerComponent';
import LoadingGif from '../TutorChat/loading.gif';
import { userStore } from '../../../stores/userStore';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const BookChat = () => {
  const location = useLocation();
  const { book, flowType } = location.state || {};
  const navigate = useNavigate();
  const [bookData, setBookData] = useState([]);
  const [initialUI, setInitialUI] = useState({
    loader: true,
    message: '',
    suggestions: [],
  });
  const wsRef = useRef(null);
  const [webSocket, setWebSocket] = useState(null);
  const [expression, setExpression] = useState('');
  const [chatData, setChatData] = useState({ messages: [] });
  const [isStreamEnd, setStreamEnd] = useState(false);
  const scrollAreaRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [citationList, setCitationList] = useState([]);
  const [isResourcesShown, setResourcesShown] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [resourceItems, setResourceItems] = useState([]);
  const [aiLoading, setAiLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const { chatHistoryId, chatIndex } = location.state || {};
  const [historyData, setHistoryData] = useState(null);
  const bookName = book || historyData;
  const [isHistoricalChat, setIsHistoricalChat] = useState(false);

  const chatIdRef = useRef(null);
  const [promptType, setPromptType] = useState({
    isTypedPrompt: false,
    isHybridPrompt: false,
  });
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;

  const isBookClass = bookData.metadata && bookData.metadata.class ? `Class ${bookData.metadata.class} - ` : '';
  const [isOngoingChat, setOngoingChat] = useState(false);

  const getFirstChat = async () => {
    try {
      await callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/firstbookchat/`,
        { bookname: book.book_id },
        (resp) => {
          if (resp && resp.data && resp.data.message) {
            setInitialUI((prevState) => ({
              ...prevState,
              loader: false,
              message: resp.data.message,
            }));
            getInitialSuggestions();
          }
        },
        (err) => {
          getInitialSuggestions();
        }
      );
    } catch (err) {
      getInitialSuggestions();
    }
  };

  const getInitialSuggestions = async () => {
    try {
      await callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/bookchatsuggs/`,
        { bookname: book.book_id },
        (resp) => {
          if (resp && resp.data && resp.data.Suggestions) {
            setInitialUI((prevState) => ({
              ...prevState,
              loader: false,
              suggestions: resp.data.Suggestions,
            }));
          } else {
            setInitialUI((prevState) => ({
              ...prevState,
              loader: false,
            }));
          }
        },
        (err) => {
          console.error('API error in getInitialSuggestions:', err);
          setInitialUI((prevState) => ({
            ...prevState,
            loader: false,
          }));
        }
      );
    } catch (err) {
      console.error('Error in getInitialSuggestions:', err);
      setInitialUI((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  };

  useEffect(() => {
    if (book && flowType && !chatHistoryId) {
      setBookData(book);
      getFirstChat();
    } else if (book) {
      setBookData(book);
      setInitialUI((prev) => ({ ...prev, loader: false }));
    }
  }, [book, flowType, chatHistoryId]);

  useEffect(() => {
    if (!bookName) return;
    if (!userId) return;

    const socketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/asyncai/bookchat-socket/`;
    const ws = new ReconnectingWebSocket(socketUrl);
    wsRef.current = ws;
    setWebSocket(ws);
    ws.onopen = () => {
      if (ws && ws.readyState && ws.readyState === 1) {
        callInitialRequest(ws);
      }
    };

    ws.onmessage = (event) => {
      const responseData = JSON.parse(event.data);

      if (responseData) {
        setAiLoading(false);

        const message = responseData.message || '';
        const mimeType = responseData['mime-type'] || responseData.mimeType;

        if (message === 'stream_end') {
          setStreamEnd(true);
          setOngoingChat(false);
          return;
        }

        let contentToAppend = message;
        if (mimeType === 'citation') {
          const citationData = JSON.parse(message);
          contentToAppend = `[ Citation ${citationData.node_index} ]`;
          setCitationList((prevCitations) => [...prevCitations, JSON.parse(message)]);
        }

        setChatData((prevData) => {
          const lastMessage = prevData.messages[prevData.messages.length - 1];

          if (lastMessage?.role === 'assistant') {
            const updatedMessages = prevData.messages.map((msg, idx) =>
              idx === prevData.messages.length - 1
                ? {
                    ...msg,
                    content: mimeType === 'citation' ? msg.content + ' ' + contentToAppend : msg.content + ' ' + contentToAppend,
                  }
                : msg
            );
            return { ...prevData, messages: updatedMessages };
          } else {
            // If no assistant message, add a new one
            return {
              ...prevData,
              messages: [
                ...prevData.messages,
                {
                  role: 'assistant',
                  content: contentToAppend,
                  guardrail: responseData.guardrail,
                  latex: responseData.latex,
                  mimeType: mimeType,
                },
              ],
            };
          }
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error ', error);
    };
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [bookName, expression]);

  const callInitialRequest = (socket) => {
    if (socket && socket.readyState && socket.readyState === 1) {
      if (expression !== null && expression !== '') {
        const payload = createPayload();
        socket.send(JSON.stringify(payload));
      }
    }
  };

  const createPayload = (messageToSend = expression) => {
    const payload = {
      bookname: bookData.book_name,
      query: messageToSend,
      expression: 'the white hat',
    };
    return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null));
  };

  const handleSendMessage = async (messageToSend = expression, chatType) => {
    if (!messageToSend || messageToSend.trim() === '') {
      return;
    }
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      setStreamEnd(false);
      setAiLoading(true);
      setOngoingChat(true);
      const payload = createPayload(messageToSend);
      webSocket.send(JSON.stringify(payload));
      setChatData((prevData) => ({
        ...prevData,
        messages: [
          ...(prevData?.messages || []),
          {
            role: 'user',
            content: messageToSend,
          },
        ],
      }));

      if (chatType === 'preprompt') {
        MixpanelEvent('chat_start', {
          source: 'ask_a_book',
          book: isHistoricalChat ? bookData.book_id : bookData.book_name,
          book_category: flowType,
          type: 'preprompt',
        });
        sendPostHogEvent('chat_start', {
          source: 'ask_a_book',
          book: isHistoricalChat ? bookData.book_id : bookData.book_name,
          book_category: flowType,
          type: 'preprompt',
        });
        setPromptType({ isHybridPrompt: true });
      }

      if (chatType === 'typed') {
        MixpanelEvent('chat_start', {
          source: 'ask_a_book',
          book: isHistoricalChat ? bookData.book_id : bookData.book_name,
          book_category: flowType,
          type: 'typed',
        });
        sendPostHogEvent('chat_start', {
          source: 'ask_a_book',
          book: isHistoricalChat ? bookData.book_id : bookData.book_name,
          book_category: flowType,
          type: 'typed',
        });
        setPromptType({ isTypedPrompt: true });
      }

      callHistoryApi(messageToSend);
      setExpression('');
      setUserMessage('');
    }
  };

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('.mantine-ScrollArea-viewport');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (isStreamEnd) {
      scrollToBottom();
    }
  }, [chatData, isStreamEnd]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resourcesHandler = async (index) => {
    let selectedResource;
    selectedResource = citationList.find((item) => item.node_index === index);

    // If not found in citationList, check in the current message's locations
    if (!selectedResource && chatData?.messages) {
      const messageWithLocation = chatData.messages.find((msg) => msg.locations && msg.locations[index]);
      if (messageWithLocation?.locations[index]) {
        selectedResource = {
          node_index: index,
          chapter: messageWithLocation.locations[index].chapter,
          chapter_num: messageWithLocation.locations[index].chapter_num,
          section: messageWithLocation.locations[index].section,
          subsection: messageWithLocation.locations[index].subsection,
        };
      }
    }

    const eventData = {
      chat_id: chatIdRef.current,
      book_name: isHistoricalChat ? bookData.book_name : book.book_name,
      reference_num: index,
    };

    MixpanelEvent('chat_book_reference_clicked', eventData);
    sendPostHogEvent('chat_book_reference_clicked', eventData);

    if (selectedResource) {
      const data = [
        {
          type: 'chapter',
          number: selectedResource.chapter_num,
          title: selectedResource.chapter,
        },
        {
          type: 'section',
          title: selectedResource.section,
        },
      ];

      if (selectedResource.subsection) {
        data.push({
          type: 'subsection',
          number: selectedResource.subsection,
          title: selectedResource.subsection,
        });
      }

      setResourceItems([{ resourceNum: index }, { data }]);
      setResourcesShown(true);
    } else {
      console.warn(`No resource found for node_index: ${index}`);
    }
  };

  const callHistoryApi = async (query) => {
    let postData = JSON.stringify({});
    if (chatId !== null && chatId !== undefined) {
      postData = JSON.stringify({
        query: query,
        user: userId,
        prev_id: isHistoricalChat ? chatHistoryId : chatId,
        book_id: isHistoricalChat ? bookData.book_id : book.book_id,
        render_type: 'text',
        chat_version: 'v3',
      });
    } else {
      postData = JSON.stringify({
        query: query,
        user: userId,
        book_id: isHistoricalChat ? bookData.book_id : book.book_id,
        render_type: 'text',
        chat_version: 'v3',
      });
    }

    try {
      await callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/bookchat/`,
        postData,
        (resp) => {
          if (resp && resp.data) {
            setChatId(resp.data.id);
            chatIdRef.current = resp.data.id;
          }
        },
        (err) => {
          console.error('API error:', err);
        }
      );
    } catch (err) {
      console.error('Error in history api:', err);
    }
  };

  useEffect(() => {
    const fetchExistingChat = async () => {
      if (chatHistoryId && userId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/bookchat/?user=${userId}&id=${chatHistoryId}`);

          if (!response.ok) {
            throw new Error('Failed to fetch chat data');
          }

          const data = await response.json();
          if (data) {
            setBookData(data);
            setHistoryData(data);
            setInitialUI((prev) => ({ ...prev, loader: false }));
            setIsHistoricalChat(true);
            setChatId(chatHistoryId);
            chatIdRef.current = chatHistoryId;
          }
        } catch (error) {
          console.error('Error fetching chat:', error);
          setInitialUI((prev) => ({ ...prev, loader: false }));
        }
      }
    };

    fetchExistingChat();
  }, [chatHistoryId, userId]);

  useEffect(() => {
    if (historyData) {
      const historicalMessages = [];
      const citationsList = [];

      if (historyData.query && historyData.query.length > 0) {
        historyData.query.forEach((query, index) => {
          historicalMessages.push({
            role: 'user',
            content: query,
          });

          if (historyData.queryresponse && historyData.queryresponse[index]) {
            const queryResponse = historyData.queryresponse[index];

            if (queryResponse.response && queryResponse.response[0]) {
              const response = queryResponse.response[0];

              // Process locations/citations
              if (response.locations && typeof response.locations === 'object') {
                Object.values(response.locations).forEach((location, citationIndex) => {
                  citationsList.push({
                    node_index: location.node_index,
                    chapter: location.chapter,
                    chapter_num: location.chapter_num,
                    section: location.section,
                    subsection: location.subsection,
                  });
                });
              }

              let formattedText = response.text;

              // Replace citation numbers with the citation format used in WebSocket
              formattedText = formattedText.replace(/\[(\d+)\]/g, (match, num) => {
                return `[ Citation ${num} ]`;
              });

              historicalMessages.push({
                role: 'assistant',
                content: formattedText,
                locations: response.locations,
              });
            } else if (queryResponse.flag === 'abnormal' && queryResponse.gptresponse) {
              historicalMessages.push({
                role: 'assistant',
                content: queryResponse.gptresponse,
                isGptResponse: true,
              });
            }
          }
        });
      }

      setChatData({ messages: historicalMessages });
      setCitationList(citationsList); // Update the citation list
      setStreamEnd(true);
    }
  }, [historyData]);
  

  useEffect(() => {
    if (chatId) {
      if (isStreamEnd) {
        const eventData = {
          source: 'ask_book',
          chat_id: chatId,
          answer_status: 'Finished',
          book: isHistoricalChat ? bookData.book_name : book.book_name,
          book_id: isHistoricalChat ? bookData.book_id : book.book_id,
          book_category: flowType,
        };
        MixpanelEvent('chat_answer', eventData);
        sendPostHogEvent('chat_answer', eventData);
      }
      if (!isHistoricalChat) {
        if (promptType.isHybridPrompt === true) {
          MixpanelEvent('chat_question', {
            source: 'ask_a_book',
            book: book.book_name,
            book_category: flowType,
            chat_method: 'hybrid',
            chat_id: chatIdRef.current,
          });
          sendPostHogEvent('chat_question', {
            source: 'ask_a_book',
            book: book.book_name,
            book_category: flowType,
            chat_method: 'hybrid',
            chat_id: chatIdRef.current,
          });
          setPromptType({
            isTypedPrompt: false,
            isHybridPrompt: false,
          });
        }

        if (promptType.isTypedPrompt === true) {
          MixpanelEvent('chat_question', {
            source: 'ask_a_book',
            book: book.book_name,
            book_type: flowType,
            chat_method: 'direct',
            chat_id: chatIdRef.current,
          });
          sendPostHogEvent('chat_question', {
            source: 'ask_a_book',
            book: book.book_name,
            book_type: flowType,
            chat_method: 'direct',
            chat_id: chatIdRef.current,
          });
          setPromptType({
            isTypedPrompt: false,
            isHybridPrompt: false,
          });
        }
      }
    }
  }, [chatId, isStreamEnd]);

  const DownloadPdf = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/download-pdf/?chat_id=${chatHistoryId}&chat_type=book`;
    window.open(url, '_blank');
  };

  return (
    <>
      <style>
        {`
        body{
          font-family : Poppins
        }
        .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
          padding-inline-end : 0
        }
      `}
      </style>
      {initialUI.loader && (
        <div style={styles.spinnerContainer}>
          <div style={styles.spinnerLoader}></div>
        </div>
      )}
      {!initialUI.loader && bookData && (
        <Flex 
        direction="column" 
        justify="space-between" 
        style={{ ...styles.container, height: `${windowHeight}px`, maxHeight: `${windowHeight}px` }}
      >
        <Flex align="center" justify="space-between" style={styles.header}>
          <Flex align="center">
            <img 
              src={ArrowLeft} 
              alt="Arrow Left" 
              style={styles.backArrow} 
              onClick={() => navigate(-1)} 
            />
            <Text fw={600} style={styles.headerText}>
              {chatHistoryId ? `Query #${chatIndex}` : 'New query'}
            </Text>
          </Flex>
      
          {chatHistoryId && (
            <img 
              src={Download} 
              alt="download" 
              onClick={DownloadPdf} 
            />
          )}
        </Flex>


          {!isStreamEnd && chatData?.messages.length === 0 && (
            <ScrollArea style={styles.scrollContent} type="never" offsetScrollbars>
              <Flex direction="column" align="center" style={{ marginBottom: '2em' }}>
                <img src={bookData.image} alt={bookData.book_name} style={styles.bookImage} />
                <Text size="lg" fw={500} align="center" mt="md" style={{ fontFamily: 'Poppins' }}>
                  {isBookClass + bookData.book_name}
                </Text>
              </Flex>

              <Box mb="lg" style={styles.suggestionsBox}>
                <img src={flowType === 'ncert' ? NcertAiAvatar : BookChatAvatar} alt="Chat Avatar" style={styles.bookIcon} />

                {initialUI.message && initialUI.message !== '' && <Markdown>{initialUI.message}</Markdown>}

                <Stack spacing="xs">
                  {initialUI.suggestions &&
                    initialUI.suggestions.map((suggestion, index) => (
                      <Flex
                        key={index}
                        align="center"
                        justify="space-between"
                        style={styles.suggestionItem}
                        onClick={() => {
                          setExpression(suggestion);
                          handleSendMessage(suggestion, 'preprompt');
                        }}
                      >
                        <Text size="sm" style={{ fontFamily: 'Poppins', color: '#464646' }}>
                          {suggestion}
                        </Text>
                        <img src={ArrowRight2} alt="Arrow Right" width={16} height={16} />
                      </Flex>
                    ))}
                </Stack>
              </Box>
            </ScrollArea>
          )}

          {chatData?.messages.length > 0 && (
            <ScrollArea ref={scrollAreaRef} style={styles.scrollContent} type="never" offsetScrollbars>
              <Container size="md" px="xs">
                <Stack>
                  {chatData?.messages.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.role === 'user' && <QuestionsComp item={item} flowType={flowType} />}
                      {item.role === 'assistant' && item.content && (
                        <AnswerComponent
                          book={bookName}
                          scrollToBottom={scrollToBottom}
                          index={index}
                          item={item}
                          isLast={index === chatData.messages.length - 1}
                          streamEnd={isStreamEnd}
                          flowType={flowType}
                          resoureCallBack={resourcesHandler}
                        />
                      )}
                    </React.Fragment>
                  ))}
                  {aiLoading && (
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#F3F3F3',
                        borderRadius: '12px',
                        padding: '1em',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          left: '0px',
                        }}
                      >
                        <img
                          src={flowType === 'ncert' ? NcertAiAvatar : BookChatAvatar}
                          alt="Ai Avatar"
                          style={{ height: '2.5em', width: '2.5em' }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: '0.3em',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={LoadingGif} alt="Loader" style={{ width: '15%', height: '100%', marginLeft: '30px' }} />
                      </div>
                    </div>
                  )}
                </Stack>
              </Container>
            </ScrollArea>
          )}

          <Box style={styles.inputBar}>
            <Flex align="center" justify={'space-between'} gap={'sm'}>
              <div style={{ flexGrow: 2 }}>
                <Textarea
                  placeholder="Type your question here"
                  value={userMessage}
                  onChange={(event) => setUserMessage(event.currentTarget.value)}
                  styles={{
                    input: {
                      height: 'auto',
                      padding: '16px',
                      backgroundColor: '#F5F5F5',
                      borderRadius: '12px',
                      border: 'none',
                      fontFamily: 'Poppins',
                    },
                  }}
                  autosize
                  minRows={1}
                  maxRows={5}
                  disabled={isOngoingChat}
                />
              </div>
              <div>
                <Image
                  onClick={() => !isOngoingChat && handleSendMessage(userMessage, 'typed')}
                  src={SendMsg}
                  alt="Send"
                  style={{ width: '2.4em', cursor: !isOngoingChat ? 'pointer' : 'not-allowed' }}
                />
              </div>
            </Flex>
          </Box>

          {isResourcesShown && (
            <div style={styles.modalOverlay}>
              <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <span style={styles.topLine} onClick={() => setResourcesShown(false)}></span>
                </div>
                <button
                  onClick={() => setResourcesShown(false)}
                  style={{
                    position: 'relative',
                    right: '5px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: 'rgb(0, 0, 0)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  &times;
                </button>
                <div style={styles.titleContainer}>
                  <h3 style={styles.title}>{`Resource-${resourceItems[0].resourceNum}`}</h3>
                </div>

                {/* Resource Items */}
                <div style={styles.resourceList}>
                  {resourceItems[1].data.map((item, index) => (
                    <div key={index} style={styles.resourceItem}>
                      <img
                        style={styles.icon}
                        src={
                          item.type === 'chapter'
                            ? ChapterBook
                            : item.type === 'section'
                              ? BookSection
                              : item.type === 'subsection'
                                ? BookSubSection
                                : null
                        }
                      />
                      <div style={styles.itemContent}>
                        <div style={styles.itemType}>
                          {item.type === 'chapter' && 'Chapter ' + item.number}
                          {item.type === 'section' && 'Section'}
                          {item.type === 'subsection' && `Sub- Section`}
                        </div>
                        <div style={styles.itemTitle}>{item.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Flex>
      )}
    </>
  );
};

const styles = {
  container: {
    maxWidth: '28rem',
    margin: '0 auto',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  header: {
    padding: '15px',
  },
  backArrow: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  headerText: {
    margin: 0,
    fontSize: '16px',
  },
  scrollContent: {
    flex: 1,
    padding: '15px',
  },
  bookImage: {
    width: '250px',
    height: '160px',
    objectFit: 'contain',
  },
  suggestionsBox: {
    backgroundColor: '#F3F3F3',
    borderRadius: '12px',
    position: 'relative',
    padding: '15px',
  },
  bookIcon: {
    position: 'absolute',
    top: '-15px',
    left: '0px',
  },
  suggestionItem: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    padding: '15px',
  },
  inputBar: {
    backgroundColor: '#ffffff',
    padding: '15px',
    boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
  },
  askButton: {
    color: '#ffffff',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    marginTop: '4em',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  spinnerLoader: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #3498db',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0',
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  titleContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  title: {
    margin: 0,
    fontSize: '18px',
    fontWeight: '600',
  },
  resourceList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  resourceItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    textAlign: 'left',
    padding: '8px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  icon: {
    fontSize: '24px',
    marginRight: '8px',
  },
  itemContent: {
    flex: 1,
  },
  itemType: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '4px',
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: '500',
  },
};

export default BookChat;
