import React from 'react';
import { Button, Group, Text, Textarea, Radio, Title, Stack } from '@mantine/core';
import Lottie from 'react-lottie';
import * as successAnimation from '../assets/json/report_success.json';

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  resportData,
  setResportData,
  details,
  onChange,
  handleResportSubmit,
}) => {
  // Default Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // If the modal is not open, return null
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={{ ...modalContainerStyle, maxWidth: isMobile ? '28rem' : '22vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span style={topLineStyle} onClick={closeModal}></span>
        </div>
        <button
          onClick={closeModal}
          style={{
            position: 'relative',
            right: '5px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'rgb(0, 0, 0)',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          &times;
        </button>

        {/* Conditionally render content based on report submission state */}
        {!isReportSubmited ? (
          <>
            <Title fz="h3" m="1em" style={{ textAlign: 'left' }}>
              Report Question
            </Title>
            <Stack spacing="lg">
              <Radio.Group value={resportData} onChange={setResportData} size="md">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} p="md" />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text style={{ textAlign: 'left' }}>Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    style={{ marginTop: '10px', fontSize: '14px' }}
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!resportData}
                style={{
                  backgroundColor: resportData ? '#000' : '#ccc',
                  fontWeight: 500,
                }}
                onClick={handleResportSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Title fz="h3" m="1em">
              Report Submitted
            </Title>
            <Title m="1em" fz="md">
              Thank you! Your report has been submitted.
            </Title>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};

// Styles for modal overlay and container
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
};

const modalContainerStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px 10px 0 0',
  width: '100%',
  textAlign: 'center',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  marginBottom: '0',
};

const topLineStyle = {
  height: '6px',
  width: '89px',
  display: 'block',
  background: '#E8E8E8',
  borderRadius: '50px',
  cursor: 'pointer',
};

export default ReportModaContent;
