import { WsOneNew, WsTwoNew, WsThreeNew, WsFourNew, WsFiveNew, SliderOneIcon, ArrowRight, AiChat, PYQSearch, DuelChat } from '../assets/images';

export const slides = [
  {
    title: 'Daily Current Affairs',
    description: 'Find the latest news along with related PYQs.',
    icon: SliderOneIcon,
    component: WsOneNew,
    svgBgColor: ['rgba(254, 255, 217, 0)', '#FEFFD9', '#FEFFD9'],
  },
  {
    title: 'AI Chat: Clarify your doubts using AI tutor',
    description: 'Challenge friends or rivals online in a Quiz against time. Get in depth explanation from AI.',
    icon: AiChat,
    component: WsTwoNew,
    svgBgColor: ['rgba(233, 255, 229, 0)', '#E9FFE5', '#E9FFE5'],
  },
  {
    title: 'PYQ Search: UPSC Prelims MCQs',
    description: 'Customized solo quizzes along with in depth explanation from AI.',
    icon: PYQSearch,
    component: WsThreeNew,
    svgBgColor: ['rgba(243, 227, 255, 0)', '#F3E3FF', '#F3E3FF'],
  },
  {
    title: 'Ask a Book: Ask anything from UPSC textbooks',
    description: 'Chat 24/7 with AI for insights, knowledge, and clarifications anytime.',
    icon: AiChat,
    component: WsFourNew,
    svgBgColor: ['rgba(253, 240, 228, 0)', '#FFF2E6', '#FFF2E6'],
  },
  {
    title: 'Duel: Compete with your friends',
    description: 'Chat 24/7 with AI for insights, knowledge, and clarifications anytime.',
    icon: DuelChat,
    component: WsFiveNew,
    svgBgColor: ['rgba(255, 233, 230, 0)', '#FFE9E6'],
  },
];
