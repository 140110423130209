import React from 'react';
import { PracticeCommingSoon } from '../../assets/images';

export default function DuelWithFriends({ img = PracticeCommingSoon, description = "Challenge your friends and compete head-to-head! Stay tuned for more updates." }) {
  return (
    <div style={{ height: '80vh', backgroundColor: '#fff' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ width: '90%', maxWidth: '600px', textAlign: 'center' }}>
          <img
            src={img}
            alt="Coming Soon"
            style={{ width: '100%', objectFit: 'cover', marginBottom: '1em' }}
          />
          <h2
            style={{
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              marginBottom: '0.5em',
            }}
          >
            Coming Soon
          </h2>
          <p
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '0.9em',
              margin: '0 2em',
              lineHeight: '1.8em',
              color: '#747474',
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
