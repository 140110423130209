import React, { useState, useEffect, useRef } from 'react';
import { IconCheck, IconCaretDownFilled } from '@tabler/icons-react';
import { icons } from '../constants/iconData';

const CustomSelect = ({ options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [focused, setFocused] = useState(false);
  const dropdownRef = useRef(null);
  const isMobile = window.innerWidth <= 768;

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (option) => {
    if (value.includes(option)) {
      onChange((prev) => prev.filter((item) => item !== option));
    } else {
      onChange((prev) => [...prev, option]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.value));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div style={{ position: 'relative', zIndex: 1000, flex: 1 }} ref={dropdownRef}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={0}
        onClick={toggleDropdown}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          border: focused ? '1px solid #6374FA' : '1px solid #ccc',
          borderRadius: '8px',
          cursor: 'pointer',
          justifyContent: 'space-between',
          width: '100%',
          height: '36px',
          fontSize: '14px',
          gap: 4,
          outline: 'none',
        }}
      >
        <span
          style={{
            color: value.length === 0 ? 'gray' : 'black',
            fontFamily: "Poppins"
          }}
        >
          {value.length === 1
            ? options.find((opt) => opt.value === value[0])?.label
            : value.length > 1
              ? `${value.length} Topics selected`
              : 'Select Topic'}
        </span>
        <IconCaretDownFilled width={12} height={12} color="gray" />
      </div>

      <div
        style={{
          position: 'fixed',
          bottom: isOpen ? 0 : '-100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? '100%' : '28rem',
          backgroundColor: 'white',
          maxHeight: '55vh',
          overflowY: 'auto',
          zIndex: 9999,
          boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
          paddingBottom: '20px',
          paddingTop: '10px',
          borderRadius: '10px 10px 0 0',
          transition: 'bottom 0.3s ease-in-out',
        }}
      >
        <div style={{ padding: '16px', textAlign: 'left' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '15px',
              cursor: 'pointer',
            }}
            onClick={handleSelectAll}
          >
            <div style={{
              width: '24px',
              height: '24px',
              border: '1.5px solid #ddd',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '12px',
              backgroundColor: selectAll ? '#6374FA' : 'white',
              borderColor: selectAll ? '#6374FA' : '#ddd',
            }}>
              <IconCheck 
                width={16} 
                height={16} 
                color={selectAll ? 'white' : '#ddd'} 
              />
            </div>
            <span style={{
              fontFamily: "Poppins",
              color: "#747474",
            }}>
              Select All
            </span>
          </div>

          {options.map((option) => (
            <div
              key={option.value}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '15px',
                cursor: 'pointer',
              }}
              onClick={() => handleSelect(option.value)}
            >
              <div style={{
                width: '24px',
                height: '24px',
                border: '1.5px solid #ddd',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '12px',
                backgroundColor: value.includes(option.value) ? '#6374FA' : 'white',
                borderColor: value.includes(option.value) ? '#6374FA' : '#ddd',
              }}>
                <IconCheck 
                  width={16} 
                  height={16} 
                  color={value.includes(option.value) ? 'white' : '#ddd'} 
                />
              </div>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center',
              }}>
                {icons[option.value]}
                <span style={{ 
                  marginLeft: '8px', 
                  fontFamily: "Poppins", 
                  color: "#070519" 
                }}>
                  {option.label}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;