import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Button, Group, Radio, Text } from '@mantine/core';
import {
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconExclamationCircle,
  IconX,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { discussWithAi } from './discussWithAi';
import { callPOSTAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { ReactComponent as MeaningBased } from '../../assets/images/meaning_based.svg';
import { Howl } from 'howler';
import correctSoundFile from '../../assets/raw/correct.mp3';
import wrongSoundFile from '../../assets/raw/wrong.mp3';
import ReportModaContent from '../../molecules/ReportQuestion';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';
import { ArrowLeftFilled } from '../../assets/images';

const PrelimsContent = ({ prelimsQuestions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState(
    prelimsQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null }))
  );
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const containerRef = useRef(null);
  const currentQuestionRef = useRef(null);
  const navigate = useNavigate();
  const authUserDetails = userStore((state) => state.userData);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 2500; // Adjust for mobile view
  const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleReportOpen = () => setIsOpen(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsReportSubmitted(false); // Reset the submission state
  };

  const handleReportSubmit = (option, comment) => {
    setIsReportSubmitted(true);
    // Handle the report submission logic here
    setTimeout(() => handleCloseModal(), 3000); // Close modal after showing success animation
  };

  // Initialize sounds
  const correctSound = new Howl({
    src: [correctSoundFile], // Add the correct answer sound file path
  });
  const wrongSound = new Howl({
    src: [wrongSoundFile], // Add the wrong answer sound file path
  });

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      const questionIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(questionIndex);
      if (questionStates[questionIndex].isSubmitted) {
        setSelectedAnswerIndex(questionStates[questionIndex].answerIndex);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < prelimsQuestions.length - 1) {
      const questionIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(questionIndex);
      if (questionStates[questionIndex].isSubmitted) {
        setSelectedAnswerIndex(questionStates[questionIndex].answerIndex);
      }
    }
  };

  const handleOptionChange = (value) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      const selectedIndex = prelimsQuestions[currentQuestionIndex].options.findIndex((option) => option === value);
      setSelectedAnswerIndex(selectedIndex);
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = value;
        newStates[currentQuestionIndex].answerIndex = selectedIndex;
        return newStates;
      });
    }
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    const currentQuestion = prelimsQuestions[currentQuestionIndex];

    // Ensure question_id and correct_answer are valid
    if (!currentQuestion || typeof currentQuestion.question_id === 'undefined' || typeof currentQuestion.correct_answer === 'undefined') {
      console.error('Invalid question data. Cannot submit.');
      return;
    }

    const correctAnswerIndex = currentQuestion.correct_answer;
    const selectedAnswerIndex = questionStates[currentQuestionIndex].answerIndex;
    const isAnswerCorrect = selectedAnswerIndex === correctAnswerIndex;

    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });

    if (isAnswerCorrect) {
      correctSound.play();
    } else {
      wrongSound.play();
    }

    // Convert all necessary fields to strings to avoid the int-related error
    const payload = {
      question_id: String(currentQuestion.question_id), // Convert to string
      username: String(authUserDetails?.username), // Already a string
      answer: selectedAnswerIndex !== null ? String(selectedAnswerIndex) : null, // Convert to string if not null
      correct_answer: String(correctAnswerIndex), // Convert to string
      category: String(currentQuestion.category), // Already a string
    };

    // Validate the payload before making the API call
    if (payload.question_id && payload.answer !== null && payload.correct_answer !== null) {
      callPOSTAPI(
        `${process.env.REACT_APP_BASE_URL}/api/pyq-question-submit/`,
        JSON.stringify(payload),
        (resp) => {
          console.log('Answer submitted successfully', resp);
        },
        (error) => {
          console.error('Error submitting answer', error);
        }
      );
    } else {
      console.error('Invalid payload:', payload); // Add error log if the payload is invalid
    }
  };

  const [marginLeft, setMarginLeft] = useState('7rem'); // Default value for desktop

  // Update marginLeft based on screen size
  useEffect(() => {
    const updateMarginLeft = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 375) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth < 450) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth < 500) {
        setMarginLeft('1rem'); // For small screens (mobile)
      } else if (screenWidth >= 600 && screenWidth < 768) {
        setMarginLeft('1rem'); // For tablets
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setMarginLeft('1rem'); // For small laptops
      } else {
        setMarginLeft('1rem'); // For desktops and larger screens
      }
    };

    // Call the function once to set the margin initially
    updateMarginLeft();

    // Add event listener to update on window resize
    window.addEventListener('resize', updateMarginLeft);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateMarginLeft);
    };
  }, []);

  const QuestionNavigation = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '7px',
      }}
    >
      <img
        src={ArrowLeftFilled}
        style={{
          borderRadius: '8px',
          border: '1px solid #D1D1D1',
          padding: '7px 5px',
          color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
          cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
        }}
        onClick={handlePrevQuestion}
        alt="Arrow Left"
      />
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '10px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          flex: 1,
          margin: '0 10px',
        }}
      >
        {prelimsQuestions.map((_, index) => (
          <Text
            key={index}
            data-index={index}
            style={{
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              cursor: 'pointer',
              flexShrink: 0,
              backgroundColor: index === currentQuestionIndex ? '#EBF3FF' : 'transparent',
              color: index === currentQuestionIndex ? '#6374FA' : '#1A1A1As',
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: '500',
            }}
            onClick={() => setCurrentQuestionIndex(index)}
          >
            Q{index + 1}
          </Text>
        ))}
      </div>
      <img
        src={ArrowLeftFilled}
        style={{
          borderRadius: '8px',
          border: '1px solid #D1D1D1',
          padding: '7px 5px',
          color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
          cursor: currentQuestionIndex === prelimsQuestions.length - 1 ? 'not-allowed' : 'pointer',
          transform: 'rotate(180deg)',
        }}
        onClick={handleNextQuestion}
        alt="Arrow Right"
      />
    </div>
  );

  useEffect(() => {
    if (containerRef.current) {
      // Ensure the scroll starts at the very beginning of the container on mount
      containerRef.current.scrollTo({
        left: 0, // Scroll all the way to the left
        behavior: 'auto', // No smooth scroll for initial load
      });
    }
  }, []);

  useEffect(() => {
    if (currentQuestionRef.current && containerRef.current) {
      const currentEl = currentQuestionRef.current;
      const containerEl = containerRef.current;

      const offsetLeft = currentEl.offsetLeft;
      const containerScrollLeft = containerEl.scrollLeft;
      const containerWidth = containerEl.clientWidth;

      // Ensure the current question is visible, scrolling if needed
      if (offsetLeft < containerScrollLeft || offsetLeft + currentEl.offsetWidth > containerScrollLeft + containerWidth) {
        containerEl.scrollTo({
          left: offsetLeft - containerWidth / 6, // Adjust for better alignment to center
          behavior: 'smooth',
        });
      }

      // Ensure the first question (Q1) is always visible
      if (currentQuestionIndex === 0) {
        containerEl.scrollTo({
          left: 0, // Scroll to the start
          behavior: 'smooth',
        });
      }
    }
  }, [currentQuestionIndex]);

  const handleDiscussWithAi = (question, selectedOption) => {
    const aiTutorParams = discussWithAi(question, selectedOption);
    navigate('/chat/new', { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'pyq_search' });
    sendPostHogEvent('discuss_with_ai', { source: 'pyq_search' });
  };

  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;

      // Get the pixel equivalent of 9rem by multiplying by the root font-size
      const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const marginToSubtract = 25 * remInPixels; // Convert 9rem to pixels

      const calculatedHeight = viewportHeight - marginToSubtract;
      setContainerHeight(`${calculatedHeight}px`);
    };

    // Update the height when the component mounts and on window resize
    updateHeight();
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <div
      style={{
        minHeight: '100dvh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '#FFFFFF',
      }}
    >
      {prelimsQuestions.length > 0 ? (
        <>
          <QuestionNavigation />
          <div
            style={{
              position: 'relative',
              border: `1px solid #E5E7EB`,
              borderRadius: '12px',
              margin: '5px',
              height: 'calc(100dvh - 180px)',
              overflow: 'hidden',
              backgroundColor: '#FFFFFF',
              marginBottom: '80px',
            }}
          >
            <div
              style={{
                height: '83%',
                overflowY: 'auto',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                padding: '15px',
              }}
            >
              <div style={{ marginBottom: '12px', display: 'flex', gap: '8px' }}>
                <Badge
                  styles={{
                    root: {
                      backgroundColor: '#E8E8E8',

                      color: '#6B7280',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {prelimsQuestions[currentQuestionIndex]?.pyq_year}
                </Badge>
                <Badge
                  styles={{
                    root: {
                      backgroundColor: '#E8E8E8',
                      color: 'gray',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {prelimsQuestions[currentQuestionIndex]?.pyq_exam}
                </Badge>
                <Badge
                  styles={{
                    root: {
                      backgroundColor: '#E8E8E8',
                      color: 'gray',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {prelimsQuestions[currentQuestionIndex]?.category}
                </Badge>
              </div>

              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  color: '#1A1A1A',
                  marginBottom: '20px',
                }}
              >
                {prelimsQuestions[currentQuestionIndex]?.question.includes('\n')
                  ? prelimsQuestions[currentQuestionIndex].question.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : prelimsQuestions[currentQuestionIndex]?.question}
              </Text>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {prelimsQuestions[currentQuestionIndex]?.options.map((option, index) => {
                  const currentState = questionStates[currentQuestionIndex];
                  const isSubmitted = currentState?.isSubmitted;
                  const isCorrectAnswer = index === prelimsQuestions[currentQuestionIndex].correct_answer;
                  const isSelectedAnswer = index === currentState?.answerIndex;

                  return (
                    <div
                      key={index}
                      onClick={() => !isSubmitted && handleOptionChange(option)}
                      style={{
                        backgroundColor: isSubmitted
                          ? isCorrectAnswer
                            ? '#E4FFEF'
                            : isSelectedAnswer
                            ? '#FFB6B6'
                            : '#FFFFFF'
                          : isSelectedAnswer
                          ? '#E1EBFF'
                          : '#FFFFFF',
                        border: isSubmitted
                          ? isCorrectAnswer
                            ? `1px solid #E4FFEF`
                            : isSelectedAnswer
                            ? `1px solid #FFB6B6`
                            : `1px solid #E5E7EB`
                          : isSelectedAnswer
                          ? `1px solid #6374FA`
                          : `1px solid #E5E7EB`,
                        borderRadius: '8px',
                        padding: '16px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          backgroundColor: isSubmitted ? (isCorrectAnswer ? '#34d399' : isSelectedAnswer ? '#ff9999' : 'transparent') : 'transparent',
                          border: isSubmitted && (isCorrectAnswer || isSelectedAnswer) ? 'none' : '2px solid #6374FA',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelectedAnswer && !isSubmitted && (
                          <div
                            style={{
                              width: '14.5px',
                              height: '14.5px',
                              backgroundColor: '#6374FA',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        {isSubmitted &&
                          (isCorrectAnswer ? <IconCheck size={14} color="white" /> : isSelectedAnswer ? <IconX size={14} color="white" /> : null)}
                      </div>

                      <Text
                        style={{
                          color: '#6374FA',
                          width: '19px',
                          textAlign: 'left',
                          fontSize: '16px',
                          fontFamily: 'Poppins',
                          fontWeight: '500',
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </Text>

                      <Text
                        style={{
                          flex: 1,
                          color: '#1A1A1A',
                          fontSize: '16px',
                          fontFamily: 'Poppins',
                          textAlign: 'left',
                          fontWeight: '500',
                        }}
                      >
                        {option}
                      </Text>
                    </div>
                  );
                })}
                {/* Footer */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    border: `1px solid #E5E7EB`,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    boxSizing: 'border-box',
                    margin: 0,
                    marginBottom: '15px',
                  }}
                >
                  <Button
                    variant="outline"
                    color="#D95353"
                    style={{
                      borderColor: 'transparent',
                      color: '#D95353',
                      display: 'flex',
                      alignItems: 'center',
                      outline: 'none',
                    }}
                    onClick={handleReportOpen}
                  >
                    <IconExclamationCircle size={16} style={{ marginRight: '4px' }} />
                    Report
                  </Button>

                  {!questionStates[currentQuestionIndex].isSubmitted ? (
                    <Button
                      variant="outline"
                      disabled={!questionStates[currentQuestionIndex].selectedOption}
                      style={{
                        backgroundColor: questionStates[currentQuestionIndex].selectedOption ? '#070519' : '#F3F3F3',
                        color: questionStates[currentQuestionIndex].selectedOption ? '#FFFFFF' : '#B9B9B9',
                        cursor: questionStates[currentQuestionIndex].selectedOption ? 'pointer' : 'not-allowed',
                        border: questionStates[currentQuestionIndex].selectedOption ? 'none' : '1px solid #D1D1D1',
                        borderRadius: '25px',
                        padding: '12px 24px',
                        height: '2.5rem',
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="filled"
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: '25px',
                        borderColor: 'black',
                        padding: '12px 24px',
                        height: '2.5rem',
                      }}
                      onClick={() => handleDiscussWithAi(prelimsQuestions[currentQuestionIndex], selectedAnswerIndex)}
                    >
                      Discuss with AI
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Text>No questions available</Text>
      )}
      <ReportModaContent
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={reportData}
        setResportData={setReportData}
        details={reportDetails}
        onChange={(e) => setReportDetails(e.currentTarget.value)}
        handleResportSubmit={handleReportSubmit}
        isReportSubmited={isReportSubmitted}
        isMobile={isMobile}
      />
    </div>
  );
};

export default PrelimsContent;
