import { supabase } from './SupabaseClient';
import { callPOSTAPI } from './NetworkUtil';

const SignInWithSupabasePostGoogleSignIn = async (idToken, provider, accessToken, setUserData = () => {}, navigate = () => {}) => {
  const { data } = await supabase.auth.signInWithIdToken({
    provider: provider,
    token: idToken,
    accessToken: accessToken,
  });

  if (data.session?.access_token && data.user.id) {
    const params = {
      user_id: data.user.id,
    };

    callPOSTAPI(
      `${process.env.REACT_APP_BASE_URL}/api/google-signin/`,
      params,
      (resp) => {
        const userData = {
          ...resp.data.data.profile,
          name: resp.data.data.profile.name || data.user.user_metadata.full_name || data.user.email, // Set name if missing
          user_id: resp.data.data.profile.user || data.user.id, // Ensure user_id is set
        };

        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('new_user', resp.data.data.new_user);
        if (resp.data.data.new_user === true) {
          localStorage.setItem('is_signup_newUser', 'true');
        }
        setUserData(userData);
        localStorage.setItem('user_id', userData.user_id);
        const isNewUser = resp.data.data.new_user;
        if (isNewUser) {
          navigate('/signupdetails');
        } else {
          navigate('/news');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
};
const SignInWithSupabasePostWhatsAppSignIn = async (otplessUser, setUserData = () => {}) => {
  let withoutCountryCode = otplessUser.number.slice(2);
  const params = {
    phone: withoutCountryCode,
  };
  try {
    callPOSTAPI(
      `${process.env.REACT_APP_BASE_URL}/api/whatsapp-signin/`,
      params,
      (resp) => {
        localStorage.setItem('is_signup_newUser', 'false');
        localStorage.setItem('userData', JSON.stringify(resp.data.data.profile));
        setUserData(resp.data.data.profile);
      },
      (err) => {
        console.log(err);
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export { SignInWithSupabasePostGoogleSignIn, SignInWithSupabasePostWhatsAppSignIn };
