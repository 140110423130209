import React, { useState, useRef } from 'react';
import { BlueCrown, BlueCheck } from '../../assets/images';

const FriendsContainer = ({ containerStyle }) => (
  <div style={{ ...styles.friendsContainer, ...containerStyle }}>
    <p style={styles.friendsHeading}>You’ve not added any friends</p>
    <p style={styles.friendsParagraph}>To add friends, play ‘Duel with Friends’ & view rankings on the Friends Leaderboard.</p>
  </div>
);

const LeaderBoardListModal = ({ globalLeaderboard, userRanking, activeTab }) => {
  const [isOpen] = useState(true);
  const [isFullyOpen, setIsFullyOpen] = useState(false);
  const modalRef = useRef(null);
  const isRespMobile = window.innerWidth <= 1400 && window.innerHeight <= 700;
  const backdropTop = activeTab === 'Performance' ? '350px' : '200px';

  const toggleModalHeight = () => {
    setIsFullyOpen(!isFullyOpen);
  };

  const getInitial = (name) => {
    if (!name) return '-';
    return name.charAt(0).toUpperCase();
  };

  const calculateUserRank = () => {
    return userRanking && userRanking.top ? `Top ${userRanking.top}%` : 'Top 0%';
  };

  const userRankGlobal = calculateUserRank();

  const getDisplayValue = (item) => {
    if (activeTab === 'Performance') {
      return item.totalcorr || 'N/A';
    }
    return item.score || 'N/A';
  };
  return (
    <>
      {isOpen && (
        <div style={{ ...styles.backdrop, top: backdropTop }}>
          <div
            onClick={toggleModalHeight}
            ref={modalRef}
            style={{
              ...styles.modalContainer,
              height: isFullyOpen ? '100%' : isRespMobile ? '36%' : '50%',
              transition: 'height 0.3s ease-in-out',
            }}
          >
            {globalLeaderboard && globalLeaderboard.length > 0 && (
              <div style={styles.topLineContainer} onClick={toggleModalHeight}>
                <span style={styles.topLine}></span>
              </div>
            )}

            <div
              style={{
                ...styles.scrollableContainer,
                overflowY: globalLeaderboard && globalLeaderboard.length > 0 ? 'auto' : 'hidden',
              }}
            >
              {!globalLeaderboard || globalLeaderboard.length === 0 ? (
                <FriendsContainer containerStyle={{ marginTop: '10px' }} />
              ) : (
                globalLeaderboard.map((item, index) => (
                  <div key={index} style={styles.listItem}>
                    <span style={styles.rank}>{index + 1}</span>
                    <div style={styles.avatarPlaceholder}>{getInitial(item.name)}</div>
                    <span style={styles.name}>{item.name || ''}</span>
                    <span style={{ ...styles.score, width: '30px', marginRight: '30px' }}>
                      <img
                        src={activeTab === 'Performance' ? BlueCheck : BlueCrown}
                        alt={activeTab === 'Performance' ? 'Check Icon' : 'Crown Icon'}
                        style={styles.crownIcon}
                      />
                      {getDisplayValue(item)}
                    </span>
                  </div>
                ))
              )}
            </div>

            <div style={styles.userStats}>
              <span style={{ ...styles.rank, fontSize: '12px' }}> {userRanking ? userRankGlobal : 'N/A'}</span>
              <div style={{ ...styles.avatarPlaceholder, marginLeft: '40px' }}>{userRanking ? getInitial(userRanking.name) : '-'}</div>
              <span style={{ flex: 1, marginLeft: '10px' }}>
                <span style={styles.meTag}>Me</span>
                {console.log(userRanking)}
                <span>
                  {userRanking && userRanking.name ? (userRanking.name.length > 10 ? `${userRanking.name.slice(0, 10)}...` : userRanking.name) : ''}
                </span>
              </span>
              <span style={{ ...styles.score, marginRight: '25px' }}>
                <img
                  src={activeTab === 'Performance' ? BlueCheck : BlueCrown}
                  alt={activeTab === 'Performance' ? 'Check Icon' : 'Crown Icon'}
                  style={styles.crownIcon}
                />
                {userRanking ? getDisplayValue(userRanking) : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  backdrop: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  modalContainer: {
    backgroundColor: 'white',
    borderRadius: '20px 20px 0 0',
    width: '100%',
    maxWidth: '28em',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #BDCBFF',
    borderBottom: 'none',
  },
  topLineContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 0',
  },
  topLine: {
    height: '6px',
    width: '60px',
    background: '#BDCBFF',
    borderRadius: '50px',
  },
  scrollableContainer: {
    flex: 1,
    padding: '0 20px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  rank: {
    width: '30px',
  },
  avatarPlaceholder: {
    width: '40px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#BDCBFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    fontWeight: 'bold',
  },
  name: {
    fontSize: '14px',
    flex: 1,
    color: 'black',
  },
  score: {
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
  },
  crownIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  userStats: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EBF3FF',
    padding: '10px',
  },
  meTag: {
    backgroundColor: '#6374FA',
    color: 'white',
    padding: '2px 6px',
    borderRadius: '10px',
    fontSize: '12px',
    marginRight: '5px',
  },
  friendsContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '10px',
    margin: '0 auto',
    border: '1px solid #E0E0E0',
    borderLeft: '3px solid green',
    marginBottom: '25px',
  },
  friendsHeading: {
    color: '#1E1E1E',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'start',
    marginBottom: '0',
    textTransform: 'uppercase',
  },
  friendsParagraph: {
    fontSize: '12px',
    marginBottom: '0px',
    marginTop: '10px',
  },
};

export default LeaderBoardListModal;
