import React from 'react';

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '1rem',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '12px 12px 0 0',
    padding: '2rem',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
  },
  modalTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: '0.75rem',
    textAlign: 'center',
  },
  modalDescription: {
    color: '#666',
    marginBottom: '1.5rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 1.4,
    letterSpacing : '0.08em'
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.75rem',
  },
  modalButton: {
    flex: 1,
    padding: '0.75rem 1rem',
    borderRadius: '25px',
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'background-color 0.2s, transform 0.1s',
    border: 'none',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#070519',
    border: '1px solid #070519',
  },
  reportButton: {
    backgroundColor: '#e74c3c',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportIcon: {
    backgroundColor: 'white',
    color: '#e74c3c',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginRight: '8px',
    fontSize: '0.9rem',
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
};

const ReportQuestionModal = ({ isOpened, onCancel, onReport }) => {
  const isResponsiveMobile =
    window.innerHeight <= 700 && window.innerWidth <= 400 ? '23em' : window.innerWidth > 400 && window.innerWidth < 900 ? '26em' : '28em';
  if (!isOpened) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={{
        ...styles.modalContent, width: isResponsiveMobile,
      }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span style={styles.topLine} onClick={onCancel}></span> 
        </div>
        <h2 style={styles.modalTitle}>Report Question</h2>
        <p style={styles.modalDescription}>
          Are you sure you want to report this question?
        </p>
        <div style={styles.modalActions}>
          <button
            onClick={onCancel}
            style={{ ...styles.modalButton, ...styles.cancelButton }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.cancelButton.backgroundColor)}
          >
            Cancel
          </button>
          <button
            onClick={onReport}
            style={{ ...styles.modalButton, ...styles.reportButton }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#c0392b')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.reportButton.backgroundColor)}
          >
            <span style={styles.reportIcon}>!</span> Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportQuestionModal;
