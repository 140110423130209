import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown, AttachedIcon, CloseIcon } from '../../assets/images';
import CompletionModal from './CompletionModal';

const FeedbackForm = ({ isVisible, onClose, userData }) => {
  const [feedbackCategory, setFeedbackCategory] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const isMobile = window.innerWidth <= 2500;
  const token = userData.user;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedbackCategory) {
      const data = new FormData();
      data.append('profile_id', userData.user);
      data.append('experience', feedbackText);
      data.append('category', feedbackCategory);

      if (selectedFile) {
        data.append('screenshot', {
          uri: selectedFile.uri,
          type: selectedFile.type || 'image/jpeg',
          name: selectedFile.name || 'screenshot.jpg',
        });
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/service-feedback/`, {
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseText = await response.text();
        try {
          const result = JSON.parse(responseText);
          setShowCompletionModal(true);
        } catch (jsonError) {
          console.error('Failed to parse JSON:', jsonError);
        }
      } catch (error) {
        console.error('Error:', error);
      }

      // Clear form after submission
      setFeedbackCategory('');
      setFeedbackText('');
      setSelectedFile(null);
      setIsButtonActive(false);
    }
  };
  const handleGoBack = () => {
    setShowCompletionModal(false);
    onClose();
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const selectCategory = (category) => {
    setFeedbackCategory(category);
    setIsDropdownOpen(false);
    setIsButtonActive(true); // Enable button when category is selected
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(); // Close modal when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length > maxLength) {
      return fileName.slice(0, maxLength - 3) + '...';
    }
    return fileName;
  };

  const clearFile = () => {
    setSelectedFile(null);
    document.getElementById('fileInput').value = ''; // Reset the input value
  };

  // Check if button should be enabled
  const isSubmitButtonDisabled = !feedbackCategory;

  if (!isVisible) return null;

  return (
    <div style={styles.modalOverlay}>
      <div ref={modalRef} style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <span style={styles.topLine} onClick={onClose}></span> {/* Close modal on click */}
        </div>
        <h2 style={styles.heading}>Help Us Improve Padhai</h2>

        {/* Feedback Category Dropdown */}
        <div style={styles.dropdownContainer} ref={dropdownRef}>
          <div style={styles.dropdownHeader} onClick={toggleDropdown}>
            <span>{feedbackCategory || 'What is your feedback about?'}</span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '56px',
                height: '56px',
                borderRadius: '8px',
                marginRight: '-8px',
                background: '#E8E8E8',
              }}
            >
              <img src={ArrowDown} alt="Arrow Down" style={styles.arrowIcon} />
            </span>
          </div>
          {isDropdownOpen && (
            <ul style={styles.dropdownList}>
              <li onClick={() => selectCategory('')}>What is your feedback about?</li>
              <li onClick={() => selectCategory('general_app_improvements')}>General app improvements</li>
              <li onClick={() => selectCategory('bugs_and_fixes')}>Bugs and fixes</li>
              <li onClick={() => selectCategory('new_feature_requests')}>New feature requests</li>
              <li onClick={() => selectCategory('other')}>Other</li>
            </ul>
          )}
        </div>

        {/* Feedback Textarea */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Tell Us More (Optional)</label>
          <textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="How can we improve your experience?"
            style={styles.textarea}
          />
        </div>

        {/* Attach Screenshot */}
        <div style={styles.attachContainer}>
          <input type="file" onChange={handleFileChange} id="fileInput" style={{ display: 'none' }} />
          <label htmlFor="fileInput" style={styles.attachContent}>
            <span style={styles.attachText}>{selectedFile ? truncateFileName(selectedFile.name) : 'Attach a Screenshot'}</span>
            <img
              src={selectedFile ? CloseIcon : AttachedIcon}
              alt={selectedFile ? 'Clear' : 'Attach'}
              style={styles.attachIcon}
              onClick={selectedFile ? clearFile : null} // Add onClick only if a file is selected
            />
          </label>
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled}
          style={isSubmitButtonDisabled ? styles.submitButtonDisabled : styles.submitButtonActive}
        >
          Submit Feedback
        </button>
        {showCompletionModal && (
          <CompletionModal
            onGoBack={() => {
              setShowCompletionModal(false);
              onClose(); // Close the parent modal
            }}
          />
        )}
      </div>
    </div>
  );
};

// Inline Styles
const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end', // Aligns modal at the bottom
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0', // Rounded only on top corners
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0', // Modal is at the bottom
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '15px',
    textAlign: 'left',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: '500',
    fontSize: '14px',
  },
  textarea: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '14px',
    resize: 'none',
    height: '250px',
  },
  dropdownContainer: {
    position: 'relative',
    marginBottom: '15px',
  },
  dropdownHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    height: '56px',
  },
  arrowIcon: {
    width: '20px',
    height: '20px',
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    maxHeight: '150px',
    overflowY: 'auto',
    zIndex: 1000,
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  dropdownListItem: {
    padding: '10px',
    cursor: 'pointer',
  },
  dropdownListItemHover: {
    backgroundColor: '#f0f0f0',
  },
  attachContainer: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  attachContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  attachIcon: {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },
  attachText: {
    color: 'black',
    fontSize: '14px',
    marginRight: '10px',
  },
  submitButton: {
    backgroundColor: 'white',
    color: '#070519',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #070519',
    width: '100%',
    cursor: 'pointer',
    fontSize: '16px',
  },
  submitButtonActive: {
    backgroundColor: '#070519',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    fontSize: '16px',
  },
  submitButtonDisabled: {
    backgroundColor: '#f0f0f0',
    color: '#ccc',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
    cursor: 'not-allowed',
    fontSize: '16px',
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
};

export default FeedbackForm;
