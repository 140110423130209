import React, { useEffect, useState } from 'react';
import { Text, Modal, Stack, Button, Paper, Flex, Loader } from '@mantine/core';
import { Google, LineOrLine, Whatsapp, LoginHomePageImg, LoginHomeLogo } from '../../assets/images';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { SignInWithSupabasePostGoogleSignIn, SignInWithSupabasePostWhatsAppSignIn } from '../../utils/AuthUtils';
import { userStore } from '../../stores/userStore';
import { initOTPless } from '../../utils/initOtpless';
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const userData = userStore((state) => state.userData);
  const setUserData = userStore((state) => state.setUserData);
  const clearUserData = userStore((state) => state.clearUserData);
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isResponsiveMobile = window.innerHeight <= 700 && window.innerWidth <= 400;

  useEffect(() => {
    const img = new Image();
    img.src = LoginHomePageImg;
    img.onload = () => setIsLoading(false);
  }, []);

  const handleOtplessUser = (otplessUser) => {
    clearUserData();
    const identities = otplessUser.identities;
    if (identities && identities.length > 0) {
      let mobileItem = identities.find((item) => item.identityType === 'MOBILE' && item.identityValue !== null && item.identityValue !== undefined);
      if (mobileItem && mobileItem.identityValue) {
        SignInWithSupabasePostWhatsAppSignIn({
          name: mobileItem.name ?? '',
          number: mobileItem.identityValue ?? '',
          setUserData,
        });
      }
    } else {
      console.log('No identities found');
    }

    setUserData(localStorage.getItem('userData'));

    setTimeout(() => {
      navigate('/news');
      window.location.reload();
    }, 2000);
  };

  const openModalAndInitializeOtpless = () => {
    setOpened(true);
    setTimeout(() => {
      initOTPless(handleOtplessUser);
    }, 300);
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      try {
        const tokens = await exchangeCodeForTokens(codeResponse.code);
        await SignInWithSupabasePostGoogleSignIn(tokens.id_token, 'google', tokens.access_token, setUserData, navigate);
      } catch (error) {
        console.log('Login Failed:', error);
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const exchangeCodeForTokens = async (code) => {
    const response = await axios.post('https://oauth2.googleapis.com/token', {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: window.location.origin,
      grant_type: 'authorization_code',
    });
    return response.data;
  };

  useEffect(() => {
    if (userData) {
      console.log('User Data', userData);
    }
  }, [userData]);

  if (isLoading) {
    return (
      <Stack justify="center" align="center" style={{ height: '100vh' }}>
        <Loader size="xl" />
      </Stack>
    );
  }
  return (
    <Stack
      spacing={0}
      align="center"
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Logo Header */}
      <div
        style={{
          position: 'absolute',
          top: isResponsiveMobile? '1em':'2em',
          left: 0,
          right: 0,
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={LoginHomeLogo} alt="PadhAI Logo" />
      </div>

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          scrollbarWidth: 'none', 
        }}
      >
        {/* Main Image Section */}
        <div style={{ marginTop: isResponsiveMobile?'4em':'6em', padding: '20px' }}>
          <img
            src={LoginHomePageImg}
            alt="Home Banner"
            style={{ width: '100%' }}
            loading="eager"
          />
        </div>

        {/* Login Section */}
        <Paper
          style={{
            width: '100%',
            backgroundColor: 'white',
            borderTopLeftRadius: '1.25em',
            borderTopRightRadius: '1.25em',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Stack
            spacing="xl"
            align="center"
            style={{
              width: '100%',
              padding: '0px 20px',
            }}
          >
            <Text
              fw={600}
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '30px',
                width:'320px',
                textAlign: 'center',
                color: '#070519',
                padding: '0px 0.5em',
              }}
            >
              Study smarter with AI in UPSC PadhAI
            </Text>

            <Button
              h={'3.2em'}
              onClick={openModalAndInitializeOtpless}
              fullWidth
              variant="outline"
              color="gray"
              radius="xl"
              size="lg"
              styles={(theme) => ({
                root: {
                  backgroundColor: 'white',
                  borderColor: theme.colors.gray[3],
                  '&:hover': {
                    backgroundColor: theme.colors.gray[0],
                  },
                  boxShadow: '0px 1px 1px 0px #0000002B, 0px 0px 4px 0px #00000015',
                  border: 'none',
                },
                label: {
                  color: theme.colors.gray[7],
                },
              })}
            >
              <Flex align="center" justify="center" gap="xs">
                <img src={Whatsapp} alt="WhatsApp Logo" width={48} height={48} />
                <Text fw={400} fz={'0.9em'} c={'#747474'}>
                  Login with WhatsApp
                </Text>
              </Flex>
            </Button>

            <img src={LineOrLine} alt="Or Line" style={{ width: '100%' }} />

            <Button
              h={'3.2em'}
              fullWidth
              variant="outline"
              color="gray"
              styles={(theme) => ({
                root: {
                  backgroundColor: 'white',
                  borderColor: theme.colors.gray[3],
                  '&:hover': {
                    backgroundColor: theme.colors.gray[0],
                  },
                  boxShadow: '0px 1px 1px 0px #0000002B, 0px 0px 4px 0px #00000015',
                  border: 'none',
                },
                label: {
                  color: theme.colors.gray[7],
                },
              })}
              radius="xl"
              size="lg"
              onClick={() => handleGoogleLogin()}
            >
              <Flex align="center" justify="center" gap="xs">
                <img src={Google} alt="Google Logo" width={48} height={48} />
                <Text fw={400} fz={'0.9em'} c={'#747474'}>
                  Login with Google
                </Text>
              </Flex>
            </Button>
          </Stack>
        </Paper>
      </div>

      <Modal 
        size={'26em'} 
        opened={opened} 
        onClose={() => setOpened(false)} 
        title="Login with WhatsApp" 
        centered
      >
        <div id="otpless-login-page"></div>
      </Modal>
    </Stack>
  );
};

export default Login;