import React, { useEffect, useRef, useState } from 'react';
import { Text, Button, Image, Stack, Center, Flex, Paper, ScrollArea, Card, Popover } from '@mantine/core';
import { TutorChatImg, HelpCircle, Add, TutorChatImg2, ArrowRight2, ArrowDown, Download, CloseIcon } from '../../../assets/images'; // Add CloseIcon import
import { userStore } from '../../../stores/userStore';
import { callGETAPI, callPOSTAPI } from '../../../utils/NetworkUtil';
import useHistoryStore from '../../../stores/historyStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../../../context/themeProvider';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS file
import InfoTips from './InfoTips';
import RichText from '../../../molecules/RichText/RichText';
import { IconCheck } from '@tabler/icons-react';
import { MixpanelEvent } from '../../../utils/mixpanelUtil';

const TutorChat = () => {
  const location = useLocation();
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 2500;
  const isResponsiveMobile = windowHeight <= 700;

  const { colors } = useTheme();
  const userData = userStore((state) => state.userData);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showInfoTips, setShowInfoTips] = useState(false);
  const navigate = useNavigate();
  const { ai_chat_history, setAiChatHistory } = useHistoryStore();
  const [selectedSource, setSelectedSource] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const dropdownRef = useRef(null);
  const [isSelectedFilter, setSelectedFilter] = useState(false);
  const [allChatsCount, setAllChatsCount] = useState(0);
  const [allChats, setAllChats] = useState([]);

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit');

    if (!isFilterApplied && (ai_chat_history.length === 0 || location.pathname === '/chat')) {
      callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/tutorhistory/?user_id=${userData.user}&source=${selectedSource}`, (resp) => {
        const sortedChatData = resp.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAiChatHistory(sortedChatData);
        setFilteredChats(sortedChatData);
        if (selectedSource === '') {
          setAllChatsCount(resp.data.data);
          setAllChats(resp.data.data);
        }
        // Show tips if it's the first visit and no history exists
        if (resp.data.data.length === 0 && !firstVisit) {
          setShowInfoTips(true);
          localStorage.setItem('firstVisit', 'true');
        }
      });
    } else if (!isFilterApplied) {
      // Set filtered chats without making another API call
      setFilteredChats(ai_chat_history);
    } else {
      // Apply filter if needed
      setFilteredChats(ai_chat_history);
    }
  }, [ai_chat_history.length, isFilterApplied, userData.user]);

  const handleDateRangeChange = (ranges) => {
    const { selection } = ranges;
    setDateRange([selection]);
  };
  useEffect(() => {
    // Apply filter if filter is applied
    if (isFilterApplied) {
      handleApplyFilter();
    }
  }, [dateRange, isFilterApplied, ai_chat_history]);
  const handleApplyFilter = () => {
    const { startDate, endDate } = dateRange[0];

    // Make sure endDate includes the whole day (set time to 23:59:59)
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    if (startDate.getTime() === endDate.getTime()) {
      const filtered = ai_chat_history.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate.toDateString() === startDate.toDateString();
      });

      setFilteredChats(filtered);
    } else {
      const filtered = ai_chat_history.filter((chat) => {
        const chatDate = new Date(chat.created_at);
        return chatDate >= startDate && chatDate <= adjustedEndDate;
      });

      setFilteredChats(filtered);
    }

    setIsFilterApplied(true);
    setPopoverOpened(false);
  };
  const handleInfoTip = () => {
    setShowInfoTips(true);
  };

  const handleClearFilter = () => {
    setFilteredChats(ai_chat_history);
    setIsFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const DownloadPdf = (chatId) => {
    const url = `${baseUrl}/api/download-pdf/?chat_id=${chatId}&chat_type=history`;

    window.open(url, '_blank');
    setTimeout(() => {
      MixpanelEvent('chat_answer_downloaded');
    }, 1000);
  };

  const handleCancel = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setPopoverOpened(false);
  };

  const handleChatSelect = (chat) => {
    if (chat.id) {
      navigate(`/chat/${chat.id}`, { state: { chat, chatTypeFlow: chat.source } });
    } else {
      navigate(`/chat/new/`, { state: { userId: userData.user, chatTypeFlow: 'ai_tutor' } });
    }
  };
  const formatDateRange = (startDate, endDate) => {
    const optionsWithoutYear = { day: '2-digit', month: 'short' };
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', optionsWithoutYear);
    const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', optionsWithYear);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const source = [
    { value: '', label: 'All Chats' },
    { value: 'duel', label: 'DUEL' },
    { value: 'news_feed', label: 'NEWS' },
    { value: 'pyq_search', label: 'PYQ Search' },
    { value: 'practice', label: 'PRACTICE' },
    { value: 'ai_tutor', label: 'Tutor Chat' },
  ];

  const getLabelFromSource = (value) => {
    const foundSource = source.find((item) => item.value === value);
    return foundSource ? foundSource.label : 'Unknown';
  };

  const convertStrongToMarkdown = (content) => {
    return content.replace(/<strong>(.*?)<\/strong>/g, '**$1**');
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    setFocused(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedFilter(true);
    setSelectedSource(value);
    callGETAPI(`${process.env.REACT_APP_BASE_URL}/api/tutorhistory/?user_id=${userData.user}&source=${value}`, (resp) => {
      setAiChatHistory(resp.data.data);
      setFilteredChats(resp.data.data);
    });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setFocused(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <style>
        {`
        p {
          margin : 0
        }
        .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
          padding-inline-end : 0
        }
      `}
      </style>
      <ScrollArea
        style={{
          height: windowHeight > 900 ? '100vh' : isResponsiveMobile ? '33em' : '45em',
          overflow: 'hidden',
          backgroundColor: 'white',
        }}
        type="never"
        offsetScrollbars
      >
        {ai_chat_history.length === 0 && !isSelectedFilter ? (
          <Stack spacing="xl" style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Flex align="center" justify="center">
              <Text fw={500} fz={'1.5em'} style={{ color: 'black' }}>
                Tutor Chat
              </Text>
              <Image ml={'0.3em'} src={HelpCircle} alt="Help icon" w={'1.5em'} />
            </Flex>
            <Text fw={400} fz={'1em'} style={{ color: '#6B7280' }} align="center">
              Chat with India's best UPSC AI tutor
            </Text>
            <Image src={TutorChatImg} alt="Tutor Chat icon" />
            <Button
              onClick={() => navigate('/chat/new/')}
              h={'3.5em'}
              border={`1px solid black`}
              style={{ backgroundColor: 'black' }}
              radius={'5em'}
              fullWidth
            >
              <Flex align={'center'} justify={'center'}>
                <Image src={Add} alt="Add icon" w={'1.5em'} mr={'0.3em'} />
                <Text fw={500} fz={'1em'} style={{ color: 'white' }}>
                  New Chat
                </Text>
              </Flex>
            </Button>
          </Stack>
        ) : (
          <Stack spacing="md" w="100%">
            <Paper p="md" shadow="xs" radius="md" w="100%" style={{ backgroundColor: 'white', borderColor: 'white' }}>
              <Stack spacing="md">
                <Image src={TutorChatImg2} alt="Ask a Book" />
                <Flex justify="space-between" align="center">
                  <Flex align="center" justify="center" style={{ cursor: 'pointer' }} onClick={handleInfoTip}>
                    <Text fw={500} fz={'1em'} style={{ color: '#1A1A1A' }}>
                      TutorChat
                    </Text>
                    <Image ml={'0.3em'} src={HelpCircle} alt="Help icon" />
                  </Flex>
                  <Button
                    onClick={() => navigate('/chat/new/')}
                    h={'2.5em'}
                    border={`1px solid black`}
                    style={{ backgroundColor: 'black' }}
                    radius={'5em'}
                  >
                    <Flex align={'center'} justify={'center'}>
                      <Image src={Add} alt="Add icon" w={'1.5em'} mr={'0.3em'} />
                      <Text fw={500} fz={'1em'} style={{ color: 'white' }}>
                        New Chat
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </Stack>
            </Paper>
            <Text fw={600} fz={'0.9em'} style={{ color: '#6B7280' }}>
              HISTORY
            </Text>
            <div style={{ display: 'flex', gap: '1.2rem', padding: '10px', width: '100%' }}>
              <div style={{ flexGrow: 3, width: '60%' }}>
                <DropdownSelect
                  selectedSource={selectedSource}
                  options={source}
                  setFocused={setFocused}
                  ArrowDown={ArrowDown}
                  handleSelect={handleSelect}
                  toggleDropdown={toggleDropdown}
                  isOpen={isOpen}
                  dropdownRef={dropdownRef}
                  focused={focused}
                />
              </div>
              <div>
                {isFilterApplied ? (
                  <Button
                    style={{
                      backgroundColor: 'white',
                      borderColor: '#E5E7EB',
                      color: '#1A1A1A',
                      border: `1px solid #E5E7EB`,
                    }}
                    onClick={handleClearFilter}
                  >
                    {formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}
                    <Image src={CloseIcon} alt="Close Filter" style={{ width: '2em', marginRight: '1em' }} />
                  </Button>
                ) : (
                  <Popover
                    width={300}
                    position="fixed"
                    withArrow
                    shadow="md"
                    opened={popoverOpened}
                    onChange={setPopoverOpened}
                    style={{ zIndex: 1000 }}
                  >
                    <Popover.Target>
                      <Button
                        onClick={() => setPopoverOpened((o) => !o)}
                        className="custom-button"
                        fullWidth
                        styles={(theme) => ({
                          root: {
                            backgroundColor: 'white',
                            color: '#747474',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            height: '36px',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          },
                        })}
                      >
                        Sort by Date
                        <img src={ArrowDown} alt="Icon" style={{ marginLeft: '0.4em' }} />
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown
                      style={{
                        zIndex: 1001,
                        position: 'absolute',
                        top: '60%',
                        left: '50%',
                        width: window.innerWidth <= 400 ? '22em' : '28rem',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={handleDateRangeChange}
                        moveRangeOnFirstSelection={false}
                        rangeColors={[colors.coloredButton]}
                        maxDate={new Date()}
                      />
                      <Flex mt="md" justify="space-between" w="100%">
                        <Button
                          variant="outline"
                          color="gray"
                          onClick={handleCancel}
                          style={{ flex: 1, marginRight: '8px', color: 'black', border: 'none' }}
                        >
                          Cancel
                        </Button>

                        <Button onClick={handleApplyFilter} style={{ flex: 1, backgroundColor: 'black', borderRadius: '200px', color: 'white' }}>
                          Select Range
                        </Button>
                      </Flex>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </div>
            </div>
            <Stack mb={'2em'} style={{ overflow: 'hidden', marginTop: '1em' }}>
              <Stack spacing="md" mb={'45%'} p="4px">
                {filteredChats.length === 0 && (
                  <Center h={200}>
                    <Text>{`No chat found for ${selectedSource || formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}`}</Text>
                  </Center>
                )}

                {filteredChats.map((chat, index) => {
                  const isTutorChat = chat.source === undefined && chat.category;
                  const originalIndex = allChats.findIndex((originalChat) => originalChat.id === chat.id);
                  if (chat.messages && chat.messages.length > 0) {
                    return (
                      <Card
                        key={index}
                        shadow="sm"
                        p="lg"
                        radius="md"
                        withBorder
                        style={{
                          cursor: 'pointer',
                          backgroundColor: 'white',
                          borderColor: '#E5E7EB',
                          maxWidth : '28em'
                        }}
                      >
                        <Flex justify="space-between" align="center">
                          <Stack spacing="xs" style={{ width: '100%' }}>
                            <Flex justify="space-between" align="center">
                              <Text fw={600} style={{ color: '#6B7280' }} fz={'0.9em'}>
                                CHAT #{allChatsCount.length - originalIndex}
                              </Text>
                              <Text fw={600} fz={'0.9em'} style={{ color: '#6B7280' }}>
                                {new Date(chat.created_at).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: 'short',
                                  year: 'numeric',
                                })}
                              </Text>
                            </Flex>
                            <RichText
                              content={convertStrongToMarkdown(
                                (chat.messages[chat.messages.length - 1].ai || chat.messages[chat.messages.length - 1].content)?.substring(0, 65) +
                                  '...'
                              )}
                              streamEnd={true}
                            />
                            <Flex justify="space-between" align="center" style={{ width: '100%' }}>
                              <div style={{ padding: '0.3em 0.7em', backgroundColor: '#F3F3F3', borderRadius: '8px' }}>
                                <span style={{ color: '#464646', fontSize: '12px', fontWeight: 'bold' }}>
                                  {isTutorChat ? getLabelFromSource('ai_tutor') : getLabelFromSource(chat.source)}
                                </span>
                              </div>
                              <Flex justify="space-between" align="center" gap={'md'}>
                                <Image
                                  onClick={() => DownloadPdf(chat.id)}
                                  src={Download}
                                  alt="Download Chat"
                                  style={{ padding: '0.3em', border: '1px solid #DCDCDC', borderRadius: '8px' }}
                                />
                                <Image
                                  src={ArrowRight2}
                                  onClick={() => handleChatSelect(chat)}
                                  alt="Arrow Right"
                                  style={{ padding: '0.3em', border: '1px solid #DCDCDC', borderRadius: '8px' }}
                                />
                              </Flex>
                            </Flex>
                          </Stack>
                        </Flex>
                      </Card>
                    );
                  }
                  return null; // Return null if there are no messages
                })}
              </Stack>
            </Stack>
          </Stack>
        )}
      </ScrollArea>
      {showInfoTips && (
        <InfoTips
          onClose={() => {
            setShowInfoTips(false);
            MixpanelEvent('screen_closed', { source_screen: 'Tutor Chat Info Tip', close_type: 'modal' });
          }}
        />
      )}
    </>
  );
};

const DropdownSelect = ({ options, selectedSource, focused, setFocused, ArrowDown, handleSelect, toggleDropdown, isOpen, dropdownRef }) => {
  return (
    <div style={{ position: 'relative', zIndex: 1000, flex: 1 }} ref={dropdownRef}>
      <div
        onFocus={() => setFocused(true)}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          border: focused ? '1px solid #747474' : '1px solid #ccc',
          borderRadius: '8px',
          cursor: 'pointer',
          justifyContent: 'space-between',
          width: '100%',
          height: '36px',
          fontSize: '14px',
          gap: 4,
          outline: 'none',
        }}
        onClick={toggleDropdown}
      >
        <span
          style={{
            color: !selectedSource ? 'gray' : 'black',
            fontFamily: 'Poppins',
          }}
        >
          {selectedSource ? options.find((opt) => opt.value === selectedSource)?.label : options[0].label}
        </span>
        <img src={ArrowDown} alt="Icon" style={{ width: '14px' }} />
      </div>

      {isOpen && (
        <div
          style={{
            width: '100%',
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 9999,
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '0px 0px 12px 12px',
          }}
        >
          <div style={{ padding: '1em', textAlign: 'left', maxHeight: '300px', overflow: 'scroll', scrollbarWidth: 'none' }}>
            {options.map((option, index) => (
              <div key={option.value} onClick={() => handleSelect(option.value)}>
                <div
                  style={{
                    cursor: 'pointer',
                    padding: '0.8em',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ fontFamily: 'Poppins', color: selectedSource === option.value ? '#070519' : '#464646' }}>{option.label}</span>
                  </div>
                  {option.value === selectedSource && (
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: '#61BD85',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconCheck color="#fff" size={14} />
                    </div>
                  )}
                </div>
                {index !== options.length - 1 && <div style={{ height: '1px', backgroundColor: '#E8E8E8' }} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TutorChat;
