import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Stack,
  Text,
  Card,
  Flex,
  ScrollArea,
  Image,
  TextInput,
  Divider,
  Group,
  Container,
  Paper,
  Radio,
  Textarea,
  Button,
  Title,
  Loader,
  Box,
} from '@mantine/core';
import {
  SendMsg,
  NewChat,
  ArrowRight2,
  ChevronUp,
  ChevronDown,
  Edit2,
  DisLike,
  Like,
  LikeFill,
  DisLikeFill,
  ArrowLeft,
  Download,
} from '../../../assets/images';
import 'katex/dist/katex.min.css';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../../stores/userStore';
import { useTheme } from '../../../context/themeProvider';
import ReconnectingWebSocket from 'reconnecting-websocket';
import AnswerComponentWrapper from '../AnswerComponentWrapper';
import QuestionsComp from '../QuestionsComp';
import { MixpanelEvent, sendPostHogEvent } from '../../../utils/mixpanelUtil';

const ChatScreen = () => {
  const location = useLocation();
  const { colors, colorScheme } = useTheme(); // Always call useTheme at the top
  const isNewsRoute = location.pathname.startsWith('/news'); // Check if it's a news route
  const appliedColors = isNewsRoute ? colors : { text: '#000', cardBackground: '#f9f9f9' }; // Conditionally apply colors
  const { chatTypeFlow, chat, aiTutorParams } = location.state || {};
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 2500;
  const isRespMobile = window.innerWidth <= 700;

  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [expression, setMessage] = useState('');
  const [ws, setWs] = useState(null);
  const [chatData, setChatData] = useState(null);

  const [showSuggestionButton, setShowSuggestionButton] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const wsRef = useRef(null);
  const scrollAreaRef = useRef(null);
  const defaultCategory = 'history';

  const [isFeedbackShown, setFeedbackShown] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [details, setDetails] = useState('');

  const [chatIdState, setChatIdState] = useState({
    chatId: aiTutorParams?.id || null,
    nodeId: aiTutorParams?.node_id || null,
    chatgraphId: aiTutorParams?.chatgraph_id || null,
  });
  const [chatType, setchatType] = useState('ai_tutor');

  const [selectedQuestion, setSelectedQuestion] = useState(aiTutorParams?.preSelectQuestion || {});
  const [endpoint, setEndpoint] = useState(null);

  const chatIdStateRef = useRef(chatIdState);
  const selectedQuestionRef = useRef(selectedQuestion);

  const [isStreamEnd, setStreamEnd] = useState(false);

  const [loading, setLoading] = useState(false);
  const [promptType, setPromptType] = useState({
    isTypedPrompt: false,
    isHybridPrompt: false,
  });

  const userOptions = selectedQuestion ? '' : null;
  const userAnswere = selectedQuestion ? '' : null;

  const [feedbackStates, setFeedbackStates] = useState({});
  const [currentInteractionIndex, setCurrentInteractionIndex] = useState(null);

  useEffect(() => {
    if (aiTutorParams) {
      setChatData({
        id: aiTutorParams.id,
        messages: aiTutorParams?.messages || [],
      });
      setchatType(aiTutorParams.chatType);
    } else if (chat && chatType) {
      if (chatTypeFlow === 'duel' || chatTypeFlow === 'pyq_search' || chatTypeFlow === 'news_feed') {
        const updatedMessages = [];
        for (let i = 0; i < chat.messages.length; i++) {
          const msg = chat.messages[i];
          if (msg.user) {
            updatedMessages.push({
              role: 'user',
              content: msg.user,
            });
          }
          if (msg.ai) {
            updatedMessages.push({
              role: 'assistant',
              content: msg.ai,
            });
          }
        }

        const updatedChat = {
          ...chat,
          messages: updatedMessages,
        };

        setChatData(updatedChat);
      } else {
        setChatData(chat);
      }

      setChatIdState({
        chatId: chat.id,
      });
      setchatType(chatType);
    }
  }, [aiTutorParams, chat, ws, chatType]);

  const [calledInitialRequest, setCalledInitialRequest] = useState(false);

  useEffect(() => {
    if (!userId) return;

    // Default socket URL
    let socketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/livec/${userId}/`;

    // Set the correct endpoint based on chatType and category
    if (aiTutorParams?.chatType === 'ai_tutor' || chatTypeFlow !== undefined) {
      const aiTutorEndpoint =
        aiTutorParams?.category === 'csat'
          ? `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/asyncai/csat_chat/`
          : `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/asyncai/`;
      setEndpoint(aiTutorEndpoint);
      socketUrl = aiTutorEndpoint;
    } else {
      setEndpoint('');
    }

    if (chatData?.id) {
      socketUrl = `${socketUrl}?chat_id=${chatData.id}`;
    }

    const socket = new ReconnectingWebSocket(socketUrl);
    wsRef.current = socket;
    setWs(socket);
    socket.onopen = () => {
      if (socket && socket.readyState && socket.readyState === 1) {
        if ((aiTutorParams?.flowType === 'postGame' || aiTutorParams?.flowType === 'history') && !calledInitialRequest) {
          if (aiTutorParams.chatType === 'ai_tutor') {
            callInitialRequest(socket);
          }
        }
      }
    };
    socket.onmessage = (event) => {
      const responseData = JSON.parse(event.data);

      if (responseData) {
        const message = responseData.first_response || responseData.message || '';
        const result = responseData.result || '';

        if (responseData.chat_id) {
          setChatData((prevData) => ({
            ...prevData,
            id: responseData.chat_id,
          }));
          setChatIdState({
            chatId: responseData.chat_id,
            nodeId: responseData.node_id || null,
            chatgraphId: responseData.chatgraph_id || null,
          });
        }

        if (result === 'stream_end' || message === 'stream_end') {
          setStreamEnd(true);
          setLoading(true);

          if (chatIdState.chatId !== null) {
            const eventData = {
              source: chatType,
              chat_id: responseData.chat_id,
              msg_id: responseData.node_id,
              answer_status: 'Finished',
              category: defaultCategory,
            };
            MixpanelEvent('chat_answer', eventData);
            sendPostHogEvent('chat_answer', eventData);
          }
        } else {
          // Check if the last message is from the assistant
          setChatData((prevData) => {
            const lastMessage = prevData.messages[prevData.messages.length - 1];

            // If the last message is from the assistant, append to it
            if (lastMessage?.role === 'assistant') {
              const updatedMessages = prevData.messages.map((msg, idx) =>
                idx === prevData.messages.length - 1 ? { ...msg, content: msg.content + message + result } : msg
              );
              return { ...prevData, messages: updatedMessages };
            } else {
              // If no assistant message, add a new one
              return {
                ...prevData,
                messages: [
                  ...prevData.messages,
                  {
                    role: 'assistant',
                    content: message + result,
                  },
                ],
              };
            }
          });
        }

        if (responseData.suggestions && responseData.suggestions.length > 0) {
          setLoading(false);
          setShowSuggestionButton(true);
          setSuggestions(responseData.suggestions);
        }
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socket.onerror = (error) => {
      console.error('WebSocket error ', error);
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [userId]);

  const optionKey = ['a', 'b', 'c', 'd'];

  const createPayload = (messageToSend = null) => {
    let c_answer = null;
    if (selectedQuestionRef.current && selectedQuestionRef.current.correct_answer) {
      c_answer =
        aiTutorParams?.chatType === 'ai_tutor' ? selectedQuestionRef.current.correct_answer : optionKey[selectedQuestionRef.current.correct_answer];
    }

    const payload = {
      question_id: selectedQuestion?.question_id || null,
      question: selectedQuestionRef.current?.question || null,
      user_option: userOptions,
      user_answer: userAnswere,
      profile_id: userId,
      choices: selectedQuestionRef.current?.choices || null,
      options: selectedQuestionRef.current?.options || null,
      correct_answer: c_answer,
      chat_id: chatIdState.chatId || null,
      node_id: chatIdState.nodeId || null,
      chatgraph_id: chatIdState.chatgraphId || null,
      category: aiTutorParams?.category || defaultCategory,
      render_type: aiTutorParams?.category === 'csat' ? 'mathjax' : 'text',
      expression: messageToSend,
      source: aiTutorParams?.source || chatTypeFlow || 'ai_tutor',
    };

    return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null));
  };

  const callInitialRequest = (socket) => {
    if (socket && socket.readyState && socket.readyState === 1) {
      if ((aiTutorParams.flowType === 'postGame' || aiTutorParams.flowType === 'history') && !calledInitialRequest) {
        if (aiTutorParams.chatType === 'ai_tutor') {
          let c_answer = null;
          if (selectedQuestionRef.current && selectedQuestionRef.current.correct_answer) {
            c_answer =
              aiTutorParams?.chatType === 'ai_tutor'
                ? selectedQuestionRef.current.correct_answer
                : optionKey[selectedQuestionRef.current.correct_answer];
          }
          const payload = createPayload();
          setCalledInitialRequest(true);
          socket.send(JSON.stringify(payload));
        }
      }
    }
  };

  const handleSendMessage = (messageToSend = expression, type) => {
    setFeedbackStates({});
    if (ws && messageToSend) {
      setShowSuggestionButton(false);
      setShowSuggestions(false);
      setStreamEnd(false);

      if (type === 'PrePrompt') {
        MixpanelEvent('chat_start', {
          source: 'ai_chat',
          type: 'preprompt',
        });
        sendPostHogEvent('chat_start', {
          source: 'ai_chat',
          type: 'preprompt',
        });
      }

      if (promptType.isTypedPrompt === true) {
        MixpanelEvent('chat_start', {
          source: 'ai_chat',
          type: 'typed',
        });
        sendPostHogEvent('chat_start', {
          source: 'ai_chat',
          type: 'typed',
        });
      }

      if (type === 'SuggestionPrompt') {
        const eventData = {
          source: chatType,
          category: aiTutorParams?.category || defaultCategory,
          chat_method: 'suggestions',
          msg_id: chatIdState.nodeId,
          chat_id: chatIdState.chatId,
        };
        MixpanelEvent('chat_question', eventData);
        sendPostHogEvent('chat_question', eventData);
      }

      if (promptType.isHybridPrompt === true) {
        const eventData = {
          source: chatType,
          category: defaultCategory,
          chat_method: 'hybrid',
          msg_id: chatIdState.nodeId,
          chat_id: chatIdState.chatId,
        };
        MixpanelEvent('chat_question', eventData);
        sendPostHogEvent('chat_question', eventData);
      }

      const payload = createPayload(messageToSend);

      ws.send(JSON.stringify(payload));
      setChatData((prevData) => ({
        ...prevData,
        messages: [
          ...(prevData?.messages || []),
          {
            role: 'user',
            content: messageToSend,
          },
        ],
      }));
      setMessage('');
      setPromptType({
        isTypedPrompt: false,
        isHybridPrompt: false,
      });
    }
  };
  const fetchSuggestedQuestions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/chat-suggestions/`);
      const data = await response.json();
      setSuggestedQuestions(data.suggestions);
    } catch (error) {
      console.error('Error fetching suggested questions:', error);
    }
  };

  useEffect(() => {
    fetchSuggestedQuestions();
  }, []);

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('.mantine-ScrollArea-viewport');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (isStreamEnd) {
      scrollToBottom();
    }
  }, [chatData, isStreamEnd]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMessageChange = (event) => {
    setMessage(event.currentTarget.value);
    setPromptType({ isTypedPrompt: true });
  };

  const toggleSuggestions = () => {
    setShowSuggestions(!showSuggestions);
    MixpanelEvent('suggestions_visibility_toggled', { action: showSuggestions ? 'hidden' : 'shown' });
    sendPostHogEvent('suggestions_visibility_toggled', { action: showSuggestions ? 'hidden' : 'shown' });
  };

  const handleDislikeModal = (index) => {
    setFeedbackShown(!isFeedbackShown);
    setCurrentInteractionIndex(index);
  };

  const submitFeedbackReaction = (data) => {
    if (ws && ws.readyState && ws.readyState === WebSocket.OPEN) {
      if (data.suggestion_text) {
        ws.send(JSON.stringify({ suggestions_feedback: data.suggestion_text, chat_id: chatData.id, profile_id: userId }));
      } else {
        ws.send(JSON.stringify({ ...data, chat_id: chatData.id, profile_id: userId }));
      }
    }
  };

  const handleFeedbackSubmit = async () => {
    const feedbackData = {
      feedback_remark: feedback,
      additionalNotes: feedback === 'Other reason (not mentioned above).' ? details : '',
      feedback: -1,
    };
    submitFeedbackReaction({ suggestion_text: feedbackData });
    setFeedbackShown(false);

    setFeedbackStates((prevStates) => {
      const newStates = { ...prevStates };
      newStates[currentInteractionIndex] = newStates[currentInteractionIndex] === 'dislike' ? null : 'dislike';
      return newStates;
    });

    setFeedback('');
    setDetails('');
  };

  const handleLikeSuggestion = async (index) => {
    const feedbackData = {
      feedback_remark: '',
      additionalNotes: '',
      feedback: 1,
    };
    submitFeedbackReaction({ suggestion_text: feedbackData });
    // Update feedback states
    setFeedbackStates((prevStates) => {
      const newStates = { ...prevStates };
      newStates[index] = newStates[index] === 'like' ? null : 'like';
      return newStates;
    });
    MixpanelEvent('suggestion_liked', { suggestion_id: index, action: feedbackStates[index] === 'like' ? 'unliked' : 'liked' });
    sendPostHogEvent('suggestion_liked', { suggestion_id: index, action: feedbackStates[index] === 'like' ? 'unliked' : 'liked' });
  };

  const DownloadPdf = () => {
    // Use aiTutorParams?.chatType or default to 'history' if not provided
    const downloadChatType = aiTutorParams?.chatType || 'history';
    const url = `${process.env.REACT_APP_BASE_URL}/api/download-pdf/?chat_id=${chatIdState.chatId}&chat_type=${downloadChatType}`;
    window.open(url, '_blank');

    setTimeout(() => {
      MixpanelEvent('chat_answer_downloaded');
      sendPostHogEvent('chat_answer_downloaded');
    }, 1000);
  };

  return (
    <Stack spacing={0} style={{ height: `${windowHeight}px`, maxHeight: `${windowHeight}px`, overflow: 'none', width: '100%' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px 14px 12px 0px ',
          backgroundColor: appliedColors.cardBackground,
          color: appliedColors.text,
          height: '4em',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      >
        <Flex align="center">
          <img
            src={ArrowLeft}
            alt="Arrow Left"
            style={{ cursor: 'pointer', marginLeft: '1em' }}
            onClick={() => {
              navigate(-1);
              MixpanelEvent('navigation_back', { source_screen: 'tutor_chat' });
              sendPostHogEvent('navigation_back', { source_screen: 'tutor_chat' });
            }}
          />
          <Text fz="x" style={{ marginLeft: '0.5em', fontWeight: '500', fontSize: '16px' }}>
            New Chat
          </Text>
        </Flex>
        {chatIdState.chatId ? <img src={Download} onClick={DownloadPdf} alt="Download chat" style={{ cursor: 'pointer' }} /> : null}
      </Box>
      <ScrollArea ref={scrollAreaRef} style={{ flex: 1 }} p="md" offsetScrollbars>
        <Container size="md" px="xs" mt={'3em'}>
          <Stack>
            {chatData?.messages.map((item, index) => (
              <React.Fragment key={index}>
                {item.role === 'user' && <QuestionsComp item={item} />}
                {item.role === 'assistant' && item.content && (
                  <AnswerComponentWrapper
                    componentName={'answer'}
                    scrollToBottom={scrollToBottom}
                    index={index}
                    item={item}
                    callBack={handleDislikeModal}
                    isLast={index === chatData.messages.length - 1}
                    streamEnd={isStreamEnd}
                    type={aiTutorParams?.flowType}
                  />
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Container>
      </ScrollArea>

      {(!chatData?.messages || chatData.messages.length === 0) && (
        <Container size="sm" px="xs" style={{ overflowY: 'auto', maxHeight: `${windowHeight - 150}px`, width: '100%' }}>
          <Stack spacing="xl" align="center" p={isRespMobile ? '0 0 0 10px' : '15px'}>
            <Image src={NewChat} alt="New Chat" style={{ width: '100%', maxWidth: '10em' }} />
            <Text fw={800} fz={'1em'} align="center">
              Ask AI directly
            </Text>
            <Text fw={500} fz={'1em'} align="center">
              Ask directly by typing your question below
            </Text>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {/* Left Dot */}
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#A0A0A0',
                }}
              />
              {/* Left Divider Line */}
              <div style={{ flex: 1, borderBottom: '1px solid #A0A0A0', marginRight: '8px' }} />
              {/* Bold OR */}
              <span style={{ fontWeight: 'bold', color: '#A0A0A0' }}>OR</span>
              {/* Right Divider Line */}
              <div style={{ flex: 1, borderBottom: '1px solid #A0A0A0', marginLeft: '8px' }} />
              {/* Right Dot */}
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: '#A0A0A0',
                }}
              />
            </div>

            <Text fw={800} fz={'1em'} align="center">
              Try asking these
            </Text>
            <Stack spacing="md" style={{ width: '100%' }}>
              {Array.isArray(suggestedQuestions) &&
                suggestedQuestions.map((question, index) => (
                  <Card
                    key={index}
                    shadow="sm"
                    p="lg"
                    radius="md"
                    withBorder
                    onClick={() => {
                      setMessage(question.content);
                      handleSendMessage(question.content, 'PrePrompt');
                    }}
                    style={{
                      backgroundColor: appliedColors.cardBackground,
                      borderColor: appliedColors.borderColor,
                      cursor: 'pointer',
                    }}
                  >
                    <Flex justify="space-between" align="center">
                      <Text fw={400} style={{ color: appliedColors.text }} fz={'0.9em'} lineClamp={3}>
                        {question.content}
                      </Text>
                      <Image src={ArrowRight2} alt="Arrow Right" style={{ width: '1.5em', color: appliedColors.arrowColor }} />
                    </Flex>
                  </Card>
                ))}
            </Stack>
          </Stack>
        </Container>
      )}

      <Stack style={{ boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)' }} p="md">
        {showSuggestionButton && (
          <>
            <Group
              style={{
                width: '100%',
                cursor: 'pointer',
              }}
              justify="center"
              onClick={toggleSuggestions}
            >
              <Text style={{ color: '#8B8B8B', fontSize: '12px', fontWeight: '600' }}>
                {showSuggestions ? 'Hide Suggestions'.toUpperCase() : 'View Suggestions'.toUpperCase()}
              </Text>
              <Image src={showSuggestions ? ChevronDown : ChevronUp} alt="Chevron Icon" width="0.6em" />
            </Group>

            {showSuggestions && (
              <ScrollArea
                style={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  width: 'auto',
                }}
              >
                <Flex
                  style={{
                    gap: '16px',
                    padding: '16px',
                    minWidth: 'max-content',
                  }}
                >
                  {suggestions.map((suggestion, index) => (
                    <Card
                      key={index}
                      shadow="sm"
                      radius="md"
                      withBorder
                      style={{
                        width: '250px',
                        flex: '0 0 auto',
                        padding: '20px',
                      }}
                    >
                      <Text
                        size="sm"
                        style={{
                          marginBottom: '8px',
                          cursor: 'pointer',
                          fontFamily: 'Arial, sans-serif',
                          color: 'black',
                          fontSize: '16px',
                        }}
                        onClick={() => {
                          setMessage(suggestion);
                          handleSendMessage();
                        }}
                      >
                        {suggestion}
                      </Text>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <img
                          src={Edit2}
                          alt="Edit"
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => {
                            setMessage(suggestion);
                            handleSendMessage();
                            setPromptType({ isHybridPrompt: true });
                            MixpanelEvent('suggestion_edit_clicked', { suggestion_id: index });
                          }}
                        />
                        <img
                          src={ArrowRight2}
                          alt="Arrow Right"
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => handleSendMessage(suggestion, 'SuggestionPrompt')}
                        />
                      </div>
                      <Divider my="md" />
                      <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'flex-end', marginTop: '20px' }}>
                        {/* <Badge color="blue" variant="light">
                          POPULAR
                        </Badge> */}
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <img
                            src={feedbackStates[index] === 'dislike' ? DisLikeFill : DisLike}
                            alt="Dislike"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleDislikeModal(index)}
                          />
                          <img
                            src={feedbackStates[index] === 'like' ? LikeFill : Like}
                            alt="Like"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleLikeSuggestion(index)}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </Flex>
              </ScrollArea>
            )}
          </>
        )}
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader color="blue" height={'3em'} />
          </div>
        )}

        <Flex align="center">
          <TextInput
            bg={'#F3F3F3'}
            placeholder="Type a message..."
            value={expression}
            onChange={handleMessageChange}
            style={{ flex: 1, marginRight: '10px', backgroundColor: '#F5F5F5' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSendMessage(expression);
              }
            }}
          />
          <Image onClick={() => handleSendMessage(expression)} src={SendMsg} alt="Send" style={{ width: '2.4em', cursor: 'pointer' }} />
        </Flex>
      </Stack>

      {isFeedbackShown && (
        <Paper
          shadow="sm"
          padding="lg"
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            borderRadius: '15px 15px 0 0',
            backgroundColor: '#fff',
            boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1)',
            padding: '3em',
            boxSizing: 'border-box',
            maxWidth: isMobile ? '28em' : '22vw',
          }}
        >
          <button
            onClick={() => setFeedbackShown(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#000',
            }}
          >
            &times; {/* Unicode character for "×", the multiplication sign */}
          </button>
          <Title order={4} style={{ marginBottom: '15px', textAlign: 'center', fontWeight: 600 }}>
            Please provide your feedback
          </Title>
          <Stack spacing="lg">
            <Radio.Group value={feedback} onChange={setFeedback} size="1em">
              <Stack spacing="lg">
                {[
                  'Factual inaccuracy.',
                  'Does not answer what i asked.',
                  'Unnecessarily long without the exact information.',
                  'This answer is ok, but the next answer did not come up.',
                  'Other reason (not mentioned above).',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} p={'md'} />
                ))}
              </Stack>
            </Radio.Group>

            {feedback === 'Other reason (not mentioned above).' && (
              <>
                <Text>Other reason*</Text>
                <Textarea
                  placeholder="Provide details about your issue here..."
                  value={details}
                  rows={4}
                  radius="sm"
                  style={{ marginTop: '10px', fontSize: '14px' }}
                  onChange={(e) => setDetails(e.currentTarget.value)}
                />
              </>
            )}

            <Group position="center" mt="lg">
              <Button
                fullWidth
                radius="xl"
                size="md"
                variant="filled"
                color="dark"
                disabled={!feedback}
                style={{
                  backgroundColor: feedback ? '#000' : '#ccc',
                  fontWeight: 500,
                }}
                onClick={handleFeedbackSubmit}
              >
                Submit Feedback
              </Button>
            </Group>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

export default ChatScreen;
