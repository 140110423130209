import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Flex, Text, Image, Stack, Box, ScrollArea } from '@mantine/core';
import { ArrowLeft, DiagonalPolygon, Vector } from '../../../assets/images';
import { IconX } from '@tabler/icons-react';

const SelectBook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bookFlowType } = location.state || {};
  const [allBooks, setAllBooks] = useState([]);
  const [bookData, setBookData] = useState({
    bookList: [],
    classList: [],
    loader: true
  });
  const windowHeight = window.innerHeight;
  const isResponsiveMobile = windowHeight <= 700;
  const [activeClass, setActiveClass] = useState('')


  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`, { state: { book, flowType: bookFlowType } });
  };

  function findAvailableClasses(books) {
    let classes = [];

    for (let book of books) {
      if (book.metadata && book.metadata.class && !classes.includes(book.metadata.class)) {
        classes.push(book.metadata.class);
      }
    }

    return classes;
  }

  useEffect(() => {
    const getBooks = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/bookrequest/?category=${bookFlowType || bookFlowType === 'book'}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const json = await response.json();
        let classes = findAvailableClasses(json)
        setAllBooks(json)
        setBookData({
          loader: false,
          classList: classes,
          bookList: json
        })
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };
    getBooks();
  }, [bookFlowType]);

  const BookCardColor = (bookid) => {
    switch (bookid) {
      case 0:
        return "#CBF2D8"
      case 1:
        return "#D5ECFA"
      case 2:
        return "#FFE5BD"
      case 3:
        return "#FFEDAC"
      case 4:
        return "#D4D7FF"
      case 5:
        return "#C1E5FF"
      case 6:
        return "#F2D7CB"
      case 7:
        return "#FFEDAC"
      case 8:
        return "#C1E5FF"
      case 9:
        return "#D5E4FA"
      case 10:
        return "#D4D7FF"
      case 11:
        return "#F5DAC3"
      case 12:
        return "#D2D3D7"
      case 13:
        return "#E5C8CD"
      case 14:
        return "#FFE5BD"
      case 15:
        return "#D5ECFA"
      case 16:
        return "#FFDA98"
      default:
        return "#FFE5BD"
    }
  }

  const handleClassClick = (selectedClass) => {
    setActiveClass((prevClass) => (prevClass === selectedClass ? '' : selectedClass));
    setBookData((prevData) => ({
      ...prevData,
      bookList: selectedClass === activeClass
        ? allBooks
        : allBooks.filter((book) => book.metadata?.class === selectedClass),
    }));
  }

  const BookItem = ({ book_id, book_name, book_description, image, color, onClick, metadata }) => (
    <Flex direction="column" style={{ ...styles.bookItem, backgroundColor: color, zIndex: 5 }} onClick={onClick}>
      <Flex style={{ height: '100%' }}>
        <Box style={styles.bookImage}>
          <Image
            src={image}
            width={100}
            height={140}
            fit="contain"
            style={{
              transform: 'rotate(15deg)',
              transformOrigin: 'center',
            }}
          />
          {metadata.class && (
            <>
              <div style={styles.newTag}>
                {`Class ${metadata.class}`}
              </div>
              <div style={styles.polImg}>
                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 8.5L10 0H0V8.5Z" fill="#B6B5B5" />
                </svg>
              </div>
            </>
          )}
        </Box>
        <Box style={styles.bookContent}>
          <Text fw={500} size="md" style={styles.bookName}>
            {book_name}
          </Text>
          <Text style={styles.bookDescription}>
            {book_description}
          </Text>
        </Box>
        <Box style={styles.playButton}>
          <img src={Vector} alt='Icon' style={{ width: '100%' }} />
        </Box>
      </Flex>
    </Flex>
  );

  return (
    <>
      <style>
        {bookFlowType === "ncert" && `
          .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
           
          }
        `}
        {bookFlowType === "upsc" && `
          .m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
            padding-inline-end: 0;
          }
        `}
      </style>

      {bookData.loader && (
        <div style={styles.spinnerContainer}>
          <div style={styles.spinnerLoader}></div>
        </div>
      )}

      {!bookData.loader && (
        <div style={styles.container}>
          <Flex align="center" mt={'0.5em'} style={styles.header}>
            <Image src={ArrowLeft} alt="Arrow Left" style={styles.backArrow} onClick={() => navigate(-1)} />
            <Text fw={500} style={styles.headerText}>
              {bookFlowType === "ncert" ? "Select a NCERT Book" : "Select Book"}
            </Text>
          </Flex>

          {bookFlowType === "ncert" && bookData.classList.length > 0 && (
            <div style={styles.classContainer}>
              {bookData.classList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      ...styles.classButton,
                      color: activeClass === item ? '#6374FA' : "#000000",
                      backgroundColor: activeClass === item ? '#EBF3FF' : "transparent",
                      borderColor: activeClass === item ? '#6374FA' : "#000000",
                    }}
                    onClick={() => handleClassClick(item)}
                  >
                    {`Class ${item}`}
                    {activeClass === item ? <IconX color="#6374FA" size={16} style={{ marginBottom: "0.1em" }} /> : null}
                  </div>
                )
              })}
            </div>
          )}

          <ScrollArea style={{
            height: windowHeight > 900 ? '100vh' : isResponsiveMobile ? '33em' : '45em',
            overflow: 'hidden', backgroundColor: 'white',
            padding: "1em",
          }}
            type='never'
            offsetScrollbars>
            <Stack spacing="xs" mb={'2em'}>
              {bookData.bookList.map((book, index) => (
                <BookItem key={book.book_id} {...book} color={BookCardColor(book.book_id)} onClick={() => handleBookClick(book)} />
              ))}
            </Stack>
          </ScrollArea>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    maxWidth: '28rem',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: 'white',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  statusBar: {
    height: '20px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 15px',
  },
  header: {
    padding: '15px',
  },
  backArrow: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  headerText: {
    margin: 0,
    fontSize: '16px',
    fontFamily: "Poppins"
  },
  bookItem: {
    padding: '15px',
    borderRadius: '10px',
    marginBottom: '10px',
    height: '150px',
    position: 'relative',
    cursor: 'pointer',
  },
  bookImage: {
    flex: '0 0 auto',
    marginRight: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bookContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center"
  },
  bookName: {
    marginBottom: '5px',
    fontFamily: "Poppins",
    fontSize: "0.95em"
  },
  bookDescription: {
    lineHeight: 1.3,
    fontFamily: "Poppins",
    color: "#464646",
    fontSize: "0.75em"
  },
  playButton: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  classContainer: {
    display: "flex",
    alignItems: 'center',
    padding: "15px",
    gap: "16px"
  },
  classButton: {
    border: "1px solid",
    padding: "0.4em 0.8em",
    borderRadius: "8px",
    fontWeight: '500',
    fontSize: "16px",
    fontFamily: "Roboto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  newTag: {
    position: 'absolute',
    top: '0px',
    right: '-0.8em',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '0.3em 0.6em',
    fontSize: '0.8em',
    fontWeight: '500',
    fontFamily: 'Poppins',
    zIndex: 8,
    border: "1px solid #B6B5B5",
    borderRadius: "4px 4px 0px 4px"
  },
  polImg: {
    position: 'absolute',
    top: '1.35em',
    right: '-0.7em',
    width: "3%",
  },
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    marginTop: '4em',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "white"
  },
  spinnerLoader: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #3498db',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
};

export default SelectBook;
