import { NoBookmarks } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { callGETAPI } from '../../utils/NetworkUtil';
import { useEffect, useState } from 'react';
import NewsListItem from './newsCard';
import { useNavigate } from 'react-router-dom';

export default function Bookmarks() {
  const userData = userStore((state) => state.userData);
  const { colors } = useTheme();
  const [userBookmarkData, setUserBookmarkData] = useState([]);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.user_id) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/user-bookmarks/?user=${userData.user_id}`,
        (response) => {
          if (response.data && Array.isArray(response.data)) {
            setUserBookmarkData(response.data);
          }
        },
        (error) => {
          console.error('API call error:', error);
        }
      );
    }
  }, [userData?.user_id]);


  const handleNewsClick = (data, index) => {
    navigate(`/news/${data.id}`, {
      state: {
        newsItem: {
          id: data.id,
        },
      },
    })
  };

  if (userBookmarkData.length === 0) {
    return <NoBookmarkComp colors={colors} />;
  }

  return (
    <div
      style={{
        padding: '15px',
        overflowY: 'scroll',
        zIndex: 1,
        height: '65vh',
        scrollbarWidth: 'none',
      }}
    >
      {userBookmarkData?.map((data, index) => (
        <NewsListItem
          key={data.id}
          tags={data?.gpt_output?.mains_syllabus || []}
          title={data.title}
          likes={data.likes}
          bookmarks={data.bookmarks}
          imageSrc={data.imageSrc}
          newsItem={data}
          newsType={selectedNews}
          created_at={data.created_at}
          onClick={() => handleNewsClick(data, index)}
        />
      ))}
    </div>
  );
}

const NoBookmarkComp = ({ colors }) => {
  return (
    <div style={{ height: '72vh', backgroundColor: colors.background, overflow :"scroll" }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ width: '80%', maxWidth: '600px', textAlign: 'center' }}>
          <img
            src={NoBookmarks}
            alt="nothing here img"
            style={{ width: '100%', objectFit: 'cover', marginBottom: '1em' }}
          />
          <h2
            style={{
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              marginBottom: '0.5em',
            }}
          >
            Nothing here!
          </h2>
          <p
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '0.9em',
              margin: '0 2em',
              lineHeight: '1.8em',
              color: '#747474',
            }}
          >
            Bookmark your favorite News Summaries for a personalized News Feed.
          </p>
        </div>
      </div>
    </div>
  );
};
