import React, { useState } from 'react';
import { useTheme } from '../../context/themeProvider';
import Bookmarks from './bookmarks';
import MyNotes from './myNotes';

const SavedTab = () => {
    const { colors, colorScheme } = useTheme();
    const windowHeight = window.innerHeight;
    const isResponsiveMobile = windowHeight <= 700;
    const [activeTab, setActiveTab] = useState('bookmarks');
    const isDarkTheme = colorScheme === 'dark'

    return (
        <div
            style={{
                backgroundColor: colors.background,
                color: colors.text,
                height: windowHeight > 900 ? '100vh' : isResponsiveMobile ? '33em' : '45em',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} isDarkTheme={isDarkTheme} />
            {activeTab === 'bookmarks' ? <Bookmarks /> : <MyNotes />}
        </div>
    );
};

const HeaderTab = ({ activeTab, setActiveTab, isDarkTheme }) => {
    return (
        <div style={{
            ...styles.container,
            backgroundColor: isDarkTheme ? '#303034' : '#E8E8E8',
        }}>
            <button
                style={{
                    ...styles.tab,
                    ...(activeTab === 'bookmarks' ? {
                        ...styles.activeTab, backgroundColor: isDarkTheme ? "#57575B" : '#fff',
                        color: isDarkTheme ? "#FFFFFF" : '#070519',

                    } : {}
                    )
                }}
                onClick={() => setActiveTab('bookmarks')}
            >
                Bookmarks
            </button>
            <button
                style={{
                    ...styles.tab,
                    ...(activeTab === 'notes' ? {
                        ...styles.activeTab, backgroundColor: isDarkTheme ? "#57575B" : '#fff',
                        color: isDarkTheme ? "#FFFFFF" : '#070519',
                    } : {}),
                }}
                onClick={() => setActiveTab('notes')}
            >
                My notes
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        gap: '10px',
        padding: '8px',
        borderRadius: '25px',
        margin: "1.3em 0.8em",
    },
    tab: {
        padding: '8px 16px',
        borderRadius: '20px',
        cursor: 'pointer',
        border: 'none',
        fontSize: '14px',
        color: '#747474',
        width: "50%",
        height: "40px",
        fontFamily: "Poppins",
        backgroundColor : "transparent"
    },
    activeTab: {
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
};

export default SavedTab;
