// NewsContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [newsData, setNewsData] = useState([]);
  const [newsDetailData, setNewsDetailData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [readingTime, setReadingTime] = useState(null);
  const [streakCount, setStreakCount] = useState(0); 

  useEffect(() => {
    const savedDate = localStorage.getItem('selectedDate');
    const savedReadingTime = localStorage.getItem('readingTime');
    if (savedDate) setSelectedDate(savedDate);
    if (savedReadingTime) setReadingTime(parseInt(savedReadingTime, 10));
  }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    if (selectedDate) localStorage.setItem('selectedDate', selectedDate);
    if (readingTime) {
      localStorage.setItem('readingTime', readingTime.toString());
    } else {
      localStorage.removeItem('readingTime');
    }
  }, [selectedDate, readingTime]);

  // Function to reset filters
  const resetFilters = () => {
    setSelectedDate(null);
    setReadingTime(null);
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('readingTime');
  };

  return (
    <NewsContext.Provider
      value={{
        newsData, setNewsData,
        newsDetailData, setNewsDetailData,
        selectedDate, setSelectedDate,
        readingTime, setReadingTime,
        resetFilters,
        streakCount, setStreakCount, 
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNews = () => useContext(NewsContext);
