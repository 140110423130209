import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconHeart, IconChevronLeft, IconChevronRight, IconExclamationCircle, IconEyeOff, IconEye, IconCheck, IconX } from '@tabler/icons-react';
import { Button, Text, Group, Badge, Title, Stack, Radio, Textarea } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
// import { useSpring, animated } from 'react-spring';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeftFilled, NoDataImage } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { callGETAPI } from '../../utils/NetworkUtil';
import { getNewsPaperParamForApi } from '../../utils/NewsFeedUtils';
import dayjs from 'dayjs';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useNavigate } from 'react-router-dom';

export default function TodaysQuestions() {
  const { colors } = useTheme();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const containerRef = useRef(null);
  const questionNavRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [mainsQuestions, setMainsQuestions] = useState([]);
  const [prelimsQuestions, setPrelimsQuestions] = useState([]);
  const [activeTab, setActiveTab] = useState('mcqs');
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate();
  const memoizedQuestions = useMemo(() => {
    switch (activeTab) {
      case 'mcqs':
        return mcqQuestions;
      case 'mains':
        return mainsQuestions;
      case 'prelims':
        return prelimsQuestions;
      default:
        return [];
    }
  }, [activeTab, mcqQuestions, mainsQuestions, prelimsQuestions]);

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    if (selectedDate && userData) {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/collatedmcqs/?news_paper=${getNewsPaperParamForApi(
        selectedNews
      )}&date=${selectedDate}&user=${userData.user}`;

      callGETAPI(
        apiUrl,
        (resp) => {
          setMcqQuestions(resp.data.mcqs || []);
          setMainsQuestions(resp.data.pyqs.mains_questions || []);
          setPrelimsQuestions(resp.data.pyqs.prelims_questions || []);
        },
        (err) => {
          console.error('API error:', err);
          setMcqQuestions([]);
          setMainsQuestions([]);
          setPrelimsQuestions([]);
        }
      );
    }
  }, [selectedNews, selectedDate, userData]);

  useEffect(() => {
    setQuestionStates(memoizedQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
    setCurrentQuestionIndex(0);
  }, [memoizedQuestions]);

  useEffect(() => {
    if (questionNavRef.current) {
      const activeQuestion = questionNavRef.current.querySelector(`[data-index="${currentQuestionIndex}"]`);
      if (activeQuestion) {
        activeQuestion.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [currentQuestionIndex]);

  const currentQuestion = memoizedQuestions[currentQuestionIndex];

  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };

  const handleOptionChange = (index) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const discussWithAi = (item, checkedStat) => {
    console.log(item, checkedStat);
    const optionKey = ['a', 'b', 'c', 'd'];
    let tutorConnectParam = {
      assoc_news: currentQuestion.news_summary_id,
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: item.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: item.options[parseInt(checkedStat)],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: [
          {
            a: item.options[0],
          },
          {
            b: item.options[1],
          },
          {
            c: item.options[2],
          },
          {
            d: item.options[3],
          },
        ],
        category: dictMyCategoryName(item.category),
        question: item.question,
        question_id: item.id,
        user_answer: item.options[checkedStat],
        user_option: optionKey[checkedStat],
        correct_answer: optionKey[item.answer],
        options: item.options,
      },
      category: dictMyCategoryName(item.category),
      preSelectAnswer: item.options[parseInt(checkedStat)],
    };
    return tutorConnectParam;
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setShowAnswer(false);
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < memoizedQuestions.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = discussWithAi(currentQuestion, questionStates[currentQuestionIndex].answerIndex);
    navigate(`/chat/mcq/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_mcq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_mcq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (questionId) => {
    setIsOpen(true);
    setSelectedQuestionId(questionId);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };

    if (resportData === 'Other') {
      postPayload.reason = details;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting report:', error);
    }
  };

  const toggleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const formatQuestionText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const ReportModalContent = ({
    isOpen,
    isMobile,
    closeModal,
    isReportSubmited,
    resportData,
    setResportData,
    details,
    onChange,
    handleResportSubmit,
    styles,
    colors,
  }) => {
    if (!isOpen) return null;

    return (
      <div style={styles.modalOverlay}>
        <div style={{ ...styles.modalContainer, maxWidth: isMobile ? '28rem' : '22vw', backgroundColor: colors.cardBackground, color: colors.text }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <span style={styles.topLine} onClick={closeModal}></span>
          </div>
          <button
            onClick={closeModal}
            style={{
              position: 'relative',
              right: '5px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'rgb(0, 0, 0)',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            &times;
          </button>

          {!isReportSubmited ? (
            <>
              <Title fz="h3" m="1em" style={{ textAlign: 'left' }}>
                Report Question
              </Title>
              <Stack spacing="lg">
                <Radio.Group value={resportData} onChange={setResportData} size="1em">
                  {[
                    'Wrong category',
                    'Answer is incorrect',
                    'Incomplete question',
                    'Mistake in question',
                    'Mistake in options',
                    'Poorly formatted question',
                    'Poorly formatted options',
                    'Other',
                  ].map((item, index) => (
                    <Radio key={index} value={item} label={item} p="md" />
                  ))}
                </Radio.Group>

                {resportData === 'Other' && (
                  <>
                    <Text style={{ textAlign: 'left' }}>Other reason*</Text>
                    <Textarea
                      placeholder="Provide details about your issue here..."
                      value={details}
                      rows={4}
                      radius="sm"
                      style={{ marginTop: '10px', fontSize: '14px' }}
                      onChange={onChange}
                    />
                  </>
                )}
              </Stack>

              <Group position="center" mt="lg">
                <Button
                  halfWidth
                  center
                  radius="xl"
                  size="md"
                  variant="filled"
                  color="dark"
                  disabled={!resportData}
                  style={{
                    backgroundColor: resportData ? '#000' : '#ccc',
                    fontWeight: 500,
                    margin: '0 auto',
                    marginBottom: '1rem',
                  }}
                  onClick={handleResportSubmit}
                >
                  Submit Feedback
                </Button>
              </Group>
            </>
          ) : (
            <>
              <Title fz="h3" m="1em">
                Report Submitted
              </Title>
              <Title m="1em" fz="md">
                Thank you! Your report has been submitted.
              </Title>
              <Lottie options={defaultOptions} height={200} width={200} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        ...styles.outerContainer,
        backgroundColor: colors.background,
        color: colors.text,
      }}
    >
      <div
        style={{
          ...styles.topContainer,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        {/* <Tabs value={activeTab} onChange={setActiveTab} style={styles.tabs}>
          <Tabs.List style={styles.tabsList}>
            <Tabs.Tab value="mains" style={activeTab === 'mains' ? styles.activeTab : styles.tab}>
              Mains PYQs
            </Tabs.Tab>
            <Tabs.Tab value="prelims" style={activeTab === 'prelims' ? styles.activeTab : styles.tab}>
              Prelims PYQs
            </Tabs.Tab>
            <Tabs.Tab value="mcqs" style={activeTab === 'mcqs' ? styles.activeTab : styles.tab}>
              MCQs
            </Tabs.Tab>
          </Tabs.List>
        </Tabs> */}

        {memoizedQuestions.length > 0 && (
          <div style={styles.questionNavigation}>
            <img
              src={ArrowLeftFilled}
              style={{
                ...styles.navIcon,
                color: currentQuestionIndex === 0 ? 'gray' : colors.arrowColor,
                cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
              }}
              onClick={handlePreviousQuestion}
              alt="Arrow Left"
            />
            <div style={styles.qNavigation} ref={questionNavRef}>
              {memoizedQuestions.map((_, index) => (
                <Text
                  key={index}
                  data-index={index}
                  style={{
                    ...styles.questionNumber,
                    backgroundColor: index === currentQuestionIndex ? '#EBF3FF' : 'transparent',
                    color: index === currentQuestionIndex ? '#6374FA' : colors.text,
                  }}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </Text>
              ))}
            </div>
            <img
              src={ArrowLeftFilled}
              style={{
                ...styles.navIcon,
                color: currentQuestionIndex !== memoizedQuestions.length - 1 ? colors.arrowColor : 'gray',
                cursor: currentQuestionIndex !== memoizedQuestions.length - 1 ? 'pointer' : 'not-allowed',
                transform: 'rotate(180deg)',
              }}
              onClick={handleNextQuestion}
              alt="Arrow Right"
            />
          </div>
        )}
      </div>

      {memoizedQuestions.length === 0 ? (
        <div
          style={{
            ...styles.noDataContainer,
            color: colors.noDataText,
          }}
        >
          <img src={NoDataImage} alt="no data" style={styles.noDataImage} />
          <Text style={styles.noDataText}>No Related Questions Found</Text>
          <Text style={styles.noDataSubtext}>We were unable to generate any questions for this category. Please try another category or date.</Text>
        </div>
      ) : (
        <>
          <div
            style={{
              ...styles.container,
              backgroundColor: colors.cardBackground,
              border: `1px solid ${colors.border}`,
            }}
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* <animated.div style={{ ...props, ...styles.animatedContainer }}> */}
            <div style={styles.animatedContainer}>
              <div
                style={{
                  ...styles.bottomContainer,
                  backgroundColor: colors.cardBackground,
                  padding: '15px',
                  height: '88%',
                  overflowY: 'auto',
                }}
              >
                <div style={styles.questionMeta}>
                  <Badge color={colors.subtleText}>{activeTab.toUpperCase()}</Badge>
                  <IconHeart style={styles.heartIcon} />
                </div>

                <Text
                  style={{
                    ...styles.questionText,
                    color: colors.text,
                  }}
                >
                  {formatQuestionText(currentQuestion.question)}
                </Text>

                {(activeTab === 'mcqs' || activeTab === 'prelims') && (
                  <div style={styles.optionsContainer}>
                    {currentQuestion.options.map((option, index) => {
                      const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                      const isCorrectAnswer = index === currentQuestion.answer;
                      const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                      return (
                        <div
                          key={index}
                          onClick={() => handleOptionChange(index)}
                          style={{
                            ...styles.optionBox,
                            backgroundColor: isSubmitted
                              ? isCorrectAnswer
                                ? colors.correctAnswer
                                : isSelectedAnswer
                                ? colors.incorrectAnswer
                                : colors.cardBackground
                              : isSelectedAnswer
                              ? colors.highlight
                              : colors.cardBackground,
                            border: isSubmitted
                              ? isCorrectAnswer
                                ? `1px solid ${colors.correctAnswer}`
                                : isSelectedAnswer
                                ? `1px solid ${colors.incorrectAnswer}`
                                : `1px solid ${colors.borderColor}`
                              : isSelectedAnswer
                              ? `1px solid ${colors.coloredButton}`
                              : `1px solid ${colors.borderColor}`,
                          }}
                        >
                          <div style={styles.optionContent}>
                            <div
                              style={{
                                ...styles.radioCircle,
                                backgroundColor: questionStates[currentQuestionIndex]?.isSubmitted
                                  ? index === currentQuestion.answer
                                    ? '#34d399'
                                    : index === questionStates[currentQuestionIndex].answerIndex
                                    ? '#ff9999'
                                    : 'transparent'
                                  : 'transparent',
                                border:
                                  questionStates[currentQuestionIndex]?.isSubmitted &&
                                  (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                                    ? 'none'
                                    : '2px solid #6374FA',
                              }}
                            >
                              {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                                <div style={styles.purpleDot}></div>
                              )}
                              {questionStates[currentQuestionIndex]?.isSubmitted &&
                                (index === currentQuestion.answer ? (
                                  <IconCheck style={styles.icon} />
                                ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                                  <IconX style={styles.icon} />
                                ) : null)}
                            </div>
                            <Text
                              style={{
                                ...styles.optionLabel,
                                color: colors.text,
                              }}
                            >
                              {String.fromCharCode(65 + index)}.
                            </Text>
                            <Text
                              style={{
                                ...styles.optionText,
                                marginLeft: '5px',
                                color: colors.text,
                              }}
                            >
                              {option}
                            </Text>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {activeTab === 'mains' && showAnswer && (
                  <div style={styles.answerContainer}>
                    <Text style={styles.answerText}>{formatQuestionText(currentQuestion.solution)}</Text>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',
                  boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
                  width: '100%',
                  backgroundColor: colors.cardBackground,
                  border: `1px solid ${colors.border}`,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  boxSizing: 'border-box',
                  margin: 0,
                  marginBottom: '5px',
                }}
              >
                <Button
                  variant="subtle"
                  color="red"
                  onClick={() => {
                    handleReport(currentQuestion.id);
                  }}
                >
                  <IconExclamationCircle style={{ marginRight: '0.25rem' }} height={18} width={18} />
                  Report
                </Button>
                {activeTab === 'mains' ? (
                  <Button style={styles.showAnswerButton} onClick={toggleShowAnswer}>
                    {showAnswer ? <IconEyeOff style={styles.eyeIcon} /> : <IconEye style={styles.eyeIcon} />}
                    {showAnswer ? 'Hide Answer' : 'Show Answer'}
                  </Button>
                ) : (activeTab === 'mcqs' || activeTab === 'prelims') && !questionStates[currentQuestionIndex]?.isSubmitted ? (
                  <Button
                    style={{
                      ...styles.submitButton,
                      backgroundColor: questionStates[currentQuestionIndex]?.selectedOption ? '#070519' : '#F3F3F3',
                      color: questionStates[currentQuestionIndex]?.selectedOption ? '#FFFFFF' : '#B9B9B9',
                    }}
                    onClick={handleSubmit}
                    disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button style={styles.discussButton} onClick={handleDiscussWithAi}>
                    Discuss with AI
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <ReportModalContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        styles={styles}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
        colors={colors}
      />
    </div>
  );
}
const isResponsiveMobiles = window.innerHeight <= 900 && window.innerWidth <= 400;

const styles = {
  outerContainer: {
    fontFamily: 'Poppins, Arial, sans-serif',
    color: '#333',
    maxWidth: '600px',
    margin: '0 auto',
    minHeight: '100dvh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    paddingBottom: '80px',
  },
  container: {
    position: 'relative',
    border: '1px solid #E8E8E8',
    margin: '15px',
    height: 'calc(100dvh - 180px)',
    overflow: 'hidden',
  },
  animatedContainer: {
    width: '100%',
    height: '100%',
    willChange: 'transform',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  topContainer: {
    padding: '15px',
    background: 'white',
    boxShadow: '0px 4px 9.2px 0px rgba(0, 0, 0, 0.10)',
  },
  bottomContainer: {
    padding: '15px',
  },
  tabs: {
    marginBottom: '15px',
    border: 'none',
  },
  tabsList: {
    display: 'flex',
    backgroundColor: '#F3F4F6',
    borderRadius: '100px',
    padding: '10px',
    justifyContent: 'space-around',
    border: 'none',
  },
  tab: {
    padding: '10px 16px',
    borderRadius: '100px',
    color: '#6B7280',
    fontWeight: 500,
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    width: '33%',
    fontFamily: 'Poppins, sans-serif',
    border: 'none',
  },
  activeTab: {
    padding: '10px 16px',
    borderRadius: '100px',
    backgroundColor: 'white',
    color: '#111827',
    fontWeight: 500,
    fontSize: '16px',
    cursor: 'pointer',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    width: '33%',
  },
  questionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  qNavigation: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    flex: 1,
    margin: '0 10px',
  },
  navIcon: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #D1D1D1',
    padding: '7px 5px',
    backgroundColor: 'white',
  },
  questionNumber: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    flexShrink: 0,
  },
  questionMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  heartIcon: {
    color: '#888',
    cursor: 'pointer',
  },
  questionText: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '20px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionBox: {
    padding: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  radioCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    transition: 'all 0.3s ease',
  },
  purpleDot: {
    width: '14.5px',
    height: '14.5px',
    backgroundColor: '#6374FA',
    borderRadius: '50%',
  },
  icon: {
    width: '14px',
    height: '14px',
    color: 'white',
  },
  optionLabel: {
    marginRight: '10px',
  },
  optionText: {
    fontSize: '15px',
    fontWeight: '500',
    flex: 1,
    transition: 'color 0.3s ease',
  },
  answerContainer: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
  },
  answerText: {
    fontSize: '14px',
    lineHeight: '1.5',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
  showAnswerButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    backgroundColor: '#333',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  submitButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 25px',
    backgroundColor: '#070519',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  discussButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    color: 'black',
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  discussIcon: {
    marginRight: '5px',
  },
  noDataContainer: {
    textAlign: 'center',
    padding: '20px',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noDataImage: {
    marginBottom: '20px',
  },
  noDataText: {
    fontWeight: 600,
    marginBottom: '10px',
  },
  noDataSubtext: {
    color: '#888',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px 10px 0 0',
    width: '100%',
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
  },
  topLine: {
    height: '6px',
    width: '89px',
    display: 'block',
    background: '#E8E8E8',
    borderRadius: '50px',
    cursor: 'pointer',
  },
};
