import React, { useState } from 'react';
import { Modal, Button, Group, Card, Text, Title, CloseButton } from '@mantine/core';
import { ArrowLeftFilled, ArrowRightFilled, AskBookModule, AskBookStep1, AskBookStep2, AskBookStep3, AskBookStep4 } from '../../../assets/images';

const InfoTips = ({ opened = true, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      imageUrl: AskBookModule,
      step: null,
    },
    {
      imageUrl: AskBookStep1,
      step: 'Step 1',
      description: 'Choose a Book',
    },
    {
      imageUrl: AskBookStep2,
      step: 'Step 2',
      description: 'Ask a Query',
    },
    {
      imageUrl: AskBookStep3,
      step: 'Step 3',
      description: 'Wait for sometime',
    },
    {
      imageUrl: AskBookStep4,
      step: 'Step 4',
      description: 'Get answers with references'
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <>
      <style>
        {`
        body{
          font-family : Poppins
        }
      `}
      </style>
      <Modal
        opened={opened}
        onClose={onClose}
        centered
        radius="12px"
        size="auto"
        zIndex="9999999999"
        withCloseButton={false}
        styles={{
          header: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          },
          title: {
            flexGrow: 1,
            textAlign: 'center',
          },
        }}
        title={
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Title fw={600} order={4} style={{ flexGrow: 1 }}>
              Ask a Book
            </Title>
            <CloseButton
              onClick={onClose}
              style={{
                position: "absolute",
                right: "15px"
              }}
            />
          </div>
        }
      >
        <Card
          radius="md"
          p="lg"
          mt={slides[currentSlide].step ? 'md' : 0}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Conditionally render step header based on currentSlide */}
          {slides[currentSlide].step && (
            <div
              align="center"
              spacing="xs"
              mt="md"
              style={{
                border: '1px solid var(--mantine-color-gray-3)',
                borderRadius: '8px 8px 0px 0px',
                padding: '10px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Title order={4}>{slides[currentSlide].step}</Title>
              <Text size="sm" color="gray">
                {slides[currentSlide].description}
              </Text>
            </div>
          )}
          <img src={slides[currentSlide].imageUrl} alt={`Slide ${currentSlide + 1}`} width="100%" style={{ width: '100%' }} />
        </Card>

        <Group position="center" mt="xl">
          <Button variant="outline" color="#D1D1D1" onClick={prevSlide} style={{ marginRight: 'auto', padding: "10px" }} disabled={currentSlide === 0}>
            <img src={ArrowLeftFilled} alt="Arrow left" style={{ width: '20px' }} />
          </Button>

          <Group style={{ gap: "8px" }}>
            {slides.map((_, index) => (
              <div
                key={index}
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: index === currentSlide ? '#6374FA' : '#E1EBFF',
                }}
              />
            ))}
          </Group>

          {currentSlide < slides.length - 1 ? (
            <Button variant="outline" color="#D1D1D1" onClick={nextSlide} style={{ marginLeft: 'auto', padding: "10px" }}>
              <img src={ArrowRightFilled} alt="Arrow right" style={{ width: '20px' }} />
            </Button>
          ) : (
            <Button
              variant="outline"
              color="white"
              style={{
                marginLeft: 'auto',
                background: '#070519',
                borderRadius: '6px',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: '20px',
                height: '40px'
              }}
              onClick={onClose}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                Check out
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.48226 12.9997C3.60486 12.9997 3.72745 12.9545 3.82424 12.8578L12.8577 3.82576C13.0448 3.63867 13.0448 3.329 12.8577 3.14191C12.6706 2.95481 12.3609 2.95481 12.1737 3.14191L3.14028 12.1739C2.95316 12.361 2.95316 12.6707 3.14028 12.8578C3.23707 12.9545 3.35966 12.9997 3.48226 12.9997Z" fill="#fff" />
                  <path d="M12.5161 10.5933C12.7806 10.5933 13 10.374 13 10.1095V3.48386C13 3.21935 12.7806 3 12.5161 3H5.88937C5.62482 3 5.40544 3.21935 5.40544 3.48386C5.40544 3.74837 5.62482 3.96772 5.88937 3.96772H12.0321V10.1095C12.0321 10.374 12.2515 10.5933 12.5161 10.5933Z" fill="#fff" />
                </svg>
              </div>
            </Button>
          )}
        </Group>
      </Modal>
    </>

  );
};

export default InfoTips;
