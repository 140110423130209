import React from 'react';
import { ComingSoon } from '../assets/images';

const ComingSoonPage = ({ img, description }) => {
  return (
    <div style={{ height: '100vh', backgroundColor: '#fff' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ width: '90%', maxWidth: '600px', textAlign: 'center' }}>
          <img
            src={img ? img : ComingSoon}
            alt="Coming Soon"
            style={{ width: '100%', objectFit: 'cover', marginBottom: '1em' }}
          />
          <h2
            style={{
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              marginBottom: '0.5em',
            }}
          >
            Coming Soon
          </h2>
          <p
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '0.9em',
              margin: '0 2em',
              lineHeight: '1.8em',
              color: '#747474',
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
