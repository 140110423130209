import React, { useEffect, useState } from 'react';
import { Popover, Button, Box } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconChevronDown } from '@tabler/icons-react';
import dayjs from 'dayjs';
import '@mantine/dates/styles.css';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';

export default function CustomDatePicker(props) {
  const { onSetDate = () => {}, apiDate } = props;
  const { colors } = useTheme();
  const { selectedDate, setSelectedDate } = useNews();

  const [opened, setOpened] = useState(false);
  const [displayDate, setDisplayDate] = useState(selectedDate);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(new Date().toISOString().split('T')[0]);
    }
  }, [selectedDate, setSelectedDate]);

  useEffect(() => {
    setDisplayDate(apiDate || selectedDate);
  }, [apiDate, selectedDate]);

  const handleDateChange = (value) => {
    const formattedDate = dayjs(value).format('YYYY-MM-DD');
    if (formattedDate !== selectedDate) {
      setSelectedDate(formattedDate);
      setDisplayDate(formattedDate);
      onSetDate(formattedDate);
      setOpened(false);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', width: 'auto' }}>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position="bottom-start"
        withArrow={false}
        transition="pop-top-left"
        width="100%"
      >
        <Popover.Target>
          <Button
            variant="outline"
            onClick={() => setOpened((o) => !o)}
            rightIcon={<IconChevronDown size={16} color={colors.text} />}
            styles={{
              root: {
                padding: '4px 8px',
                borderRadius: '10px',
                border: `1px solid ${colors.borderColor}`,
                backgroundColor: colors.cardBackground,
                fontSize: '12px',
                fontWeight: 500,
                color: colors.text,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '10rem',
              },
              label: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {displayDate ? dayjs(displayDate).format('DD MMM, YYYY') : 'Select date'}
          </Button>
        </Popover.Target>
        <Popover.Dropdown
          style={{
            padding: '0px',
            borderRadius: '10px',
            zIndex: 1000,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: colors.cardBackground,
            minWidth: '200px',
            maxWidth: '20rem',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box p="sm" style={{ width: '100%' }}>
            <DatePicker
              maxDate={new Date()}
              value={selectedDate ? new Date(selectedDate) : null}
              onChange={handleDateChange}
              inputFormat="YYYY-MM-DD"
              labelFormat="MMMM YYYY"
              clearable={false}
              styles={{
                calendar: {
                  backgroundColor: colors.cardBackground,
                  color: colors.text,
                  width: '100%',
                },
                day: {
                  color: colors.text,
                  '&[data-selected]': {
                    backgroundColor: colors.primary,
                    color: colors.cardBackground,
                  },
                  '&[data-outside]': {
                    color: colors.subtleText,
                  },
                },
                weekday: {
                  color: colors.subtleText,
                },
                month: {
                  color: colors.text,
                },
                calendarHeader: {
                  '& .mantine-DatePicker-calendarHeaderControl': {
                    color: colors.text,
                  },
                  '& .mantine-DatePicker-calendarHeaderLevel': {
                    color: colors.text,
                  },
                },
              }}
            />
          </Box>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}