import React, { useCallback, useState, useEffect } from 'react';
import { useTheme } from '../context/themeProvider';
const UniversalVideoModal = ({ videoUrl, isOpen, onClose, title = "Video" }) => {
  const { colors } = useTheme();
  const [hasError, setHasError] = useState(false);
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);
  const handleVideoEnd = () => {
    closeModal(); // Close modal when the video ends
  };
  const handleVideoError = () => {
    setHasError(true);
  };
  useEffect(() => {
    if (isOpen) {
      setIsTitleVisible(true);
      const timer = setTimeout(() => setIsTitleVisible(false), 3000); // Hide title after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isOpen]);
  if (!isOpen) {
    return null;
  }
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1000,
      overflow: 'hidden'
    }}>
      <div style={{
        position: 'absolute',
        top: '4%',
        left: '50%',
        transform: `translateX(-50%)`,
        animation: isOpen ? 'slide-in 0.5s ease-in-out' : 'none',  // Add simple slide-in animation
        backgroundColor: colors.cardBackground,
        color: colors.text,
        borderRadius: '8px',
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
        width: 'auto',
        maxWidth: '90%',
        zIndex: 1001,
      }}>
        <h2 style={{
          margin: '2px',
          fontSize: '14px',
          fontWeight: '500',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 'calc(100% - 30px)'
        }}>{title}</h2>
        <button
          onClick={closeModal}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: colors.text,
            fontSize: '20px',
            cursor: 'pointer',
            marginLeft: '12px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.2s'
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = colors.hover;
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
          }}
        >
          ✕
        </button>
      </div>
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {hasError ? (
            <div style={{ color: colors.text, textAlign: 'center' }}>
              <p style={{ marginBottom: '10px' }}>Video cannot be loaded in the modal.</p>
              <a
                href={videoUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: colors.text, textDecoration: 'underline' }}
              >
                Click here to watch the video in a new tab
              </a>
            </div>
          ) : (
            <div style={{
              width: '100%',
              height: '100%',
              maxWidth: 'calc(100vh - 60px)', // Subtracting header height
              maxHeight: '100%',
              position: 'relative'
            }}>
              <video
                src={videoUrl}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                controls
                autoPlay
                onEnded={handleVideoEnd} // Close modal when the video ends
                onError={handleVideoError} // Handle video load errors
              >
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
      <style>{`
        @keyframes slide-in {
          0% {
            transform: translateX(30%);
          }
          30% {
            transform: translateX(0%);
          }
        }
      `}</style>
    </div>
  );
};
export default UniversalVideoModal;