import React, { useState, useEffect, useRef } from 'react';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { icons } from '../constants/iconData';
import { ArrowDown } from '../assets/images';

const BottomDrawerSelect = ({ options, onChange, value,width = '100%'  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const dropdownRef = useRef(null);
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth <= 768;

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div style={{ position: 'relative', zIndex: 1000, flex: 1 }} ref={dropdownRef}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={0}
        onClick={toggleDropdown}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          border: focused ? '1px solid blue' : '1px solid #ccc',
          borderRadius: '8px',
          cursor: 'pointer',
          justifyContent: 'space-between',
          width: width,
          height: '36px',
          fontSize: '14px',
          gap: 4,
          outline: 'none',
        }}
      >
        <span
          style={{
            color: !value ? 'gray' : 'black',
            fontFamily : "Poppins"
          }}
        >
          {value ? options.find((opt) => opt.value === value)?.label : options[0].label}
        </span>
        <img src={ArrowDown} alt="Icon" style={{width : "14px" }} />
      </div>

      <div
        style={{
          position: 'fixed',
          bottom: isOpen ? '.5rem' : '-100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? '100%' : '28rem',
          backgroundColor: 'white',
          maxHeight: '55vh',
          overflowY: 'scroll',
          zIndex: 9999,
          boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
          paddingBottom: '20px',
          paddingTop: '10px',
          borderRadius: '10px 10px 0 0',
          transition: 'bottom 0.3s ease-in-out',
        }}
      >
        <div style={{ padding: '16px', textAlign: 'left' }}>
          {options.map((option) => (
            <div
              key={option.value}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                cursor: 'pointer',
                padding : '12px 8px'
              }}
              onClick={() => handleSelect(option.value)}
            >
              {icons[option.value] && <div style={{ marginRight: '8px' }}>{icons[option.value]}</div>}
              <span style={{ marginLeft: '8px', fontFamily : "Poppins" }}>{option.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomDrawerSelect;
